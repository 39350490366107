import * as S from "./styles";
import { IPosts } from "../../../../../../types/media-kit";
import AppImageSelector from "../../../../../AppPostsInstagram/components/AppImageSelector";

interface IProps {
  posts: IPosts[] | undefined;
  onSelectPosts: (item: IPosts) => void;
}

const PostsSelector: React.FC<IProps> = ({ posts, onSelectPosts }) => {
  return (
    <S.Images>
      {posts &&
        posts.map((post) => (
          <AppImageSelector
            key={post.id}
            images={post.thumbnailUrl}
            onClick={() => onSelectPosts(post)}
            isSelected={post.isSelected || false}
          />
        ))}
    </S.Images>
  );
};

export default PostsSelector;
