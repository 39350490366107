import * as S from "./styles";
import P from "../../atoms/Typography/P";
import { TIconOptions } from "../../../types";
import { Theme, Snackbar } from "../../../hooks";
import { IInterval } from "../../../types/calendar";
import AvailableIcons from "../../../components/atoms/AvailableIcons";
import { getWhatsappUrl } from "../../../pages/Calendar/components/Appointment/utils";

export interface IAppointmentProps {
  id: string;
  title: string;
  endAt?: string;
  startAt?: string;
  callUrl?: string;
  interval: IInterval;
  customerName: string;
  customerMail: string;
  customerPhone: string;
  onSyncWithExternalCalendar: () => void;
  onExcludeAppointment: (val: string) => void;
}

const AppointmentItem: React.FC<IAppointmentProps> = ({
  id,
  title,
  callUrl,
  interval,
  customerName,
  customerMail,
  customerPhone,
  onExcludeAppointment,
  onSyncWithExternalCalendar,
}) => {
  const { newSuccess } = Snackbar.useSnackbar();
  const { primaryColor, textColor } = Theme.useTheme();

  const onCopyLinkHandler = () => {
    navigator.clipboard.writeText(callUrl || "");

    newSuccess("Link do Zoom copiado");
  };

  const whatsappUrl = getWhatsappUrl(customerPhone);

  const contacts = [
    { option: "whatsappTwo", href: whatsappUrl, onClick: () => null },
    { option: "email", href: `mailto:${customerMail}`, onClick: () => null },
    { option: "phone", href: `tel:${customerPhone}`, onClick: () => null },
    {
      href: "",
      option: "calendar",
      onClick: onSyncWithExternalCalendar,
    },
  ];

  const appointmentTypeText = callUrl
    ? "Reunião online por zoom"
    : "Reunião presencial";

  return (
    <S.Container>
      <S.AppointmentHeader>
        <S.AppointmentTimeAndName>
          <P color={primaryColor} fontWeight="regular">
            {interval.from} - {interval.to}
          </P>

          <P color={textColor} fontWeight="regular">
            {customerName}
          </P>
        </S.AppointmentTimeAndName>

        {callUrl && (
          <S.LinkIcon
            variant="solid"
            backgroundColor={`${textColor}0d`}
            onClick={() => onCopyLinkHandler()}
          >
            <AvailableIcons option="link" />
          </S.LinkIcon>
        )}
      </S.AppointmentHeader>

      <S.Hr />

      <S.AppointmentDetails>
        <S.Title color={textColor} fontWeight="regular">
          {title}
        </S.Title>

        <S.AppointmentType color={textColor} fontWeight="regular">
          {appointmentTypeText}
        </S.AppointmentType>

        <S.Buttons>
          <S.AppointmentContacts>
            {contacts.map((item) => (
              <S.IconButton
                variant="solid"
                key={item.option}
                backgroundColor="#fafafa0d"
              >
                <S.AnchorTag
                  target="_blank"
                  href={item.href || ""}
                  onClick={item.onClick}
                  rel="noopener noreferrer"
                  as={item.href ? "a" : "span"}
                >
                  <AvailableIcons option={item.option as TIconOptions} />
                </S.AnchorTag>
              </S.IconButton>
            ))}
          </S.AppointmentContacts>

          <S.IconButton
            variant="solid"
            backgroundColor="#fafafa05"
            border="1px solid #fafafa0d"
            onClick={() => onExcludeAppointment(id)}
          >
            <AvailableIcons
              opacity={0.25}
              option="trashCan"
              color={textColor}
            />
          </S.IconButton>
        </S.Buttons>
      </S.AppointmentDetails>
    </S.Container>
  );
};

export default AppointmentItem;
