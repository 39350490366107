import styled, { css } from "styled-components";

type ProfileImageProps = {
  size: "small" | "medium" | "large";
  imageUrl: string;
};

const profileImageSizes = {
  small: () => css`
    width: 26px;
    height: 26px;
  `,
  medium: () => css`
    width: 83px;
    height: 83px;
  `,
  large: () => css`
    width: 123px;
    height: 123px;
  `,
};

export const ProfileImage = styled.div<ProfileImageProps>`
  ${({ size, imageUrl }) => css`
    border-radius: 50%;
    background: ${imageUrl ? `url(${imageUrl})` : "#fafafa"};
    background-size: cover;
    ${profileImageSizes[size]}
  `}
`;

export const PlaceholderContainer = styled.div<Pick<ProfileImageProps, "size">>`
  ${({ size }) => css`
    border-radius: 50%;
    background-color: #fafafa1a;
    display: flex;
    align-items: center;
    justify-content: center;
    ${profileImageSizes[size]}
  `}
`;

const iconSizes = {
  small: () => css`
    width: 70px;
  `,
  medium: () => css`
    width: 160px;
  `,
  large: () => css`
    width: 220px;
  `,
};

type ImageProps = Pick<ProfileImageProps, "size">;

export const Image = styled.div<ImageProps>`
  ${({ size }) => css`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    .background {
      position: absolute;

      svg {
        ${iconSizes[size]}
      }
    }
  `}
`;
