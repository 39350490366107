import styled from "styled-components";
import Box from "../../../../componentsV2/atoms/Box";
import Button from "../../../../componentsV2/atoms/Button";
import SeparatorLine from "../../../../componentsV2/atoms/SeparatorLine";

export const BoxContainer = styled(Box)`
  z-index: 2;
  display: flex;
  margin-top: 32px;
  position: relative;
  flex-direction: column;
`;

export const WorkHoursList = styled.div`
  gap: 4px;
  width: 100%;
  display: flex;
  margin-top: 24px;
  flex-direction: column;
`;

export const WorkHoursText = styled.div`
  z-index: 10;
  display: flex;
  position: relative;
  margin: 40px 0 20px;
  flex-direction: column;

  small {
    font-size: 10px;
    padding-top: 20px;
  }
`;

export const LunchTime = styled.div`
  display: flex;
  flex-direction: column;

  small {
    font-size: 10px;
  }
`;

export const Hr = styled(SeparatorLine)`
  margin: 32px 0;
`;

export const SaveButton = styled(Button)`
  left: 50%;
  z-index: 3;
  bottom: 90px;
  position: fixed;
  max-width: 960px;
  width: calc(100% - 40px);
  transform: translateX(-50%);
`;
