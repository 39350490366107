import { TouchEventHandler, TouchEvent, useRef, useState } from "react";

import * as S from "./styles";
import { Theme } from "../../../../../../hooks";
import PostDraggableCard from "../PostDraggableCard";
import { IPosts } from "../../../../../../types/media-kit";
import H5 from "../../../../../../componentsV2/atoms/Typography/H5";
import DropZone from "../../../../../../components/molecules/DropZone";
import AvailableIcons from "../../../../../../components/atoms/AvailableIcons";
import DragAndDrop from "../../../../../../componentsV2/organisms/DragAndDrop";

interface IProps {
  posts: IPosts[];
  onSave: () => void;
  tiktokText: string;
  saveEnable: boolean;
  instagramText: string;
  onClickTiktok: () => void;
  onClickInstagram: () => void;
  onRemovePost: (post: IPosts) => void;
  setPosts: (items: IPosts[] | ((arg: IPosts[]) => IPosts[])) => void;
}

const AddPost: React.FC<IProps> = ({
  posts,
  onSave,
  setPosts,
  tiktokText,
  saveEnable,
  onRemovePost,
  instagramText,
  onClickTiktok,
  onClickInstagram,
}) => {
  const [hoveredDropZone, setHoveredDropZone] = useState(-1);
  const [isMobileDragOn, setIsMobileDragOn] = useState(false);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const [indexOfItemOnDrag, setIndexOfItemOnDrag] = useState(-1);

  const { primaryColor, textColor, backgroundColor } = Theme.useTheme();

  const linksContainer = useRef<HTMLDivElement>(null);
  const scrollInterval = useRef<NodeJS.Timer | null>(null);

  const touchCancelHandler: TouchEventHandler<HTMLDivElement> = () => {
    if (scrollInterval.current) {
      clearInterval(scrollInterval.current);
      scrollInterval.current = null;
    }

    setIsMobileDragOn(false);
    setIndexOfItemOnDrag(-1);
    setHoveredDropZone(-1);
  };

  const touchStartHandler = (e: TouchEvent<HTMLDivElement>, index: number) => {
    setIndexOfItemOnDrag(index);
    setCoordinates({
      x: e.targetTouches[0].pageX - 20,
      y: e.targetTouches[0].pageY - 80,
    });
    setIsMobileDragOn(true);
  };

  const touchEndHandler: TouchEventHandler<HTMLDivElement> = () => {
    if (scrollInterval.current) {
      clearInterval(scrollInterval.current);
      scrollInterval.current = null;
    }

    setIsMobileDragOn(false);
    setIndexOfItemOnDrag(-1);
    setHoveredDropZone(-1);

    onDropHandler();
  };

  const touchMoveHandler: TouchEventHandler<HTMLDivElement> = (e) => {
    if (!linksContainer.current) return;

    setCoordinates({
      x: e.targetTouches[0].pageX - 20,
      y: e.targetTouches[0].pageY - 80,
    });

    const hoveredIndex = Math.floor(
      (e.targetTouches[0].pageY - (linksContainer.current.offsetTop || 0)) /
        (linksContainer.current.offsetHeight / posts.length)
    );
    const finalHoveredIndex =
      hoveredIndex > indexOfItemOnDrag ? hoveredIndex + 1 : hoveredIndex;

    setHoveredDropZone(finalHoveredIndex);

    if (scrollInterval.current) {
      clearInterval(scrollInterval.current);
      scrollInterval.current = null;
    }

    if (e.targetTouches[0].clientY > window.innerHeight - 60) {
      scrollInterval.current = setInterval(() => {
        window.scrollTo({ top: window.scrollY + 6 });
      }, 20);
    }
    if (e.targetTouches[0].clientY < 60) {
      scrollInterval.current = setInterval(() => {
        window.scrollTo({ top: window.scrollY - 6 });
      }, 20);
    }
  };

  const onDropHandler = () => {
    if (hoveredDropZone <= -1) return;

    setPosts((curr) => {
      const newArr = [...curr];
      const draggedElement = newArr.splice(indexOfItemOnDrag, 1)[0];

      const indexToInsertItem =
        indexOfItemOnDrag < hoveredDropZone
          ? hoveredDropZone - 1
          : hoveredDropZone;

      newArr.splice(indexToInsertItem, 0, draggedElement);
      return newArr;
    });

    setHoveredDropZone(-1);
    setIndexOfItemOnDrag(-1);
  };

  const renderCardsOrText = () => {
    if (posts && posts.length > 0) {
      return (
        <S.Cards ref={linksContainer}>
          <DropZone
            isDroppable
            onDrop={onDropHandler}
            isHovered={hoveredDropZone === 0}
            onDragEnter={() => setHoveredDropZone(0)}
            onDragLeave={() => setHoveredDropZone(-1)}
          />

          {posts.map((post, index) => (
            <DragAndDrop
              onDragEnd={() => null}
              coordinates={coordinates}
              key={`${post.id}#${index}`}
              onDropHandler={onDropHandler}
              touchEndHandler={touchEndHandler}
              touchMoveHandler={touchMoveHandler}
              touchCancelHandler={touchCancelHandler}
              onDragLeave={() => setHoveredDropZone(-1)}
              onDragStart={() => setIndexOfItemOnDrag(index)}
              isDropZoneHovered={index + 1 === hoveredDropZone}
              onDragEnter={() => setHoveredDropZone(index + 1)}
              touchStartHandler={(e) => touchStartHandler(e, index)}
              isMobileDragOn={isMobileDragOn && index === indexOfItemOnDrag}
              draggableItem={
                <PostDraggableCard
                  post={post}
                  onRemove={() => onRemovePost(post)}
                />
              }
            />
          ))}
        </S.Cards>
      );
    } else {
      return (
        <H5 color={`${textColor}80`}>
          Adicione suas publicações em parceria com outras marcas para construir
          seu portfolio de #publis e mostrar seu trabalho para o mundo.
        </H5>
      );
    }
  };

  return (
    <>
      {renderCardsOrText()}

      <S.SocialMediaButtons>
        <S.SocialMediaButton
          variant="solid"
          textColor={backgroundColor}
          backgroundColor={primaryColor}
          onClick={() => onClickInstagram()}
        >
          <S.Icon>
            <AvailableIcons option="instagramTwo" color={backgroundColor} />
          </S.Icon>
          {instagramText} Instagram
        </S.SocialMediaButton>

        <S.SocialMediaButton
          variant="solid"
          textColor={backgroundColor}
          backgroundColor={primaryColor}
          onClick={() => onClickTiktok()}
        >
          <S.Icon>
            <AvailableIcons option="tiktok" color={backgroundColor} />
          </S.Icon>
          {tiktokText} TikTok
        </S.SocialMediaButton>
      </S.SocialMediaButtons>

      {posts && posts.length > 0 && (
        <S.SaveButton
          variant="outline"
          disabled={!saveEnable}
          textColor={primaryColor}
          onClick={() => onSave()}
          borderColor={primaryColor}
        >
          Salvar alterações
        </S.SaveButton>
      )}
    </>
  );
};

export default AddPost;
