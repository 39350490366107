/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  TouchEvent,
  TouchEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import { Instagram } from "../../../../services";
import Page from "../../../../components/atoms/Page";
import { ILinkToPostInstagram } from "../../../../types";
import Button from "../../../../componentsV2/atoms/Button";
import P from "../../../../componentsV2/atoms/Typography/P";
import H4 from "../../../../componentsV2/atoms/Typography/H4";
import DropZone from "../../../../components/molecules/DropZone";
import DragAndDrop from "../../../../componentsV2/organisms/DragAndDrop";
import AvailableIcons from "../../../../components/atoms/AvailableIcons";
import { Auth, Loading, NavBar, Snackbar, Theme } from "../../../../hooks";
import appConfig from "../../../../config/app-link-to-post-instagram.json";
import AppFormHeader from "../../../../componentsV2/organisms/AppFormHeader";
import MediaWithLink from "../../../../componentsV2/molecules/MediaWithLink";
import ExternalLinkSkeleton from "../../../../components/atoms/ExternalLinkSkeleton";

const Home: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [hoveredDropZone, setHoveredDropZone] = useState(-1);
  const [isMobileDragOn, setIsMobileDragOn] = useState(false);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const [indexOfItemOnDrag, setIndexOfItemOnDrag] = useState(-1);
  const [posts, setPosts] = useState<ILinkToPostInstagram[]>([]);
  const [initialPosts, setInitialPosts] = useState<ILinkToPostInstagram[]>([]);

  const hasUnsavedChanges =
    JSON.stringify(initialPosts) !== JSON.stringify(posts);

  const navigate = useNavigate();

  const { token } = Auth.useAuth();
  const { hideNavBar, showNavBar } = NavBar.useNavBar();
  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { hideLoading, showLoading } = Loading.useLoading();
  const { primaryColor, textColor, backgroundColor } = Theme.useTheme();

  useEffect(() => {
    const run = async () => {
      try {
        setLoading(true);
        const { posts } = await Instagram.getPosts(token);

        if (posts && posts.length > 0) {
          setPosts(posts.map((i: any) => ({ ...i })));
          setInitialPosts(posts.map((i: any) => ({ ...i })));
        }
      } catch (error) {
        newError("Houve um erro ao obter os posts");
      } finally {
        setLoading(false);
      }
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasUnsavedChanges) hideNavBar();
    else showNavBar();

    return () => showNavBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUnsavedChanges]);

  const linksContainer = useRef<HTMLDivElement>(null);
  const scrollInterval = useRef<NodeJS.Timer | null>(null);

  const setLinkHandler = (value: string, index: number) => {
    setPosts((curr) => {
      const newPosts = [...curr];

      if (!newPosts[index].link)
        newPosts[index] = { ...newPosts[index], link: value };
      else newPosts[index].link = value || null;

      return newPosts;
    });
  };

  const touchCancelHandler: TouchEventHandler<HTMLDivElement> = () => {
    if (scrollInterval.current) {
      clearInterval(scrollInterval.current);
      scrollInterval.current = null;
    }

    setIsMobileDragOn(false);
    setIndexOfItemOnDrag(-1);
    setHoveredDropZone(-1);
  };

  const touchStartHandler = (e: TouchEvent<HTMLDivElement>, index: number) => {
    setIndexOfItemOnDrag(index);
    setCoordinates({
      x: e.targetTouches[0].pageX - 20,
      y: e.targetTouches[0].pageY - 80,
    });
    setIsMobileDragOn(true);
  };

  const touchEndHandler: TouchEventHandler<HTMLDivElement> = () => {
    if (scrollInterval.current) {
      clearInterval(scrollInterval.current);
      scrollInterval.current = null;
    }

    setIsMobileDragOn(false);
    setIndexOfItemOnDrag(-1);
    setHoveredDropZone(-1);

    onDropHandler();
  };

  const touchMoveHandler: TouchEventHandler<HTMLDivElement> = (e) => {
    if (!linksContainer.current) return;

    setCoordinates({
      x: e.targetTouches[0].pageX - 20,
      y: e.targetTouches[0].pageY - 80,
    });

    const hoveredIndex = Math.floor(
      (e.targetTouches[0].pageY - (linksContainer.current.offsetTop || 0)) /
        (linksContainer.current.offsetHeight / posts.length)
    );
    const finalHoveredIndex =
      hoveredIndex > indexOfItemOnDrag ? hoveredIndex + 1 : hoveredIndex;

    setHoveredDropZone(finalHoveredIndex);

    if (scrollInterval.current) {
      clearInterval(scrollInterval.current);
      scrollInterval.current = null;
    }

    if (e.targetTouches[0].clientY > window.innerHeight - 60) {
      scrollInterval.current = setInterval(() => {
        window.scrollTo({ top: window.scrollY + 6 });
      }, 20);
    }
    if (e.targetTouches[0].clientY < 60) {
      scrollInterval.current = setInterval(() => {
        window.scrollTo({ top: window.scrollY - 6 });
      }, 20);
    }
  };

  const onDropHandler = () => {
    if (hoveredDropZone <= -1) return;

    setPosts((curr) => {
      const newArr = [...curr];
      const draggedElement = newArr.splice(indexOfItemOnDrag, 1)[0];

      const indexToInsertItem =
        indexOfItemOnDrag < hoveredDropZone
          ? hoveredDropZone - 1
          : hoveredDropZone;

      newArr.splice(indexToInsertItem, 0, draggedElement);
      return newArr;
    });

    setHoveredDropZone(-1);
    setIndexOfItemOnDrag(-1);
  };

  const onSaveHandler = async () => {
    showLoading();

    try {
      const data = await Instagram.savePostsSelected(token, posts);

      if (data.posts) {
        setPosts(data.posts);
        setInitialPosts(data.posts);
      } else {
        setPosts([]);
        setInitialPosts([]);
      }

      newSuccess("Alterações salvas com sucesso");
    } catch (error) {
      newError("Houve um erro ao salvar as alterações");
    } finally {
      hideLoading();
    }
  };

  return (
    <Page>
      <AppFormHeader appTitle="Instagram" onBack={() => navigate("/apps")} />

      <S.BoxContainer>
        <H4 color={textColor}>Vincular conta do Instagram</H4>

        {posts && posts.length === 0 && (
          <S.Icons>
            <S.InstagramIcon backgroundColor={textColor}>
              <AvailableIcons option="instagramTwo" color={textColor} />
            </S.InstagramIcon>

            <S.StantiIcon backgroundColor={textColor}>
              <AvailableIcons option="stanti" color={textColor} />
            </S.StantiIcon>
          </S.Icons>
        )}

        <P color={`${textColor}80`}>
          Para exibir posts do Instagram em sua Stanti , clique no botão abaixo
          e autorize o app através do Instagram
        </P>

        <S.ShareButton
          variant="solid"
          textColor={textColor}
          onClick={() => navigate(`/apps/${appConfig.id}/new`)}
        >
          Compartilhar um post do Instagram
        </S.ShareButton>
      </S.BoxContainer>

      <S.LinkedAccount>
        {!loading && posts && posts.length > 0 && (
          <div ref={linksContainer}>
            <DropZone
              isDroppable
              onDrop={onDropHandler}
              isHovered={hoveredDropZone === 0}
              onDragEnter={() => setHoveredDropZone(0)}
              onDragLeave={() => setHoveredDropZone(-1)}
            />
            <div>
              {posts.map((item, index) => (
                <DragAndDrop
                  key={item.id}
                  onDragEnd={() => null}
                  coordinates={coordinates}
                  onDropHandler={onDropHandler}
                  touchEndHandler={touchEndHandler}
                  touchMoveHandler={touchMoveHandler}
                  touchCancelHandler={touchCancelHandler}
                  onDragLeave={() => setHoveredDropZone(-1)}
                  onDragStart={() => setIndexOfItemOnDrag(index)}
                  isDropZoneHovered={index + 1 === hoveredDropZone}
                  onDragEnter={() => setHoveredDropZone(index + 1)}
                  touchStartHandler={(e) => touchStartHandler(e, index)}
                  isMobileDragOn={isMobileDragOn && index === indexOfItemOnDrag}
                  draggableItem={
                    <MediaWithLink
                      index={index || 0}
                      galleryItem={{
                        url: item.url,
                        link: item.link || "",
                      }}
                      setLink={(value: string) => setLinkHandler(value, index)}
                    />
                  }
                />
              ))}
            </div>
          </div>
        )}

        {loading && <ExternalLinkSkeleton />}

        {hasUnsavedChanges && (
          <S.SaveButton>
            <Button
              variant="solid"
              onClick={onSaveHandler}
              textColor={backgroundColor}
              backgroundColor={primaryColor}
            >
              Salvar Alterações
            </Button>
          </S.SaveButton>
        )}
      </S.LinkedAccount>
    </Page>
  );
};

export default Home;
