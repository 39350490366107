import { useNavigate } from "react-router-dom";
import H2 from "../../../../componentsV2/atoms/Typography/H2";

import { Auth } from "../../../../hooks";

import ProfileImage from "../../../../componentsV2/atoms/ProfileImage";
import * as S from "./styles";

const Header: React.FC = () => {
  const { user } = Auth.useAuth();

  const navigate = useNavigate();

  const firstName = user.name?.split(" ")[0];

  return (
    <S.Header>
      <ProfileImage
        hasBackgroundImage={true}
        size="medium"
        imageUrl={user.profileImage as string}
        onClick={() => navigate("/edit")}
      />

      <H2 color="#fafafa">
        Olá, <S.Highlight>{firstName}</S.Highlight>!
      </H2>
    </S.Header>
  );
};

export default Header;
