import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Page from "../../../../components/atoms/Page";
import Box from "../../../../componentsV2/atoms/Box";
import SeparatorLine from "../../../../componentsV2/atoms/SeparatorLine";
import H4 from "../../../../componentsV2/atoms/Typography/H4";
import P from "../../../../componentsV2/atoms/Typography/P";
import InputText from "../../../../componentsV2/molecules/InputText";
import Switch from "../../../../componentsV2/molecules/Switch";
import AppFormFooter from "../../../../componentsV2/organisms/AppFormFooter";
import AppFormHeader from "../../../../componentsV2/organisms/AppFormHeader";
import appConfig from "../../../../config/app-stanti-pay.json";
import { StantiPay, Theme } from "../../../../hooks";
import { IAppProduct } from "../../../../types";

import * as S from "./styles";

const validate = (data: IAppProduct): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.title)
    errors.title = [...(errors.title || []), "Título obrigatório"];

  if (!data.allowPix && !data.allowCreditCard)
    errors.payment = [
      ...(errors.payment || []),
      "Escolha pelo menos um meio de pagamento",
    ];

  return errors;
};

const emptyProduct: IAppProduct = {
  order: 0,
  title: "",
  allowPix: true,
  description: "",
  allowCreditCard: true,
};

const Form = () => {
  const { id } = useParams();
  const isEditingItem = !!id;

  const navigate = useNavigate();

  const [product, setProduct] = useState<IAppProduct>(emptyProduct);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

  const { textColor, primaryColor } = Theme.useTheme();

  const { getProduct, saveProductHandler, removeProductHandler } =
    StantiPay.useStantiPay();

  useEffect(() => {
    const run = async () => {
      if (!id) return;

      const productData = await getProduct(id);
      !!productData && setProduct(productData);
    };

    run();
  }, [getProduct, id]);

  const onSave = useCallback(() => {
    const currErrors = validate(product);

    if (currErrors && Object.keys(currErrors).length) {
      setErrors(currErrors);

      window.scrollTo({ top: 0, behavior: "smooth" });
      return alert(
        "O cadastro possui erros, por favor verifique os campos para continuar"
      );
    }

    saveProductHandler(product, id);
  }, [id, saveProductHandler, product]);

  const onChangeHandler = (value: any, field: keyof IAppProduct) => {
    if (errors[field])
      setErrors((curr) => {
        const newErrors = { ...curr };
        delete newErrors[field];

        return newErrors;
      });

    setProduct((curr) => ({ ...curr, [field]: value }));
  };

  return (
    <Page bgColor="#080808">
      <AppFormHeader
        appTitle={appConfig.title}
        onBack={() => navigate(`/apps/${appConfig.id}`)}
      />

      <Box>
        <H4 color={textColor}>Produto/serviço</H4>

        <InputText
          label="Título do produto/serviço"
          onChange={(val) => onChangeHandler(val, "title")}
          value={product.title || ""}
          limit={true}
          charLimit={50}
          placeholder="Qual o título do seu produto/serviço?"
        />

        {errors.title && errors.title.length > 0 && (
          <S.Error color="#F94144">Erros: {errors.title.join(", ")}</S.Error>
        )}

        <SeparatorLine opacity={0.25} />

        <InputText
          label="Descrição (opicional)"
          onChange={(val) => onChangeHandler(val, "description")}
          value={product.description || ""}
          limit={true}
          charLimit={300}
          as="textarea"
          placeholder="Adicione uma descrição opcional ao seu produto/serviço..."
        />
      </Box>

      <Box>
        <H4 color={primaryColor}>Configurações de pagamento</H4>

        <S.SwitchAndText>
          <Switch
            label="Aceitar pagamento via pix"
            isOn={!!product.allowPix}
            setIsOn={() => onChangeHandler(!product.allowPix, "allowPix")}
          />
          <P color={`${textColor}80`}>Taxa - 2,99% (Mercado Pago + Stanti)</P>
        </S.SwitchAndText>

        <SeparatorLine opacity={0.25} />

        <S.SwitchAndText>
          <Switch
            label="Aceitar pagamento via cartão"
            isOn={!!product.allowCreditCard}
            setIsOn={() =>
              onChangeHandler(!product.allowCreditCard, "allowCreditCard")
            }
          />
          <P color={`${textColor}80`}>Taxa - 6,99% (Mercado Pago + Stanti)</P>
        </S.SwitchAndText>

        {errors.payment && errors.payment.length > 0 && (
          <S.Error color="#F94144">Erros: {errors.payment.join(", ")}</S.Error>
        )}
      </Box>

      <AppFormFooter
        onSave={onSave}
        saveDraft={false}
        appTitle="produto/serviço"
        isEditingItem={isEditingItem}
        onRemove={() => id && removeProductHandler(id)}
      />
    </Page>
  );
};

export default Form;
