import Small from "../../../../components/atoms/Typography/Small";

import { capitalizeFirstLetter } from "../../../../utils/capitalizeLetter";

import * as S from "./styles";
import InputText from "../../../../componentsV2/molecules/InputText";

interface IProps {
  value: string;
  socialMedia: string;
  errors?: string[];
  onChange: (val: string) => void;
}

const AppFormSocialMedia: React.FC<IProps> = ({
  onChange,
  value,
  errors,
  socialMedia,
}) => {
  const shouldBeCompleteLink = [
    "linkedin",
    "youtube",
    "beatport",
    "vimeo",
  ].includes(socialMedia);

  const isWhatsapp = socialMedia === "whatsapp";
  const isEmail = socialMedia === "email";

  const getLabel = (socialMedia: string) => {
    if (shouldBeCompleteLink) return "Cole aqui o link da sua página ou perfil";

    if (isWhatsapp)
      return `Digite seu número do ${capitalizeFirstLetter(socialMedia) || ""}`;

    if (isEmail) return "Digite seu e-mail";

    return `Nome de usuário do ${capitalizeFirstLetter(socialMedia) || ""}`;
  };

  const getPlaceholder = (socialMedia: string) => {
    if (shouldBeCompleteLink)
      return `Cole seu link do ${capitalizeFirstLetter(socialMedia) || ""}`;

    if (isWhatsapp) return "Insira seu telefone incluindo DDI e DDD";

    if (isEmail) return "Insira seu e-mail completo";

    return "Digite seu nome de usuário (@)";
  };

  return (
    <S.SocialMediaContainer>
      <InputText
        label={getLabel(socialMedia)}
        value={value}
        onChange={onChange}
        placeholder={getPlaceholder(socialMedia)}
      />

      {errors && errors.length && (
        <Small color="#FF4D4F">Erros: {errors.join(", ")}</Small>
      )}
    </S.SocialMediaContainer>
  );
};

export default AppFormSocialMedia;
