import styled, { css } from "styled-components";

import Button from "../../../../../../componentsV2/atoms/Button";

export const SocialMediaButtons = styled.div`
  gap: 12px;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
`;

type SocialMediaButtonProps = {
  textColor: string;
};

export const SocialMediaButton = styled(Button)<SocialMediaButtonProps>`
  ${({ textColor }) => css`
    gap: 12px;
    display: flex;
    color: ${textColor};
    align-items: center;
    flex-direction: row;
    justify-content: center;
  `}
`;

export const Icon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Cards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SaveButton = styled(Button)`
  margin-top: -12px;
`;
