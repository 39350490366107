import { Theme } from "../../../hooks";
import H5 from "../Typography/H5";

import * as S from "./styles";

interface IProps {
  message: string;
  isVisible: boolean;
  onClick: () => void;
  type: "error" | "info" | "success" | "warning";
}

const Snackbar: React.FC<IProps> = ({ type, message, onClick, isVisible }) => {
  const { textColor } = Theme.useTheme();

  if (!isVisible) return null;

  const color = {
    error: "#F94144",
    success: "#86C257",
    info: "#4285C1",
    warning: "#F99B29",
  };

  return (
    <S.Snackbar onClick={onClick}>
      <S.Container backgroundColor={color[type]}>
        <H5 color={textColor}>{message}</H5>
      </S.Container>
    </S.Snackbar>
  );
};

export default Snackbar;
