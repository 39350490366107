import styled from "styled-components";
import Small from "../../atoms/Typography/Small";

export const Container = styled.div`
  display: flex;
  gap: 4px;

  span {
    color: #fafafa;
  }

  input {
    width: 3rem;
    text-align: center;
  }
`;

export const Hyphen = styled.span`
  padding-left: 0.65rem;
`;

export const Slash = styled.span`
  padding-right: 0.65rem;
`;

export const ErrorMessage = styled(Small)`
  font-size: 0.75rem;
`;
