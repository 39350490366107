import styled, { css } from "styled-components";

export const Container = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

type OptionBoxProps = {
  backgroundColor: string;
};

export const OptionBox = styled.div<OptionBoxProps>`
  ${({ backgroundColor }) => css`
    gap: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding: 20px 20px 20px 24px;
    background-color: ${backgroundColor};
  `}
`;

type SelectorProps = {
  isSelected?: boolean;
  backgroundIn?: string;
  backgroundOut?: string;
};

export const SelectorOut = styled.div<SelectorProps>`
  ${({ backgroundOut }) => css`
    width: 16px;
    height: 16px;
    padding: 4px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundOut};
  `}
`;

export const SelectorIn = styled.div<SelectorProps>`
  ${({ isSelected, backgroundIn }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 50%;
    background-color: ${isSelected && backgroundIn};
  `}
`;
