import { Theme } from "../../../hooks";
import Button from "../../atoms/Button";

import * as S from "./styles";

interface IProps {
  appTitle: string;
  saveDraft: boolean;
  onRemove: () => void;
  isEditingItem: boolean;
  onSave: (isDraft: boolean) => void;
}

const AppFormFooter: React.FC<IProps> = ({
  onSave,
  appTitle,
  onRemove,
  saveDraft,
  isEditingItem,
}) => {
  const { primaryColor, textColor } = Theme.useTheme();

  return (
    <S.Container>
      <Button
        variant="outline"
        borderColor={primaryColor}
        textColor={primaryColor}
        onClick={() => onSave(false)}
      >
        Publicar {appTitle}
      </Button>

      {saveDraft && (
        <Button
          variant="solid"
          borderColor={primaryColor}
          textColor={textColor}
          onClick={() => onSave(true)}
        >
          Salvar como rascunho
        </Button>
      )}

      {isEditingItem && (
        <S.RemoveItem onClick={onRemove} textColor="#F94144">
          Apagar {appTitle}
        </S.RemoveItem>
      )}
    </S.Container>
  );
};

export default AppFormFooter;
