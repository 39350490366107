import EditIcon from "../../../components/icons/EditIcon";
import InputBase from "../../atoms/InputBase";
import * as S from "./styles";

type InputPriceProps = {
  value: string;
  errors?: string[];
  onChange: (value: string) => void;
};

const InputPrice: React.FC<InputPriceProps> = ({ value, errors, onChange }) => {
  const onChangeHander = (value: string) => {
    const onlyNumbersValue = value.replace(/[^0-9]/g, "");

    if (!onlyNumbersValue.length) return onChange("0");

    onChange(onlyNumbersValue);
  };

  return (
    <S.Container>
      <S.InputPriceWrapper>
        <S.Money>R$</S.Money>

        <InputBase
          id="price"
          value={value}
          onChange={({ target }) => onChangeHander(target.value)}
          placeholder="0,00"
        />

        <label htmlFor="price">
          <EditIcon />
        </label>
      </S.InputPriceWrapper>

      {errors && errors.length ? (
        <S.ErrorMessage color="#FF4D4F">
          Erros: {errors.join(", ")}
        </S.ErrorMessage>
      ) : null}
    </S.Container>
  );
};

export default InputPrice;
