import styled from "styled-components";
import ProfileImagePlaceholder from "../../../components/icons/ProfileImagePlaceholder";

export const EditableProfileImage = styled.div`
  display: flex;
  width: 170px;
  position: relative;

  button {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 40px;
    cursor: pointer;

    input[type="file"] {
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: pointer;
      top: 0;
      right: 0;
      opacity: 0;
    }
  }
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;
  margin-left: -70px;
`;

export const ImagePlaceholder = styled(ProfileImagePlaceholder)`
  margin-top: 12px;
  margin-left: -4px;
`;
