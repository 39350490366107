import styled, { css } from "styled-components";

import Box from "../../atoms/Box";
import Small from "../../atoms/Typography/Small";

type ImageContentProps = {
  backgroundColor: string;
};

export const ImageContent = styled.div<ImageContentProps>`
  ${({ backgroundColor }) => css`
    gap: 16px;
    width: 100%;
    padding: 16px;
    display: flex;
    border-radius: 12px;
    flex-direction: column;
    align-items: flex-start;
    background: ${backgroundColor && `${backgroundColor}0a`};
  `}
`;

export const TextImageAndClose = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
`;

export const TextAndImage = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Label = styled(Small)`
  font-size: 14px;
  letter-spacing: 0;
`;

type ImageProps = {
  imageUrl: string;
};

export const Image = styled.div<ImageProps>`
  ${({ imageUrl }) => css`
    width: 40px;
    height: 40px;
    border-radius: 8px;
    align-self: flex-end;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${imageUrl});
  `}
`;

export const CloseIcon = styled.div`
  right: 0;
  top: 4px;
  width: 12px;
  height: 12px;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Link = styled(Box)`
  margin-top: 0;
  flex-direction: row;
  align-items: center;

  svg {
    width: 23px;
    height: auto;
  }
`;
