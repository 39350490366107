import { PropsWithChildren } from "react";

import { Theme } from "../../../hooks";
import CreatorImageBackground from "../CreatorImageBackground";
import * as S from "./styles";

interface IProps {
  bgColor?: string;
}

const Page: React.FC<PropsWithChildren<IProps>> = ({ bgColor, children }) => {
  const { backgroundColor } = Theme.useTheme();

  return (
    <S.Page backgroundColor={bgColor || backgroundColor}>
      {!bgColor ? <CreatorImageBackground /> : null}
      <S.Container>{children}</S.Container>
    </S.Page>
  );
};

export default Page;
