import { useEffect, useState } from "react";

import * as S from "./styles";
import {
  getCalendarPayload,
  getLunchTimePayload,
  normalizeSchedules,
} from "../../utils";
import {
  validateDaysWithError,
  validateLunchTime,
} from "../../../../utils/dates";
import { Calendar } from "../../../../services";
import { emptyCalendar, emptyLunchTime } from "./utils";
import { workDayLabel } from "../../../../types/calendar";
import { Auth, Loading, Snackbar, Theme } from "../../../../hooks";
import TitleDescription from "../../../../componentsV2/molecules/TitleDescription";
import CalendarDateInput from "../../../../componentsV2/molecules/CalendarDateInput";

const WorkAndLunchHours: React.FC = () => {
  const [schedule, setSchedule] = useState(emptyCalendar);
  const [lunchTime, setLunchTime] = useState(emptyLunchTime);
  const [initialSchedule, setInitialSchedule] = useState(emptyCalendar);
  const [initialLunchTime, setInitialLunchTime] = useState(emptyLunchTime);

  const [errors, setErros] = useState<{ [key: string]: string[] }>({});
  const [errorsLunchTime, setErrorsLunchTime] = useState<string[]>([]);

  const { token } = Auth.useAuth();
  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { showLoading, hideLoading, isLoading } = Loading.useLoading();
  const { textColor, primaryColor, backgroundColor } = Theme.useTheme();

  useEffect(() => {
    const run = async () => {
      try {
        showLoading();

        const [calendar, lunchBlock] = await Promise.all([
          Calendar.getCalendar(token),
          Calendar.getLunchTimeBlock(token),
        ]);

        if (calendar && calendar.schedules && calendar.schedules.length) {
          setSchedule((curr) => ({
            ...curr,
            ...normalizeSchedules(calendar.schedules),
          }));
          setInitialSchedule((curr) => ({
            ...curr,
            ...normalizeSchedules(calendar.schedules),
          }));
        }

        if (!lunchBlock) return;

        setLunchTime({
          isSelected: true,
          to: lunchBlock.intervals.monday[0].to,
          from: lunchBlock.intervals.monday[0].from,
        });

        setInitialLunchTime({
          isSelected: true,
          to: lunchBlock.intervals.monday[0].to,
          from: lunchBlock.intervals.monday[0].from,
        });

        return;
      } catch (error) {
        newError("Houve um erro ao obter a agenda");
      } finally {
        hideLoading();
      }
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const weekDays = Object.keys(schedule) as Array<keyof typeof schedule>;

  const hasUnsavedWorkHours =
    JSON.stringify(initialSchedule) !== JSON.stringify(schedule);

  const hasUnsavedLunchTime =
    JSON.stringify(lunchTime) !== JSON.stringify(initialLunchTime);

  const hasUnsavedChanges = hasUnsavedWorkHours || hasUnsavedLunchTime;

  const onChangeSchedule = (
    weekDay: keyof typeof schedule,
    attribute: "from" | "to" | "isSelected",
    value: string | boolean
  ) => {
    setSchedule((curr) => {
      const newSchedule = { ...curr };

      if (attribute === "isSelected" || typeof value === "boolean") {
        newSchedule[weekDay] = {
          isSelected: value as boolean,
          to: "",
          from: "",
        };

        return newSchedule;
      }

      newSchedule[weekDay][attribute] = value;

      return newSchedule;
    });
  };

  const onChangeLunchTime = (attribute: string, value: string | boolean) => {
    setLunchTime((curr) => ({ ...curr, [attribute]: value }));

    if (attribute === "isSelected" && value === false) {
      setLunchTime({ to: "", from: "", [attribute]: value });
    }
  };

  const onSaveWorkHours = async () => {
    showLoading();

    try {
      setErrorsLunchTime([]);

      const currErrors = validateDaysWithError(weekDays, schedule);
      setErros(currErrors);

      const daysWithError = Object.keys(currErrors);

      const currLunchTimeErrors = validateLunchTime(lunchTime);
      setErrorsLunchTime(currLunchTimeErrors);

      if (daysWithError.length > 0 || currLunchTimeErrors.length > 0) return;

      const calendarData = await Calendar.upsertCalendar(
        getCalendarPayload(schedule),
        token
      );

      const normalizedSchedules = normalizeSchedules(calendarData.schedules);

      setSchedule({ ...emptyCalendar, ...normalizedSchedules });
      setInitialSchedule({ ...emptyCalendar, ...normalizedSchedules });

      if (lunchTime.isSelected) {
        const lunchTimeData = await Calendar.createBlock(
          getLunchTimePayload(
            lunchTime,
            calendarData?.schedules.map(({ type }) => type),
            calendarData.id
          ),
          token
        );

        setLunchTime({
          isSelected: true,
          to: lunchTimeData.intervals.monday[0].to,
          from: lunchTimeData.intervals.monday[0].from,
        });

        setInitialLunchTime({
          isSelected: true,
          to: lunchTimeData.intervals.monday[0].to,
          from: lunchTimeData.intervals.monday[0].from,
        });
      } else {
        await Calendar.deleteLunchTimeBlock(token);

        setLunchTime(emptyLunchTime);
        setInitialLunchTime(emptyLunchTime);
      }

      newSuccess("Calendário salvo com sucesso");

      window.location.reload();
    } catch (error) {
      newError("Houve um erro ao atualizar o calendário");
    } finally {
      hideLoading();
    }
  };

  return (
    <S.BoxContainer>
      <TitleDescription
        titleColor={textColor}
        title="Qual seu horário de trabalho?"
        textColor={`${textColor}80`}
      >
        Sua disponibilidade será exibida para seus clientes ao realizar um
        agendamento online com você, de acordo com seu horário de trabalho.
      </TitleDescription>

      <S.WorkHoursList>
        {weekDays.map((key) => {
          return (
            <CalendarDateInput
              key={key}
              to={schedule[key].to}
              from={schedule[key].from}
              label={workDayLabel[key]}
              errors={errors[key] || ""}
              isSelected={schedule[key].isSelected}
              onChangeTo={(to) => onChangeSchedule(key, "to", to)}
              onChangeFrom={(from) => onChangeSchedule(key, "from", from)}
              onSelect={(isSelected) =>
                onChangeSchedule(key, "isSelected", isSelected)
              }
            />
          );
        })}
      </S.WorkHoursList>

      <S.Hr opacity={0.25} />

      <S.LunchTime>
        <TitleDescription
          titleColor={textColor}
          title="Horário de Almoço"
          textColor={`${textColor}80`}
        >
          Utilize o espaço abaixo caso deseje adicionar um bloqueio para seu
          horário de almoço em todos os dias. Atenção: Seus serviços não
          permitirão agendamentos neste intervalo de tempo.
        </TitleDescription>

        <div style={{ margin: "24px 0 12px" }}>
          <CalendarDateInput
            label="Almoço"
            to={lunchTime.to}
            from={lunchTime.from}
            errors={errorsLunchTime}
            isSelected={lunchTime.isSelected}
            onChangeTo={(to) => onChangeLunchTime("to", to)}
            onChangeFrom={(from) => onChangeLunchTime("from", from)}
            onSelect={(isSelected) =>
              onChangeLunchTime("isSelected", isSelected)
            }
          />
        </div>
      </S.LunchTime>

      {hasUnsavedChanges && (
        <S.SaveButton
          variant="solid"
          disabled={isLoading}
          textColor={backgroundColor}
          backgroundColor={primaryColor}
          onClick={() => onSaveWorkHours()}
        >
          Salvar Horários de trabalho
        </S.SaveButton>
      )}
    </S.BoxContainer>
  );
};

export default WorkAndLunchHours;
