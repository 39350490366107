import styled from "styled-components";
import H2 from "../../../componentsV2/atoms/Typography/H2";

export const Header = styled.header`
  z-index: 2;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const BackButton = styled.div`
  left: 0;
  width: 8px;
  height: 12px;
  display: flex;
  position: absolute;
  align-items: center;
  transform: rotate(180deg);

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Text = styled(H2)`
  max-width: 240px;
  text-align: center;
`;
