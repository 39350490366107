import styled from "styled-components";
import Button from "../../../components/atoms/Button";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  max-width: 960px;
  flex-direction: column;
  z-index: 10;
`;

export const RegisterFormHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .stantiLogo {
    svg {
      height: 42px;
      width: auto;
      align-self: flex-start;
    }
  }
`;

export const ProgressBar = styled.div`
  gap: 18px;
  display: flex;
  margin-top: 2.5rem;
  flex-direction: column;
`;

export const BackButton = styled(Button)`
  color: #fafafa !important;
`;

export const Footer = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
`;
