import styled, { css } from "styled-components";
import { Theme } from "../../../hooks";
import Box from "../../atoms/Box";
import Small from "../../atoms/Typography/Small";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const InputPriceWrapper = styled(Box)`
  position: relative;
  display: flex;
  justify-content: center;
  gap: 0px;
  margin-top: 0px;

  svg {
    position: absolute;
    top: 1.4rem;
    right: 1rem;
  }

  input {
    flex-grow: 1;
    text-align: center;
  }
`;

export const Money = styled.span`
  ${() => {
    const { primaryColor } = Theme.useTheme();

    return css`
      width: 3.5rem;
      height: 100%;
      background-color: #fafafa05;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0.75rem;
      color: ${primaryColor};
    `;
  }}
`;

export const ErrorMessage = styled(Small)`
  font-size: 0.75rem;
`;
