import * as S from "./styles";
import { Auth, Theme } from "../../../../hooks";
import { Analytics } from "../../../../services";
import { IAppointments } from "../../../../types/calendar";
import P from "../../../../componentsV2/atoms/Typography/P";
import H3 from "../../../../componentsV2/atoms/Typography/H3";
import ICloud from "../../../../components/assets/Icloud_icon.png";
import Outlook from "../../../../components/assets/Outlook_icon.png";
import AvailableIcons from "../../../../components/atoms/AvailableIcons";
import ModalBluredBackdrop from "../../../../components/atoms/ModalBluredBackdrop";
import GoogleCalendar from "../../../../components/assets/Google_Calendar_icon.png";
import {
  getAppleICalendarUrl,
  getGoogleCalendarUrl,
  getOutlookAgendaUrl,
} from "./utils";

interface IProps {
  isOpen: boolean;
  onCloseModal: () => void;
  appointment: IAppointments | null;
}

const CalendarAppointmentModal: React.FC<IProps> = ({
  isOpen,
  appointment,
  onCloseModal,
}) => {
  const { user } = Auth.useAuth();
  const { primaryColor, textColor, backgroundColor } = Theme.useTheme();

  const onClickHandler = (type: string) => {
    Analytics.submitClickEvent({
      origin: "calendar",
      meta: { type: type },
      creator: user.id || "",
      creatorUsername: user.username || "",
      description: "appointment-to-external-email",
      actionDescription: `appointment-to-external-email-${type}`,
    });
  };

  if (!isOpen) return null;

  const googleUrl = getGoogleCalendarUrl({
    location: appointment?.callUrl,
    endAt: appointment?.endAt || "",
    startAt: appointment?.startAt || "",
    productTitle: appointment?.title || "",
    customerName: appointment?.customerName || "",
  });

  const outlookUrl = getOutlookAgendaUrl({
    location: appointment?.callUrl,
    endAt: appointment?.endAt || "",
    startAt: appointment?.startAt || "",
    productTitle: appointment?.title || "",
    customerName: appointment?.customerName || "",
  });

  const iCalendarUrl = getAppleICalendarUrl({
    location: appointment?.callUrl,
    endAt: appointment?.endAt || "",
    startAt: appointment?.startAt || "",
    productTitle: appointment?.title || "",
    customerName: appointment?.customerName || "",
  });

  return (
    <ModalBluredBackdrop onClickOutside={onCloseModal}>
      <S.Container
        borderColor={primaryColor}
        backgroundColor={backgroundColor}
        onClick={(evt) => evt.stopPropagation()}
      >
        <S.Icon backgroundColor={`${textColor}1a`}>
          <AvailableIcons option="calendar" color={primaryColor} />
        </S.Icon>

        <S.Texts>
          <H3 color={textColor}>Adicionar no seu calendário</H3>

          <S.Subtitle color={primaryColor}>
            Você pode adicionar eventos da sua agenda Stanti no seu calendário
            particular!
          </S.Subtitle>

          <S.ChooseCalendarText color={textColor}>
            Escolha abaixo a qual calendário deseja adicionar este evento:
          </S.ChooseCalendarText>
        </S.Texts>

        <S.Calendars>
          <S.Calendar
            target="_blank"
            href={outlookUrl}
            rel="noopener noreferrer"
            onClick={() => onClickHandler("outlook")}
          >
            <S.CalendarIcon backgroundColor={`${textColor}1a`}>
              <img src={Outlook} />
            </S.CalendarIcon>

            <P color={textColor}>Calendário do Outlook</P>
          </S.Calendar>

          <S.Calendar
            target="_blank"
            href={googleUrl}
            rel="noopener noreferrer"
            onClick={() => onClickHandler("google-agenda")}
          >
            <S.CalendarIcon backgroundColor={`${textColor}1a`}>
              <img src={GoogleCalendar} />
            </S.CalendarIcon>

            <P color={textColor}>Google Agenda</P>
          </S.Calendar>

          <S.Calendar
            target="_blank"
            href={iCalendarUrl}
            rel="noopener noreferrer"
            onClick={() => onClickHandler("icloud")}
            download={`${appointment?.customerName}-${appointment?.title}.ics`
              .toLowerCase()
              .replace(/[^a-z0-9-.]/g, "")}
          >
            <S.CalendarIcon backgroundColor={`${textColor}1a`}>
              <img src={ICloud} />
            </S.CalendarIcon>

            <P color={textColor}>Calendário do iCloud</P>
          </S.Calendar>
        </S.Calendars>

        <S.WarningText color={textColor}>
          <S.Atention color={textColor} fontWeight="600">
            Atenção:
          </S.Atention>{" "}
          Excluir um evento da sua agenda Stanti não removerá o evento do seu
          calendário particular
        </S.WarningText>
      </S.Container>
    </ModalBluredBackdrop>
  );
};

export default CalendarAppointmentModal;
