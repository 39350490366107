import styled from "styled-components";
import Button from "../../../../componentsV2/atoms/Button";
import P from "../../../../componentsV2/atoms/Typography/P";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Container = styled.div`
  z-index: 10;
  width: 100%;
  flex-grow: 1;
  display: flex;
  max-width: 960px;
  flex-direction: column;
`;

export const QualificationStepProgressBar = styled.div`
  gap: 18px;
  display: flex;
  padding-top: 80px;
  flex-direction: column;
`;

export const Back = styled(Button)`
  svg {
    transform: scale(-1);
  }
`;

export const RegisterFormHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProgressBar = styled.div`
  gap: 18px;
  display: flex;
  margin-top: 2.5rem;
  flex-direction: column;
`;

export const Text = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: column;

  span {
    font-weight: 400;
  }
`;

export const Title = styled(P)`
  font-size: 1.5rem;
`;

export const Subtitle = styled(P)`
  margin-top: 1.5rem;
  font-size: 1rem;
`;

export const Highlight = styled.span`
  font-weight: 500;
`;

export const Icon = styled.div`
  z-index: 100;
  display: flex;
  position: relative;
  margin: 40px 0 16px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const CallToAction = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  small {
    font-size: 10px;
    font-weight: 275;
    line-height: 15px;
    font-style: normal;

    &:first-of-type {
      font-weight: 300;
    }
  }

  p {
    font-size: 16px;
    font-weight: 275;
    max-width: 220px;
    line-height: 24px;
    font-style: normal;
    text-align: center;
  }
`;

export const Answers = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const NextButton = styled(Button)`
  margin-top: 20px;
`;
