import AvailableIcons from "../../../components/atoms/AvailableIcons";
import { TIconOptions } from "../../../types";

import * as S from "./styles";

interface IProps {
  uploadedImageUrl: string;
  selectedImage: TIconOptions | "";
}

const ImagePreview: React.FC<IProps> = ({
  selectedImage,
  uploadedImageUrl,
}) => {
  return (
    <S.ImagePreview imageUrl={uploadedImageUrl}>
      {!uploadedImageUrl && (
        <S.Icon>
          {selectedImage ? (
            <AvailableIcons option={selectedImage} />
          ) : (
            <S.StantiIconWithMargin color="#fafafa40" />
          )}
        </S.Icon>
      )}
    </S.ImagePreview>
  );
};

export default ImagePreview;
