import { Snackbar } from "../../../../hooks";
import { ICreatorData, TCategories } from "../../../../types";
import InputSelect from "../../../../componentsV2/molecules/InputSelect";

const options = [
  "Selecionar interesses",
  "Beleza",
  "eSports",
  "Fitness",
  "Fotografia",
  "Lifestyle",
  "Moda",
  "Arte",
  "Culinária",
  "Espiritualidade",
  "Finanças",
  "Música",
  "Tecnologia",
  "Livros",
  "Negócios",
  "Dança",
  "Esportes",
  "Games",
  "Saúde",
  "Viagem",
  "Entretenimento",
  "Empreendedorismo",
  "Tattoo",
  "Nutrição",
  "Pet",
  "Maquiagem",
  "Criatividade",
];

interface IProps {
  categories: ICreatorData["categories"];
  onChangeCategories: (categories: ICreatorData["categories"]) => void;
}

const EditCategories: React.FC<IProps> = ({
  categories,
  onChangeCategories,
}) => {
  const { newError } = Snackbar.useSnackbar();

  const selectOption = (index: number) => {
    const selected = options[index];
    const newCategories = [...(categories || [])];

    if (newCategories.includes(selected.toLowerCase() as TCategories)) {
      return;
    }

    newCategories.push(selected.toLowerCase() as TCategories);

    if (newCategories.length <= 3) onChangeCategories(newCategories);
    else newError("Já alcançou o limite de 3 interesses");
  };

  const removeCategories = (index: number) => {
    const newCategories = [...(categories || [])];

    newCategories?.splice(index, 1);

    onChangeCategories(newCategories);
  };

  return (
    <InputSelect
      listType="interests"
      onSelect={(value) => selectOption(value)}
      options={options}
      selectedIndex={0}
      userCategories={categories || []}
      removeCategories={removeCategories}
    />
  );
};

export default EditCategories;
