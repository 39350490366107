import styled, { css } from "styled-components";
import P from "../../../../componentsV2/atoms/Typography/P";

type ContainerProps = {
  borderColor: string;
  backgroundColor: string;
};

export const Container = styled.div<ContainerProps>`
  ${({ backgroundColor, borderColor }) => css`
    gap: 20px;
    width: 100%;
    display: flex;
    border-radius: 16px;
    align-items: center;
    flex-direction: column;
    padding: 40px 20px 28px;
    border: 1px solid ${borderColor};
    background-color: ${backgroundColor};
  `}
`;

type IconProps = {
  backgroundColor: string;
};

export const Icon = styled.div<IconProps>`
  ${({ backgroundColor }) => css`
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundColor};

    svg {
      width: 40%;
      height: 40%;
    }
  `}
`;

export const Texts = styled.div`
  gap: 10px;
  display: flex;
  max-width: 280px;
  text-align: center;
  flex-direction: column;
`;

export const Subtitle = styled(P)``;

export const ChooseCalendarText = styled(Subtitle)`
  padding-top: 10px;
`;

export const Calendars = styled.div`
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const Calendar = styled.a`
  gap: 6px;
  display: flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
  text-decoration: none;
  flex-direction: column;
  justify-content: center;

  p {
    text-align: center;
  }
`;

export const CalendarIcon = styled.div<IconProps>`
  ${({ backgroundColor }) => css`
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundColor};
  `}
`;

export const WarningText = styled(P)`
  text-align: center;
`;

type AtentionProps = {
  color: string;
  fontWeight: string;
};

export const Atention = styled.span<AtentionProps>`
  ${({ color, fontWeight }) => css`
    color: ${color};
    font-size: 0.75rem;
    font-weight: ${fontWeight};
  `}
`;
