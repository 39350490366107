import { ResponsivePie } from "@nivo/pie";

import * as S from "./styles";
import {
  selectedColors,
  calculatePercentagePie,
} from "../../../../../../../../utils/media-kit";
import { Theme } from "../../../../../../../../hooks";
import H2 from "../../../../../../../../componentsV2/atoms/Typography/H2";
import { ISocialMediaEntity } from "../../../../../../../../types/media-kit";
import Small from "../../../../../../../../componentsV2/atoms/Typography/Small";

interface IProps {
  data: ISocialMediaEntity["followerGender"];
}

const PieChart: React.FC<IProps> = ({ data }) => {
  const { textColor, primaryColor } = Theme.useTheme();

  const colors = selectedColors(primaryColor);

  const normData = [
    {
      id: "homens",
      label: "male",
      value: data.male,
    },
    {
      id: "mulheres",
      label: "female",
      value: data.female,
    },
    {
      id: "outros",
      label: "others",
      value: data.uncategorised,
    },
  ];

  const percentageValues = calculatePercentagePie(normData);

  return (
    <S.Container>
      <ResponsivePie
        fit={true}
        data={normData}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        arcLinkLabelsColor={{ from: "color" }}
        margin={{ top: 0, right: 0, bottom: 0, left: -40 }}
        defs={[
          {
            id: "male",
            type: "linearGradient",
            colors: [
              { offset: 0, color: colors[400] },
              { offset: 100, color: colors[400] },
            ],
          },
          {
            id: "female",
            type: "linearGradient",
            colors: [
              { offset: 0, color: colors[700] },
              { offset: 100, color: colors[700] },
            ],
          },
          {
            id: "others",
            type: "linearGradient",
            colors: [
              { offset: 0, color: colors[600] },
              { offset: 100, color: colors[600] },
            ],
          },
        ]}
        fill={[
          { match: (d) => d.data.label === "male", id: "male" },
          { match: (d) => d.data.label === "female", id: "female" },
          { match: (d) => d.data.label === "others", id: "others" },
        ]}
      />

      <S.PieChartLegend>
        <S.LegendItem>
          <H2 color={textColor}>{percentageValues[0].value}</H2>
          <Small color={textColor}>{percentageValues[0].id}</Small>
        </S.LegendItem>

        <S.LegendItem>
          <H2 color={textColor}>{percentageValues[1].value}</H2>
          <Small color={textColor}>{percentageValues[1].id}</Small>
        </S.LegendItem>

        <S.LegendItem>
          <H2 color={textColor}>{percentageValues[2].value}</H2>
          <Small color={textColor}>{percentageValues[2].id}</Small>
        </S.LegendItem>
      </S.PieChartLegend>
    </S.Container>
  );
};

export default PieChart;
