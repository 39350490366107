import styled from "styled-components";
import Box from "../../atoms/Box";
import Button from "../../atoms/Button";
import Small from "../../atoms/Typography/Small";

export const ImageList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BoxImages = styled(Box)`
  gap: 0px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1.5rem 0;
  gap: 1.5rem;
`;

export const Loading = styled.div`
  display: flex;
  width: 84px;
  height: 94px;
  border-radius: 12px;
  background-color: #fafafa18;
  background-image: linear-gradient(
    90deg,
    #fafafa18 0px,
    #fafafa33 10px,
    #fafafa18 20px
  );
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;

type ImageProps = {
  imageUrl: string;
};

export const Image = styled.div<ImageProps>`
  display: flex;
  width: 84px;
  height: 94px;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${({ imageUrl }) => imageUrl});
`;

export const ImageSkeleton = styled.div`
  height: 94px;
  border-radius: 20px;
  mix-blend-mode: screen;
  margin-top: 1.5rem;

  background-image: linear-gradient(
    90deg,
    #fafafa18 0px,
    #fafafa33 20px,
    #fafafa18 40px
  );
  background-size: 200% 100%;
  animation: 2s shine linear infinite;

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;

export const ButtonsImage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
`;

export const RemoveButtonsImage = styled(Button)`
  padding: 12px 0px;
`;

export const ButtonUploadImage = styled(Button)`
  margin-top: 1rem;
  overflow: hidden;
  position: relative;

  input {
    height: 100%;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 99;
    opacity: 0;
    -moz-opacity: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  }
`;

export const Error = styled(Small)`
  display: flex;
  margin-top: 1rem;
`;
