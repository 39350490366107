import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Analytics } from "../../../../services";
import { Auth, Snackbar, Theme } from "../../../../hooks";
import { ICreatorCampaign } from "../../../../services/affiliates/types";
import AffiliateCard from "../../../../componentsV2/molecules/AffiliateCard";

import * as S from "./styles";

interface IProps {
  searchTerm: string;
  isLoading: boolean;
  campaigns: ICreatorCampaign[];
  onApplyForCampaign: (campaignId: string) => void;
}

const CampaignsList: React.FC<IProps> = ({
  campaigns,
  isLoading,
  searchTerm,
  onApplyForCampaign,
}) => {
  const navigate = useNavigate();
  const { user } = Auth.useAuth();
  const { newSuccess } = Snackbar.useSnackbar();
  const { textColor } = Theme.useTheme();

  const onCardClick = (id: string, brand: string) => {
    Analytics.submitClickEvent({
      origin: "home-app-afiliados",
      creator: user.id || "",
      description: `affiliation-details-${brand}`,
      creatorUsername: user.username || "",
      actionDescription: `affiliation-details-${brand}`,
    });

    navigate(id);
  };

  const onCopyHandler = useCallback((code: string, brand: string) => {
    if (!code) return;

    Analytics.submitClickEvent({
      origin: "home-app-afiliados",
      creator: user.id || "",
      creatorUsername: user.username || "",
      description: `copy-code-affiliation-${brand}`,
      actionDescription: `copy-code-affiliation-home-${brand}`,
    });

    navigator.clipboard.writeText(code);

    newSuccess("Código copiado para área de transferência");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredCampaigns = searchTerm
    ? campaigns.filter((campaign) => {
        const normalizedTitle = campaign.title
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        const normalizedSegment = campaign.segment
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        const normalizedSearch = searchTerm
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        return (
          normalizedTitle.includes(normalizedSearch) ||
          normalizedSegment.includes(normalizedSearch)
        );
      })
    : campaigns;

  if (!campaigns.length)
    return (
      <S.NoCampaign color={textColor}>
        Nenhuma campanha disponível, ainda
      </S.NoCampaign>
    );

  if (!filteredCampaigns.length)
    return (
      <S.NoCampaign color={textColor}>Nenhuma campanha encontrada</S.NoCampaign>
    );

  return (
    <S.CampaingList>
      {filteredCampaigns.map((campaign) => (
        <AffiliateCard
          key={campaign.id}
          disabled={isLoading}
          title={campaign.title}
          segment={campaign.segment}
          imageUrl={campaign.imageUrl}
          status={campaign.affiliation?.status}
          onApplyForCampaign={() => onApplyForCampaign(campaign.id)}
          onClickCard={() => onCardClick(campaign.id, campaign.brand)}
          onCopyCode={() =>
            onCopyHandler(
              campaign.affiliation?.coupon || campaign.affiliation?.link || "",
              campaign.brand
            )
          }
        />
      ))}
    </S.CampaingList>
  );
};

export default CampaignsList;
