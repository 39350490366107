import { useEffect, useState } from "react";
import CloseIcon from "../../../components/icons/CloseIcon";
import { Theme } from "../../../hooks";
import { IFAQ } from "../../../types";
import Box from "../../atoms/Box";
import H5 from "../../atoms/Typography/H5";
import InputText from "../../molecules/InputText";
import Switch from "../../molecules/Switch";

import * as S from "./styles";

interface IProps {
  faqs: IFAQ[];
  onChange: (newFaq: IFAQ[]) => void;
}

const Faq: React.FC<IProps> = ({ faqs, onChange }) => {
  const [isSwitcherOn, setIsSwitcherOn] = useState(true);

  const { textColor } = Theme.useTheme();

  const onChangeHandler = (
    value: string,
    index: number,
    type: "question" | "answer"
  ) => {
    const newFaqs = [...faqs];
    newFaqs[index][type] = value;

    onChange(newFaqs);
  };

  const onSwitcherHandler = () => {
    if (!isSwitcherOn) return setIsSwitcherOn(true);

    const isConfirmed = window.confirm(
      "Ao clicar em confirmar, todas as perguntas frequentes serão removidas. Deseja continuar?"
    );

    if (isConfirmed) {
      setIsSwitcherOn(false);
      onChange([]);
    }
  };

  const addQuestionHandler = () => {
    const newFaqs = [...faqs];
    newFaqs.push({ question: "", answer: "" });
    onChange(newFaqs);
  };

  const onRemove = (index: number) => {
    const newFaqs = [...faqs];
    newFaqs.splice(index, 1);

    onChange(newFaqs);
  };

  useEffect(() => {
    const newFaqs = [...faqs];
    newFaqs.push({ question: "", answer: "" });
    onChange(newFaqs);
  }, []);

  return (
    <Box>
      <Switch
        isOn={isSwitcherOn}
        label="Adicionar FAQ"
        setIsOn={onSwitcherHandler}
      />

      {isSwitcherOn &&
        faqs.map((faq, index) => (
          <S.Container key={`#${index}`}>
            <S.InputTextArea>
              <InputText
                onChange={(val: string) =>
                  onChangeHandler(val, index, "question")
                }
                value={faq.question}
                rows={3}
                as="textarea"
                label={`Pergunta ${index + 1}`}
                limit={true}
                charLimit={300}
              />

              <S.ButtonDelete
                size="small"
                variant="icon"
                onClick={() => onRemove(index)}
              >
                <CloseIcon />
              </S.ButtonDelete>
            </S.InputTextArea>

            <S.Separator color={textColor} />

            <S.InputTextArea>
              <InputText
                onChange={(val: string) =>
                  onChangeHandler(val, index, "answer")
                }
                value={faq.answer}
                rows={3}
                as="textarea"
                label={`Resposta ${index + 1}`}
                limit={true}
                charLimit={300}
              />
            </S.InputTextArea>
          </S.Container>
        ))}

      {isSwitcherOn && (
        <S.ButtonAddQuestion
          size="medium"
          variant="solid"
          onClick={addQuestionHandler}
        >
          <H5 color={textColor}>Adicionar mais uma pergunta</H5>
        </S.ButtonAddQuestion>
      )}
    </Box>
  );
};

export default Faq;
