import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import InformationStep, {
  IInformationStepForm,
} from "./components/InformationStep";
import { TSteps } from "../../types/index";
import Page from "../../components/atoms/Page";
import { normalizeUsername } from "../../utils";
import UsernameStep from "./components/UsernameStep";
import { Auth, GA, Loading, Snackbar } from "../../hooks";
import { Analytics, Creator, OAuth } from "../../services";
import {
  LOCAL_STORAGE_USERNAME_KEY,
  LOCAL_STORAGE_AUTHENTICATION_METHOD,
} from "../../constants";

const Register: React.FC = () => {
  const [username, setUsername] = useState("");
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(false);
  const [emailFieldStatus, setEmailFieldStatus] = useState<
    "error" | "success" | "loading" | ""
  >("");
  const [informationStepForm, setInformationStepForm] =
    useState<IInformationStepForm>({
      name: "",
      phone: "",
      email: "",
      password: "",
    });

  const navigate = useNavigate();

  const { sendEvents } = GA.useGA();
  const { newError } = Snackbar.useSnackbar();
  const { register, user, setUserHandler } = Auth.useAuth();
  const { hideLoading, showLoading } = Loading.useLoading();

  useEffect(() => {
    if (window.location.hash) {
      const params: { access_token: string; error: string; state: "" } =
        window.location.hash
          .substring(1)
          .split("&")
          .reduce(
            (acc, curr) => {
              const [key, ...value] = curr.split("=");
              return { ...acc, [key]: value.join("=") };
            },
            { access_token: "", error: "", state: "" }
          );

      if (!params.access_token) {
        newError("Houve um erro ao fazer o cadastro");
      } else {
        const provider = params.state ? "facebook" : "google";
        window.location.hash = "";
        register(null, provider, params.access_token);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const urlParams = Object.fromEntries(
      new URLSearchParams(window.location.search).entries()
    );

    if (urlParams && urlParams.username) {
      setUsername(normalizeUsername(urlParams.username));

      delete urlParams.username;
    }

    if (Object.keys(urlParams).length > 0)
      localStorage.setItem("@searchParams", JSON.stringify(urlParams));
  }, []);

  const emailCheckTimeout = useRef<NodeJS.Timeout | null>(null);

  const informationStepFormHandler = (key: string, value: string) => {
    setInformationStepForm((curr) => ({ ...curr, [key]: value }));
  };

  const onChangeEmail = (value: string) => {
    informationStepFormHandler("email", value.toLowerCase().trim());

    if (!value) setEmailFieldStatus("");

    if (emailCheckTimeout.current) {
      clearTimeout(emailCheckTimeout.current);
      emailCheckTimeout.current = null;
    }

    if (value) {
      emailCheckTimeout.current = setTimeout(async () => {
        const isEmailValid =
          value && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(value);

        setEmailFieldStatus("loading");

        const isEmailInUse = await Creator.checkEmailAvailability(value);

        const status = isEmailValid && !isEmailInUse ? "success" : "error";

        if (isEmailInUse)
          newError(
            "O e-mail informado já está cadastrado. Faça login (entrar) ou utilize um e-mail diferente"
          );

        setEmailFieldStatus(status);
      }, 2000);
    }
  };

  const onBackHandler = (step: TSteps) => {
    setUserHandler({ formStep: step });
  };

  const checkUsernameAvailability = async () => {
    try {
      const isUsernameRegistered = await Creator.checkUsernameAvailability(
        username
      );

      if (isUsernameRegistered)
        newError(
          "Esse nome de usuário já foi cadastrado, por favor escolha outro nome"
        );

      setIsUsernameAvailable(!isUsernameRegistered);

      return isUsernameRegistered;
    } catch (error) {
      newError("Não foi possivel verificar o nome de usuário");
    }
  };

  const onNextHandler = async (nextStep: TSteps) => {
    try {
      showLoading();
      const isUsernameRegistered = await checkUsernameAvailability();

      if (isUsernameRegistered) return;

      setUserHandler({ formStep: nextStep });

      nextStep === "profile-image"
        ? onRegisterInfo()
        : localStorage.setItem(LOCAL_STORAGE_USERNAME_KEY, username);
    } catch (error) {
      newError("Houve um erro ao verificar o username escolhido");
    } finally {
      hideLoading();
    }
  };

  const onRegisterInfo = async () => {
    const payload = {
      username: username,
      name: informationStepForm.name,
      email: informationStepForm.email,
      phone: informationStepForm.phone,
      formStep: "profile-image" as TSteps,
      password: informationStepForm.password,
    };

    (Object.keys(payload) as (keyof typeof payload)[]).forEach((key) => {
      if (!payload[key]) delete payload[key];
    });

    register(payload);
  };

  const clearSecondStepForm = () => {
    setInformationStepForm({
      name: "",
      email: "",
      phone: "",
      password: "",
    });
  };

  const onRegisterWithGoogle = () => {
    clearSecondStepForm();
    Analytics.submitClickEvent({
      creator: user.id || "",
      origin: document.referrer,
      creatorUsername: username,
      meta: { registerMode: "google" },
      description: "authenticate-with-google",
      actionDescription: `authenticate-with-google-${username}`,
    });
    localStorage.setItem(
      LOCAL_STORAGE_AUTHENTICATION_METHOD,
      "authenticate-with-google"
    );
    sendEvents({
      name: "Lead",
      category: "google-button",
      buttonLocation: "register-information-step",
    });
    window.fbq("track", "Lead");
    window.location.href = OAuth.getGoogleOAuthURI("register");
  };

  const onRegisterWithFacebook = () => {
    clearSecondStepForm();
    Analytics.submitClickEvent({
      creator: user.id || "",
      origin: document.referrer,
      creatorUsername: username,
      meta: { registerMode: "facebook" },
      description: "authenticate-with-facebook",
      actionDescription: `authenticate-with-facebook-${username}`,
    });
    localStorage.setItem(
      LOCAL_STORAGE_AUTHENTICATION_METHOD,
      "authenticate-with-facebook"
    );
    sendEvents({
      name: "Lead",
      category: "facebook-button",
      buttonLocation: "register-information-step",
    });
    window.fbq("track", "Lead");
    window.location.href = OAuth.getFacebookOAuthURI("register");
  };

  return (
    <>
      <Page>
        {user.formStep === "username" ? (
          <UsernameStep
            username={username}
            onChangeUsername={setUsername}
            onLogin={() => navigate("/login")}
            onNext={() => onNextHandler("information")}
          />
        ) : (
          user.formStep === "information" && (
            <InformationStep
              username={username}
              onChangeEmail={onChangeEmail}
              onChangeUsername={setUsername}
              emailFieldStatus={emailFieldStatus}
              onBack={() => onBackHandler("username")}
              isUsernameAvailable={isUsernameAvailable}
              informationStepForm={informationStepForm}
              onRegisterWithGoogle={onRegisterWithGoogle}
              onNext={() => onNextHandler("profile-image")}
              onBlurUsernameInput={checkUsernameAvailability}
              onRegisterWithFacebook={onRegisterWithFacebook}
              informationStepFormHandler={informationStepFormHandler}
            />
          )
        )}
      </Page>
    </>
  );
};

export default Register;
