import { Theme } from "../../../../hooks";
import { useNavigate } from "react-router-dom";

import Page from "../../../../components/atoms/Page";
import Chevron from "../../../../components/icons/Chevron";
import Button from "../../../../componentsV2/atoms/Button";
import H2 from "../../../../componentsV2/atoms/Typography/H2";
import H4 from "../../../../componentsV2/atoms/Typography/H4";
import ProfileImage from "../../../../componentsV2/atoms/ProfileImage";

import * as S from "./styles";

interface IProps {
  username: string;
  profileImage?: string;
}

const SuccessfulChange: React.FC<IProps> = ({ username, profileImage }) => {
  const navigate = useNavigate();

  const { primaryColor, textColor } = Theme.useTheme();

  return (
    <Page>
      <S.Container>
        <S.RegisterFormHeader>
          <S.Back
            variant="icon"
            color="#fafafa05"
            onClick={() => navigate("/")}
          >
            <Chevron color="#fafafa" />
          </S.Back>
        </S.RegisterFormHeader>

        <S.Content>
          <ProfileImage
            imageUrl={profileImage || ""}
            size="large"
            hasBackgroundImage={true}
          />
          <S.Cta>
            <H2 color={primaryColor}>@{username}</H2>
            <H4 color={textColor}>Sua senha foi atualizada.</H4>
          </S.Cta>
          <Button
            variant="outline"
            textColor={primaryColor}
            borderColor={primaryColor}
            onClick={() => navigate("/login")}
          >
            Acesse sua conta
          </Button>
        </S.Content>
      </S.Container>
    </Page>
  );
};

export default SuccessfulChange;
