import styled from "styled-components";

export const CampaignInfo = styled.div`
  display: flex;
  flex-direction: column;

  small {
    opacity: 0.5;
    margin-top: 4px;
  }
`;

export const DiscountBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  position: relative;

  h5 {
    top: 50%;
    left: 50%;
    font-weight: 500;
    position: absolute;
    transform: translate(-50%, -50%);
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Info = styled.div`
  gap: 12px;
  display: flex;
  margin-top: 12px;
  align-items: center;
`;

export const ComissionBlock = styled(DiscountBlock)`
  margin-top: 20px;
`;

export const ComissionIcon = styled(Icon)`
  padding: 8px;
  border-radius: 50%;
  background-color: #fafafa0a;
`;
