import styled, { css } from "styled-components";

import Box from "../../../../componentsV2/atoms/Box";
import Button from "../../../../componentsV2/atoms/Button";
import ProfileImage from "../../../../componentsV2/atoms/ProfileImage";

export const Container = styled.div`
  display: flex;
  margin-bottom: 108px;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

type CoverProps = {
  background?: string;
  backgroundColor: string;
};

export const CoverImage = styled.div<CoverProps>`
  ${({ background, backgroundColor }) => css`
    width: 100%;
    height: 300px;
    opacity: 0.56;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundColor};
    background: ${background && `url(${background})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    svg {
      width: 24px;
      height: 24px;
    }

    input[type="file"] {
      top: 0;
      right: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      position: absolute;
    }
  `}
`;

export const ProfileImageContainer = styled.div`
  width: 100%;
  display: flex;
  max-width: 960px;
  align-self: center;
`;

type ProfileImageProps = {
  borderColor: string;
};

export const ProfileImageBox = styled.div<ProfileImageProps>`
  ${({ borderColor }) => css`
    left: 20px;
    width: 126px;
    padding: 2px;
    height: 126px;
    display: flex;
    margin-top: -63px;
    border-radius: 50%;
    position: relative;
    align-items: center;
    align-self: center;
    justify-content: center;
    border: 2px solid ${borderColor};

    svg {
      width: 24px;
      height: 24px;
      position: absolute;
    }

    input[type="file"] {
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      position: absolute;
    }
  `}
`;

export const Image = styled(ProfileImage)`
  position: absolute;
`;

export const Information = styled.div`
  width: 100%;
  display: flex;
  padding: 0 20px;
  margin-top: 28px;
  max-width: 960px;
  align-self: center;
  position: relative;
  flex-direction: column;
`;

export const BoxContainer = styled(Box)`
  gap: 16px;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
`;

export const SaveButton = styled(Button)`
  width: 100%;
  bottom: 100px;
  max-width: 960px;
  position: sticky;
  margin-bottom: 40px;
`;
