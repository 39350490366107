import styled from "styled-components";
import Box from "../../../../componentsV2/atoms/Box";
import Button from "../../../../componentsV2/atoms/Button";
import P from "../../../../componentsV2/atoms/Typography/P";
import Small from "../../../../componentsV2/atoms/Typography/Small";

export const AgendaOptions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelService = styled(Small)`
  font-size: 0.75rem;
  letter-spacing: 0;
`;

export const Label = styled(P)`
  margin-bottom: 16px;
`;

export const BoxContent = styled(Box)`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 0px;
`;

export const AddCalendar = styled(Button)`
  margin-top: 24px;
`;

export const ErrorMessage = styled(Small)`
  font-size: 0.75rem;
`;
