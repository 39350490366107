import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const DribbbleIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="50"
      height="40"
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 20C5 16.3733 5.89336 13.0267 7.68 9.96C9.46668 6.89332 11.8934 4.46668 14.96 2.68C18.0267 0.89332 21.3734 0 25 0C28.6267 0 31.9734 0.89332 35.04 2.68C38.1067 4.46668 40.5334 6.89332 42.32 9.96C44.1067 13.0267 45 16.3733 45 20C45 23.6267 44.1067 26.9733 42.32 30.04C40.5334 33.1067 38.1067 35.5333 35.04 37.32C31.9734 39.1067 28.6267 40 25 40C21.3734 40 18.0267 39.1067 14.96 37.32C11.8934 35.5333 9.46668 33.1067 7.68 30.04C5.89336 26.9733 5 23.6267 5 20ZM8.32 20C8.32 24.16 9.72 27.8267 12.52 31C13.8 28.4933 15.8267 26.1067 18.6 23.84C21.3734 21.5733 24.08 20.1467 26.72 19.56C26.32 18.6267 25.9334 17.7867 25.56 17.04C20.9734 18.5067 16.0134 19.24 10.68 19.24C9.64 19.24 8.86668 19.2267 8.36 19.2C8.36 19.3067 8.35336 19.44 8.34 19.6C8.32668 19.76 8.32 19.8933 8.32 20ZM8.84 15.88C9.42668 15.9333 10.2934 15.96 11.44 15.96C15.8934 15.96 20.12 15.36 24.12 14.16C22.0934 10.56 19.8667 7.56 17.44 5.16C15.3334 6.22668 13.5267 7.70668 12.02 9.6C10.5134 11.4933 9.45336 13.5867 8.84 15.88ZM14.8 33.16C17.8134 35.5067 21.2134 36.68 25 36.68C26.9734 36.68 28.9334 36.3067 30.88 35.56C30.3467 31 29.3067 26.5867 27.76 22.32C25.3067 22.8533 22.8334 24.2 20.34 26.36C17.8467 28.52 16 30.7867 14.8 33.16ZM20.92 3.88C23.2667 6.30668 25.44 9.33332 27.44 12.96C31.0667 11.44 33.8 9.50668 35.64 7.16C32.5467 4.6 29 3.32 25 3.32C23.64 3.32 22.28 3.50668 20.92 3.88ZM28.88 15.8C29.28 16.6533 29.7334 17.7333 30.24 19.04C32.2134 18.8533 34.36 18.76 36.68 18.76C38.3334 18.76 39.9734 18.8 41.6 18.88C41.3867 15.2533 40.08 12.0267 37.68 9.2C35.9467 11.7867 33.0134 13.9867 28.88 15.8ZM31.24 21.84C32.6 25.7867 33.52 29.84 34 34C36.1067 32.64 37.8267 30.8933 39.16 28.76C40.4934 26.6267 41.2934 24.32 41.56 21.84C39.6134 21.7067 37.84 21.64 36.24 21.64C34.7734 21.64 33.1067 21.7067 31.24 21.84Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default DribbbleIcon;
