import { useState, TouchEventHandler, MouseEventHandler } from "react";

import * as S from "./styles";
import { Theme } from "../../../../../../hooks";
import { IPostsEntity } from "../../../../../../types/media-kit";
import Small from "../../../../../../componentsV2/atoms/Typography/Small";
import AvailableIconsTemp from "../../../../../../components/atoms/AvailableIconsTemp";

interface IProps {
  posts: IPostsEntity;
  imageWidth?: number;
  startPosition?: number;
  clearPadding?: boolean;
  hideIndicator: boolean;
}

const MIN_DISTANCE_TO_SWIPE = 40;

const PostsSlider: React.FC<IProps> = ({
  posts,
  clearPadding,
  hideIndicator,
  startPosition,
  imageWidth = 240,
}) => {
  const { textColor, primaryColor } = Theme.useTheme();

  const images = posts.posts.map((post) => post.thumbnailUrl);

  const [currentImage, setCurrentImage] = useState(startPosition || 0);
  const [movementStartPosition, setMovementStartPosition] = useState(
    startPosition || 0
  );

  const onSwipeRightHandler = () => {
    if (currentImage < images.length - 1) setCurrentImage((curr) => curr + 1);
  };

  const onSwipeLeftHandler = () => {
    if (currentImage > 0) setCurrentImage((curr) => curr - 1);
  };

  const onTouchStartHandler: TouchEventHandler<HTMLDivElement> = (e) => {
    setMovementStartPosition(e.changedTouches[0].clientX);
  };

  const onTouchEndHandler: TouchEventHandler<HTMLDivElement> = (e) => {
    const endPosition = e.changedTouches[0].clientX;

    if (Math.abs(endPosition - movementStartPosition) > MIN_DISTANCE_TO_SWIPE) {
      if (endPosition > movementStartPosition) {
        onSwipeLeftHandler();
      } else onSwipeRightHandler();
    }
  };

  const onMouseDownHandler: MouseEventHandler<HTMLDivElement> = (e) => {
    setMovementStartPosition(e.clientX);
  };

  const onMouseUpHandler: MouseEventHandler<HTMLDivElement> = (e) => {
    const endPosition = e.clientX;

    if (Math.abs(endPosition - movementStartPosition) > MIN_DISTANCE_TO_SWIPE) {
      if (endPosition > movementStartPosition) {
        onSwipeLeftHandler();
      } else onSwipeRightHandler();
    }
  };

  return (
    <S.PostsSlider>
      <S.Container
        centralize={hideIndicator}
        onMouseUp={onMouseUpHandler}
        clearPadding={!!clearPadding}
        onTouchEnd={onTouchEndHandler}
        onMouseDown={onMouseDownHandler}
        onTouchStart={onTouchStartHandler}
        transformValue={currentImage * imageWidth}
        paddingLeft={`${(images.length - 1) * imageWidth}px`}
      >
        {posts.posts.map((post) => (
          <S.ImageBox key={post.id}>
            <S.BrandInfo>
              <S.BrandLogo
                borderColor={primaryColor}
                logo={post.brandLogo || ""}
              />

              <Small color={textColor}>@{post.brandUsername}</Small>
            </S.BrandInfo>

            <S.Image width={`${imageWidth}px`} image={post.thumbnailUrl} />

            <S.PostsInfo>
              <S.Info>
                <AvailableIconsTemp
                  option="showPassword"
                  color={`${textColor}8f`}
                />

                <Small color={textColor}>{post.viewCount || "-"}</Small>
              </S.Info>

              <S.Info>
                <AvailableIconsTemp option="heart" color={`${textColor}8f`} />

                <Small color={textColor}>{post.likeCount || "-"}</Small>
              </S.Info>

              <S.Info>
                <AvailableIconsTemp option="message" color={`${textColor}8f`} />

                <Small color={textColor}>{post.commentCount || "-"}</Small>
              </S.Info>
            </S.PostsInfo>
          </S.ImageBox>
        ))}
      </S.Container>

      {!hideIndicator && (
        <S.Indicators>
          {Array(images.length)
            .fill(null)
            .map((_, index) => (
              <S.Indicator
                key={index}
                selectedWidth={"16px"}
                selectedColor={primaryColor}
                isSelected={index === currentImage}
                notSelectedColor={`${textColor}29`}
                onClick={() => setCurrentImage(index)}
              />
            ))}
        </S.Indicators>
      )}
    </S.PostsSlider>
  );
};

export default PostsSlider;
