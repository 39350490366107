import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const OpenPageIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.00299412 7.47413C0.00299412 5.88744 0.00299412 4.30149 0.00299412 2.7148C0.00299412 1.25464 0.998598 0.135792 2.43807 0.0504622C3.3054 -0.00102976 4.17855 0.0203026 5.0488 0.0335434C5.52328 0.0408994 5.84615 0.375597 5.85417 0.805187C5.86219 1.23551 5.5138 1.58271 5.02402 1.59301C4.33526 1.60699 3.64577 1.59448 2.95628 1.59816C2.20557 1.60258 1.56345 2.23813 1.562 2.98844C1.55908 6.00293 1.55835 9.01742 1.562 12.0312C1.56273 12.7565 2.2114 13.4141 2.92931 13.4148C5.91612 13.4192 8.90294 13.4192 11.889 13.4148C12.6237 13.4134 13.2585 12.7476 13.2607 11.9988C13.2622 11.2058 13.2585 10.4121 13.2622 9.61913C13.2644 9.05861 13.5588 8.71141 14.0289 8.70258C14.5165 8.69375 14.8139 9.01227 14.8183 9.5875C14.8255 10.5511 14.8503 11.517 14.8037 12.4791C14.7337 13.9202 13.6645 14.9743 12.2411 14.9839C9.02465 15.0052 5.80825 15.0059 2.59186 14.9839C1.09408 14.9736 0.0146557 13.8304 0.00518066 12.307C-0.00502319 10.6961 0.00299412 9.08509 0.00299412 7.47413Z"
        fill={color || primaryColor}
      />
      <path
        d="M4.1524 10.5863C3.71655 9.05185 3.86013 7.63656 4.54233 6.29262C5.72452 3.96298 7.52258 2.37188 10.0735 1.7194C10.2485 1.67453 10.43 1.61863 10.6071 1.62304C10.8818 1.62966 10.9759 1.52226 10.9365 1.25745C10.9219 1.16182 10.9474 1.06031 10.9321 0.965414C10.8731 0.599086 10.9212 0.225402 11.2754 0.0863738C11.6413 -0.0578036 12.0443 -0.0335289 12.3949 0.251148C13.4219 1.08385 14.4605 1.90183 15.4925 2.72791C16.166 3.2671 16.1711 3.84234 15.5027 4.37933C14.435 5.23777 13.3621 6.09107 12.2929 6.94731C11.9817 7.19667 11.6063 7.15842 11.2965 7.04661C10.989 6.93554 10.919 6.61114 10.9219 6.25216C10.9292 5.47611 11.1595 5.43124 10.1625 5.54672C8.4074 5.74975 7.02697 6.66851 5.81781 7.90285C5.4512 8.27727 5.1378 8.69435 4.85428 9.13645C4.57221 9.57634 4.36012 10.0508 4.15313 10.5863H4.1524Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default OpenPageIcon;
