import styled, { css } from "styled-components";

export const Container = styled.a`
  width: 100%;
  display: flex;
  cursor: pointer;
  user-select: none;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  background-color: #fafafa0d;
  padding: 16px 24px 16px 20px;
  justify-content: space-between;
`;

export const IconAndText = styled.div`
  gap: 20px;
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Texts = styled.div`
  display: flex;
  align-items: center;
`;

type AheadIconProps = {
  width: string;
};

export const AheadIcon = styled.div<AheadIconProps>`
  ${({ width }) => css`
    display: flex;
    width: ${width};
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
    }
  `}
`;
