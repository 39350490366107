import { ReactNode } from "react";
import H5 from "../../atoms/Typography/H5";

import * as S from "./styles";

type TitleDescriptionProps = {
  title: string;
  children: ReactNode;
  titleColor?: string;
  textColor?: string;
  centralize?: boolean;
  titleSize?: "medium" | "small";
};

const TitleDescription: React.FC<TitleDescriptionProps> = ({
  title,
  children,
  centralize,
  titleSize = "medium",
  titleColor = "#fafafa",
  textColor = "#fafafa80",
}) => {
  return (
    <S.TextField centralize={centralize}>
      <S.Title titleSize={titleSize} color={titleColor}>
        {title}
      </S.Title>
      <H5 color={textColor}>{children}</H5>
    </S.TextField>
  );
};

export default TitleDescription;
