import styled from "styled-components";
import Link from "../../../components/atoms/Link";

type BannerProps = {
  backgroundColor: string;
};

export const Banner = styled.div<BannerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem 1.25rem;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 12px;

  button {
    cursor: pointer;
    margin-top: -10px;
  }
`;

export const BannerText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const BannerLink = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
