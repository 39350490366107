import styled from "styled-components";
import Box from "../../../../componentsV2/atoms/Box";
import Button from "../../../../componentsV2/atoms/Button";
import P from "../../../../componentsV2/atoms/Typography/P";
import { Wrapper } from "../styles";

export const MissingUsernameWrapper = styled(Wrapper)`
  &::after {
    content: "";
    width: 140%;
    top: -550px;
    left: -175px;
    height: 140%;
    opacity: 0.7;
    cursor: none;
    position: absolute;
    filter: blur(100px);
    pointer-events: none;
    background: url("../../../../components/assets/image_bg.png");
    background-size: cover;
    background-position: top center;

    @media (max-width: 920px) {
      width: 120%;
      left: -40px;
      top: -250px;
      height: 120%;
      filter: blur(70px);
    }

    @media (max-width: 600px) {
      left: 40px;
      top: -150px;
      filter: blur(60px);
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  max-width: 960px;
  flex-direction: column;
`;

export const RegisterFormHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    transform: scale(-1);
  }
`;

export const Form = styled(Box)`
  margin: 1rem 0 1.25rem;
`;

export const FormTitle = styled(P)`
  font-size: 1.5rem;
`;

export const FormSubtitle = styled(P)`
  margin-top: 1.5rem;
`;

export const ProgressBar = styled.div`
  gap: 18px;
  display: flex;
  padding-top: 60px;
  flex-direction: column;
`;

export const Back = styled(Button)`
  width: 6rem;
  color: #fafafa;
`;
