import styled from "styled-components";

export const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  svg {
    transform: scale(-1);
  }
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    font-size: 1.5rem;
  }
`;
