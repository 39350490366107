import * as S from "./styles";
import PieChart from "./components/PieChart";
import BarChart from "./components/BarChart";
import { Theme } from "../../../../../../hooks";
import LocationTable from "./components/LocationTable";
import H5 from "../../../../../../componentsV2/atoms/Typography/H5";
import { ISocialMediaEntity } from "../../../../../../types/media-kit";
import AvailableIcons from "../../../../../../components/atoms/AvailableIcons";

interface IProps {
  type: string;
  title: string;
  tolltip: string;
  genderData: ISocialMediaEntity["followerGender"];
  ageData: ISocialMediaEntity["followerAge"] | undefined;
  locationData: ISocialMediaEntity["location"] | undefined;
}

const PublicBox: React.FC<IProps> = ({
  type,
  title,
  tolltip,
  ageData,
  genderData,
  locationData,
}) => {
  const { backgroundColor, textColor } = Theme.useTheme();

  const content = (() => {
    switch (type) {
      case "gender":
        return (
          <S.Content>
            <PieChart data={genderData} />
          </S.Content>
        );

      case "age":
        return (
          <S.Content>
            <BarChart data={ageData} />
          </S.Content>
        );

      case "location":
        return (
          <S.LocationContent>
            <LocationTable data={locationData} />
          </S.LocationContent>
        );
    }
  })();

  return (
    <S.Box borderColor={`${textColor}29`}>
      <S.TitleAndTip>
        <H5 color={textColor}>{title}</H5>

        <S.Tolltip>
          <S.TipIcon>
            <AvailableIcons option="infoOne" color={`${textColor}29`} />
          </S.TipIcon>

          <S.Tip
            textColor={textColor}
            borderColor={`${textColor}80`}
            backgroundColor={backgroundColor}
          >
            {tolltip}
          </S.Tip>
        </S.Tolltip>
      </S.TitleAndTip>

      {content}
    </S.Box>
  );
};

export default PublicBox;
