import styled from "styled-components";
import Button from "../../atoms/Button";

export const Container = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  margin-top: 12px;
  flex-direction: column;
`;

export const RemoveItem = styled(Button)`
  padding: 8px 16px;
`;
