/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from "react";
import InputBase from "../../atoms/InputBase";
import { IDate, ITime } from "../../../types/dates";

import * as S from "./styles";

interface IProps {
  time?: {
    start: ITime;
    end: ITime;
  };
  dates?: IDate[];
  errors?: string[];
  isAllDay?: boolean;
  dateIndex?: number;
  timeType?: "start" | "end";
  onChange: (val: any, field: string) => void;
  variant?: "complete" | "fullDate" | "dayAndMonth" | "time";
}

const DateTime: React.FC<IProps> = ({
  time,
  dates,
  errors,
  timeType,
  onChange,
  dateIndex,
  variant = "complete",
}) => {
  const dayInput = useRef<HTMLInputElement | null>(null);
  const monthInput = useRef<HTMLInputElement | null>(null);
  const yearInput = useRef<HTMLInputElement | null>(null);
  const hoursInput = useRef<HTMLInputElement | null>(null);
  const minutesInput = useRef<HTMLInputElement | null>(null);

  const date = (dates || [])[dateIndex || 0];

  const timeFocused = (time || {})[timeType || "start"] || null;

  const onChangeDate = (key: "day" | "month" | "year", value: string) => {
    const currentValue = date[key];

    const normalizers = {
      day: /[^0-90-9]/g,
      month: /[^0-90-9]/g,
      year: /[^0-90-90-90-9]/g,
    };

    const errors = {
      day: (val: number) => val > 31,
      month: (val: number) => val > 12,
      year: (val: number) => val > new Date().getFullYear() + 5,
    };

    const normalizedValue = value.replace(normalizers[key], "");

    const isErasing = normalizedValue.length < (currentValue || "").length;

    const error = errors[key](+normalizedValue);

    if (error) return;

    const newDates = [...(dates || [])];

    newDates[dateIndex || 0] = {
      ...newDates[dateIndex || 0],
      [key]: normalizedValue,
    };

    if (normalizedValue.length === 2) {
      if (key === "day") {
        monthInput.current && monthInput.current.focus();
      }
      if (key === "month") {
        yearInput.current && yearInput.current.focus();
      }
    }

    if (isErasing && normalizedValue.length === 0) {
      if (key === "year") {
        monthInput.current && monthInput.current.focus();
      }
      if (key === "month") {
        dayInput.current && dayInput.current.focus();
      }
    }

    onChange(newDates, "dates");
  };

  const onChangeTime = (key: "hours" | "minutes", value: string) => {
    const currentValue = timeFocused && timeFocused[key];

    const normalizers = {
      hours: /[^0-90-9]/g,
      minutes: /[^0-90-9]/g,
    };

    const errors = {
      hours: (val: number) => val > 23,
      minutes: (val: number) => val > 59,
    };

    const normalizedValue = value.replace(normalizers[key], "");

    const isErasing = normalizedValue.length < (currentValue || "").length;

    const error = errors[key](+normalizedValue);

    if (error) return;

    if (isErasing && normalizedValue.length === 0 && key === "minutes") {
      hoursInput.current && hoursInput.current.focus();
    }

    if (normalizedValue.length === 2 && key === "hours") {
      minutesInput.current && minutesInput.current.focus();
    }

    const newInputTime = JSON.parse(
      JSON.stringify(
        time || {
          start: { hours: "", minutes: "" },
          end: { hours: "", minutes: "" },
        }
      )
    );

    newInputTime[timeType || "start"][key] = normalizedValue;

    onChange(newInputTime, "time");
  };

  const dateTimeVariants = {
    complete: (
      <S.Container>
        <InputBase
          ref={dayInput}
          placeholder="dd"
          value={date?.day || ""}
          onChange={({ target }) => onChangeDate("day", target.value)}
        />

        <span>/</span>

        <InputBase
          ref={monthInput}
          placeholder="mm"
          value={date?.month || ""}
          onChange={({ target }) => onChangeDate("month", target.value)}
        />

        <S.Slash>/</S.Slash>

        <InputBase
          ref={yearInput}
          placeholder="aaaa"
          value={date?.year || ""}
          onChange={({ target }) => onChangeDate("year", target.value)}
        />

        <S.Hyphen>-</S.Hyphen>

        <InputBase
          ref={hoursInput}
          placeholder="HH"
          value={timeFocused?.hours || ""}
          onChange={({ target }) => onChangeTime("hours", target.value)}
        />

        <span>:</span>

        <InputBase
          ref={minutesInput}
          placeholder="MM"
          value={timeFocused?.minutes || ""}
          onChange={({ target }) => onChangeTime("minutes", target.value)}
        />
      </S.Container>
    ),
    fullDate: (
      <S.Container>
        <InputBase
          ref={dayInput}
          placeholder="dd"
          value={date?.day || ""}
          onChange={({ target }) => onChangeDate("day", target.value)}
        />

        <span>/</span>

        <InputBase
          ref={monthInput}
          placeholder="mm"
          value={date?.month || ""}
          onChange={({ target }) => onChangeDate("month", target.value)}
        />

        <S.Slash>/</S.Slash>

        <InputBase
          ref={yearInput}
          placeholder="aaaa"
          value={date?.year || ""}
          onChange={({ target }) => onChangeDate("year", target.value)}
        />
      </S.Container>
    ),
    dayAndMonth: (
      <S.Container>
        <InputBase
          ref={dayInput}
          placeholder="dd"
          value={date?.day || ""}
          onChange={({ target }) => onChangeDate("day", target.value)}
        />

        <span>/</span>

        <InputBase
          ref={monthInput}
          placeholder="mm"
          value={date?.month || ""}
          onChange={({ target }) => onChangeDate("month", target.value)}
        />
      </S.Container>
    ),
    time: (
      <S.Container>
        <InputBase
          ref={hoursInput}
          placeholder="HH"
          value={timeFocused?.hours || ""}
          onChange={({ target }) => onChangeTime("hours", target.value)}
        />

        <span>:</span>

        <InputBase
          ref={minutesInput}
          placeholder="MM"
          value={timeFocused?.minutes || ""}
          onChange={({ target }) => onChangeTime("minutes", target.value)}
        />
      </S.Container>
    ),
  };

  return (
    <>
      {dateTimeVariants[variant]}
      {errors && errors.length && (
        <S.ErrorMessage color="#FF4D4F">
          Erros: {errors.join(", ")}
        </S.ErrorMessage>
      )}
    </>
  );
};

export default DateTime;
