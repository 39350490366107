import styled from "styled-components";

type H3Props = {
  color: string;
};

const H3 = styled.h3<H3Props>`
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
  color: ${({ color }) => color};
`;

export default H3;
