import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const TiktokIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="50"
      height="40"
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.2903 0C32.906 5.53428 35.8615 8.83379 41 9.1848V15.4094C38.0221 15.7136 35.4137 14.6957 32.3798 12.7768V24.4187C32.3798 39.208 16.9531 43.8296 10.751 33.2291C6.76556 26.4078 9.20609 14.4383 21.9908 13.9586V20.5225C21.0169 20.6863 19.9757 20.9437 19.0241 21.283C16.1806 22.2892 14.5685 24.173 15.0163 27.4959C15.8783 33.8609 27.051 35.7447 26.1218 23.3072V0.0117004H32.2903V0Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default TiktokIcon;
