import styled, { css } from "styled-components";

type BoxProps = {
  backgroundColor: string;
};

export const Box = styled.div<BoxProps>`
  ${({ backgroundColor }) => css`
    width: 100%;
    display: flex;
    padding: 20px;
    border-radius: 12px;
    flex-direction: column;
    background-color: ${backgroundColor};
  `}
`;

export const IconAndTip = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: space-between;
`;

export const Icon = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Tolltip = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;

  :hover {
    span {
      visibility: visible;
    }
  }
`;

export const TipIcon = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);

  svg {
    width: 100%;
    height: 100%;
  }
`;

type TipProps = {
  textColor: string;
  borderColor: string;
  backgroundColor: string;
};

export const Tip = styled.span<TipProps>`
  ${({ textColor, borderColor, backgroundColor }) => css`
    top: 0;
    width: 100%;
    right: 16px;
    z-index: 100;
    padding: 12px;
    font-size: 12px;
    position: absolute;
    visibility: hidden;
    border-radius: 8px;
    color: ${textColor};
    border: 1px solid ${borderColor};
    background-color: ${backgroundColor};
  `}
`;

export const ValueAndLabel = styled.div`
  display: flex;
  flex-direction: column;
`;
