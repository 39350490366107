import axios from "axios";

import { logoutUserIfTokenInvalid } from "../../utils";

const { REACT_APP_APPS_API_URL } = process.env;

const getContent = async (token: string, itemId: string) => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_APPS_API_URL}/youtube/${itemId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.item || {};
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter o conteúdo");
  }
};

const createContent = async (
  token: string,
  content: { url: string; type: "video" | "channel" }
) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_APPS_API_URL}/youtube`,
      content,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.item || {};
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao salvar o conteúdo");
  }
};

const updateContent = async (
  token: string,
  id: string,
  content: { url: string; type: "video" | "channel" }
) => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_APPS_API_URL}/youtube/${id}`,
      content,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.item;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao atualizar o conteúdo");
  }
};

const deleteContent = async (token: string, id: string) => {
  try {
    const { data } = await axios.delete(
      `${REACT_APP_APPS_API_URL}/youtube/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao remover o conteúdo");
  }
};

export default {
  getContent,
  deleteContent,
  createContent,
  updateContent,
};
