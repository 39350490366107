import styled from "styled-components";
import Box from "../../../../componentsV2/atoms/Box";

export const Text = styled.div`
  display: flex;
  margin-top: 40px;
`;

export const BoxContainer = styled(Box)`
  gap: 20px;
`;

export const Buttons = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`;
