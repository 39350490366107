import styled from "styled-components";
import Button from "../Button";

export const Container = styled.div`
  display: flex;
  align-items: center;

  input {
    width: 58px;
    outline: none;
    font-size: 1rem;
    padding: 8px 0px;
    text-align: center;
  }
`;

export const MinusButton = styled(Button)`
  margin-right: 1rem;
  font-size: 1.5rem;
  line-height: 1rem;
`;

export const PlusButton = styled(Button)`
  margin-left: 1rem;
  font-size: 1.5rem;
  line-height: 1rem;
`;
