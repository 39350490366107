import styled from "styled-components";

export const MercadoPagoButton = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  cursor: pointer;
  user-select: none;
  position: relative;
  border-radius: 12px;
  align-items: center;
  justify-content: space-between;
  background-color: #fafafa05;

  .mercadoPagoLogo {
    z-index: 0;
    width: 33%;
    height: 100%;
    display: flex;
    min-width: 140px;
    padding: 8px 16px;
    position: relative;
    border-radius: 12px;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      max-width: 120px;
      margin-left: -4px;
    }
  }
`;

export const ButtonLabel = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  width: 50%;
  height: 56px;
  border-radius: 12px;

  background-color: #d9d9d905;
`;

export const Icon = styled.div`
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
