import { ResponsiveBar } from "@nivo/bar";

import * as S from "./styles";
import {
  selectedColors,
  calculatePercentageBar,
} from "../../../../../../../../utils/media-kit";
import { Theme } from "../../../../../../../../hooks";
import { ISocialMediaEntity } from "../../../../../../../../types/media-kit";

interface IProps {
  data: ISocialMediaEntity["followerAge"];
}

const BarChart: React.FC<IProps> = ({ data }) => {
  const { textColor, primaryColor } = Theme.useTheme();

  const colors = selectedColors(primaryColor);

  const normData = [
    {
      ageRange: "<18",
      value: data["<18"],
    },
    {
      ageRange: "18-24",
      value: data["18-24"],
    },
    {
      ageRange: "25-34",
      value: data["25-34"],
    },
    {
      ageRange: ">34",
      value: data[">34"],
    },
  ];

  const percData = calculatePercentageBar(normData);

  const range = (() => {
    const max = percData.reduce((acc, curr) => {
      return curr.value > acc ? curr.value : acc;
    }, 0);

    const range = [0];
    let i = 1;
    while (max >= (i - 1) / 10) {
      range.push(i / 10);
      i += 1;
    }

    return range;
  })();

  return (
    <S.Container axisValuesColor={textColor}>
      <ResponsiveBar
        colorBy="id"
        minValue={0}
        data={percData}
        indexBy="ageRange"
        enableLabel={false}
        gridYValues={range}
        valueFormat=" >-.0%"
        valueScale={{ type: "linear" }}
        maxValue={range[range.length - 1]}
        margin={{ top: 20, right: 0, bottom: 20, left: 36 }}
        axisLeft={{
          tickSize: 4,
          format: ">-.0%",
          tickValues: range,
          tickPadding: 4,
          tickRotation: 0,
          legendPosition: "middle",
        }}
        axisBottom={{
          tickSize: 0,
          tickPadding: 8,
        }}
        defs={[
          {
            id: "<18",
            type: "linearGradient",
            colors: [
              { offset: 0, color: colors[300] },
              { offset: 100, color: colors[300] },
            ],
          },
          {
            id: "18-24",
            type: "linearGradient",
            colors: [
              { offset: 0, color: colors[400] },
              { offset: 100, color: colors[400] },
            ],
          },
          {
            id: "25-34",
            type: "linearGradient",
            colors: [
              { offset: 0, color: colors[600] },
              { offset: 100, color: colors[600] },
            ],
          },
          {
            id: ">34",
            type: "linearGradient",
            colors: [
              { offset: 0, color: colors[700] },
              { offset: 100, color: colors[700] },
            ],
          },
        ]}
        fill={[
          { match: (d) => d.data.data.ageRange === "<18", id: "<18" },
          { match: (d) => d.data.data.ageRange === "18-24", id: "18-24" },
          { match: (d) => d.data.data.ageRange === "25-34", id: "25-34" },
          { match: (d) => d.data.data.ageRange === ">34", id: ">34" },
        ]}
      />
    </S.Container>
  );
};

export default BarChart;
