import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import { Order } from "../../services";
import Header from "./components/Header";
import NoSales from "./components/NoSales";
import Page from "../../components/atoms/Page";
import { IIncomeReport } from "../../services/order";
import Button from "../../componentsV2/atoms/Button";
import P from "../../componentsV2/atoms/Typography/P";
import { normalizeNumber } from "../../utils/numbers";
import H4 from "../../componentsV2/atoms/Typography/H4";
// import RadioMenu from "../../componentsV2/atoms/RadioMenu";
import { Auth, Loading, Snackbar, Theme } from "../../hooks";
import Label from "../../componentsV2/atoms/Typography/Label";
import TitleDescription from "../../componentsV2/molecules/TitleDescription";
import CollapsableOrder from "../../componentsV2/molecules/CollapsableOrder";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();

  // const [optionSelected, setOptionSelected] = useState<number>(0);
  const [saleClicked, setSaleClicked] = useState<number | null>();
  const [incomeReport, setIncomeReport] = useState<IIncomeReport | null>(null);

  const { token } = Auth.useAuth();
  const { newError } = Snackbar.useSnackbar();
  const { primaryColor, textColor } = Theme.useTheme();
  const { showLoading, hideLoading } = Loading.useLoading();

  const onClickBack = () => {
    navigate("/");
  };

  useEffect(() => {
    const run = async () => {
      try {
        showLoading();
        const dataIncomeReport = await Order.getIncomeReport(token);
        setIncomeReport(dataIncomeReport);
      } catch {
        newError("Houve um erro ao obter as vendas");
      } finally {
        hideLoading();
      }
    };
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalValueNorm = normalizeNumber(
    incomeReport?.totalizers.allTimeValue || 0
  );
  const monthValueNorm = normalizeNumber(
    incomeReport?.totalizers.thisMonthValue || 0
  );

  const hasSales =
    (incomeReport?.totalizers.productsWithSales || []).length > 0;

  const onSaleClick = (index: number) => {
    if (index === saleClicked) {
      setSaleClicked(null);
      return;
    }
    setSaleClicked(index);
  };

  return (
    <Page>
      <Header onClickBack={() => onClickBack()} />

      {/* <div style={{ margin: "40px 0 20px" }}>
        <RadioMenu
          protectionSize="single"
          selectedIndex={optionSelected}
          values={["Vendas", "Analytics"]}
          onSelect={(val) => setOptionSelected(val)}
        />
      </div> */}

      {/* {optionSelected === 0 ? ( */}
      <S.BoxContainer>
        <TitleDescription
          title="Faturamento"
          titleColor={textColor}
          textColor={`${textColor}80`}
        >
          Acompanhe o quanto você já vendeu
        </TitleDescription>

        <S.Revenue backgroundColor={primaryColor}>
          <S.ThisMonth>
            <Label color={`${textColor}80`}>Este mês</Label>

            <P color={primaryColor} fontWeight="400">
              R$ {monthValueNorm}
            </P>
          </S.ThisMonth>

          <S.SinceAlways>
            <Label color={`${textColor}80`}>Desde sempre</Label>

            <P color={textColor} fontWeight="400">
              R$ {totalValueNorm}
            </P>
          </S.SinceAlways>
        </S.Revenue>
      </S.BoxContainer>

      <S.BoxContainer>
        <H4 color={textColor}>Últimas vendas</H4>

        {hasSales ? (
          <>
            {(incomeReport?.sales.items || [])
              .slice(0, 3)
              .map((sale, index) => (
                <CollapsableOrder
                  sale={sale}
                  key={sale.id}
                  isOpen={index === saleClicked}
                  onClick={() => onSaleClick(index)}
                  productsWithSales={
                    incomeReport?.totalizers.productsWithSales || []
                  }
                />
              ))}

            <Button
              variant="solid"
              textColor={textColor}
              onClick={() => navigate("/dashboard/statement")}
            >
              Ver extrato
            </Button>
          </>
        ) : (
          <NoSales
            title="Nenhuma venda por enquanto!"
            description="Você ainda não possui vendas registradas. Clique em adicionar para
              incluir serviços e produtos para venda."
          />
        )}
      </S.BoxContainer>
      {/* ) : ()} */}
    </Page>
  );
};

export default Dashboard;
