import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const YoutubeIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="50"
      height="40"
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.2996 5.89565C43.1498 6.38847 44.6069 7.84056 45.1014 9.6846C46 13.0267 46 20 46 20C46 20 46 26.9731 45.1014 30.3154C44.6069 32.1594 43.1498 33.6115 41.2996 34.1046C37.9465 35 24.5 35 24.5 35C24.5 35 11.0535 35 7.70019 34.1046C5.84997 33.6115 4.39286 32.1594 3.89836 30.3154C3 26.9731 3 20 3 20C3 20 3 13.0267 3.89836 9.6846C4.39286 7.84056 5.84997 6.38847 7.70019 5.89565C11.0535 5 24.5 5 24.5 5C24.5 5 37.9465 5 41.2996 5.89565ZM20.4688 14.1304V27.1739L31.2188 20.6524L20.4688 14.1304Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default YoutubeIcon;
