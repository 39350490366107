import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import {
  IPosts,
  IPostsEntity,
  ISocialMediaEntity,
  IPersonalInfoEntity,
} from "../../../../types/media-kit";
import AddPost from "./components/AddPost";
import { MediaKit } from "../../../../services";
import BrandInput from "./components/BrandInput";
import PostsSelector from "./components/PostsSelector";
import {
  emptyUserInfo,
  getTiktokAuhtorizationUrl,
  getInstagramAuhtorizationUrl,
} from "../../../../utils/media-kit";
import H3 from "../../../../componentsV2/atoms/Typography/H3";
import { Auth, Loading, NavBar, Snackbar, Theme } from "../../../../hooks";

const Brands: React.FC = () => {
  const [posts, setPosts] = useState<IPosts[]>();
  const [postsData, setPostsData] = useState<IPostsEntity>();
  const [selectedPost, setSelectedPost] = useState<IPosts>();
  const [brandUsername, setBrandUsername] = useState<string>();
  const [brandInput, setBrandInput] = useState<boolean>(false);
  const [currentPosts, setCurrentPosts] = useState<IPosts[]>([]);
  const [selectingPost, setSelectingPost] = useState<boolean>(false);
  const [tiktokData, setTiktokData] = useState<ISocialMediaEntity>();
  const [instagramData, setInstagramData] = useState<ISocialMediaEntity>();
  const [userInfo, setUserInfo] = useState<IPersonalInfoEntity>(emptyUserInfo);
  const [postSelectedSocialMedia, setPostSelectedSocialMedia] = useState<
    "instagram" | "tiktok"
  >();

  const { token } = Auth.useAuth();
  const { primaryColor, textColor } = Theme.useTheme();
  const { hideNavBar, showNavBar } = NavBar.useNavBar();
  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();

  const navigate = useNavigate();

  useEffect(() => {
    showLoading();

    try {
      const run = async () => {
        if (!brandInput) {
          const fullData = await MediaKit.getMediaKitData(token);

          if (fullData.mediaKitData.personal) {
            setUserInfo(fullData.mediaKitData.personal);
          }
          if (fullData.mediaKitData.tiktok) {
            setTiktokData(fullData.mediaKitData.tiktok);
          }
          if (fullData.mediaKitData.instagram) {
            setInstagramData(fullData.mediaKitData.instagram);
          }
          if (fullData.mediaKitData.posts) {
            const sortedPosts = fullData.mediaKitData.posts.posts.sort(
              (a, b) => a.order - b.order
            );
            setPostsData({
              ...fullData.mediaKitData.posts,
              posts: sortedPosts,
            });
            setCurrentPosts(sortedPosts);
          }
        }
      };

      run();
    } catch (error) {
      newError("Houve um erro ao importar os dados do usuário");
    } finally {
      hideLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandInput]);

  const onClickInstagram = () => {
    if (instagramData) {
      const run = async () => {
        const instagramPosts = await MediaKit.getInstagramPosts(token);
        setPosts(instagramPosts);
        setPostSelectedSocialMedia("instagram");
        setSelectingPost(true);
      };

      run();
      return;
    }
    hideNavBar();

    const authorizationUrl = getInstagramAuhtorizationUrl();

    window.location.href = authorizationUrl;

    return () => showNavBar();
  };

  const onClickTiktok = () => {
    if (tiktokData) {
      const run = async () => {
        const tiktokPosts = await MediaKit.getTiktokPosts(token);
        setPosts(tiktokPosts);
        setPostSelectedSocialMedia("tiktok");
        setSelectingPost(true);
      };

      run();
      return;
    }
    hideNavBar();

    const authorizationUrl = getTiktokAuhtorizationUrl();

    window.location.href = authorizationUrl;

    return () => showNavBar();
  };

  const onSelectPosts = (post: IPosts) => {
    setSelectedPost(post);
    setSelectingPost(false);
    setBrandInput(true);
  };

  const onSaveSelectedPostHandler = async () => {
    showLoading();

    try {
      if (postSelectedSocialMedia === "tiktok") {
        await MediaKit.savePostTiktok(
          token,
          brandUsername || "",
          selectedPost?.id || ""
        );
      } else {
        await MediaKit.savePostInstagram(
          token,
          brandUsername || "",
          selectedPost?.id || ""
        );
      }

      setBrandInput(false);
      setBrandUsername("");
      newSuccess("Post salvo com sucesso");
    } catch (error) {
      newError("Houve um erro ao salvar o post");
    } finally {
      hideLoading();
    }
  };

  const onSaveReorder = async () => {
    showLoading();

    try {
      const postsIds = currentPosts.map((post) => post.id);

      await MediaKit.savePostsReorder(token, postsIds);

      navigate("/apps/media-kit/edit");
      newSuccess("Alterações dos posts salvas com sucessos");
    } catch (error) {
      newError("Houve um erro ao salvar a alteração dos posts");
    } finally {
      hideLoading();
    }
  };

  const onDiscardHandler = () => {
    setBrandInput(false);
    setSelectedPost(undefined);
  };

  const onRemovePost = async (post: IPosts) => {
    await MediaKit.removePost(token, post.socialMedia || "", post.id);
    setCurrentPosts((curr) => curr.filter((item) => item.id !== post.id));
  };

  const changedPosts =
    currentPosts.length === postsData?.posts.length &&
    JSON.stringify(currentPosts) !== JSON.stringify(postsData?.posts);

  const tiktokText = tiktokData ? "Adicionar publicação" : "Conectar";
  const instagramText = instagramData ? "Adicionar publicação" : "Conectar";

  const content = (() => {
    if (selectingPost) {
      return <PostsSelector posts={posts} onSelectPosts={onSelectPosts} />;
    } else if (brandInput) {
      return (
        <BrandInput
          onChangeUsername={setBrandUsername}
          onDiscardHandler={onDiscardHandler}
          brandUsername={brandUsername || ""}
          onSaveHandler={onSaveSelectedPostHandler}
          image={selectedPost?.thumbnailUrl || ""}
        />
      );
    }
    return (
      <AddPost
        onSave={onSaveReorder}
        tiktokText={tiktokText}
        saveEnable={changedPosts}
        setPosts={setCurrentPosts}
        instagramText={instagramText}
        onClickTiktok={onClickTiktok}
        onClickInstagram={onClickInstagram}
        onRemovePost={(val) => onRemovePost(val)}
        posts={currentPosts || ([] as IPosts[])}
      />
    );
  })();

  return (
    <S.Container>
      <S.Header>
        <S.CoverImage
          backgroundColor={primaryColor}
          background={userInfo?.coverImageUrl}
        />

        <S.ProfileContainer>
          <S.ProfileImageBox borderColor={primaryColor}>
            <S.Image
              size="large"
              imageUrl={userInfo?.profileImageUrl || primaryColor}
            />
          </S.ProfileImageBox>
        </S.ProfileContainer>
      </S.Header>

      <S.Content>
        <H3 color={textColor}>Marcas parceiras</H3>

        {content}
      </S.Content>
    </S.Container>
  );
};

export default Brands;
