import { useEffect, useState } from "react";

import * as S from "./styles";
import {
  IAppointments,
  IGetAppointmentsAndBlocksResponse,
} from "../../../../types/calendar";
import { weekDays } from "../../../../constants/calendar";
import { Analytics, Calendar } from "../../../../services";
import H4 from "../../../../componentsV2/atoms/Typography/H4";
import ExcludeAppointmentModal from "../ExcludeAppointmentModal";
import { Auth, Loading, Snackbar, Theme } from "../../../../hooks";
import CalendarAppointmentModal from "../CalendarAppointmentModal";
import AvailableIcons from "../../../../components/atoms/AvailableIcons";
import TitleDescription from "../../../../componentsV2/molecules/TitleDescription";
import ExternalLinkSkeleton from "../../../../components/atoms/ExternalLinkSkeleton";
import CollapsableAppointment from "../../../../componentsV2/molecules/CollapsableAppointment";

const YourAppointments: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [appointments, setAppointments] =
    useState<IGetAppointmentsAndBlocksResponse>({});
  const [toBeExcludedAppointment, setToBeExcludedAppointment] = useState<
    string | null
  >(null);
  const [collapsableOpen, setCollapsableOpen] = useState<number | null>();
  const [itemToBeAddedToCalendar, setItemToBeAddedToCalendar] =
    useState<IAppointments | null>(null);

  const { token, user } = Auth.useAuth();
  const { newError } = Snackbar.useSnackbar();
  const { textColor, primaryColor } = Theme.useTheme();
  const { showLoading, hideLoading } = Loading.useLoading();

  useEffect(() => {
    const run = async () => {
      try {
        const appointmentsData = await Calendar.getAppointmentsAndBlocks(token);

        setAppointments(appointmentsData || {});
      } catch (error) {
        newError("Houve um erro ao obter os agendamentos");
      } finally {
        setIsLoading(false);
      }
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const onExcludeAppointment = async () => {
    if (!toBeExcludedAppointment) return;

    Analytics.submitClickEvent({
      origin: "calendar",
      creator: user.id || "",
      description: "exclude-appointment",
      creatorUsername: user.username || "",
      actionDescription: `exclude-appointment-${toBeExcludedAppointment}`,
    });

    try {
      showLoading();

      await Calendar.removeAppointment(toBeExcludedAppointment, token);

      const appointmentsData = await Calendar.getAppointmentsAndBlocks(token);

      setAppointments(appointmentsData || {});
    } catch (error) {
      newError("Houve um erro ao remover o agendamento");
    } finally {
      setToBeExcludedAppointment(null);
      hideLoading();
    }
  };

  const appointmentDates = appointments ? Object.keys(appointments) : null;

  const appointmentsValues = Object.values(appointments).flat();

  const hasAppointments =
    appointmentsValues.length > 0 &&
    appointmentsValues.filter((item) => item.type !== "BLOCK").length > 0;

  const onClickCollapsable = (index: number) => {
    if (collapsableOpen === index) {
      setCollapsableOpen(null);
      return;
    }
    setCollapsableOpen(index);
  };

  if (isLoading) return <ExternalLinkSkeleton />;

  return (
    <>
      <S.YourAppointments>
        <S.BoxContainer>
          <TitleDescription
            titleColor={textColor}
            title="Seus agendamentos"
            textColor={`${textColor}80`}
          >
            Abaixo sua lista de agendamentos. Para cancelamentos ou
            reagendamentos, entrar em contato com o cliente pessoalmente.
          </TitleDescription>
        </S.BoxContainer>

        {appointmentDates && hasAppointments ? (
          <S.AppointmentList>
            {appointmentDates.map((item, index) => {
              const date = new Date(item);
              const day = date.getDate();
              const month = date.getMonth() + 1;
              const weekDay = date.getDay() as keyof typeof weekDays;

              const monthDate =
                day.toLocaleString("pt-BR", { minimumIntegerDigits: 2 }) +
                "/" +
                month.toLocaleString("pt-BR", { minimumIntegerDigits: 2 });

              const appointmentsList =
                appointments &&
                appointments[item].filter(
                  (appointment) => appointment.type !== "BLOCK"
                );

              if (appointmentsList && appointmentsList.length > 0) {
                return (
                  <CollapsableAppointment
                    key={weekDay}
                    date={monthDate}
                    weekDay={weekDays[weekDay]}
                    appointments={appointmentsList}
                    isOpen={collapsableOpen === index}
                    onExcludeAppointment={(val) =>
                      setToBeExcludedAppointment(val)
                    }
                    onSyncWithExternalCalendar={(val) =>
                      setItemToBeAddedToCalendar(val)
                    }
                    setIsOpen={() => onClickCollapsable(index)}
                  />
                );
              }
            })}
          </S.AppointmentList>
        ) : (
          <S.NoAppointments>
            <S.Icon backgroundColor={`${textColor}1a`}>
              <AvailableIcons option="calendar" color={primaryColor} />
            </S.Icon>

            <H4 color={textColor}>Você ainda não possui nenhum agendamento</H4>
          </S.NoAppointments>
        )}
      </S.YourAppointments>

      <ExcludeAppointmentModal
        onCloseModal={() => {
          setToBeExcludedAppointment(null);
        }}
        isOpen={!!toBeExcludedAppointment}
        onExcludeAppointment={onExcludeAppointment}
      />

      <CalendarAppointmentModal
        isOpen={!!itemToBeAddedToCalendar}
        appointment={itemToBeAddedToCalendar}
        onCloseModal={() => setItemToBeAddedToCalendar(null)}
      />
    </>
  );
};

export default YourAppointments;
