import styled from "styled-components";
import Box from "../../../../componentsV2/atoms/Box";

export const ImageContainer = styled.div`
  gap: 36px;
  display: flex;
  height: 132px;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`;

type ProfileImageProps = {
  imageUrl?: string;
};

export const ProfileImage = styled.div<ProfileImageProps>`
  width: 96px;
  height: 96px;
  flex-shrink: 0;
  position: relative;
  border-radius: 96px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${({ imageUrl }) => imageUrl});
`;

type ProfileImageBorderProps = {
  color: string;
};

export const ProfileImageBorder = styled.div<ProfileImageBorderProps>`
  top: -18px;
  left: -18px;
  width: 132px;
  height: 132px;
  position: absolute;
  border-radius: 132px;
  mask-composite: exclude;
  border: 9px solid transparent;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  background: ${({ color }) => `conic-gradient(
    from 180deg,
    ${color},
    rgba(10, 255, 181, 0)
  ) border-box`};

  @keyframes rotate {
    0% {
      transform: rotateZ(0deg);
    }

    100% {
      transform: rotateZ(360deg);
    }
  }
`;

type ProfileImagePlaceholderContainerProps = {
  backgroundColor: string;
};

export const ProfileImagePlaceholderContainer = styled(
  ProfileImage
)<ProfileImagePlaceholderContainerProps>`
  background: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BoxContainer = styled(Box)`
  gap: 20px;
`;

export const Channels = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;
