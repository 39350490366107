import {
  ChangeEvent,
  TouchEvent,
  TouchEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

import * as S from "./styles";
import { ImageGallery } from "../../../../services";
import Page from "../../../../components/atoms/Page";
import P from "../../../../components/atoms/Typography/P";
import { TImageGalleryOrientation } from "../../../../types";
import DropZone from "../../../../components/molecules/DropZone";
import Small from "../../../../components/atoms/Typography/Small";
import { Auth, Loading, Snackbar, Theme } from "../../../../hooks";
import appConfig from "../../../../config/app-galeria-imagens.json";
import SeparatorLine from "../../../../components/atoms/SeparatorLine";
import AppFormHeader from "../../../../components/atoms/AppFormHeader";
import DragAndDrop from "../../../../componentsV2/organisms/DragAndDrop";
import AppFormFooter from "../../../../components/molecules/AppFormFooter";
import MediaWithLink from "../../../../componentsV2/molecules/MediaWithLink";
import ImageReposition from "../../../../components/molecules/ImageReposition";
import PhotoFormatSelector from "../../../../components/atoms/PhotoFormatSelector";

export const formatData: {
  [key in TImageGalleryOrientation]: {
    title: string;
    width: number;
    height: number;
  };
} = {
  landscape: {
    width: 320,
    height: 320 * (9 / 16),
    title: "Paisagem • 16:9",
  },
  portrait: {
    width: 320,
    height: (320 * 5) / 4,
    title: "Retrato • 4:5",
  },
  square: {
    width: 320,
    height: 320,
    title: "Quadrado • 1:1",
  },
};

const Form: React.FC = () => {
  const [rawImage, setRawImage] = useState("");
  const [hoveredDropZone, setHoveredDropZone] = useState(-1);
  const [isMobileDragOn, setIsMobileDragOn] = useState(false);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const [indexOfItemOnDrag, setIndexOfItemOnDrag] = useState(-1);
  const [images, setImages] = useState<{ url: string; link?: string }[]>([]);
  const [selectedFormat, setSelectedFormat] =
    useState<TImageGalleryOrientation>("portrait");

  const { id } = useParams();
  const isEditingItem = !!id;

  const input = useRef<HTMLInputElement | null>(null);
  const linksContainer = useRef<HTMLDivElement>(null);
  const scrollInterval = useRef<NodeJS.Timer | null>(null);

  const navigate = useNavigate();
  const { token } = Auth.useAuth();
  const { primaryColor, textColor } = Theme.useTheme();
  const { newError } = Snackbar.useSnackbar();
  const { hideLoading, showLoading } = Loading.useLoading();

  useEffect(() => {
    const run = async () => {
      if (!id) return;

      try {
        showLoading();
        const galleryData = await ImageGallery.getGallery(id, token);

        if (galleryData) {
          setImages(galleryData.images || []);
          setSelectedFormat(galleryData.orientation || "portrait");
        }
      } catch (error) {
        newError("Houve um erro obter a galeria");
      } finally {
        hideLoading();
      }
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeImage = async (image: string) => {
    showLoading();
    try {
      const url = await ImageGallery.uploadImage(image, token);

      setImages((curr) => [...curr, { url, link: "" }]);
    } catch (error) {
      newError("Houve um erro ao enviar a sua imagem");
    } finally {
      hideLoading();
    }
  };

  const onSelectFormatHandler = (formatId: TImageGalleryOrientation) => {
    setSelectedFormat(formatId);
  };

  const onChangeImageHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const file = (files || [])[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async () => {
        const base64 = reader.result?.toString();

        if (base64) setRawImage(base64);

        if (input.current) input.current.value = "";
      };
    }
  };

  const onDiscardImage = () => {
    setRawImage("");
  };

  const setLinkHandler = (value: string, index: number) => {
    setImages((curr) => {
      const newImages = [...curr];
      if (!newImages[index].link)
        newImages[index] = { ...newImages[index], link: value };
      else newImages[index].link = value;
      return newImages;
    });
  };

  const removeGalleryHandler = async () => {
    if (!id) return;

    try {
      showLoading();
      await ImageGallery.removeGallery(id, token);
      navigate(`/`);
    } catch (error) {
      newError("Houve um erro ao remover a galeria");
    } finally {
      hideLoading();
    }
  };

  const removeImageHandler = async (index: number) => {
    setImages((curr) => {
      const newImages = [...curr];
      newImages.splice(index, 1);

      return newImages;
    });
  };

  const onSaveHandler = async () => {
    if (images.length < 1) {
      newError("Adicione ao menos uma imagem para publicar");
      return;
    }

    try {
      showLoading();
      if (!id) {
        await ImageGallery.createGallery(selectedFormat, images, token);
      } else {
        await ImageGallery.updateGallery(id, selectedFormat, images, token);
      }
      navigate(`/`);
    } catch (error) {
      if (!id) {
        newError("Houve um erro ao criar a galeria");
      } else {
        newError("Houve um erro ao atualizar a galeria");
      }
    } finally {
      hideLoading();
    }
  };

  const touchCancelHandler: TouchEventHandler<HTMLDivElement> = () => {
    if (scrollInterval.current) {
      clearInterval(scrollInterval.current);
      scrollInterval.current = null;
    }

    setIsMobileDragOn(false);
    setIndexOfItemOnDrag(-1);
    setHoveredDropZone(-1);
  };

  const touchStartHandler = (e: TouchEvent<HTMLDivElement>, index: number) => {
    setIndexOfItemOnDrag(index);
    setCoordinates({
      x: e.targetTouches[0].pageX - 20,
      y: e.targetTouches[0].pageY - 80,
    });
    setIsMobileDragOn(true);
  };

  const touchEndHandler: TouchEventHandler<HTMLDivElement> = () => {
    if (scrollInterval.current) {
      clearInterval(scrollInterval.current);
      scrollInterval.current = null;
    }

    setIsMobileDragOn(false);
    setIndexOfItemOnDrag(-1);
    setHoveredDropZone(-1);

    onDropHandler();
  };

  const touchMoveHandler: TouchEventHandler<HTMLDivElement> = (e) => {
    if (!linksContainer.current) return;

    setCoordinates({
      x: e.targetTouches[0].pageX - 20,
      y: e.targetTouches[0].pageY - 80,
    });

    const hoveredIndex = Math.floor(
      (e.targetTouches[0].pageY - (linksContainer.current.offsetTop || 0)) /
        (linksContainer.current.offsetHeight / images.length)
    );
    const finalHoveredIndex =
      hoveredIndex > indexOfItemOnDrag ? hoveredIndex + 1 : hoveredIndex;

    setHoveredDropZone(finalHoveredIndex);

    if (scrollInterval.current) {
      clearInterval(scrollInterval.current);
      scrollInterval.current = null;
    }

    if (e.targetTouches[0].clientY > window.innerHeight - 60) {
      scrollInterval.current = setInterval(() => {
        window.scrollTo({ top: window.scrollY + 6 });
      }, 20);
    }
    if (e.targetTouches[0].clientY < 60) {
      scrollInterval.current = setInterval(() => {
        window.scrollTo({ top: window.scrollY - 6 });
      }, 20);
    }
  };

  const onDropHandler = () => {
    if (hoveredDropZone <= -1) return;

    setImages((curr) => {
      const newArr = [...curr];
      const draggedElement = newArr.splice(indexOfItemOnDrag, 1)[0];

      const indexToInsertItem =
        indexOfItemOnDrag < hoveredDropZone
          ? hoveredDropZone - 1
          : hoveredDropZone;

      newArr.splice(indexToInsertItem, 0, draggedElement);
      return newArr;
    });

    setHoveredDropZone(-1);
    setIndexOfItemOnDrag(-1);
  };

  return (
    <Page>
      <AppFormHeader
        appTitle={appConfig.title}
        showLiveLabel={appConfig.showIsLiveLabel}
        onBack={() => navigate(`/apps`)}
      />

      <S.Info>
        <P color={primaryColor}>{isEditingItem ? "Galeria" : "Nova galeria"}</P>

        <Small color={textColor}>Formato da galeria</Small>

        <PhotoFormatSelector
          disabled={images.length > 0}
          selectedFormat={selectedFormat}
          onSelectFormat={onSelectFormatHandler}
        />
      </S.Info>

      <SeparatorLine />

      <S.AddMediaButton onClick={() => input.current?.click()}>
        <div className="addIcon">
          <P color="#71726F">+</P>
        </div>

        <P color="#71726F">Adicionar foto</P>

        <input
          type="file"
          ref={input}
          onChange={onChangeImageHandler}
          accept="image/png, image/jpeg, image/jpg, image/webp"
        />
      </S.AddMediaButton>

      {images.length > 0 && (
        <S.ImagesOrder ref={linksContainer}>
          <S.SectionTitle color={textColor}>Ordem das fotos:</S.SectionTitle>

          <DropZone
            isDroppable
            onDrop={onDropHandler}
            isHovered={hoveredDropZone === 0}
            onDragEnter={() => setHoveredDropZone(0)}
            onDragLeave={() => setHoveredDropZone(-1)}
          />

          {images.map((item, index) => (
            <DragAndDrop
              onDragEnd={() => null}
              coordinates={coordinates}
              onDropHandler={onDropHandler}
              key={`${item.link}#${item.url}`}
              touchEndHandler={touchEndHandler}
              touchMoveHandler={touchMoveHandler}
              touchCancelHandler={touchCancelHandler}
              onDragLeave={() => setHoveredDropZone(-1)}
              onDragStart={() => setIndexOfItemOnDrag(index)}
              isDropZoneHovered={index + 1 === hoveredDropZone}
              onDragEnter={() => setHoveredDropZone(index + 1)}
              touchStartHandler={(e) => touchStartHandler(e, index)}
              isMobileDragOn={isMobileDragOn && index === indexOfItemOnDrag}
              draggableItem={
                <MediaWithLink
                  allowMediaRemoval
                  index={index || 0}
                  galleryItem={{
                    url: item.url,
                    link: item.link || "",
                  }}
                  onRemoveMedia={() => removeImageHandler(index)}
                  setLink={(value: string) => setLinkHandler(value, index)}
                />
              }
            />
          ))}
        </S.ImagesOrder>
      )}

      <AppFormFooter
        saveDraft={false}
        appTitle="galeria"
        onSave={onSaveHandler}
        isEditingItem={isEditingItem}
        onRemove={removeGalleryHandler}
      />

      {rawImage && (
        <ImageReposition
          borderRadius={0}
          initialImage={rawImage}
          onChangeImage={onChangeImage}
          onDiscardImage={onDiscardImage}
          width={formatData[selectedFormat].width}
          height={formatData[selectedFormat].height}
        />
      )}
    </Page>
  );
};

export default Form;
