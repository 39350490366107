import styled from "styled-components";
import Button from "../../componentsV2/atoms/Button";
import H1 from "../../components/atoms/Typography/H1";
import P from "../../componentsV2/atoms/Typography/P";
import H5 from "../../componentsV2/atoms/Typography/H5";
import bgImage from "../../components/assets/image_bg.png";
import Small from "../../components/atoms/Typography/Small";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  &::after {
    content: "";
    width: 120%;
    height: 120%;
    top: -100px;
    left: -75px;
    cursor: none;
    position: absolute;
    filter: blur(50px);
    pointer-events: none;
    background-image: url(${bgImage});
    background-size: cover;
    background-position: top center;
  }
`;

export const Back = styled(Button)`
  color: #fafafa;
`;

export const RegisterFormHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    transform: scale(-1);
  }
`;

export const TextContainer = styled.div`
  width: 50%;
  margin-top: 50px;

  @media (max-width: 600px) {
    width: 80%;
  }

  span {
    font-weight: 600;
  }
`;

export const Title = styled(H1)`
  font-weight: 300;

  @media (max-width: 600px) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`;

export const Subtitle = styled(H5)`
  margin-top: 10px;
  line-height: 1.5rem;
`;

export const RegisterFormFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  margin-top: 120px;
`;

export const LinkText = styled(P)`
  position: absolute;
  top: 100px;
`;

export const Form = styled.div`
  gap: 2rem;
  display: flex;
  flex-direction: column;
  z-index: 100;
`;

export const Message = styled(Small)`
  white-space: pre-wrap;
  text-align: center;
`;
