import styled from "styled-components";
import Box from "../../../../componentsV2/atoms/Box";
import Button from "../../../../componentsV2/atoms/Button";
import P from "../../../../componentsV2/atoms/Typography/P";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Container = styled.div`
  z-index: 10;
  width: 100%;
  flex-grow: 1;
  display: flex;
  max-width: 960px;
  flex-direction: column;
`;

export const Back = styled(Button)`
  svg {
    transform: scale(-1);
  }
`;

export const RegisterFormHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProgressBar = styled.div`
  gap: 18px;
  display: flex;
  margin-top: 2.5rem;
  flex-direction: column;
`;

export const Text = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: column;

  span {
    font-weight: 400;
  }
`;

export const Title = styled(P)`
  font-size: 1.5rem;
`;

export const Subtitle = styled(P)`
  margin-top: 1.5rem;
  font-size: 1rem;
`;

export const Highlight = styled.span`
  font-weight: 500;
`;

type CallToActionProps = {
  color: string;
};

export const CallToAction = styled.div<CallToActionProps>`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  h2 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 300;
  }
`;

export const Form = styled(Box)`
  gap: 20px;
  display: flex;
  flex-direction: column;

  .text {
    display: flex;
    margin-top: -100px;
    margin-bottom: 20px;
    justify-content: center;
  }
`;

export const InputSeparator = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  &:not(:last-child)::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #fafafa26;
  }
`;

type InputContainerProps = {
  inputTextColor: string;
};

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  align-items: center;
  gap: 12px;

  input {
    color: ${({ inputTextColor }) => inputTextColor};
  }

  svg {
    width: 24px;
    height: auto;
  }
`;

export const NextButton = styled(Button)`
  margin-top: 20px;
`;
