import * as S from "./styles";
import { Theme } from "../../../hooks";

interface IProps {
  isOn: boolean;
  setIsOn: (isOn: boolean) => void;
}

const SwitchBase: React.FC<IProps> = ({ isOn, setIsOn }) => {
  const { primaryColor, textColor, backgroundColor } = Theme.useTheme();

  return (
    <S.Switcher
      isOn={isOn}
      onClick={() => setIsOn(!isOn)}
      backgroundColorOff={textColor}
      backgroundColorOn={primaryColor}
    >
      <S.SwitcherButton switcherColor={backgroundColor} role="button" />
    </S.Switcher>
  );
};

export default SwitchBase;
