import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const ArtstationIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="50"
      height="40"
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 29.7554L7.45532 35.726C8.13839 37.076 9.52842 38 11.1437 38H34.0776L29.3204 29.7554H4ZM44.1624 27.5537L30.8071 4.19374C30.1161 2.89204 28.7419 2 27.1588 2H20.0553L40.7312 37.9678L44.0017 32.3027C44.1545 32.0457 45.6893 29.916 44.1624 27.5537ZM25.9375 23.8973L16.6563 7.82597L7.37498 23.8973H25.9375Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default ArtstationIcon;
