import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const TwitchIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="50"
      height="40"
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.3478 2L7 8.261V33.302H15.6046V38H20.3024L24.9954 33.302H32.037L41.4278 23.9146V2H9.3478ZM12.4757 5.12852H38.2985V22.3462L32.8198 27.8253H24.2139L19.5221 32.5168V27.8253H12.4757V5.12852ZM21.0838 20.7831H24.2139V11.3928H21.0838V20.7831ZM29.6903 20.7831H32.8198V11.3928H29.6903V20.7831Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default TwitchIcon;
