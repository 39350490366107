import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const BeatportIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="50"
      height="40"
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.104 28.3688C41.104 34.7991 35.9976 40 29.4728 40C23.0426 40 17.9362 34.9409 17.9362 28.3688C17.9362 25.2955 19.1182 22.5532 20.9622 20.5201L13.1135 28.3688L9 24.208L17.8416 15.461C19.0236 14.279 19.6383 12.7187 19.6383 10.9693V0H25.4539V11.0165C25.4539 14.3735 24.2719 17.2577 21.9078 19.5745L21.6714 19.8109C23.7045 17.9196 26.4941 16.7849 29.4728 16.7849C36.0922 16.7849 41.104 22.0331 41.104 28.3688ZM35.8558 28.3688C35.8558 24.9173 33.0189 22.1277 29.5201 22.1277C25.974 22.1277 23.1844 25.0591 23.1844 28.3688C23.1844 31.7731 25.974 34.6572 29.4728 34.6572C33.1135 34.6572 35.8558 31.6785 35.8558 28.3688Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default BeatportIcon;
