import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const WhatsappIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="50"
      height="40"
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.2949 38L14.4078 35.1019L15.0839 35.4919C17.9265 37.1313 21.1849 37.9987 24.5071 38H24.5146C34.7191 38 43.0245 29.928 43.0286 20.0065C43.0305 15.1986 41.1066 10.6779 37.6111 7.27677C34.1156 3.87563 29.4671 2.00167 24.5219 2C14.3096 2 6.00406 10.0712 6 19.9919C5.99857 23.3919 6.9774 26.703 8.8307 29.5677L9.2709 30.2485L6.2949 38ZM34.9744 24.6686C35.3626 24.8509 35.6247 24.9741 35.7367 25.1557C35.8758 25.3812 35.8758 26.4642 35.4122 27.728C34.9482 28.9915 32.7247 30.1447 31.6552 30.2999C30.6964 30.4393 29.4829 30.4974 28.1497 30.0856C27.3413 29.8363 26.3046 29.5035 24.9767 28.9461C19.7589 26.7565 16.2327 21.8415 15.5663 20.9126C15.5196 20.8475 15.4869 20.802 15.4687 20.7784L15.4642 20.7726C15.1697 20.3906 13.196 17.8314 13.196 15.1827C13.196 12.6911 14.4554 11.3851 15.0351 10.7839C15.0748 10.7428 15.1113 10.7049 15.144 10.6702C15.6542 10.1286 16.2572 9.99322 16.6282 9.99322C16.9992 9.99322 17.3707 9.99655 17.695 10.0124C17.735 10.0143 17.7766 10.0141 17.8196 10.0139C18.144 10.012 18.5483 10.0097 18.9472 10.9409C19.1007 11.2993 19.3253 11.8306 19.5621 12.391C20.041 13.5242 20.5702 14.7762 20.6633 14.9574C20.8025 15.2281 20.8952 15.5439 20.7097 15.9051C20.6818 15.9592 20.6561 16.0103 20.6315 16.0591C20.4921 16.3356 20.3896 16.539 20.1531 16.8074C20.0601 16.9129 19.964 17.0267 19.8679 17.1404C19.6764 17.367 19.485 17.5937 19.3182 17.7551C19.0396 18.0248 18.7495 18.3175 19.0742 18.859C19.3989 19.4006 20.5159 21.1718 22.1705 22.6062C23.9491 24.1481 25.495 24.7997 26.2785 25.13C26.4316 25.1945 26.5555 25.2468 26.6464 25.291C27.2028 25.5619 27.5275 25.5166 27.8522 25.1557C28.1769 24.7947 29.2435 23.5761 29.6145 23.0347C29.9855 22.4933 30.3567 22.5835 30.8668 22.7639C31.377 22.9446 34.1134 24.2531 34.67 24.5238C34.7787 24.5767 34.8802 24.6244 34.9744 24.6686Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default WhatsappIcon;
