import styled from "styled-components";

export const Card = styled.div`
  gap: 12px;
  display: flex;
  height: 128px;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
`;

export const Head = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`;

type LogoProps = {
  imageUrl: string;
};

export const Logo = styled.div<LogoProps>`
  width: 100%;
  flex-grow: 1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${({ imageUrl }) => imageUrl});
`;
