import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import Header from "./Header";
import Extrato from "./Extrato";
import { Order } from "../../services";
import Page from "../../components/atoms/Page";
import { Snackbar, Auth, Theme } from "../../hooks";
import { IIncomeReport } from "../../services/order";
import H4 from "../../componentsV2/atoms/Typography/H4";
import InputSelect from "../../componentsV2/molecules/InputSelect";

const productFilterOpt = [
  { id: "all", label: "Todos" },
  { id: "by-product", label: "Por produto/serviço determinado" },
];

const periodFilterOpt = [
  { id: "current-month", label: "Este mês" },
  { id: "all-time", label: "Desde sempre" },
  { id: "past-30-days", label: "Últimos 30 dias" },
];

type TSelectedProductFilter = "all" | "by-product";

type TSelectedPeriodFilter = "current-month" | "past-30-days" | "all-time";

const DashboardStatement: React.FC = () => {
  const [selectedPeriodFilter, setSelectedPeriodFilter] =
    useState<TSelectedPeriodFilter>("current-month");
  const [selectedProductFilter, setSelectedProductFilter] =
    useState<TSelectedProductFilter>("all");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState<string>("");
  const [incomeReport, setIncomeReport] = useState<IIncomeReport | null>(null);
  const [saleClickedStatement, setSaleClickedStatement] = useState<number>(-1);

  const navigate = useNavigate();

  const onBackHandler = () => {
    selectedProduct ? setSelectedProduct("") : navigate("/dashboard");
  };

  const { token } = Auth.useAuth();
  const { textColor } = Theme.useTheme();
  const { newError } = Snackbar.useSnackbar();

  useEffect(() => {
    const run = async () => {
      try {
        setIsLoading(true);
        const dataIncomeReport = await Order.getIncomeReport(
          token,
          selectedProduct,
          selectedPeriodFilter === "all-time" ? undefined : selectedPeriodFilter
        );
        setIncomeReport(dataIncomeReport);
      } catch {
        newError("Houve um erro ao obter as vendas");
      } finally {
        setIsLoading(false);
      }
    };
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriodFilter, selectedProduct]);

  const productFilterOptions = ["Todos", "Por produto/serviço determinado"];

  const periodFilterOptions = ["Este mês", "Desde sempre", "Últimos 30 dias"];

  const onSelectProductFilter = (index: number) => {
    const value = productFilterOpt[index].id as TSelectedProductFilter;

    if (value === selectedProductFilter) return;

    setSelectedProductFilter(value);
  };

  const onSelectPeriodFilter = (index: number) => {
    const value = periodFilterOpt[index].id as TSelectedPeriodFilter;

    if (value === selectedPeriodFilter) return;

    setSelectedPeriodFilter(value);
  };

  const onSaleClickStatement = (index: number) => {
    if (index === saleClickedStatement) {
      setSaleClickedStatement(-1);
      return;
    }
    setSaleClickedStatement(index);
  };

  return (
    <Page>
      <Header onClickBack={onBackHandler} />

      <S.BoxContainer>
        <H4 color={textColor}>Filtrar</H4>

        <S.Filters>
          <InputSelect
            listType="text"
            options={productFilterOptions}
            selectedIndex={productFilterOpt.findIndex(
              (i) => i.id === selectedProductFilter
            )}
            onSelect={(val) => onSelectProductFilter(val)}
          />

          <InputSelect
            listType="text"
            options={periodFilterOptions}
            selectedIndex={periodFilterOpt.findIndex(
              (i) => i.id === selectedPeriodFilter
            )}
            onSelect={(val) => onSelectPeriodFilter(val)}
          />
        </S.Filters>
      </S.BoxContainer>

      <Extrato
        isLoading={isLoading}
        selectedProduct={selectedProduct}
        sales={incomeReport?.sales.items || []}
        onOpenSaleDetails={onSaleClickStatement}
        saleDetailsSelected={saleClickedStatement}
        selectedProductFilter={selectedProductFilter}
        // productTotalValue={incomeReport?.sales.totalValue}
        setSelectedProduct={(val: string) => setSelectedProduct(val)}
        productsWithSales={incomeReport?.totalizers.productsWithSales || []}
      />
    </Page>
  );
};

export default DashboardStatement;
