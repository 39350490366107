import * as S from "./styles";
import { Theme } from "../../../hooks";
import AppointmentItem from "../AppointmentItem";
import { IAppointments } from "../../../types/calendar";
import AvailableIcons from "../../../components/atoms/AvailableIcons";

interface IProps {
  date: string;
  weekDay: string;
  isOpen: boolean;
  setIsOpen: () => void;
  appointments: IAppointments[];
  onExcludeAppointment: (val: string) => void;
  onSyncWithExternalCalendar: (val: IAppointments) => void;
}

const CollapsableAppointment: React.FC<IProps> = ({
  date,
  isOpen,
  weekDay,
  setIsOpen,
  appointments,
  onExcludeAppointment,
  onSyncWithExternalCalendar,
}) => {
  const { primaryColor, textColor } = Theme.useTheme();

  const numOfAppointments =
    appointments.length < 10 ? `0${appointments.length}` : appointments.length;

  return (
    <S.Container>
      <S.Collapsable onClick={setIsOpen} isOpen={isOpen}>
        <S.Texts>
          <S.Date color={textColor} fontWeight="regular" isOpen={isOpen}>
            {date}
          </S.Date>

          <S.WeekDay
            isOpen={isOpen}
            color={textColor}
            fontWeight="regular"
            openColor={primaryColor}
          >
            {weekDay}
          </S.WeekDay>
        </S.Texts>

        <S.NumberAppointmentsAndIcon>
          <S.NumberOfAppointments
            isOpen={isOpen}
            color={textColor}
            fontWeight="regular"
          >
            {numOfAppointments}
          </S.NumberOfAppointments>

          <S.Icon isOpen={isOpen}>
            <AvailableIcons option="chevron" color={primaryColor} />
          </S.Icon>
        </S.NumberAppointmentsAndIcon>
      </S.Collapsable>

      {isOpen &&
        appointments.map((item, index) => (
          <S.Appointments
            key={`${item.customerName}#${index}`}
            isLast={index === appointments.length - 1}
          >
            <AppointmentItem
              id={item.id}
              title={item.title}
              endAt={item.endAt}
              callUrl={item.callUrl}
              startAt={item.startAt}
              interval={item.interval}
              customerName={item.customerName || ""}
              customerMail={item.customerEmail || ""}
              customerPhone={item.customerPhone || ""}
              onExcludeAppointment={(val) => onExcludeAppointment(val)}
              onSyncWithExternalCalendar={() =>
                onSyncWithExternalCalendar(item)
              }
            />
          </S.Appointments>
        ))}
    </S.Container>
  );
};

export default CollapsableAppointment;
