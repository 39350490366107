import { Theme } from "../../../hooks";
import InputNumber from "../../molecules/InputNumber";
import Switch from "../../molecules/Switch";
import * as S from "./styles";

interface IProps {
  max?: number;
  min?: number;
  value: number;
  errors?: string[];
  isUnlimited: boolean;
  onChangeValue: (val: number) => void;
  setIsUnlimited: (val: boolean) => void;
}

const Vacancy: React.FC<IProps> = ({
  max,
  min,
  value,
  errors,
  isUnlimited,
  onChangeValue,
  setIsUnlimited,
}) => {
  const { primaryColor } = Theme.useTheme();
  const onChangeHandler = (val: string) => {
    const normalizedValue = +val.replace(/([^0-9-])/g, "");

    if (normalizedValue < 0) return 0;

    if ((max || max === 0) && normalizedValue > max) return onChangeValue(max);

    onChangeValue(normalizedValue);
  };

  return (
    <S.BoxVacancy>
      {!isUnlimited && (
        <>
          <InputNumber
            min={min}
            max={max}
            label="Quantidade de vagas"
            labelColor={primaryColor}
            onChange={(value) => onChangeHandler(String(value))}
            value={value}
          />
          <S.Line />
        </>
      )}
      {isUnlimited && (
        <S.Title color={primaryColor}>Quantidade de vagas</S.Title>
      )}

      <Switch
        isOn={isUnlimited}
        label="Vagas ilimitadas"
        setIsOn={setIsUnlimited}
      />

      {errors && errors.length ? (
        <S.Error color="#FF4D4F">Erro: {errors.join(", ")}</S.Error>
      ) : null}
    </S.BoxVacancy>
  );
};

export default Vacancy;
