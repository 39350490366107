import { Theme } from "../../../../hooks";
import Box from "../../../../componentsV2/atoms/Box";
import Button from "../../../../componentsV2/atoms/Button";
import P from "../../../../componentsV2/atoms/Typography/P";
import H4 from "../../../../componentsV2/atoms/Typography/H4";

import * as S from "./styles";
interface IProps {
  disabled: boolean;
  linkOrCoupon?: string;
  onCopyCode: () => void;
  type?: "link" | "coupon";
  onLeaveCampaign: () => void;
  onApplyForCampaign: () => void;
  status?:
    | "pending"
    | "approved"
    | "rejected"
    | "coming"
    | "exhaust"
    | "finished";
}

const AffiliationInfo: React.FC<IProps> = ({
  type,
  status,
  disabled,
  onCopyCode,
  linkOrCoupon,
  onLeaveCampaign,
  onApplyForCampaign,
}) => {
  const { primaryColor, textColor } = Theme.useTheme();

  const isApproved = status && status === "approved";

  const onClickHandler = () => {
    if (disabled) return;

    if (isApproved) onCopyCode();
    if (!status) onApplyForCampaign();
  };

  const buttonLabels = {
    coming: "Em breve!",
    rejected: "Cupons esgotados :(",
    approved: `Cupom: ${linkOrCoupon}`,
    exhaust: "Cupons esgotados :(",
    pending: "Solicitação em análise",
    finished: "Campanha finalizada :(",
  };

  const isCampainDisableButton =
    status &&
    ["pending", "exhaust", "rejected", "finished", "coming"].includes(status);

  const renderMessage = (status: any) => {
    switch (status) {
      case "approved":
        return "Parabéns, sua solicitação foi aprovada! Comece a divulgar seu cupom agora mesmo (clique para copiar).";
      case "exhaust" || "rejected" || "finished":
        return "Infelizmente os cupons para essa campanha já esgotaram...";
      case "coming":
        return "Essa campanha ainda não está vigente, mas fique de olho que irá começar em breve...";
      default:
        return "Entraremos em contato por e-mail caso seu perfil seja aprovado.";
    }
  };

  return (
    <>
      <Box>
        <S.AffiliationInfo>
          {type ? <H4 color={textColor}>Solicite seu código</H4> : null}

          <Button
            variant={isApproved ? "solid" : "outline"}
            onClick={(e) => {
              e.stopPropagation();
              onClickHandler();
            }}
            borderColor={isCampainDisableButton ? "#FAFAFA80" : primaryColor}
            textColor={
              isApproved
                ? "black"
                : isCampainDisableButton
                ? "#FAFAFA80"
                : primaryColor
            }
            backgroundColor={isApproved ? primaryColor : "none"}
            disabled={disabled || isCampainDisableButton}
          >
            {status ? buttonLabels[status] : "Solicitar código"}
          </Button>

          <P color={isApproved ? primaryColor : `${textColor}80`}>
            {renderMessage(status)}
          </P>
        </S.AffiliationInfo>
      </Box>
      {isApproved ? (
        <S.ButtonLeaveCampaing textColor="#F94144" onClick={onLeaveCampaign}>
          Sair do programa
        </S.ButtonLeaveCampaing>
      ) : null}
    </>
  );
};

export default AffiliationInfo;
