import styled from "styled-components";

export const AddContent = styled.div``;

export const SectionTitle = styled.div`
  gap: 20px;
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  gap: 12px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
`;
