import * as S from "./styles";
import { Theme } from "../../../../../../hooks";
import Box from "../../../../../../componentsV2/atoms/Box";
import Button from "../../../../../../componentsV2/atoms/Button";
import InputText from "../../../../../../componentsV2/molecules/InputText";

interface IProps {
  image: string;
  brandUsername: string;
  onSaveHandler: () => void;
  onDiscardHandler: () => void;
  onChangeUsername: (val: string) => void;
}

const BrandInput: React.FC<IProps> = ({
  image,
  brandUsername,
  onSaveHandler,
  onDiscardHandler,
  onChangeUsername,
}) => {
  const { primaryColor } = Theme.useTheme();

  return (
    <S.BrandContainer>
      <Box>
        <S.SelectedImage image={image} />

        <InputText
          value={brandUsername}
          label="Instagram da marca (@marca) *"
          onChange={(val: string) => onChangeUsername(val)}
          placeholder="Adicione o Instagram da marca aqui"
        />
      </Box>

      {/* Adicionar verificação e mensagem de erro, caso não encontre o insta da marca */}
      <Button
        variant="outline"
        textColor={primaryColor}
        disabled={!brandUsername}
        borderColor={primaryColor}
        onClick={() => onSaveHandler()}
      >
        Salvar
      </Button>

      <Button
        variant="default"
        textColor="#F94144"
        onClick={() => onDiscardHandler()}
      >
        Descartar alterações
      </Button>
    </S.BrandContainer>
  );
};

export default BrandInput;
