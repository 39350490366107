import styled from "styled-components";
import Button from "../../../../componentsV2/atoms/Button";

export const AffiliationInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ButtonLeaveCampaing = styled(Button)`
  padding: 12px 0;
  margin-top: 0.5rem;
`;
