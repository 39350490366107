import styled, { css } from "styled-components";
import Button from "../../atoms/Button";
import P from "../../atoms/Typography/P";
import SeparatorLine from "../../atoms/SeparatorLine";

export const Container = styled.div`
  gap: 20px;
  width: 100%;
  padding: 20px;
  display: flex;
  position: relative;
  border-radius: 12px;
  flex-direction: column;
  background-color: #fafafa05;
`;

export const AppointmentHeader = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
`;

export const AppointmentTimeAndName = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const LinkIcon = styled(Button)`
  right: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Hr = styled(SeparatorLine)`
  opacity: 0.25;
`;

export const AppointmentDetails = styled.div`
  gap: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(P)`
  opacity: 0.5;
  line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
`;

export const AppointmentType = styled(P)`
  opacity: 0.25;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AppointmentContacts = styled.div`
  gap: 12px;
  display: flex;
`;

export const AnchorTag = styled.a`
  width: 100%;
  height: 100%;
`;

type IconButtonProps = {
  border?: string;
};

export const IconButton = styled(Button)<IconButtonProps>`
  ${({ border }) => css`
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: ${border && border};

    svg {
      width: 100%;
      height: 100%;
    }
  `}
`;
