import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  IAppItem,
  TAvailableSocialMedia,
  TIconOptions,
} from "../../../../types";
import { App } from "../../../../hooks";
import Box from "../../../../componentsV2/atoms/Box";
import Page from "../../../../components/atoms/Page";
import AppFormSocialMedia from "../AppFormSocialMedia";
import appConfig from "../../../../config/app-redes-sociais.json";
import AvailableIcons from "../../../../components/atoms/AvailableIcons";
import AppFormFooter from "../../../../componentsV2/organisms/AppFormFooter";
import AppFormHeader from "../../../../componentsV2/organisms/AppFormHeader";
import TitleDescription from "../../../../componentsV2/molecules/TitleDescription";
import AddContentShortcut from "../../../../componentsV2/molecules/AddContentShortcut";

import * as S from "./styles";

const validate = (
  data: IAppItem,
  rede: string
): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (
    (["linkedin", "youtube", "beatport", "vimeo"].includes(rede as string) ||
      ["linkedin", "youtube", "beatport", "vimeo"].includes(
        data.socialMedia as string
      )) &&
    !data.url?.trim()
  ) {
    errors.url = [...(errors.url || []), "A url é obrigatória"];

    return errors;
  }

  if (
    !["linkedin", "youtube", "beatport", "vimeo"].includes(rede as string) &&
    !["linkedin", "youtube", "beatport", "vimeo"].includes(
      data.socialMedia as string
    ) &&
    !data.username?.trim()
  )
    errors.username = [...(errors.username || []), "O username é obrigatório"];

  if (
    (rede === "whatsapp" || data.socialMedia === "whatsapp") &&
    data.username?.trim().match(/[a-z]/g)
  )
    errors.username = [...(errors.username || []), "Número inválido"];

  return errors;
};

const normalizeData = (data: IAppItem): IAppItem => {
  return data;
};

const Form: React.FC = () => {
  const { id, rede } = useParams();

  const [item, setItem] = useState<IAppItem>({});
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

  const navigate = useNavigate();
  const { getItem, saveFreeItemHandler, removeItemHandler } = App.useApp();

  useEffect(() => {
    const run = async () => {
      if (!id) return;

      const itemData = await getItem(appConfig.id, id);

      setItem(itemData || {});
    };

    run();
  }, [getItem, id, rede]);

  const onSave = useCallback(
    (isDraft: boolean) => {
      const normalizedData = normalizeData(item);

      if (!isDraft) {
        const currErrors = validate(normalizedData, rede as string);

        if (currErrors && Object.keys(currErrors).length) {
          setErrors(currErrors);

          window.scrollTo({ top: 0, behavior: "smooth" });
          return alert(
            "O cadastro possui erros, por favor verifique os campos para continuar"
          );
        }
      }

      normalizedData.socialMedia =
        (rede as TAvailableSocialMedia) || item.socialMedia;
      if (normalizedData.socialMedia === "whatsapp") {
        normalizedData.username = normalizedData.username?.replace(
          /[(]*[)]*[-]*[\s]*[a-z]*/g,
          ""
        );
      }

      saveFreeItemHandler(appConfig.id, normalizedData, isDraft, id);
    },
    [item, saveFreeItemHandler, id, rede]
  );

  const onChangeHandler = (value: any, field: keyof IAppItem) => {
    if (errors[field])
      setErrors((curr) => {
        const newErrors = { ...curr };
        delete newErrors[field];

        return newErrors;
      });

    setItem((curr) => ({ ...curr, [field]: value }));
  };

  const isEditingItem = !!id;
  const shouldBeCompleteLink =
    ["linkedin", "youtube", "beatport", "vimeo"].includes(rede as string) ||
    ["linkedin", "youtube", "beatport", "vimeo"].includes(
      item.socialMedia as string
    );

  return (
    <Page bgColor="#080808">
      <AppFormHeader
        appTitle={appConfig.title}
        onBack={() => navigate(`/apps/${appConfig.id}`)}
      />

      <S.ContentBox>
        <TitleDescription title="Suas redes sociais em um único lugar">
          Adicione links para suas redes sociais na sua Stanti. Dessa forma fica
          ainda mais fácil para seus fãs te encontrarem.
        </TitleDescription>

        <AddContentShortcut
          label={
            (rede?.toUpperCase() as string) ||
            (item.socialMedia?.toUpperCase() as string) ||
            ""
          }
          route="/"
          icon={
            <AvailableIcons
              option={
                (rede === "email" || item.socialMedia === "email"
                  ? "email"
                  : (rede as TIconOptions)) || item.socialMedia
              }
              color="#fafafa1a"
            />
          }
          containedBox
        />
      </S.ContentBox>

      <Box>
        <AppFormSocialMedia
          socialMedia={(rede as string) || (item.socialMedia as string)}
          errors={shouldBeCompleteLink ? errors.url : errors.username}
          value={shouldBeCompleteLink ? item.url || "" : item.username || ""}
          onChange={(val) =>
            onChangeHandler(val, shouldBeCompleteLink ? "url" : "username")
          }
        />
      </Box>
      <AppFormFooter
        onSave={onSave}
        saveDraft={true}
        isEditingItem={isEditingItem}
        onRemove={() => id && removeItemHandler(appConfig.id, id)}
        appTitle="rede social"
      />
    </Page>
  );
};

export default Form;
