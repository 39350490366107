import styled, { css } from "styled-components";
import Button from "../../atoms/Button";

export const Backdrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
`;

type ContainerProps = {
  isOpen: boolean;
  listType: "colors" | "text" | "interests" | "images";
};

export const Container = styled.div<ContainerProps>`
  ${({ isOpen, listType }) => css`
    z-index: ${isOpen && 10};
    width: 100%;
    position: relative;
    border-radius: 12px;
    mix-blend-mode: "normal";
    height: ${listType === "colors" ? "66px" : "56px"};
  `}
`;

type DropdownProps = {
  isOpen: boolean;
  textColor: string;
  borderColor: string;
  backgroundColor: string;
  listType: "colors" | "text" | "interests" | "images";
};

export const Dropdown = styled.ul<DropdownProps>`
  ${({ isOpen, listType, textColor, borderColor, backgroundColor }) => css`
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    cursor: pointer;
    list-style: none;
    user-select: none;
    position: absolute;
    color: ${textColor};
    border-radius: 12px;
    flex-direction: column;
    height: ${!isOpen && "100%"};
    background-color: ${isOpen && backgroundColor};
    border: ${isOpen ? `1px solid ${borderColor}` : "1px solid transparent"};

    li {
      display: flex;
      transition: none;
      align-items: center;
      margin: 0 24px 0 20px;
      border-bottom: 1px solid #fafafa30;
      padding: ${listType === "colors" ? "16px 0 16px 0" : "20px 0 20px 0"};

      &:last-of-type,
      &:first-of-type {
        border-bottom: none;
      }

      &:first-of-type {
        margin: 0;
        padding: ${isOpen ? "20px 24px 20px 20px" : "16px 24px 16px 20px"};
      }
    }
  `}
`;

export const Selected = styled.li`
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
  user-select: none;
  position: relative;
  border-radius: 12px;
  align-items: center;
  background-color: #fafafa0d;
  justify-content: space-between;
`;

export const IconAndText = styled.div`
  gap: 20px;
  display: flex;
  align-items: center;
`;

type IconProps = {
  color: string;
};

export const Icon = styled.div<IconProps>`
  ${({ color }) => css`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${color};
  `};
`;

type ImageProps = {
  image: string;
};

export const Image = styled.div<ImageProps>`
  ${({ image }) => css`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: url(${image});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  `}
`;

export const AheadIcon = styled.div`
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(90deg);

  svg {
    width: 100%;
  }
`;

export const InterestButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 20px;
  margin-top: 0.75rem;

  svg {
    width: 9px;
  }
`;
