import styled from "styled-components";
import Box from "../../componentsV2/atoms/Box";

export const BoxContainer = styled(Box)`
  gap: 20px;
`;

export const Filters = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;
