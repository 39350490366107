import styled, { css } from "styled-components";

type SeparatorLineProps = {
  opacity?: number;
  color?: string;
};

const SeparatorLine = styled.span<SeparatorLineProps>`
  ${({ opacity = 0.5, color = "#fafafa" }) => css`
    width: 100%;
    border-top: 0.5px solid ${color};
    opacity: ${opacity};
  `}
`;

export default SeparatorLine;
