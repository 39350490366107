import styled, { css } from "styled-components";
import Small from "../../atoms/Typography/Small";

type BoxProps = {
  isContained: boolean;
};

export const Box = styled.div<BoxProps>`
  width: 100%;
  height: 100px;
  display: flex;
  cursor: pointer;
  user-select: none;
  position: relative;
  border-radius: 12px;
  mix-blend-mode: normal;
  justify-content: center;
  background-color: #fafafa0d;

  ${({ isContained }) =>
    isContained &&
    css`
      width: 106px;
    `}

  .plusIcon {
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;
    background-color: rgba(0, 0, 0, 0.1);

    svg {
      width: 40%;
      height: 40%;
    }
  }
`;

export const Content = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 28px 28px 10px 28px;
`;

export const Icon = styled.div`
  width: 44px;
  height: 44px;

  svg {
    width: 100%;
    height: 100%;
    opacity: 0.1 !important;
    mix-blend-mode: normal !important;
  }
`;

export const Label = styled(Small)`
  text-align: center;
  text-transform: uppercase;
`;
