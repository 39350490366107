import Page from "../../components/atoms/Page";
import Box from "../../componentsV2/atoms/Box";
import styled, { css } from "styled-components";

type StantiAppsProps = {
  backgroundColor: string;
};

export const StantiApps = styled(Page)<StantiAppsProps>`
  ${({ backgroundColor }) => css`
    background-color: ${backgroundColor};
  `}
`;

type IconProps = {
  borderColor: string;
};

export const Icon = styled.div<IconProps>`
  ${({ borderColor }) => css`
    width: 44px;
    height: 44px;
    display: flex;
    border-radius: 50%;
    align-self: center;
    align-items: center;
    justify-content: center;
    border: 1px solid ${borderColor};

    svg {
      width: 40%;
      height: 40%;
    }
  `}
`;

export const AppSectionContainer = styled(Box)`
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

export const Links = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;
