import LinkIcon from "../../../components/icons/LinkIcon";
import { Auth, Theme } from "../../../hooks";
import Button from "../../atoms/Button";
import ProfileImage from "../../atoms/ProfileImage";
import P from "../../atoms/Typography/P";

import bgIcons from "../../assets/bg-icons.png";

import AvailableIcons from "../../../components/atoms/AvailableIcons";
import { TIconOptions } from "../../../types";
import * as S from "./styles";

interface IProps {
  isCopied: boolean;
  setIsCopied: () => void;
  socialMediaOpened?: (socialMedia: string) => void;
}

const { REACT_APP_STOREFRONT_BASE_URL } = process.env;

const socialMediaIcons = [
  {
    icon: "whatsapp",
    href: "https://web.whatsapp.com/",
  },
  {
    icon: "instagram",
    href: "https://instagram.com/",
  },
  {
    icon: "tiktok",
    href: "https://tiktok.com/",
  },
  {
    icon: "twitter",
    href: "https://twitter.com/",
  },
];

const CopyLinkModal: React.FC<IProps> = ({
  isCopied,
  setIsCopied,
  socialMediaOpened,
}) => {
  const { user } = Auth.useAuth();
  const { primaryColor, textColor } = Theme.useTheme();

  const onCopyHandler = () => {
    navigator.clipboard.writeText(
      `${REACT_APP_STOREFRONT_BASE_URL}/${user.username}`
    );
    setIsCopied();
  };

  return (
    <S.Container
      borderColor={primaryColor}
      onClick={(evt) => evt.stopPropagation()}
    >
      {user.profileImage ? (
        <S.ImageContainer imageUrl={bgIcons}>
          <ProfileImage size="medium" imageUrl={user.profileImage} />
        </S.ImageContainer>
      ) : null}

      <S.UserInfo>
        <P color={textColor}>{user.name}</P>

        <S.Username>{`@${user.username}`}</S.Username>
      </S.UserInfo>

      <S.LinkBox>
        <S.Link>
          <div className="icon">
            <LinkIcon color={primaryColor} />
          </div>

          <S.LinkText color="#fafafa" highlightColor={primaryColor}>
            {REACT_APP_STOREFRONT_BASE_URL}/<span>{user.username}</span>
          </S.LinkText>
        </S.Link>

        <S.CopyLink>
          <Button
            textColor={isCopied ? "#000" : primaryColor}
            borderColor={primaryColor}
            backgroundColor={primaryColor}
            onClick={onCopyHandler}
            variant={isCopied ? "solid" : "outline"}
          >
            {isCopied ? "Link copiado!" : "Copiar Link"}
          </Button>
        </S.CopyLink>
      </S.LinkBox>

      <S.SocialMedia>
        <S.SocialMediaText color={textColor}>
          Acesso rápido a suas redes
        </S.SocialMediaText>

        <S.SocialMediaBoxes>
          {socialMediaIcons.map((item) => (
            <a
              key={item.icon}
              target="_blank"
              rel="noreferrer"
              href={item.href}
              onClick={() => socialMediaOpened && socialMediaOpened(item.icon)}
            >
              <AvailableIcons option={item.icon as TIconOptions} />
            </a>
          ))}
        </S.SocialMediaBoxes>
      </S.SocialMedia>
    </S.Container>
  );
};

export default CopyLinkModal;
