import styled from "styled-components";

type H2Props = {
  color: string;
};

const H2 = styled.h2<H2Props>`
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  color: ${({ color }) => color};
`;

export default H2;
