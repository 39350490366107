import React, { ChangeEvent, useRef, useState } from "react";
import { Snackbar, Theme } from "../../../hooks";
import { Apps } from "../../../services";
import SeparatorLine from "../../atoms/SeparatorLine";
import H5 from "../../atoms/Typography/H5";
import P from "../../atoms/Typography/P";
import TitleDescription from "../../molecules/TitleDescription";

import * as S from "./styles";

interface IProps {
  images: string[];
  errors?: string[];
  description?: string;
  onChange: (images: string) => void;
  onRemoveImage: (index: number) => void;
  onUpdateImage: (index: number, imageUrl: string) => void;
}

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const AddImages: React.FC<IProps> = ({
  images,
  errors,
  onChange,
  description,
  onRemoveImage,
  onUpdateImage,
}) => {
  const [loadingImages, setLoadingImages] = useState(false);
  const [loadingChangeImage, setloadingChangeImage] = useState(false);
  const [indexBtnClicked, setIndexBtnClicked] = useState<number>();

  const { textColor, primaryColor } = Theme.useTheme();
  const { newError } = Snackbar.useSnackbar();

  const input = useRef<HTMLInputElement | null>(null);

  const handleClick = (index: number) => setIndexBtnClicked(index);

  const lastIndex = images.length - 1;

  const onUploadHandler = (
    event: ChangeEvent<HTMLInputElement>,
    index?: number
  ) => {
    const { files } = event.target;
    const file = (files || [])[0];

    if (file.size > MAX_FILE_SIZE) {
      if (input.current) input.current.value = "";
      return newError("Arquivo muito grande: máx 5Mb");
    }

    typeof index === "number"
      ? setloadingChangeImage(true)
      : setLoadingImages(true);

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async () => {
        const base64 = reader.result?.toString();

        if (base64) {
          const imageUrl = await Apps.uploadImage(base64);

          typeof index === "number"
            ? onUpdateImage(index, imageUrl)
            : onChange(imageUrl);

          typeof index === "number"
            ? setloadingChangeImage(false)
            : setLoadingImages(false);
        }

        if (input.current) input.current.value = "";
      };
    }
  };

  return (
    <S.BoxImages>
      <TitleDescription title="Adicionar fotos ao seu produto/serviço">
        Recomendamos a proporção 4:5 para que não ocorra nenhum corte na sua
        image
      </TitleDescription>

      {description && description !== undefined && (
        <P color={textColor}>{description}</P>
      )}

      <S.ImageList>
        {images.map((image, index) => (
          <React.Fragment key={image}>
            <S.Container>
              {loadingChangeImage && index === indexBtnClicked ? (
                <S.Loading />
              ) : (
                <S.Image imageUrl={image} />
              )}

              <S.ButtonsImage>
                <S.ButtonUploadImage
                  variant="solid"
                  size="small"
                  onClick={() => handleClick(index)}
                >
                  <H5 color={textColor}>Substituir</H5>

                  <input
                    type="file"
                    ref={input}
                    onChange={(e) => onUploadHandler(e, index)}
                    accept="image/png, image/jpeg, image/jpg, image/webp"
                  />
                </S.ButtonUploadImage>
                <S.RemoveButtonsImage
                  variant="default"
                  size="small"
                  textColor={primaryColor}
                  onClick={() => onRemoveImage(index)}
                >
                  <H5 color={primaryColor}>Remover</H5>
                </S.RemoveButtonsImage>
              </S.ButtonsImage>
            </S.Container>
            {index < lastIndex && <SeparatorLine opacity={0.25} />}
          </React.Fragment>
        ))}
        {loadingImages && <S.ImageSkeleton />}
      </S.ImageList>

      <S.ButtonUploadImage variant="solid">
        Adicionar Imagem
        <input
          type="file"
          ref={input}
          onChange={onUploadHandler}
          accept="image/png, image/jpeg, image/jpg, image/webp"
        />
      </S.ButtonUploadImage>

      {errors && errors.length ? (
        <S.Error color="#FF4D4F">Erro: {errors.join(", ")}</S.Error>
      ) : null}
    </S.BoxImages>
  );
};

export default AddImages;
