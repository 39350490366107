import * as S from "./styles";
import { Theme } from "../../../../hooks";
import H3 from "../../../../componentsV2/atoms/Typography/H3";
import AvailableIcons from "../../../../components/atoms/AvailableIcons";
import ModalBluredBackdrop from "../../../../components/atoms/ModalBluredBackdrop";

interface IProps {
  isOpen: boolean;
  onCloseModal: () => void;
  onExcludeAppointment: () => void;
}

const ExcludeAppointmentModal: React.FC<IProps> = ({
  isOpen,
  onCloseModal,
  onExcludeAppointment,
}) => {
  const { primaryColor, textColor, backgroundColor } = Theme.useTheme();

  if (!isOpen) return null;

  return (
    <ModalBluredBackdrop onClickOutside={onCloseModal}>
      <S.Container
        borderColor={primaryColor}
        backgroundColor={backgroundColor}
        onClick={(evt) => evt.stopPropagation()}
      >
        <S.Icon backgroundColor={`${textColor}1a`}>
          <AvailableIcons option="trashCan" color={primaryColor} />
        </S.Icon>

        <S.Texts>
          <H3 color={textColor}>Confirmar exclusão</H3>

          <S.Subtitle color={primaryColor}>
            Deseja mesmo excluir este agendamento? Essa ação é permanente.
          </S.Subtitle>

          <S.Subtitle color={textColor}>
            A Stanti não se responsabiliza por avisar, nem reembolsar o cliente
            pelo cancelamento. Para efetuar reembolsos de clientes, acesse seu
            Mercado Pago.{" "}
          </S.Subtitle>
        </S.Texts>

        <S.Buttons>
          <S.ModalButton
            variant="solid"
            color={primaryColor}
            onClick={onCloseModal}
            backgroundColor={`${textColor}40`}
          >
            Manter o agendamento
          </S.ModalButton>

          <S.ModalButton
            variant="outline"
            textColor={textColor}
            borderColor={primaryColor}
            onClick={onExcludeAppointment}
          >
            Excluir agendamento
          </S.ModalButton>
        </S.Buttons>

        <S.WarningText color={textColor}>
          <S.Atention color={textColor} fontWeight="600">
            Atenção:
          </S.Atention>{" "}
          Excluir um evento da sua agenda Stanti não removerá o evento do seu
          calendário particular
        </S.WarningText>
      </S.Container>
    </ModalBluredBackdrop>
  );
};

export default ExcludeAppointmentModal;
