import { Theme } from "../../../hooks";

import * as S from "./styles";

interface IProps {
  color?: string;
  progress: number;
}

const ProgressBar: React.FC<IProps> = ({ color, progress }) => {
  const { primaryColor } = Theme.useTheme();

  const currentColor = color || primaryColor;

  return (
    <S.StepsProgressBar>
      <S.BarContainer>
        <S.Bar backgroundColor={currentColor} width={`${progress}%`} />
      </S.BarContainer>
    </S.StepsProgressBar>
  );
};

export default ProgressBar;
