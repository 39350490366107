import styled from "styled-components";
import bgImage from "../../../../components/assets/image_bg.png";
import Button from "../../../../componentsV2/atoms/Button";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  &::after {
    content: "";
    width: 120%;
    height: 120%;
    top: -100px;
    left: -75px;
    cursor: none;
    position: absolute;
    filter: blur(50px);
    pointer-events: none;
    background-image: url(${bgImage});
    background-size: cover;
    background-position: top center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 110px 0 32px;
  z-index: 1000;
  width: 100%;
`;

export const Back = styled(Button)`
  color: #fafafa;
`;

export const RegisterFormHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    transform: scale(-1);
  }
`;

export const Cta = styled.div`
  gap: 12px;
  display: flex;
  padding: 50px 0;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
