import Small from "../../../../components/atoms/Typography/Small";
import SeparatorLine from "../../../../componentsV2/atoms/SeparatorLine";
import H5 from "../../../../componentsV2/atoms/Typography/H5";
import { Theme } from "../../../../hooks";

import * as S from "./styles";

interface IProps {
  title: string;
  segment: string;
  imageUrl: string;
}

const CampaignCard: React.FC<IProps> = ({ title, segment, imageUrl }) => {
  const { textColor } = Theme.useTheme();

  return (
    <S.Card>
      <S.Head>
        <Small color={textColor}>{segment.toLocaleUpperCase()}</Small>
        <H5 color={textColor}>{title}</H5>

        <S.Logo imageUrl={imageUrl} />
      </S.Head>
      <SeparatorLine opacity={0.2} />
    </S.Card>
  );
};

export default CampaignCard;
