import styled from "styled-components";

export const Items = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
`;

export const StantiPayProduct = styled.div`
  width: 100%;
  height: 58px;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;

  border-radius: 12px;
  background: #fafafa0a;
`;

export const IndexProduct = styled.div`
  padding: 20px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  height: 58px;
  padding: 0 20px;
  align-items: center;
  flex-direction: row;
  border-radius: 12px;
  background: #fafafa0a;
  justify-content: space-between;
`;

export const EditIcon = styled.div`
  svg {
    width: 13px;
  }
`;
