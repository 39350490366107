import styled, { css } from "styled-components";
import Box from "../../../../componentsV2/atoms/Box";
import Small from "../../../../components/atoms/Typography/Small";

export const YourAppointments = styled.div`
  display: flex;
  flex-direction: column;

  small {
    font-size: 10px;
  }
`;

export const BoxContainer = styled(Box)`
  z-index: 2;
  display: flex;
  margin-top: 32px;
  position: relative;
  flex-direction: column;
`;

type IconProps = {
  backgroundColor: string;
};

export const Icon = styled.div<IconProps>`
  ${({ backgroundColor }) => css`
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundColor};

    svg {
      width: 40%;
      height: 40%;
    }
  `}
`;

export const Disclaimer = styled(Small)`
  padding-top: 20px;
`;

export const AppointmentList = styled.div`
  gap: 12px;
  display: flex;
  padding-top: 20px;
  flex-direction: column;
`;

export const NoAppointments = styled(Box)`
  gap: 16px;
  z-index: 2;
  display: flex;
  padding: 20px 0;
  margin-top: 32px;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h4 {
    text-align: center;
  }
`;
