import axios from "axios";
import {
  IPosts,
  IMediaKitResponse,
  ISocialMediaEntity,
  IPersonalInfoEntity,
  IInstagramBusinessAccounts,
} from "../../types/media-kit";

import { logoutUserIfTokenInvalid } from "../../utils";

const { REACT_APP_MEDIA_KIT_API_BASE_URL } = process.env;

const getTikTokAccessToken = async (
  token: string,
  authorizationCode: string
): Promise<ISocialMediaEntity> => {
  try {
    const dataResponse = (await axios.post(
      `${REACT_APP_MEDIA_KIT_API_BASE_URL}/tiktok/?code=${authorizationCode}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )) as ISocialMediaEntity;

    return dataResponse;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter a autorização do TikTok");
  }
};

const getTikTokAccessTokenBusDev = async (
  token: string,
  authorizationCode: string
): Promise<ISocialMediaEntity> => {
  try {
    const dataResponse = (await axios.post(
      `${REACT_APP_MEDIA_KIT_API_BASE_URL}/tiktok-busdev/?code=${authorizationCode}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )) as ISocialMediaEntity;

    return dataResponse;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter a autorização do TikTok");
  }
};

const registerInstagram = async (
  token: string,
  accessToken: string
): Promise<IInstagramBusinessAccounts[] | undefined> => {
  try {
    const dataResponse = await axios.post(
      `${REACT_APP_MEDIA_KIT_API_BASE_URL}/instagram`,
      { access_token: accessToken },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (dataResponse.status === 200) {
      return dataResponse.data.instagramBusinessAccountsData;
    }
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter a autorização do Instagram");
  }
};

const updateInstagram = async (token: string, userId: string) => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_MEDIA_KIT_API_BASE_URL}/instagram`,
      { instagramUserId: userId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter os dados da conta selecionada");
  }
};

const getMediaKitData = async (token: string): Promise<IMediaKitResponse> => {
  try {
    const dataResponse = await axios.get(
      `${REACT_APP_MEDIA_KIT_API_BASE_URL}/media-kit`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const fullData = dataResponse.data;

    return {
      mediaKitData: fullData.mediaKitData,
      creatorSocialMedia: fullData.creatorSocialMedia,
    };
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter os dados do Mídia Kit");
  }
};

const getTiktokPosts = async (token: string): Promise<IPosts[]> => {
  try {
    const dataResponse = await axios.get(
      `${REACT_APP_MEDIA_KIT_API_BASE_URL}/posts/tiktok`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return dataResponse.data.media;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter os posts do TikTok");
  }
};

const getInstagramPosts = async (token: string): Promise<IPosts[]> => {
  try {
    const dataResponse = await axios.get(
      `${REACT_APP_MEDIA_KIT_API_BASE_URL}/posts/instagram`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return dataResponse.data.media;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter os posts do Instagram");
  }
};

const getPersonalInfo = async (token: string): Promise<IPersonalInfoEntity> => {
  try {
    const dataResponse = await axios.get(
      `${REACT_APP_MEDIA_KIT_API_BASE_URL}/personal-info`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return dataResponse.data.personal;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao atualizar os dados do TikTok");
  }
};

const savePersonalInfo = async (
  token: string,
  data: Partial<IPersonalInfoEntity>
) => {
  try {
    const dataResponse = await axios.put(
      `${REACT_APP_MEDIA_KIT_API_BASE_URL}/personal-info`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return dataResponse;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao atualizar os dados do TikTok");
  }
};

const uploadPersonalImage = async (
  token: string,
  image: string
): Promise<string> => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_MEDIA_KIT_API_BASE_URL}/upload-image`,
      image,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.imageUrl;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao salvar a imagem");
  }
};

const savePostTiktok = async (
  token: string,
  username: string,
  imageId: string
) => {
  try {
    const dataResponse = await axios.post(
      `${REACT_APP_MEDIA_KIT_API_BASE_URL}/partner/tiktok`,
      {
        usernameInstagram: username,
        imageIdTiktok: imageId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return dataResponse;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao salvar o Post do TikTok");
  }
};

const savePostsReorder = async (token: string, posts: string[]) => {
  try {
    const dataResponse = await axios.put(
      `${REACT_APP_MEDIA_KIT_API_BASE_URL}/partner/reorder`,
      {
        posts: posts,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return dataResponse;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao salvar a alteração dos posts");
  }
};

const savePostInstagram = async (
  token: string,
  username: string,
  imageId: string
) => {
  try {
    const dataResponse = await axios.post(
      `${REACT_APP_MEDIA_KIT_API_BASE_URL}/partner/instagram`,
      {
        usernameInstagram: username,
        imageIdInstagram: imageId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return dataResponse;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao salvar o Post do Instagram");
  }
};

const removePost = async (token: string, socialMedia: string, id: string) => {
  try {
    const dataResponse = await axios.delete(
      `${REACT_APP_MEDIA_KIT_API_BASE_URL}/partner/${socialMedia}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return dataResponse;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao deletar o Post");
  }
};

const removeMediaKitEntity = async (
  token: string,
  entity: "tiktok" | "instagram" | "media-kit"
) => {
  try {
    const dataResponse = await axios.delete(
      `${REACT_APP_MEDIA_KIT_API_BASE_URL}/media-kit/${entity}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return dataResponse;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao deletar o Post");
  }
};

export default {
  removePost,
  savePostTiktok,
  getTiktokPosts,
  updateInstagram,
  getMediaKitData,
  getPersonalInfo,
  savePersonalInfo,
  savePostsReorder,
  getInstagramPosts,
  registerInstagram,
  savePostInstagram,
  uploadPersonalImage,
  getTikTokAccessToken,
  removeMediaKitEntity,
  getTikTokAccessTokenBusDev,
};
