import { Theme } from "../../../../hooks";
import SealIcon from "../../../../components/icons/SealIcon";
import H5 from "../../../../componentsV2/atoms/Typography/H5";
import MoneyTwoIcon from "../../../../components/icons/MoneyTwoIcon";
import TitleDescription from "../../../../componentsV2/molecules/TitleDescription";

import * as S from "./styles";

interface IProps {
  discount?: string;
  comission?: string;
}

const CampaignInfo: React.FC<IProps> = ({ comission, discount }) => {
  const { textColor } = Theme.useTheme();

  return (
    <S.CampaignInfo>
      <S.DiscountBlock>
        <TitleDescription title="Desconto para seus seguidores">
          Ao utilizar seu código seus seguidores também ganham!
        </TitleDescription>

        {discount ? (
          <S.Info>
            <S.Icon>
              <SealIcon />

              <H5 color="black">{discount}</H5>
            </S.Icon>

            <H5 color={textColor}>{`${discount} de desconto a cada compra`}</H5>
          </S.Info>
        ) : null}
      </S.DiscountBlock>

      {comission ? (
        <S.ComissionBlock>
          <TitleDescription title="Você também ganha!">
            Para cada venda realizada utilizando seu link/coupon, você receberá
            uma comissão.
          </TitleDescription>

          <S.Info>
            <S.ComissionIcon>
              <MoneyTwoIcon />
            </S.ComissionIcon>

            <H5 color={textColor}>{`${comission} de comissão por venda`}</H5>
          </S.Info>
        </S.ComissionBlock>
      ) : null}
    </S.CampaignInfo>
  );
};

export default CampaignInfo;
