import styled from "styled-components";

export const Head = styled.div`
  padding: 40px 0 20px 0;
`;

export const LinkToPay = styled.div`
  margin-bottom: 20px;
`;

export const MyProducts = styled.div`
  display: flex;
  padding: 30px 0;
  flex-direction: column;
`;

export const NewProduct = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  margin-top: 15px;

  button {
    flex-grow: 1;
  }
`;
