import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const TelegramIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="50"
      height="40"
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1037 24.7984L20.5404 33.1828C21.368 33.1828 21.7337 32.8067 22.1957 32.3605L26.172 28.5189L34.4446 34.5914C35.9671 35.426 37.0658 34.9938 37.4451 33.187L42.8757 7.76079C43.4317 5.54602 42.026 4.5415 40.5695 5.19846L8.68087 17.4153C6.50417 18.2828 6.51686 19.4893 8.28411 20.0269L16.4675 22.5646L35.4128 10.6893C36.3072 10.1504 37.128 10.4401 36.4543 11.0342L21.1037 24.7984Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default TelegramIcon;
