import { Theme } from "../../../hooks";
import P from "../../atoms/Typography/P";
import Chevron from "../../../components/icons/Chevron";
import CheckCircleIcon from "../../../components/icons/CheckCircleIcon";
import MercadoPagoLogo from "../../../components/icons/mercado-pago-logo.png";

import * as S from "./styles";

interface IProps {
  onClick: () => void;
  hasLinkedAccount: boolean;
}
const MercadoPagoIntegration: React.FC<IProps> = ({
  hasLinkedAccount,
  onClick,
}) => {
  const { primaryColor, textColor } = Theme.useTheme();

  return (
    <S.MercadoPagoButton onClick={onClick}>
      <div className="mercadoPagoLogo">
        <img src={MercadoPagoLogo} alt="" />
      </div>

      <S.ButtonLabel>
        <P color={textColor}>
          {hasLinkedAccount ? "Conta vinculada" : "Vincular conta"}
        </P>

        <S.Icon>
          {hasLinkedAccount ? (
            <CheckCircleIcon color={primaryColor} />
          ) : (
            <Chevron color={primaryColor} />
          )}
        </S.Icon>
      </S.ButtonLabel>
    </S.MercadoPagoButton>
  );
};

export default MercadoPagoIntegration;
