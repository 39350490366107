import InputSelect from "../../../../componentsV2/molecules/InputSelect";
import {
  primaryColors,
  primaryLabelColors,
} from "../../../../constants/colors";
import { ICreatorData } from "../../../../types";

interface IProps {
  onChange: (color: string) => void;
  value: string;
  selectedBackgroundColor: ICreatorData["primaryColor"];
}

const PrimarySelectorList: React.FC<IProps> = ({ value, onChange }) => {
  const selectColor = (index: number) => {
    const colorSelected = primaryColors[index];

    onChange(colorSelected);
  };

  const selectedColorIndex = primaryColors.indexOf(value);

  return (
    <InputSelect
      listType="colors"
      onSelect={(value) => selectColor(value)}
      options={primaryLabelColors}
      colors={primaryColors}
      selectedIndex={selectedColorIndex}
    />
  );
};

export default PrimarySelectorList;
