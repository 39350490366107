import styled, { css } from "styled-components";

import Box from "../../atoms/Box";
import Button from "../../atoms/Button";
import P from "../../atoms/Typography/P";
import Switch from "../../molecules/Switch";
import SeparatorLine from "../../atoms/SeparatorLine";
import Small from "../../atoms/Typography/Small";

export const Container = styled(Box)`
  gap: 24px;
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const BoxContent = styled(Box)`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 0px;
`;

export const Label = styled(P)`
  margin-bottom: 16px;
`;

export const Dates = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
`;

type RemoveProps = {
  borderColor: string;
};

export const RemoveIcon = styled.div<RemoveProps>`
  ${({ borderColor }) => css`
    width: 20px;
    height: 20px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 1px solid ${borderColor};

    svg {
      width: 50%;
      height: 50%;
    }
  `}
`;

export const Text = styled(P)``;

export const Hr = styled(SeparatorLine)`
  opacity: 0.25;
  margin: 16px 0 20px;
`;

export const TimeBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AllDay = styled(Switch)``;

export const AgendaOptions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled(P)``;

export const AddCalendar = styled(Button)`
  margin-top: 24px;
`;

export const ErrorMessage = styled(Small)`
  font-size: 0.75rem;
`;
