import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const DeviantartIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="50"
      height="40"
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.7784 1H29.2456L28.5485 1.69336L25.2252 8.04531L24.2548 8.60639H13V18.099H19.0135L19.6399 18.7262L13 31.4164V39L19.5374 38.9977L20.2367 38.3021L23.5669 31.9478L24.5168 31.3982H35.7784V21.9101H29.7467L29.1362 21.2989L35.7784 8.60182"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default DeviantartIcon;
