import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import {
  statBoxes,
  publicBoxes,
  emptyUserInfo,
  normalizeStatsData,
  normalizePublicData,
  getTiktokAuhtorizationUrl,
  getInstagramAuhtorizationUrl,
} from "../../../../utils/media-kit";
import {
  IPostsEntity,
  ISocialMediaEntity,
  IPersonalInfoEntity,
  ICreatorSocialMedia,
} from "../../../../types/media-kit";
import StatsBox from "./components/StatsBox";
import PublicBox from "./components/PublicBox";
import PostsGrid from "./components/PostsGrid";
import { MediaKit } from "../../../../services";
import PostsSlider from "./components/PostsSlider";
import { TIconOptionsTemp } from "../../../../types";
import P from "../../../../componentsV2/atoms/Typography/P";
import H2 from "../../../../componentsV2/atoms/Typography/H2";
import H3 from "../../../../componentsV2/atoms/Typography/H3";
import H4 from "../../../../componentsV2/atoms/Typography/H4";
import H5 from "../../../../componentsV2/atoms/Typography/H5";
import Label from "../../../../componentsV2/atoms/Typography/Label";
import Small from "../../../../componentsV2/atoms/Typography/Small";
import { Auth, Loading, NavBar, Snackbar, Theme } from "../../../../hooks";
import AvailableIconsTemp from "../../../../components/atoms/AvailableIconsTemp";
import TitleDescription from "../../../../componentsV2/molecules/TitleDescription";

const Form: React.FC = () => {
  const [posts, setPosts] = useState<IPostsEntity>();
  const [tiktokData, setTiktokData] = useState<ISocialMediaEntity>();
  const [socialMedia, setSocialMedia] = useState<ICreatorSocialMedia[]>();
  const [instagramData, setInstagramData] = useState<ISocialMediaEntity>();
  const [userInfo, setUserInfo] = useState<IPersonalInfoEntity>(emptyUserInfo);

  const navigate = useNavigate();

  const { token } = Auth.useAuth();
  const { newError } = Snackbar.useSnackbar();
  const { hideNavBar, showNavBar } = NavBar.useNavBar();
  const { showLoading, hideLoading } = Loading.useLoading();
  const { primaryColor, textColor, backgroundColor } = Theme.useTheme();

  useEffect(() => {
    showLoading();
    const run = async () => {
      try {
        const fullData = await MediaKit.getMediaKitData(token);

        if (fullData.mediaKitData.personal) {
          setUserInfo(fullData.mediaKitData.personal);
        }
        if (fullData.mediaKitData.tiktok) {
          setTiktokData(fullData.mediaKitData.tiktok);
        }
        if (fullData.mediaKitData.instagram) {
          setInstagramData(fullData.mediaKitData.instagram);
        }
        if (fullData.mediaKitData.posts) {
          const sortedPosts = fullData.mediaKitData.posts.posts.sort(
            (a, b) => a.order - b.order
          );
          setPosts({
            ...fullData.mediaKitData.posts,
            posts: sortedPosts,
          });
        }
        if (
          fullData.creatorSocialMedia &&
          fullData.creatorSocialMedia.length > 0
        ) {
          setSocialMedia(fullData.creatorSocialMedia);
        }
        hideLoading();
      } catch (error) {
        hideLoading();
        navigate("/apps/media-kit");
        newError("Houve um erro ao importar os dados do usuário");
      }
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickEdit = () => {
    navigate("/apps/media-kit/edit/profile");
  };

  const location = (() => {
    if (userInfo.city) {
      if (userInfo.state) {
        if (userInfo.country) {
          return `${userInfo.city}/${userInfo.state} - ${userInfo.country}`;
        }
        return `${userInfo.city}/${userInfo.state}`;
      }

      if (userInfo.country) {
        return `${userInfo.city} - ${userInfo.country}`;
      }

      return userInfo.city;
    }

    if (userInfo.state) {
      if (userInfo.country) {
        return `${userInfo.state} - ${userInfo.country}`;
      }
      return userInfo.state;
    }

    return userInfo.country;
  })();

  const statsData = normalizeStatsData(tiktokData, instagramData);

  const { ageData, locationData, genderData } = normalizePublicData(
    [tiktokData?.followerAge, instagramData?.followerAge],
    [
      tiktokData && tiktokData.location,
      instagramData && instagramData.location,
    ],
    [
      tiktokData && tiktokData.followerGender,
      instagramData && instagramData.followerGender,
    ]
  );

  const onClickInstagram = async () => {
    hideNavBar();

    const authorizationUrl = getInstagramAuhtorizationUrl();

    window.location.href = authorizationUrl;

    return () => showNavBar();
  };

  const onClickTiktok = async () => {
    hideNavBar();

    const authorizationUrl = getTiktokAuhtorizationUrl();

    window.location.href = authorizationUrl;

    return () => showNavBar();
  };

  const callToAction = (() => {
    if (!instagramData || !tiktokData) {
      return (
        <S.CallToActionSocialMedia>
          {!tiktokData && (
            <S.ConnectSocialMedia>
              <H5 color={textColor}>
                Conecte-se ao TikTok e deixe o Mídia Kit ainda mais completo!
              </H5>

              <S.SocialMediaButton
                variant="solid"
                textColor={backgroundColor}
                backgroundColor={primaryColor}
                onClick={() => onClickTiktok()}
              >
                <S.Icon>
                  <AvailableIconsTemp option="tiktok" color={backgroundColor} />
                </S.Icon>
                Conectar TikTok
              </S.SocialMediaButton>
            </S.ConnectSocialMedia>
          )}

          {!instagramData && (
            <S.ConnectSocialMedia>
              <H5 color={textColor}>
                Conecte-se ao Instagram e deixe o Mídia Kit ainda mais completo!
              </H5>

              <S.SocialMediaButton
                variant="solid"
                textColor={backgroundColor}
                backgroundColor={primaryColor}
                onClick={() => onClickInstagram()}
              >
                <S.Icon>
                  <AvailableIconsTemp
                    option="instagramTwo"
                    color={backgroundColor}
                  />
                </S.Icon>
                Conectar Instagram
              </S.SocialMediaButton>
            </S.ConnectSocialMedia>
          )}
        </S.CallToActionSocialMedia>
      );
    }
  })();

  return (
    <S.Container>
      <S.Header>
        <S.CoverImage
          backgroundColor={primaryColor}
          background={userInfo?.coverImageUrl}
        />

        <S.ProfileAndEdit>
          <S.ProfileImageBox borderColor={primaryColor}>
            <S.Image
              size="large"
              imageUrl={userInfo?.profileImageUrl || primaryColor}
            />
          </S.ProfileImageBox>

          <S.EditIcon onClick={() => onClickEdit()}>
            <AvailableIconsTemp option="edit" color={primaryColor} />
          </S.EditIcon>
        </S.ProfileAndEdit>
      </S.Header>

      <S.UserInformation>
        {userInfo.categories ? (
          <S.Categories>
            {(userInfo.categories || []).map((categorie, index) => (
              <S.Categorie
                key={`${categorie}#${index}`}
                backgroundColor={primaryColor}
              >
                <Label color={backgroundColor}>{categorie}</Label>
              </S.Categorie>
            ))}
          </S.Categories>
        ) : (
          <S.TitleH4 color={primaryColor} onClick={() => onClickEdit()}>
            + Adicione seus nichos aqui
          </S.TitleH4>
        )}

        <S.InfoAndSocialMedia>
          <S.BasicInfo>
            <H3 color={textColor} fontWeight="bold">
              {userInfo.name}
            </H3>

            {userInfo.city || userInfo.state || userInfo.country ? (
              <Small color={textColor}>{location}</Small>
            ) : (
              <S.TitleH4 color={primaryColor} onClick={() => onClickEdit()}>
                + Adicione sua localização aqui
              </S.TitleH4>
            )}
          </S.BasicInfo>

          <S.SocialMedia>
            {(socialMedia || []).map((item, index) => (
              <AvailableIconsTemp
                color={textColor}
                key={`${item.link}#${index}`}
                option={(item.socialMedia || "") as TIconOptionsTemp}
              />
            ))}
          </S.SocialMedia>
        </S.InfoAndSocialMedia>

        <S.Followers>
          {!!instagramData && (
            <S.FollowersBox
              separatorColor={`${textColor}33`}
              separator={!!instagramData && !!tiktokData}
            >
              <S.FollowersIcon>
                <AvailableIconsTemp option="instagramTwo" color={textColor} />
              </S.FollowersIcon>

              <S.FollowersText>
                <H2 color={textColor}>
                  {normalizeStatsData(undefined, instagramData).followerCount}
                </H2>

                <Small color={textColor}>seguidores</Small>
              </S.FollowersText>
            </S.FollowersBox>
          )}

          {!!tiktokData && (
            <S.FollowersBox separator={false}>
              <S.FollowersIcon>
                <AvailableIconsTemp option="tiktok" color={textColor} />
              </S.FollowersIcon>

              <S.FollowersText>
                <H2 color={textColor}>
                  {normalizeStatsData(tiktokData, undefined).followerCount}
                </H2>

                <Small color={textColor}>seguidores</Small>
              </S.FollowersText>
            </S.FollowersBox>
          )}
        </S.Followers>

        <TitleDescription
          title="Sobre"
          titleColor={textColor}
          textColor={`${textColor}80`}
        >
          {userInfo.about ? (
            userInfo.about
          ) : (
            <>
              {
                "Perfis completos se destacam mais. Dica: Adicione uma descrição falando sobre você, sua personalidade, tom e como se relaciona com sua audiência."
              }

              <S.EditButton
                variant="solid"
                textColor={backgroundColor}
                onClick={() => onClickEdit()}
                backgroundColor={primaryColor}
              >
                Adicionar sobre
              </S.EditButton>
            </>
          )}
        </TitleDescription>

        <S.Separator opacity={0.25} />
      </S.UserInformation>

      {callToAction}

      <S.Stats>
        <H4 color={textColor}>Estatísticas</H4>

        <S.Boxes minmax="132px">
          {statBoxes.map((item) => (
            <StatsBox
              key={item.label}
              label={item.label}
              tolltip={item.tolltip}
              value={statsData[item.key] || ""}
              iconId={item.iconId as TIconOptionsTemp}
            />
          ))}
        </S.Boxes>
      </S.Stats>

      <S.Public>
        <H4 color={textColor}>Público</H4>

        {instagramData ? (
          <>
            <S.Boxes minmax="320px">
              {publicBoxes.slice(0, 2).map((item) => (
                <PublicBox
                  key={item.label}
                  type={item.type}
                  ageData={ageData}
                  title={item.title}
                  tolltip={item.tolltip}
                  locationData={locationData}
                  genderData={
                    (genderData || {}) as ISocialMediaEntity["followerGender"]
                  }
                />
              ))}
            </S.Boxes>

            <S.LocationBoxes>
              <PublicBox
                key={publicBoxes[2].label}
                type={publicBoxes[2].type}
                ageData={ageData}
                title={publicBoxes[2].title}
                tolltip={publicBoxes[2].tolltip}
                locationData={locationData}
                genderData={
                  (genderData || {}) as ISocialMediaEntity["followerGender"]
                }
              />
            </S.LocationBoxes>
          </>
        ) : (
          <S.ConnectSocialMedia>
            <H5 color={textColor}>
              Conecte-se ao Instagram para obter dados sobre seu público e deixe
              o Mídia Kit ainda mais completo!
            </H5>

            <S.SocialMediaButton
              variant="solid"
              textColor={backgroundColor}
              backgroundColor={primaryColor}
              onClick={() => onClickInstagram()}
            >
              <S.Icon>
                <AvailableIconsTemp
                  option="instagramTwo"
                  color={backgroundColor}
                />
              </S.Icon>
              Conectar Instagram
            </S.SocialMediaButton>
          </S.ConnectSocialMedia>
        )}

        <S.Separator opacity={0.25} />
      </S.Public>

      <S.Brands>
        <S.TitleAndEditBrands>
          <H4 color={textColor}>Marcas parceiras</H4>

          <S.EditIcon onClick={() => navigate("/apps/media-kit/edit/brands")}>
            <AvailableIconsTemp option="edit" color={primaryColor} />
          </S.EditIcon>
        </S.TitleAndEditBrands>

        {posts && posts.posts.length > 0 ? (
          <S.Posts>
            <PostsSlider
              posts={posts}
              imageWidth={300}
              hideIndicator={posts.posts.length === 1}
            />

            <PostsGrid posts={posts} imageWidth={300} />
          </S.Posts>
        ) : (
          <>
            <P color={textColor}>
              Adicione suas publicações em parceria com outras marcas para
              construir seu portfolio de #publis e mostrar seu trabalho para o
              mundo.
            </P>

            <S.EditButton
              variant="solid"
              textColor={backgroundColor}
              backgroundColor={primaryColor}
              onClick={() => navigate("/apps/media-kit/edit/brands")}
            >
              Adicionar publicações
            </S.EditButton>
          </>
        )}
      </S.Brands>
    </S.Container>
  );
};

export default Form;
