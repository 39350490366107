import styled from "styled-components";

const InputBase = styled.input`
  color: #fafafa;
  font-size: 1rem;
  outline: none;
  resize: none;

  &::placeholder {
    color: #fafafa80;
  }
`;

export default InputBase;
