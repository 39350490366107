import BackgroundProfileImageIcon from "../../../components/icons/BackgroundProfileImageIcon";
import ProfileImagePlaceholder from "../../../components/icons/ProfileImagePlaceholder";
import * as S from "./styles";

type ProfileImageProps = {
  imageUrl: string;
  onClick?: () => void;
  hasBackgroundImage?: boolean;
  size: "small" | "medium" | "large";
};

const ProfileImage: React.FC<ProfileImageProps> = ({
  size,
  imageUrl,
  onClick,
  hasBackgroundImage = false,
}) => {
  return (
    <>
      {hasBackgroundImage ? (
        <S.Image size={size}>
          <div className="background">
            <BackgroundProfileImageIcon />
          </div>

          {imageUrl ? (
            <S.ProfileImage size={size} imageUrl={imageUrl} onClick={onClick} />
          ) : (
            <S.PlaceholderContainer size={size}>
              <ProfileImagePlaceholder />
            </S.PlaceholderContainer>
          )}
        </S.Image>
      ) : (
        <S.ProfileImage size={size} imageUrl={imageUrl} onClick={onClick} />
      )}
    </>
  );
};

export default ProfileImage;
