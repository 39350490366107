import styled from "styled-components";
import Box from "../../../../componentsV2/atoms/Box";
import Button from "../../../../componentsV2/atoms/Button";
import P from "../../../../componentsV2/atoms/Typography/P";

export const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  max-width: 960px;
  flex-direction: column;
  z-index: 10;
`;

export const ProfileImageContainer = styled(Container)`
  position: relative;
`;

export const ProgressBar = styled.div`
  gap: 18px;
  display: flex;
  margin-top: 2.5rem;
  flex-direction: column;
`;

export const RegisterFormHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Text = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: column;

  span {
    font-weight: 400;
  }
`;

export const Form = styled(Box)`
  gap: 12px;
  display: flex;
  flex-direction: column;
  margin-top: 3.5rem;
`;

export const FormTitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    width: 24px;
    height: auto;
  }
`;

export const SocialMediaContainer = styled(Box)`
  margin-top: 8px;
`;

export const ImportButton = styled(Button)`
  margin-top: 8px;
`;

export const Image = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 3.5rem;
  justify-content: center;
`;

export const Cta = styled.div`
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  small {
    font-weight: 300;
    line-height: 28px;
    text-align: center;
    font-style: normal;
  }

  p {
    font-size: 16px;
    font-weight: 275;
    line-height: 24px;
    text-align: center;
    font-style: normal;
  }

  @media (max-width: 600px) {
    p {
      max-width: 200px;
    }
  }
`;

export const Back = styled(Button)`
  width: 6rem;

  svg {
    transform: scale(-1);
  }
`;

export const Title = styled(P)`
  font-size: 1.5rem;
`;

export const Subtitle = styled(P)`
  margin-top: 1.5rem;
  font-size: 1rem;
`;

export const AddImageManually = styled.div`
  display: none;
  cursor: pointer;
  margin-top: 20px;
  user-select: none;
  align-items: center;
  justify-content: center;

  small {
    font-size: 14px;
  }

  input {
    display: none;
  }
`;

export const NextButton = styled(Button)`
  margin-top: 20px;
`;
