import styled, { css } from "styled-components";

import Box from "../../../../componentsV2/atoms/Box";
import Button from "../../../../componentsV2/atoms/Button";

export const BoxContainer = styled(Box)`
  gap: 32px;
`;

export const Icons = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type IconProps = {
  backgroundColor: string;
};

export const InstagramIcon = styled.div<IconProps>`
  ${({ backgroundColor }) => css`
    width: 76px;
    height: 76px;
    display: flex;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundColor}1a;

    svg {
      width: 50%;
      height: 50%;
    }
  `}
`;

export const StantiIcon = styled.div<IconProps>`
  ${({ backgroundColor }) => css`
    width: 76px;
    height: 76px;
    display: flex;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundColor}1a;

    svg {
      width: 50%;
      height: 50%;
    }
  `}
`;

export const ShareButton = styled(Button)`
  margin-top: -8px;
`;

export const LinkedAccount = styled.div`
  display: flex;
  margin-top: 12px;
  flex-direction: column;

  .newPost {
    margin-top: 26px;
  }
`;

export const SaveButton = styled.div`
  left: 50%;
  width: 100%;
  bottom: 40px;
  display: flex;
  position: fixed;
  padding: 0 24px;
  max-width: 960px;
  flex-direction: column;

  button {
    height: 56px;
    transform: translate(calc(-50% - 24px), 0%);
    box-shadow: 4px 10px 10px -2px rgba(0, 0, 0, 0.6);
    animation: buttonPop 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;

    @keyframes buttonPop {
      0% {
        transform: translate(calc(-50% - 24px), 200%);
      }
      60% {
        transform: translate(calc(-50% - 24px), -60%);
      }
      100% {
        transform: translate(calc(-50% - 24px), 0%);
      }
    }
  }
`;
