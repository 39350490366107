import styled from "styled-components";
import P from "../../../../components/atoms/Typography/P";
import Button from "../../../../componentsV2/atoms/Button";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  max-width: 960px;
  flex-direction: column;
  z-index: 10;
`;

export const Back = styled(Button)`
  svg {
    transform: scale(-1);
  }
`;

export const RegisterFormHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProgressBar = styled.div`
  gap: 18px;
  display: flex;
  margin-top: 2.5rem;
  flex-direction: column;
`;

export const Text = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: column;

  span {
    font-weight: 400;
  }
`;

export const Title = styled(P)`
  font-size: 1.5rem;
`;

export const Subtitle = styled(P)`
  margin-top: 1.5rem;
  font-size: 1rem;
`;

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  gap: 0.75rem;
`;

export const Image = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 3.5rem;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > span {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 80%;
    color: #fafafa;
    margin: 30px 0 20px 0;
  }
`;

export const ColorStepProgressBar = styled.div`
  gap: 18px;
  display: flex;
  padding-top: 80px;
  flex-direction: column;
`;

export const IntroText = styled(P)`
  font-size: 16px;

  strong {
    font-weight: 500;
  }
`;

export const ImageContainer = styled.div`
  gap: 0px;
  display: flex;
  height: 171px;
  margin-top: 40px;
  margin-bottom: 24px;
  align-items: center;
  justify-content: center;

  .background {
    width: 280px;
    position: absolute;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

type ProfileImageProps = {
  imageUrl: string | undefined;
};

export const ProfileImage = styled.div<ProfileImageProps>`
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  position: relative;
  border-radius: 96px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  background-image: url(${({ imageUrl }) => imageUrl});

  .profileImageBorder {
    top: -18px;
    left: -18px;
    width: 171px;
    height: 171px;
    position: absolute;
    border-radius: 132px;
    mask-composite: exclude;
    border: 9px solid transparent;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;

    @keyframes rotate {
      0% {
        transform: rotateZ(0deg);
      }

      100% {
        transform: rotateZ(360deg);
      }
    }
  }
`;

export const ChooseColors = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3.5rem;
`;

export const NextButton = styled(Button)`
  margin-top: 20px;
`;
