import { useEffect, useState } from "react";

import * as S from "./styles";
import { Apps } from "../../../services";
import Button from "../../atoms/Button";
import Radio from "../../atoms/RadioMenu";
import Switch from "../../molecules/Switch";
import { TIconOptions } from "../../../types";
import Label from "../../atoms/Typography/Label";
import Small from "../../atoms/Typography/Small";
import ImagePreview from "../../atoms/ImagePreview";
import EditLinkImage from "../../atoms/EditLinkImage";
import { Loading, Snackbar, Theme } from "../../../hooks";

interface IProps {
  errors?: string[];
  onClear: () => void;
  description?: string;
  icon: TIconOptions | string;
  onChange: (icon: TIconOptions | string) => void;
}

const AddIcon: React.FC<IProps> = ({
  icon,
  errors,
  onClear,
  onChange,
  description,
}) => {
  const [isSwitcherOn, setIsSwitcherOn] = useState(!!icon);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isEditImageOpen, setIsEditImageOpen] = useState(false);
  const [selectClickEvent, setSelectClickEvent] =
    useState<React.MouseEvent<HTMLButtonElement> | null>(null);

  const radioOptions = ["Ícone", "Foto"];

  useEffect(() => {
    setIsSwitcherOn(!!icon);
  }, [icon]);

  const { showLoading, hideLoading } = Loading.useLoading();
  const { newError } = Snackbar.useSnackbar();
  const { textColor } = Theme.useTheme();

  const onUploadImageHandler = async (base64: string) => {
    setIsEditImageOpen(false);
    showLoading();
    try {
      const url = await Apps.uploadImage(base64);
      onChange(url);
    } catch (error) {
      newError("Houve um erro ao enviar a sua imagem");
    } finally {
      hideLoading();
    }
  };

  const onSelectIconHandler = (icon: TIconOptions) => {
    onChange(icon);
    setIsEditImageOpen(false);
  };

  const switcherHandler = (isOn: boolean) => {
    if (!isOn) onClear();
    setIsSwitcherOn(isOn);
  };

  const radioChangeHandler = (index: number) => {
    setSelectedIndex(index);
    setSelectClickEvent(null);
  };

  const isUploadedImage = icon.match(/https:\/\//g);

  const selectClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsEditImageOpen(true);
    setSelectClickEvent(event);
  };

  return (
    <S.FormAddImage>
      <S.AddImageHeader>
        <Switch
          color={textColor}
          isOn={isSwitcherOn}
          setIsOn={switcherHandler}
          label="Adicionar ícone ao botão"
        />
      </S.AddImageHeader>

      {!!description && isSwitcherOn ? (
        <Label color="#fafafa40">{description}</Label>
      ) : null}

      {isSwitcherOn ? (
        <S.ImagePreviewAndSelector>
          <ImagePreview
            uploadedImageUrl={isUploadedImage ? icon : ""}
            selectedImage={isUploadedImage ? "" : (icon as TIconOptions)}
          />

          <S.SelectorContainer>
            <Radio
              protectionSize="full"
              values={radioOptions}
              selectedIndex={selectedIndex}
              onSelect={radioChangeHandler}
            />

            <Button onClick={(e) => selectClickHandler(e)} variant="solid">
              Selecionar
            </Button>
          </S.SelectorContainer>
        </S.ImagePreviewAndSelector>
      ) : null}

      {isSwitcherOn && isEditImageOpen ? (
        <EditLinkImage
          selectClickEvent={selectClickEvent}
          type={radioOptions[selectedIndex] === "Ícone" ? "icon" : "image"}
          onSelectIcon={onSelectIconHandler}
          onUploadImage={onUploadImageHandler}
        />
      ) : null}

      {errors && errors.length ? (
        <Small color="#FF4D4F">Erros: {errors.join(", ")}</Small>
      ) : null}
    </S.FormAddImage>
  );
};

export default AddIcon;
