import { ChangeEvent, useEffect, useRef, useState } from "react";

import Page from "../../../../components/atoms/Page";
import P from "../../../../components/atoms/Typography/P";
import Small from "../../../../components/atoms/Typography/Small";
import ImageReposition from "../../../../components/molecules/ImageReposition";
import socialMediaAppConfig from "../../../../config/app-redes-sociais.json";
import {
  LOCAL_STORAGE_AUTHENTICATION_METHOD,
  LOCAL_STORAGE_INFO_STEP_DURATION,
} from "../../../../constants";
import { App, Auth, Loading, Snackbar, Theme } from "../../../../hooks";
import { Analytics, Creator } from "../../../../services";

import AvailableIcons from "../../../../components/atoms/AvailableIcons";
import ProfileImage from "../../../../componentsV2/atoms/ProfileImage";
import ProgressBar from "../../../../componentsV2/atoms/ProgressBar";
import InputText from "../../../../componentsV2/molecules/InputText";

import * as S from "./styles";

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const authenticationMethod =
  localStorage.getItem(LOCAL_STORAGE_AUTHENTICATION_METHOD) || "";

const ProfileImageStep: React.FC = () => {
  const [username, setUsername] = useState("");
  const [initialImage, setInitialImage] = useState("");

  const input = useRef<HTMLInputElement | null>(null);

  const { textColor } = Theme.useTheme();
  const { newError } = Snackbar.useSnackbar();
  const { saveFreeItemHandler } = App.useApp();
  const { user, token, setUserHandler, logout } = Auth.useAuth();
  const { showLoading, hideLoading, isLoading } = Loading.useLoading();

  useEffect(() => {
    const stepDurationInSeconds =
      localStorage.getItem(LOCAL_STORAGE_INFO_STEP_DURATION) || "";

    Analytics.submitClickEvent({
      creator: user.id || "",
      origin: document.referrer,
      creatorUsername: username,
      description: "information-step-concluded",
      meta: { stepDuration: stepDurationInSeconds },
      actionDescription: `information-step-concluded-${username}`,
    });

    localStorage.removeItem(LOCAL_STORAGE_INFO_STEP_DURATION);

    Analytics.submitPageViewEvent({
      creator: user.id || "",
      origin: document.referrer,
      description: "profile-image-step",
      creatorUsername: user.username || "",
      actionDescription: "import-profile-image-step",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  setTimeout(() => {
    const manuallyImport = document.getElementById("addImageManually");
    if (manuallyImport) {
      manuallyImport.style.display = "flex";
    }
  }, 6000);

  const onUploadProfileImage = async (image: string) => {
    showLoading();

    try {
      setInitialImage("");

      const imageUrl = await Creator.uploadProfileImage(image);
      const user = await Creator.updateProfile(
        { profileImage: imageUrl },
        token
      );

      setUserHandler(user);
    } catch (error) {
      newError("Houve um erro ao enviar a sua foto de perfil");
    } finally {
      hideLoading();
    }
  };

  const onManuallyChangeProfileImage = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { files } = event.target;
    const file = (files || [])[0];

    if (file.size > MAX_FILE_SIZE) {
      if (input.current) input.current.value = "";
      return newError("Arquivo muito grande: máx 5Mb");
    }

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async () => {
        const base64 = reader.result?.toString();

        if (base64) {
          setInitialImage(base64);
        }

        if (input.current) input.current.value = "";
      };
    }
  };

  const getInstagramProfileImage = async (alias: string) => {
    if (!alias) return;

    try {
      showLoading();
      const imageUrl = await Creator.getInstagramProfileImage(alias, token);

      const user = await Creator.updateProfile(
        { profileImage: imageUrl, formStep: "profile-image" },
        token
      );

      setUserHandler(user);
    } catch (error) {
      newError("Houve um erro ao importar a imagem");
    } finally {
      hideLoading();
    }
  };

  const onChangeHandler = (val: string) => {
    const normalizedValue = val.replace(/[^a-zA-Z0-9_.]/g, "");

    setUsername(normalizedValue);
  };

  const onNextHandler = async () => {
    try {
      showLoading();
      const newFormStep = hasProfileImage ? "colors" : "profile-image";

      if (newFormStep === user.formStep) return;

      const importFromEmailOrManually =
        authenticationMethod === "authenticate-with-google"
          ? "google-email"
          : "manually";

      Analytics.submitClickEvent({
        creator: user.id || "",
        origin: document.referrer,
        creatorUsername: user.username || "",
        description: "profile-image-step-concluded",
        actionDescription: `profile-image-step-concluded-${user.username}`,
        meta: {
          importMode: username ? "instagram" : importFromEmailOrManually,
        },
      });

      localStorage.removeItem(LOCAL_STORAGE_AUTHENTICATION_METHOD);

      const updatedUser = await Creator.updateProfile(
        {
          formStep: newFormStep,
        },
        token
      );

      if (username)
        await saveFreeItemHandler(
          socialMediaAppConfig.id,
          {
            socialMedia: "instagram",
            username,
          },
          false
        );

      setUserHandler(updatedUser);
    } catch (error) {
      newError("Houve um erro ao avançar");
    } finally {
      hideLoading();
    }
  };

  const hasProfileImage = !!user.profileImage;

  const isReady = hasProfileImage && !isLoading;

  return (
    <Page>
      <S.Container>
        <S.ProfileImageContainer>
          <S.RegisterFormHeader>
            <S.Back variant="solid" color="#fafafa05" onClick={logout}>
              Sair
            </S.Back>
          </S.RegisterFormHeader>

          <S.ProgressBar>
            <S.Text>
              <S.Title color={textColor}>Personalize sua Stanti</S.Title>
            </S.Text>

            <ProgressBar
              progress={!hasProfileImage ? (2.5 / 6) * 100 : (3 / 6) * 100}
            />
          </S.ProgressBar>

          <S.Subtitle color="#fafafa">
            Que tal adicionar uma foto de perfil?
          </S.Subtitle>

          <S.Image>
            <ProfileImage
              size="large"
              hasBackgroundImage
              imageUrl={user.profileImage ?? ""}
            />
          </S.Image>

          <S.Form>
            <S.FormTitleWithIcon>
              <AvailableIcons option="instagram" />
              <P color="#fafafa"> Importe a foto do seu Instagram</P>
            </S.FormTitleWithIcon>

            <S.SocialMediaContainer>
              <InputText
                label="Instagram"
                value={username}
                baseURL="instagram.com/"
                placeholder="username_instagram"
                onChange={(val) => onChangeHandler(val)}
              />
            </S.SocialMediaContainer>

            <S.ImportButton
              variant="outline"
              borderColor="#ddff66"
              textColor="#ddff66"
              onClick={() => getInstagramProfileImage(username)}
            >
              Importar foto do Instagram
            </S.ImportButton>
          </S.Form>

          <S.AddImageManually
            id="addImageManually"
            onClick={() => input.current?.click()}
          >
            <Small color="#fafafa80">Adicionar uma foto manualmente</Small>

            <input
              ref={input}
              type="file"
              onChange={onManuallyChangeProfileImage}
              accept="image/png, image/jpeg, image/jpg"
            />
          </S.AddImageManually>

          {isReady ? (
            <S.NextButton
              variant="outline"
              disabled={!isReady}
              textColor="#ddff66"
              borderColor="#ddff66"
              onClick={() => onNextHandler()}
            >
              Avançar
            </S.NextButton>
          ) : null}
        </S.ProfileImageContainer>

        {initialImage && (
          <ImageReposition
            initialImage={initialImage}
            onChangeImage={onUploadProfileImage}
            onDiscardImage={() => setInitialImage("")}
          />
        )}
      </S.Container>
    </Page>
  );
};

export default ProfileImageStep;
