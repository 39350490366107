import styled, { css } from "styled-components";
import { Theme } from "../../../hooks";
import H4 from "../../atoms/Typography/H4";
import Small from "../../atoms/Typography/Small";
import ShowPasswordIcon from "../../../components/icons/ShowPasswordIcon";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;

  input,
  textarea {
    flex-grow: 1;
  }
`;

export const LabelAndLimit = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RightBar = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column-reverse;
  justify-content: space-between;
`;

export const CharLimit = styled(Small)`
  font-size: 0.75rem;
  color: #fafafa80;
`;

export const Label = styled.label`
  ${() => {
    const { primaryColor } = Theme.useTheme();

    return css`
      font-size: 0.75rem;
      color: ${primaryColor};
    `;
  }}
`;

export const ErrorMessage = styled(Small)`
  font-size: 0.75rem;
`;

export const Icons = styled.div`
  gap: 8px;
  display: flex;
  cursor: pointer;
  align-items: center;
`;

export const PasswordIcon = styled(ShowPasswordIcon)`
  opacity: 0.5;
`;

type InputAndIconProps = {
  hasIcon: boolean;
};

export const InputAndIcon = styled.div<InputAndIconProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  ${({ hasIcon }) =>
    hasIcon &&
    css`
      gap: 0.75rem;
    `}
`;

export const BaseUrl = styled(H4)`
  flex-shrink: 0;
`;
