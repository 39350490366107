import styled, { css } from "styled-components";

type AppImageSelectorProps = {
  imageUrl: string;
  isSelected?: boolean;
};

export const AppImageSelector = styled.div<AppImageSelectorProps>`
  ${({ imageUrl, isSelected }) => css`
    width: 100%;
    height: 108px;
    display: flex;
    cursor: pointer;
    user-select: none;
    position: relative;
    align-items: flex-start;
    justify-content: flex-end;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 12px;
      background-size: cover;
      background-position: center;
      opacity: ${isSelected ? 0.5 : 1};
      background-image: url(${imageUrl});
    }
  `}
`;

type BoxProps = {
  borderColor: string;
};

export const Box = styled.div<BoxProps>`
  ${({ borderColor }) => css`
    top: 8px;
    right: 8px;
    width: 20px;
    height: 20px;
    display: flex;
    position: absolute;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    border: 1px solid ${borderColor};

    svg {
      width: 70%;
      height: 70%;
    }
  `}
`;
