import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const LinkedinIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="50"
      height="40"
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6721 38H7.59016V12.234H14.6721V38ZM11.1311 9.26386C8.85075 9.26386 7 7.4134 7 5.13193C7 2.85047 8.85075 1 11.1311 1C13.4115 1 15.2623 2.85047 15.2623 5.13193C15.2623 7.4134 13.4139 9.26386 11.1311 9.26386ZM43 38H35.918V24.8734C35.918 16.9843 26.4754 17.5816 26.4754 24.8734V38H19.3934V12.234H26.4754V16.3683C29.7709 10.3109 43 9.86351 43 22.168V38Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default LinkedinIcon;
