import React, { createContext, useContext, useState } from "react";
import NavBar from "../../componentsV2/organisms/NavBar";

interface INavBarContext {
  hideNavBar: () => void;
  showNavBar: () => void;
}

const defaultContext: INavBarContext = {
  hideNavBar: () => null,
  showNavBar: () => null,
};

const Context = createContext(defaultContext);
const useNavBar = () => useContext(Context);

const Provider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const hideNavBar = () => {
    setIsVisible(false);
  };

  const showNavBar = () => {
    setIsVisible(true);
  };

  const providerValue = {
    showNavBar,
    hideNavBar,
  };

  return (
    <Context.Provider value={providerValue}>
      {children}
      {isVisible && <NavBar />}
    </Context.Provider>
  );
};

export default {
  useNavBar,
  Provider,
};
