import * as S from "./styles";
import { IOrder } from "../../../types";
// import ProductCard from "../ProductCard";
import ProductList from "../ProductList";
import { IIncomeReport } from "../../../services/order";
import NoSales from "../../Dashboard/components/NoSales";
import CollapsableOrder from "../../../componentsV2/molecules/CollapsableOrder";
import ExternalLinkSkeleton from "../../../components/atoms/ExternalLinkSkeleton";

interface IProps {
  sales: IOrder[];
  isLoading: boolean;
  selectedProduct: string;
  saleDetailsSelected?: number;
  // productTotalValue?: number;
  selectedProductFilter: string;
  onOpenSaleDetails: (val: number) => void;
  setSelectedProduct: (val: string) => void;
  productsWithSales: IIncomeReport["totalizers"]["productsWithSales"];
}

const Extrato: React.FC<IProps> = ({
  sales,
  isLoading,
  selectedProduct,
  productsWithSales,
  onOpenSaleDetails,
  setSelectedProduct,
  saleDetailsSelected,
  // productTotalValue,
  selectedProductFilter,
}) => {
  // const selectedProductData = (productsWithSales || []).find(
  //   (product) => product.id === selectedProduct.split("||")[1]
  // );

  // const appData = selectedProductData
  //   ? require(`../../../config/app-${selectedProductData.app}.json`)
  //   : {};

  const body = (
    <>
      {/* Comentando o card que era exibido, com um resumo das vendas daquele produto, no período selecionado */}
      {/* {selectedProduct && selectedProductData && (
        <ProductCard
          appIcon={appData.icon}
          appColor={appData.color}
          productLabel={appData.title}
          appGradient={appData.gradient}
          totalIncome={productTotalValue}
          productTitle={selectedProductData.title}
        />
      )} */}

      {selectedProductFilter === "by-product" && !selectedProduct ? (
        <ProductList
          products={productsWithSales}
          onProductClick={(id) => setSelectedProduct(id)}
        />
      ) : (
        <>
          {sales &&
            sales.length > 0 &&
            sales.map((sale, index) => (
              <CollapsableOrder
                sale={sale}
                key={sale.id}
                productsWithSales={productsWithSales}
                isOpen={saleDetailsSelected === index}
                onClick={() => onOpenSaleDetails(index)}
              />
            ))}
        </>
      )}
    </>
  );

  if (sales.length === 0) {
    return (
      <S.Extrato>
        <NoSales
          title="Nenhuma venda com esses filtros!"
          description="Você não possui vendas registradas, nas condições filtradas. Clique em adicionar para
              incluir serviços e produtos para venda."
        />
      </S.Extrato>
    );
  }

  return <S.Extrato>{isLoading ? <ExternalLinkSkeleton /> : body}</S.Extrato>;
};

export default Extrato;
