import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import { Theme } from "../../../hooks";
import PlusIcon from "../../../components/icons/PlusIcon";

interface IProps {
  label: string;
  route: string;
  icon: ReactElement;
  containedBox?: boolean;
}

const AddContentShortcut: React.FC<IProps> = ({
  icon,
  route,
  label,
  containedBox = false,
}) => {
  const navigate = useNavigate();
  const { textColor } = Theme.useTheme();

  return (
    <S.Box
      isContained={containedBox}
      onClick={() => navigate(`/apps/${route}`)}
    >
      <div className="plusIcon">
        <PlusIcon />
      </div>

      <S.Content>
        <S.Icon>{icon}</S.Icon>

        <S.Label color={textColor} fontWeight="medium">
          {label}
        </S.Label>
      </S.Content>
    </S.Box>
  );
};

export default AddContentShortcut;
