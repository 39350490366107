import * as S from "./styles";
import { TIconOptions } from "../../../../types";
import Navigation from "../../../../componentsV2/molecules/Navigation";

const customizationApps = [
  {
    app: "galeria-imagens",
    icon: "personPics",
    link: "galeria-imagens/new",
    title: "Adicione galerias de imagens",
  },
  {
    icon: "instagramTwo",
    app: "link-to-post-instagram",
    link: "link-to-post-instagram",
    title: "Compartilhe seus posts do Instagram",
  },
  {
    app: "youtube",
    icon: "youtube",
    link: "youtube",
    title: "Exiba vídeos ou canais do Youtube",
  },
];

interface IProps {
  onClick: (app: string, link: string) => void;
}

const CustomizeAppList: React.FC<IProps> = ({ onClick }) => {
  return (
    <S.CustomizeAppListContainer>
      {customizationApps.map((app) => (
        <Navigation
          key={app.title}
          iconId={app.icon as TIconOptions}
          textFirstColor={app.title}
          onClick={() => onClick(app.app, app.link)}
        />
      ))}
    </S.CustomizeAppListContainer>
  );
};

export default CustomizeAppList;
