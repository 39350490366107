import { useEffect, useState } from "react";

import Page from "../../../../components/atoms/Page";
import P from "../../../../components/atoms/Typography/P";
import Chevron from "../../../../components/icons/Chevron";
import ProfileImage from "../../../../componentsV2/atoms/ProfileImage";
import ProgressBar from "../../../../componentsV2/atoms/ProgressBar";
import InputSelect from "../../../../componentsV2/molecules/InputSelect";
import {
  backgroundColors,
  backgroundLabelColors,
  primaryColors,
  primaryLabelColors,
} from "../../../../constants/colors";
import { Auth, Loading, Snackbar, Theme } from "../../../../hooks";
import { Analytics, Creator } from "../../../../services";
import { ICreatorData, TSteps } from "../../../../types";

import * as S from "./styles";

const ColorStep: React.FC = () => {
  const [creatorForm, setCreatorForm] = useState<ICreatorData>();
  const [hasChangedTheme, setHasChangedTheme] = useState<boolean>(false);

  const { primaryColor, textColor } = Theme.useTheme();
  const { newError } = Snackbar.useSnackbar();
  const { user, setUserHandler, token } = Auth.useAuth();
  const { showLoading, hideLoading } = Loading.useLoading();

  const selectedBackgroundColorIndex = backgroundColors.indexOf(
    creatorForm?.backgroundColor ?? ""
  );
  const selectedPrimaryColorIndex = primaryColors.indexOf(
    creatorForm?.primaryColor ?? ""
  );

  useEffect(() => {
    setCreatorForm(user);
  }, [user]);

  useEffect(() => {
    Analytics.submitPageViewEvent({
      creator: user.id || "",
      origin: document.referrer,
      description: "colors-step",
      actionDescription: "select-colors",
      creatorUsername: user.username || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeTheme = (
    key: "primaryColor" | "backgroundColor",
    value: string
  ) => {
    setCreatorForm((curr) => ({ ...curr, [key]: value }));
    setUserHandler({ [key]: value });
    setHasChangedTheme(true);
  };

  const onBackHandler = () => {
    setUserHandler({ formStep: "profile-image" });
  };

  const onNextHandler = async () => {
    try {
      showLoading();
      const payload = {
        formStep: "social-media" as TSteps,
        primaryColor: creatorForm?.primaryColor,
        backgroundColor: creatorForm?.backgroundColor,
      };

      (Object.keys(payload) as (keyof typeof payload)[]).forEach((key) => {
        if (!payload[key]) delete payload[key];
      });

      const updateUser = await Creator.updateProfile(payload, token);

      Analytics.submitClickEvent({
        creator: user.id || "",
        origin: document.referrer,
        creatorUsername: user.username || "",
        description: "colors-step-concluded",
        meta: { hasChangedTheme: hasChangedTheme },
        actionDescription: `colors-step-concluded-${user.username}`,
      });

      setUserHandler(updateUser);
    } catch (error) {
      newError("Houve um erro ao salvar os dados.");
    } finally {
      hideLoading();
    }
  };

  return (
    <Page>
      <S.Wrapper>
        <S.Container>
          <S.RegisterFormHeader>
            <S.Back variant="icon" onClick={onBackHandler}>
              <Chevron color="#fafafa" />
            </S.Back>
          </S.RegisterFormHeader>

          <S.ProgressBar>
            <S.Text>
              <S.Title color={textColor}>Personalize sua Stanti</S.Title>
            </S.Text>

            <ProgressBar progress={66.66} />
          </S.ProgressBar>

          <S.Subtitle color="#fafafa">
            Vamos definir as cores da sua Stanti?
          </S.Subtitle>

          <S.Content>
            <S.Image>
              <ProfileImage
                size="large"
                hasBackgroundImage
                imageUrl={user.profileImage ?? ""}
              />
            </S.Image>

            <S.ChooseColors>
              <S.ContentBox>
                <P color={primaryColor}>Cor de destaque</P>
                <InputSelect
                  listType="colors"
                  onSelect={(index) =>
                    onChangeTheme("primaryColor", primaryColors[index])
                  }
                  options={primaryLabelColors}
                  colors={primaryColors}
                  selectedIndex={selectedPrimaryColorIndex}
                />
              </S.ContentBox>

              <S.ContentBox>
                <P color={primaryColor}>Cor de fundo</P>
                <InputSelect
                  listType="colors"
                  onSelect={(index) =>
                    onChangeTheme("backgroundColor", backgroundColors[index])
                  }
                  options={backgroundLabelColors}
                  colors={backgroundColors}
                  selectedIndex={selectedBackgroundColorIndex}
                />
              </S.ContentBox>
            </S.ChooseColors>
          </S.Content>

          <S.NextButton
            variant="outline"
            borderColor={primaryColor}
            textColor={primaryColor}
            onClick={onNextHandler}
          >
            Avançar
          </S.NextButton>
        </S.Container>
      </S.Wrapper>
    </Page>
  );
};

export default ColorStep;
