import styled, { css } from "styled-components";

type ButtonProps = {
  textColor?: string;
  variant?: "default" | "solid" | "outline" | "icon";
  size?: "medium" | "large" | "small";
  backgroundColor?: string;
  borderColor?: string;
};

const buttonModifier = {
  solid: (backgroundColor: string) => css`
    width: 100%;
    background-color: ${backgroundColor};
    border-radius: 12px;
  `,
  outline: (borderColor: string) => css`
    width: 100%;
    background: none;
    border: 1px solid ${borderColor};
    border-radius: 12px;
  `,
  icon: (backgroundColor: string, size: string) => css`
    background-color: ${backgroundColor};
    border-radius: 50%;
    width: ${size == "small" ? "20px" : "40px"};
    height: ${size == "small" ? "20px" : "40px"};
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      line-height: 100%;
      height: ${size == "small" ? "8px" : "12px"};
      width: auto;
    }
  `,

  small: () => css`
    padding: 8px 0px;
  `,
  medium: () => css`
    padding: 12px 0px;
  `,
  large: () => css`
    padding: 18px 0px;
  `,
};

const Button = styled.button<ButtonProps>`
  ${({
    size = "medium",
    variant = "default",
    textColor = "#fafafa",
    borderColor = "#fafafa",
    backgroundColor = ["solid", "icon"].includes(variant)
      ? "#fafafa0D"
      : "none",
  }) => {
    return css`
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
      color: ${textColor};

      ${variant === "solid" && buttonModifier[variant](backgroundColor)}
      ${variant === "outline" && buttonModifier[variant](borderColor)}
      ${variant === "icon" && buttonModifier[variant](backgroundColor, size)}
      ${!["default", "icon"].includes(variant) && buttonModifier[size]()}

      &:disabled, &[disabled] {
        color: ${textColor}80;
        border-color: ${borderColor}80;
        background-color: ${backgroundColor.length === 9
          ? backgroundColor
          : `${backgroundColor}80`};
        cursor: not-allowed;

        svg {
          opacity: 0.5;
        }
      }
    `;
  }}
`;

export default Button;
