import styled, { css } from "styled-components";

type DraggableProps = {
  isMobileDragOn?: boolean;
  coordinates?: {
    x: number;
    y: number;
  };
};

export const Container = styled.div<DraggableProps>`
  ${({ isMobileDragOn, coordinates }) =>
    isMobileDragOn &&
    css`
      position: absolute;
      top: ${coordinates?.y && `${coordinates.y}px`};
      left: ${coordinates?.x && `${coordinates.x}px`};
      transition: none;
      width: calc(100% - 48px);
    `}
`;

export const DraggableItem = styled.div<DraggableProps>`
  gap: 8px 0;
  cursor: grab;
  display: flex;
  user-select: none;
  border-radius: 12px;
  background-color: #fafafa0d;

  ${({ isMobileDragOn }) =>
    isMobileDragOn &&
    css`
      opacity: 0.5;
    `}
`;

export const Drag = styled.div`
  gap: 4px;
  width: 3.75rem;
  touch-action: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    width: 18px;
    height: 2px;
    content: "";
    background-color: #fafafa40;
    border-radius: 20px;
  }
`;
