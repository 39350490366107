import styled, { css } from "styled-components";

type CardProps = {
  backgroundColor: string;
};

export const Card = styled.div<CardProps>`
  ${({ backgroundColor }) => css`
    width: 100%;
    display: flex;
    border-radius: 12px;
    align-items: center;
    padding: 20px 24px 20px 20px;
    background-color: ${backgroundColor};
  `}
`;

export const Content = styled.div`
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: center;
`;

type ImageProps = {
  image: string;
};

export const Image = styled.div<ImageProps>`
  ${({ image }) => css`
    width: 40px;
    height: 40px;
    flex-grow: 1;
    display: flex;
    flex-shrink: 0;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    background: url(${image});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  `}
`;

export const Information = styled.div`
  gap: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TrashIcon = styled.div`
  z-index: 10;
  width: 12px;
  height: 16px;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
