import * as S from "./styles";
import { TIconOptions } from "../../../../types";
import Navigation from "../../../../componentsV2/molecules/Navigation";

const links = [
  {
    app: "mentoria",
    icon: "bookTwo",
    link: "mentoria/new",
    title: "Venda mentorias e encontros online",
  },
  {
    app: "arquivo",
    icon: "folder",
    title: "Venda cursos gravados",
    link: "arquivo/course/new",
  },
  {
    app: "arquivo",
    icon: "files",
    link: "arquivo",
    title: "Venda e-books, PDFs e arquivos",
  },
  {
    app: "servico",
    title: "Serviço",
    icon: "call",
    link: "servico",
  },
  {
    app: "curso-live",
    icon: "onlineCall",
    link: "curso-live/new",
    title: "Venda cursos transmitidos online",
  },
  {
    app: "stanti-pay",
    icon: "moneyTwo",
    link: "stanti-pay",
    title: "Crie links de pagamentos",
  },
  {
    app: "grupo",
    link: "grupo/new",
    icon: "group",
    title: "Venda grupos pagos e privados",
  },
  {
    app: "afiliados",
    icon: "receipt",
    link: "afiliados",
    title: "Programa de afiliados da Stanti",
  },
];

interface IProps {
  onClick: (app: string, link: string) => void;
}

const MonetizedAppList: React.FC<IProps> = ({ onClick }) => {
  return (
    <S.MonetizedAppListContainer>
      {links.map((link) => (
        <Navigation
          key={link.title}
          iconId={link.icon as TIconOptions}
          textFirstColor={link.title}
          onClick={() => onClick(link.app, link.link)}
        />
      ))}
    </S.MonetizedAppListContainer>
  );
};

export default MonetizedAppList;
