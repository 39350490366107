import { Theme } from "../../../hooks";
import SwitchBase from "../../atoms/SwitchBase";
import * as S from "./styles";

interface IProps {
  isOn: boolean;
  label: string;
  color?: string;
  labelSize?: "medium" | "small";
  setIsOn: (isOn: boolean) => void;
}

const Switch: React.FC<IProps> = ({
  isOn,
  label,
  color,
  setIsOn,
  labelSize = "medium",
}) => {
  const { textColor } = Theme.useTheme();

  return (
    <S.Container>
      <S.Label
        labelSize={labelSize}
        color={color || textColor}
        fontWeight="regular"
      >
        {label}
      </S.Label>

      <SwitchBase isOn={isOn} setIsOn={setIsOn} />
    </S.Container>
  );
};

export default Switch;
