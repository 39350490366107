import * as S from "./styles";
import { Theme } from "../../../../hooks";
import AvailableIcons from "../../../../components/atoms/AvailableIcons";

interface IProps {
  images: string;
  isSelected: boolean;
  onClick: () => void;
}

const AppImageSelector: React.FC<IProps> = ({
  images,
  onClick,
  isSelected,
}) => {
  const { primaryColor } = Theme.useTheme();

  return (
    <>
      <S.AppImageSelector
        onClick={onClick}
        imageUrl={images}
        isSelected={isSelected}
      >
        {isSelected && (
          <S.Box borderColor={primaryColor}>
            <AvailableIcons option="check" color={primaryColor} />
          </S.Box>
        )}
      </S.AppImageSelector>
    </>
  );
};

export default AppImageSelector;
