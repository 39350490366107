/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import Radio from "../../atoms/RadioMenu";
import Button from "../../atoms/Button";
import { Auth, Theme } from "../../../hooks";
import { Analytics } from "../../../services";
import { IDate, ITime } from "../../../types/dates";
import DateTime from "../../molecules/InputDateTime";
import InputNumber from "../../molecules/InputNumber";
import InputSelect from "../../molecules/InputSelect";
import { duration } from "../../../constants/calendar";
import TitleDescription from "../../molecules/TitleDescription";
import AvailableIcons from "../../../components/atoms/AvailableIcons";
import { ICalendar, TSchedulingOption } from "../../../types/calendar";

interface IProps {
  appId: string;
  time?: {
    start: ITime;
    end: ITime;
  };
  dates?: IDate[];
  isAllDay: boolean;
  optionsValues: string[];
  durationSelected: string;
  numberOfSessions?: number;
  calendar?: ICalendar | null;
  selectedIndexOption: number;
  optionsKeys: TSchedulingOption[];
  onSave: (isDraft: boolean) => void;
  errors?: { [key: string]: string[] };
  onChangeInput: (val: any, field: string) => void;
  onSelectOption: (index: TSchedulingOption) => void;
}

const DatesTimesAndScheduling: React.FC<IProps> = ({
  time,
  appId,
  dates,
  onSave,
  errors,
  calendar,
  isAllDay,
  optionsKeys,
  optionsValues,
  onChangeInput,
  onSelectOption,
  numberOfSessions,
  durationSelected,
  selectedIndexOption,
}) => {
  const navigate = useNavigate();

  const { user } = Auth.useAuth();
  const { primaryColor, textColor } = Theme.useTheme();

  const addDateHandler = () => {
    const newDates = [...(dates || [])];
    newDates.push({ day: "", month: "", year: "" });

    onChangeInput(newDates, "dates");
  };

  const onRemoveDate = (index: number) => {
    const newDates = [...(dates || [])];
    newDates.splice(index, 1);

    onChangeInput(newDates, "dates");
  };

  const onSelectOptionHandler = (index: number) => {
    onSelectOption(optionsKeys[index]);
  };

  const durationOptions = duration.map(
    (item) => `${item.text} de duração por sessão`
  );

  const durationSelectedIndex = duration.findIndex(
    (item) => item.durationFormat === durationSelected
  );

  const onChangeDuration = (index: number) => {
    onChangeInput(duration[index].durationFormat, "duration");
  };

  const onAddCalendarClick = () => {
    Analytics.submitClickEvent({
      origin: appId,
      creator: user.id || "",
      description: "set-my-agenda",
      actionDescription: "open-my-agenda",
      creatorUsername: user.username || "",
    });

    onSave(true);

    navigate("/calendar");
  };

  const agendaErrors =
    errors?.duration || errors?.numberOfSessions
      ? [errors?.duration, errors?.numberOfSessions].filter((i) => !!i)
      : null;

  return (
    <S.Container>
      <TitleDescription
        titleColor={textColor}
        textColor={`${textColor}80`}
        title="Datas, horários e agendamentos"
      >
        Selecione se deseja disponibilizar sua agenda ou definir uma data e
        horário para este produto/serviço.
      </TitleDescription>

      <Radio
        protectionSize="full"
        values={optionsValues}
        selectedIndex={selectedIndexOption}
        onSelect={(val) => onSelectOptionHandler(val)}
      />

      {optionsKeys[selectedIndexOption] === "defined-dates" && (
        <>
          <S.BoxContent>
            <S.Label color={primaryColor} fontWeight="regular">
              Data
            </S.Label>

            {dates &&
              dates.map((date, index) => (
                <S.Dates key={`${index}`}>
                  <DateTime
                    dates={dates}
                    dateIndex={index}
                    variant="fullDate"
                    onChange={(val) => onChangeInput(val, "dates")}
                  />

                  {dates.length > 1 && (
                    <S.RemoveIcon
                      borderColor={primaryColor}
                      onClick={() => onRemoveDate(index)}
                    >
                      <AvailableIcons option="close" />
                    </S.RemoveIcon>
                  )}
                </S.Dates>
              ))}

            <S.Hr />

            <Button variant="solid" onClick={() => addDateHandler()}>
              <S.Text color={textColor} fontWeight="medium">
                Adicionar mais uma data
              </S.Text>
            </Button>
          </S.BoxContent>

          <S.BoxContent>
            <S.Label color={primaryColor} fontWeight="regular">
              Horário
            </S.Label>

            {!isAllDay && (
              <>
                <S.TimeBox>
                  <DateTime
                    time={time}
                    variant="time"
                    timeType="start"
                    onChange={(val) => onChangeInput(val, "time")}
                  />

                  <S.Text color={primaryColor} fontWeight="regular">
                    às
                  </S.Text>

                  <DateTime
                    time={time}
                    variant="time"
                    timeType="end"
                    onChange={(val) => onChangeInput(val, "time")}
                  />
                </S.TimeBox>

                <S.Hr />
              </>
            )}

            <S.AllDay
              isOn={isAllDay}
              label="Dia inteiro"
              setIsOn={(val) => onChangeInput(val, "isAllDay")}
            />
          </S.BoxContent>

          {errors?.dates && errors.dates.length && (
            <S.ErrorMessage color="#FF4D4F">
              Erros: {errors.dates.join(", ")}
            </S.ErrorMessage>
          )}
        </>
      )}

      {optionsKeys[selectedIndexOption] === "agenda" && (
        <>
          <S.Description color={textColor} fontWeight="regular">
            Ao selecionar esta opção, você disponibilizará seus horários
            definidos previamente por você no menu Editar Perfil.
          </S.Description>

          {calendar ? (
            <S.AgendaOptions>
              <InputNumber
                labelColor={primaryColor}
                label="Quantidade de sessões"
                value={numberOfSessions || 0}
                onChange={(val) => onChangeInput(val, "numberOfSessions")}
              />

              <S.Hr />

              <S.Label color={primaryColor} fontWeight="regular">
                Selecione a duração de casa sessão
              </S.Label>

              <InputSelect
                label="Selecione a opção"
                options={durationOptions}
                selectedIndex={durationSelectedIndex}
                onSelect={(val) => onChangeDuration(val)}
              />
            </S.AgendaOptions>
          ) : (
            <S.BoxContent>
              <TitleDescription
                centralize={true}
                titleColor={textColor}
                textColor={`${textColor}80`}
                title="Nenhum horário cadastrado"
              >
                Você ainda não possui horários cadastrados. Clique no botão
                abaixo para definir sua agenda de trabalho para agendamentos.
              </TitleDescription>

              <S.AddCalendar
                variant="solid"
                onClick={() => onAddCalendarClick()}
              >
                Cadastrar meus horários
              </S.AddCalendar>
            </S.BoxContent>
          )}

          {agendaErrors && agendaErrors.length && (
            <S.ErrorMessage color="#FF4D4F">
              Erros: {agendaErrors.join(", ")}
            </S.ErrorMessage>
          )}
        </>
      )}
    </S.Container>
  );
};

export default DatesTimesAndScheduling;
