import styled, { css } from "styled-components";

type ImageStepProps = {
  isImage: boolean;
};

export const ImageStep = styled.div<ImageStepProps>`
  gap: 12px;
  padding-top: 16px;
  flex-direction: column;

  ${({ isImage }) => css`
    display: ${isImage ? "none" : "flex"};
  `}

  input[type="file"] {
    display: none;
  }
`;

export const IconOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(4rem, 1fr));
  gap: 12px;
  margin-top: 12px;

  .iconOption {
    width: 64px;
    height: 64px;
    background-color: #fafafa0d;
    padding: 20px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;
