import styled, { css } from "styled-components";
import Box from "../../componentsV2/atoms/Box";
import Button from "../../components/atoms/Button";

export const BoxContainer = styled(Box)`
  gap: 20px;
`;

type RevenueProps = {
  backgroundColor: string;
};

export const Revenue = styled.div<RevenueProps>`
  ${({ backgroundColor }) => css`
    display: flex;
    padding: 20px;
    align-items: center;
    border-radius: 12px;
    justify-content: space-between;
    background-color: ${backgroundColor}05;
  `}
`;

export const ThisMonth = styled.div`
  gap: 6px;
  display: flex;
  text-align: start;
  flex-direction: column;
`;

export const SinceAlways = styled.div`
  gap: 6px;
  display: flex;
  text-align: end;
  flex-direction: column;
`;

export const SeeAllButton = styled(Button)`
  margin: 10px 0 20px 0;
`;
