import {
  IStatsData,
  IPieChartData,
  IBarChartData,
  ISocialMediaEntity,
  IPersonalInfoEntity,
} from "../types/media-kit";

const {
  REACT_APP_TIKTOK_CLIENT_ID,
  REACT_APP_TIKTOK_AUTH_BASE_URL,
  REACT_APP_OAUTH_BASE_REDIRECT_URL,
  REACT_APP_INSTAGRAM_MEDIA_KIT_CLIENT_ID,
  REACT_APP_INSTAGRAM_MEDIA_KIT_AUTH_BASE_URL,
} = process.env;

export const emptyUserInfo: IPersonalInfoEntity = {
  id: "",
  name: "",
  city: "",
  email: "",
  about: "",
  state: "",
  country: "",
  updatedAt: "",
  createdAt: "",
  removed: false,
  coverImageUrl: "",
  profileImageUrl: "",
  entity: "PERSONAL-INFO",
  visibleOnStorefront: true,
};

export const statBoxes = [
  {
    iconId: "people",
    label: "seguidores",
    key: "followerCount" as keyof IStatsData,
    tolltip: "Número total de seguidores",
  },
  {
    iconId: "heart",
    label: "curtidas",
    key: "likeCount" as keyof IStatsData,
    tolltip: "Número de curtidas nos últimos 6 meses",
  },
  {
    iconId: "personPics",
    label: "publicações",
    key: "mediaCount" as keyof IStatsData,
    tolltip: "Número de publicações nos últimos 6 meses",
  },
  {
    iconId: "message",
    label: "comentários",
    key: "commentCount" as keyof IStatsData,
    tolltip: "Número de comentários nos últimos 6 meses",
  },
  {
    iconId: "linesChart",
    label: "engajamento",
    key: "engagement" as keyof IStatsData,
    tolltip:
      "Engajamento dos últimos 6 meses. Cálculo: (soma de curtidas + soma de comentários) / (número de posts x números de seguidores) x 100",
  },
  {
    iconId: "showPassword",
    label: "visualizações",
    key: "viewCount" as keyof IStatsData,
    tolltip: "Número de visualizações nos últimos 6 meses",
  },
];

export const publicBoxes = [
  {
    label: "1",
    type: "gender",
    title: "Gênero",
    tolltip: "Gênero de seus seguidores do Instagram",
  },
  {
    label: "2",
    type: "age",
    title: "Idade",
    tolltip: "Idade de seus seguidores do Instagram",
  },
  {
    label: "3",
    type: "location",
    title: "Localização",
    tolltip:
      "Localização de seus seguidores do Instagram. Limitamos a visualização de dados que representem mais que 1% de seus seguidores",
  },
];

export const selectedColors = (color: string) => {
  const colors = [];

  switch (color) {
    case "#FFA8BD":
      colors[300] = "#FED5DF";
      colors[400] = "#F8A3AD";
      colors[600] = "#EC5071";
      colors[700] = "#ED2F52";
      break;
    case "#DDFF66":
      colors[300] = "#F8FFCC";
      colors[400] = "#FAFCA1";
      colors[600] = "#BCE057";
      colors[700] = "#AAD13B";
      break;
    case "#FF9960":
      colors[300] = "#FBE6D8";
      colors[400] = "#FFBEAA";
      colors[600] = "#FF774B";
      colors[700] = "#FC6130";
      break;
    case "#A0D7FF":
      colors[300] = "#DBEDFD";
      colors[400] = "#A6D4FE";
      colors[600] = "#5FB1FE";
      colors[700] = "#49A2FB";
      break;
    case "#FAFAFA":
      colors[300] = "#FAFAFA";
      colors[400] = "#F5F5F5";
      colors[600] = "#CCCCCC";
      colors[700] = "#B8B8B8";
      break;
    case "#A091FF":
      colors[300] = "#DBD6FC";
      colors[400] = "#C9C2FA";
      colors[600] = "#8271F7";
      colors[700] = "#5F48F5";
      break;
    case "#FFC700":
      colors[300] = "#FDF5D9";
      colors[400] = "#FBE7A3";
      colors[600] = "#F6C445";
      colors[700] = "#E9B026";
      break;
    case "#00FFB2":
      colors[300] = "#7FFFD4";
      colors[400] = "#00CC8E";
      colors[600] = "#00FA9A";
      colors[700] = "#00FF7F";
      break;
  }

  return colors;
};

export const getInstagramAuhtorizationUrl = () => {
  const params = [
    `client_id=${REACT_APP_INSTAGRAM_MEDIA_KIT_CLIENT_ID}`,
    `redirect_uri=${encodeURIComponent(
      `${REACT_APP_OAUTH_BASE_REDIRECT_URL}/apps/media-kit/`
    )}`,
    `response_type=token`,
    `scope=pages_show_list,instagram_basic,instagram_manage_insights,pages_read_engagement,public_profile`,
  ].join("&");

  const authorizationUrl = `${REACT_APP_INSTAGRAM_MEDIA_KIT_AUTH_BASE_URL}?${params}`;

  return authorizationUrl;
};

export const getTiktokAuhtorizationUrl = () => {
  const params = [
    `client_key=${REACT_APP_TIKTOK_CLIENT_ID}`,
    `response_type=code`,
    `scope=user.info.basic,video.list`,
    `redirect_uri=${encodeURIComponent(
      `${REACT_APP_OAUTH_BASE_REDIRECT_URL}/apps/media-kit/`
    )}`,
  ].join("&");

  const authorizationUrl = `${REACT_APP_TIKTOK_AUTH_BASE_URL}?${params}`;

  return authorizationUrl;
};

const statsDataMask = (value: number, field?: string) => {
  if (value === 0 || !value) return "-";

  if (field === "percentage") {
    return (
      value.toLocaleString("pt-BR", {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      }) + "%"
    );
  } else if (value < 1000) return value.toString();

  const numOfAlgarisms = value.toString().length;

  if (numOfAlgarisms >= 3 && numOfAlgarisms <= 5) {
    return (
      (value / Math.pow(10, 3)).toLocaleString("pt-BR", {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      }) + "K"
    );
  } else if (numOfAlgarisms === 6) {
    return (
      (value / Math.pow(10, 3)).toLocaleString("pt-BR", {
        maximumFractionDigits: 0,
      }) + "K"
    );
  } else if (numOfAlgarisms > 6 && numOfAlgarisms <= 8) {
    return (
      (value / Math.pow(10, 6)).toLocaleString("pt-BR", {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      }) + "M"
    );
  } else if (numOfAlgarisms === 9) {
    return (
      (value / Math.pow(10, 6)).toLocaleString("pt-BR", {
        maximumFractionDigits: 0,
      }) + "M"
    );
  } else if (numOfAlgarisms > 9 && numOfAlgarisms <= 11) {
    return (
      (value / Math.pow(10, 9)).toLocaleString("pt-BR", {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      }) + "B"
    );
  }

  return (
    (value / Math.pow(10, 9)).toLocaleString("pt-BR", {
      maximumFractionDigits: 0,
    }) + "B"
  );
};

export const normalizeStatsData = (
  tiktokData: ISocialMediaEntity | undefined,
  instagramData: ISocialMediaEntity | undefined
): IStatsData => {
  const statsData: IStatsData = (() => {
    if (tiktokData && instagramData) {
      const likeCountValue =
        (tiktokData?.likeCount || 0) + (instagramData?.likeCount || 0);
      const viewCountValue =
        (tiktokData?.viewCount || 0) + (instagramData?.viewCount || 0);
      const mediaCountValue =
        (tiktokData?.mediaCount || 0) + (instagramData?.mediaCount || 0);
      const commentCountValue =
        (tiktokData?.commentCount || 0) + (instagramData?.commentCount || 0);
      const followerCountValue =
        (tiktokData?.followerCount || 0) + (instagramData?.followerCount || 0);

      const engagement = statsDataMask(
        ((likeCountValue + commentCountValue) /
          (mediaCountValue * followerCountValue)) *
          100,
        "percentage"
      );
      const likeCount = statsDataMask(likeCountValue);
      const viewCount = statsDataMask(viewCountValue);
      const mediaCount = statsDataMask(mediaCountValue);
      const commentCount = statsDataMask(commentCountValue);
      const followerCount = statsDataMask(followerCountValue);

      return {
        likeCount,
        viewCount,
        mediaCount,
        engagement,
        commentCount,
        followerCount,
      };
    } else if (tiktokData) {
      const likeCountValue = tiktokData?.likeCount || 0;
      const viewCountValue = tiktokData?.viewCount || 0;
      const mediaCountValue = tiktokData?.mediaCount || 0;
      const commentCountValue = tiktokData?.commentCount || 0;
      const followerCountValue = tiktokData?.followerCount || 0;

      const engagement = statsDataMask(
        ((likeCountValue + commentCountValue) /
          (mediaCountValue * followerCountValue)) *
          100,
        "percentage"
      );
      const likeCount = statsDataMask(likeCountValue);
      const viewCount = statsDataMask(viewCountValue);
      const mediaCount = statsDataMask(mediaCountValue);
      const commentCount = statsDataMask(commentCountValue);
      const followerCount = statsDataMask(followerCountValue);

      return {
        likeCount,
        viewCount,
        mediaCount,
        engagement,
        commentCount,
        followerCount,
      };
    }

    const likeCountValue = instagramData?.likeCount || 0;
    const viewCountValue = instagramData?.viewCount || 0;
    const mediaCountValue = instagramData?.mediaCount || 0;
    const commentCountValue = instagramData?.commentCount || 0;
    const followerCountValue = instagramData?.followerCount || 0;

    const engagement = statsDataMask(
      ((likeCountValue + commentCountValue) /
        (mediaCountValue * followerCountValue)) *
        100,
      "percentage"
    );
    const likeCount = statsDataMask(likeCountValue);
    const viewCount = statsDataMask(viewCountValue);
    const mediaCount = statsDataMask(mediaCountValue);
    const commentCount = statsDataMask(commentCountValue);
    const followerCount = statsDataMask(followerCountValue);

    return {
      likeCount,
      viewCount,
      mediaCount,
      engagement,
      commentCount,
      followerCount,
    };
  })();

  return statsData;
};

export const calculatePercentagePie = (data: IPieChartData[]) => {
  const sumValues = data.reduce((acc, curr) => {
    acc += curr.value;

    return acc;
  }, 0);

  const percentageValues = data.map((item) => ({
    id: item.id,
    value: ((item.value / sumValues) * 100).toFixed() + "%",
  }));

  return percentageValues;
};

export const calculatePercentageBar = (data: IBarChartData[]) => {
  const sumValues = data.reduce((acc, curr) => {
    acc += curr.value;

    return acc;
  }, 0);

  const percentageValues = data.map((item) => ({
    ageRange: item.ageRange,
    value: sumValues === 0 ? 0 : item.value / sumValues,
  }));

  return percentageValues;
};

const normalizeLocation = (
  a: ISocialMediaEntity["location"] | undefined,
  b: ISocialMediaEntity["location"] | undefined
) => {
  if (a && b) {
    const location = a.reduce((acc, curr) => {
      const cityIndex = acc.findIndex((item) => item.city === curr.city);

      if (cityIndex !== -1) {
        acc[cityIndex].value += curr.value;
        return acc;
      }

      acc.push(curr);
      return acc;
    }, b);

    return location;
  } else if (a) {
    return a;
  } else {
    return b;
  }
};

export const normalizePublicData = (
  age: ISocialMediaEntity["followerAge"][],
  location: (ISocialMediaEntity["location"] | undefined)[],
  gender: (ISocialMediaEntity["followerGender"] | undefined)[]
) => {
  const normAge = age
    .filter((data) => !!data)
    .reduce(
      (acc, curr) => {
        acc["<18"] += curr["<18"];
        acc["18-24"] += curr["18-24"];
        acc["25-34"] += curr["25-34"];
        acc[">34"] += curr[">34"];

        return acc;
      },
      {
        "<18": 0,
        "18-24": 0,
        "25-34": 0,
        ">34": 0,
      }
    );

  const normLocation = normalizeLocation(location[0], location[1]);

  const normGender = gender
    .filter((data) => !!data)
    .reduce(
      (acc, curr) => {
        if (curr && acc) {
          acc["male"] += curr["male"];
          acc["female"] += curr["female"];
          acc["uncategorised"] += curr["uncategorised"];

          return acc;
        }
      },
      {
        male: 0,
        female: 0,
        uncategorised: 0,
      }
    );

  return {
    ageData: normAge,
    genderData: normGender,
    locationData: normLocation,
  };
};

export const calculatePercentageLocation = (
  data: ISocialMediaEntity["location"] | undefined
) => {
  if (!data) return;

  const sumValues = data.reduce((acc, curr) => {
    acc += curr.value;

    return acc;
  }, 0);

  const percentageValues = data
    .filter((item) => item.value / sumValues > 0.01)
    .map((item) => ({
      city: item.city,
      state: item.state,
      value:
        sumValues === 0
          ? "0%"
          : ((item.value / sumValues) * 100).toFixed() + "%",
    }));

  return percentageValues;
};
