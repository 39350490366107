import styled, { css } from "styled-components";

import ProfileImage from "../../../../componentsV2/atoms/ProfileImage";

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 108px;
  flex-direction: column;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
`;

type CoverProps = {
  background?: string;
  backgroundColor: string;
};

export const CoverImage = styled.div<CoverProps>`
  ${({ background, backgroundColor }) => css`
    width: 100%;
    height: 160px;
    opacity: 0.56;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundColor};
    background: ${background && `url(${background})`};
    background-size: cover;
    background-repeat: no-repeat;

    svg {
      width: 24px;
      height: 24px;
    }

    input[type="file"] {
      top: 0;
      right: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      position: absolute;
    }
  `}
`;

export const ProfileContainer = styled.div`
  width: 100%;
  display: flex;
  max-width: 960px;
  padding-left: 20px;
  padding-right: 24px;
  align-items: flex-end;
  justify-content: space-between;
`;

type ProfileImageProps = {
  borderColor: string;
};

export const ProfileImageBox = styled.div<ProfileImageProps>`
  ${({ borderColor }) => css`
    width: 126px;
    padding: 2px;
    height: 126px;
    display: flex;
    margin-top: -63px;
    border-radius: 50%;
    position: relative;
    align-items: center;
    align-self: center;
    justify-content: center;
    border: 2px solid ${borderColor};

    svg {
      width: 24px;
      height: 24px;
      position: absolute;
    }

    input[type="file"] {
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      position: absolute;
    }
  `}
`;

export const Image = styled(ProfileImage)`
  position: absolute;
`;

export const Content = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  max-width: 960px;
  margin-top: 28px;
  flex-direction: column;
  padding: 0 20px 0 168px;

  @media (max-width: 960px) {
    padding: 0 20px;
  }
`;
