import { useCallback } from "react";
import MercadoPagoIntegration from "../../../../componentsV2/molecules/MercadoPagoIntegration";

import * as S from "./styles";
interface IProps {
  hasLinkedAccount: boolean;
}

const { REACT_APP_OAUTH_MERCADO_PAGO } = process.env;

const MonetizeSuaStanti: React.FC<IProps> = ({ hasLinkedAccount }) => {
  const onClickHandler = useCallback(() => {
    window.location.href = `${REACT_APP_OAUTH_MERCADO_PAGO}&state=edit`;
  }, []);

  return (
    <S.MonetizeYourStanti>
      <MercadoPagoIntegration
        onClick={onClickHandler}
        hasLinkedAccount={hasLinkedAccount}
      />
    </S.MonetizeYourStanti>
  );
};

export default MonetizeSuaStanti;
