import styled from "styled-components";

import Box from "../../componentsV2/atoms/Box";
import Button from "../../componentsV2/atoms/Button";
import SeparatorLine from "../../componentsV2/atoms/SeparatorLine";

export const Content = styled.div`
  display: flex;
  padding-bottom: 80px;
  flex-direction: column;
  justify-content: center;
`;

export const PersonalInfoEdit = styled.div`
  gap: 12px;
  display: flex;
  margin-top: 32px;
  flex-direction: column;
`;

export const ImageProfile = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 50px;
`;

export const Line = styled(SeparatorLine)`
  display: flex;
  margin: 1rem auto;
`;

export const ContainerBox = styled(Box)`
  margin-top: 1.75rem;
`;

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  gap: 0.75rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 12px;
`;

export const DeleteButton = styled(Button)`
  padding: 0.75rem;
`;

export const SaveButton = styled.div`
  left: 50%;
  width: 100%;
  bottom: 40px;
  display: flex;
  position: fixed;
  padding: 0 24px;
  max-width: 960px;
  flex-direction: column;

  button {
    height: 56px;
    transform: translate(calc(-50% - 24px), 0%);
    box-shadow: 4px 10px 10px -2px rgba(0, 0, 0, 0.6);
    animation: buttonPop 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;

    @keyframes buttonPop {
      0% {
        transform: translate(calc(-50% - 24px), 200%);
      }
      60% {
        transform: translate(calc(-50% - 24px), -60%);
      }
      100% {
        transform: translate(calc(-50% - 24px), 0%);
      }
    }
  }
`;
