import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import { Theme } from "../../../../hooks";
import Button from "../../../../componentsV2/atoms/Button";
import TitleDescription from "../../../../componentsV2/molecules/TitleDescription";

interface IProps {
  title: string;
  description: string;
}

const NoSales: React.FC<IProps> = ({ title, description }) => {
  const navigate = useNavigate();

  const { textColor } = Theme.useTheme();

  return (
    <S.NoSales>
      <TitleDescription
        title={title}
        centralize={true}
        titleColor={textColor}
        textColor={`${textColor}80`}
      >
        {description}
      </TitleDescription>

      <Button
        variant="solid"
        textColor={textColor}
        onClick={() => navigate("/apps")}
      >
        Adicionar
      </Button>
    </S.NoSales>
  );
};

export default NoSales;
