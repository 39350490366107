import AvailableIcons from "../../../components/atoms/AvailableIcons";
import Button from "../../atoms/Button";
import H3 from "../../atoms/Typography/H3";

import * as S from "./styles";

interface IProps {
  appTitle: string;
  onBack: () => void;
}

const AppFormHeader: React.FC<IProps> = ({ onBack, appTitle }) => {
  return (
    <S.Header>
      <Button onClick={onBack}>
        <AvailableIcons option="chevron" />
      </Button>

      <S.Title>
        <H3 color="#fafafa" fontWeight="300">
          {appTitle}
        </H3>
      </S.Title>
    </S.Header>
  );
};

export default AppFormHeader;
