import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import * as S from "./styles";
import { App } from "../../../../hooks";
import { IAppItem } from "../../../../types";
import Page from "../../../../components/atoms/Page";
import appConfig from "../../../../config/app-link-externo.json";
import AddIcon from "../../../../componentsV2/organisms/AddIcon";
import InputText from "../../../../componentsV2/molecules/InputText";
import SeparatorLine from "../../../../componentsV2/atoms/SeparatorLine";
import AppFormHeader from "../../../../componentsV2/organisms/AppFormHeader";
import AppFormFooter from "../../../../componentsV2/organisms/AppFormFooter";

const validate = (data: IAppItem): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.title)
    errors.title = [...(errors.title || []), "Título obrigatório"];

  if (!data.url) errors.url = [...(errors.url || []), "A url é obrigatória"];

  return errors;
};

const normalizeData = (data: IAppItem): IAppItem => {
  return data;
};

const Form: React.FC = () => {
  const { id } = useParams();

  const [item, setItem] = useState<IAppItem>({});
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

  const navigate = useNavigate();
  const { getItem, saveFreeItemHandler, removeItemHandler } = App.useApp();

  useEffect(() => {
    const run = async () => {
      if (!id) return;

      const itemData = await getItem(appConfig.id, id);
      setItem(itemData || {});
    };

    run();
  }, [getItem, id]);

  const onSave = useCallback(
    (isDraft: boolean) => {
      const normalizedData = normalizeData(item);

      if (!isDraft) {
        const currErrors = validate(normalizedData);

        if (currErrors && Object.keys(currErrors).length) {
          setErrors(currErrors);

          window.scrollTo({ top: 0, behavior: "smooth" });
          return alert(
            "O cadastro possui erros, por favor verifique os campos para continuar"
          );
        }
      }

      saveFreeItemHandler(appConfig.id, normalizedData, isDraft, id);
    },
    [id, saveFreeItemHandler, item]
  );

  const onChangeHandler = (value: any, field: keyof IAppItem) => {
    if (errors[field])
      setErrors((curr) => {
        const newErrors = { ...curr };
        delete newErrors[field];

        return newErrors;
      });

    setItem((curr) => ({ ...curr, [field]: value }));
  };

  const isEditingItem = !!id;

  return (
    <Page>
      <AppFormHeader
        appTitle="Adicionar link"
        onBack={() => navigate(`/apps`)}
      />

      <S.BoxContainer>
        <InputText
          limit
          charLimit={50}
          label="Título"
          errors={errors.title}
          value={item.title || ""}
          onChange={(val) => onChangeHandler(val, "title")}
          placeholder="Título para o seu link: Ex: Meu portfolio"
        />

        <SeparatorLine opacity={0.25} />

        <InputText
          label="URL"
          errors={errors.url}
          value={item.url || ""}
          placeholder="https://url"
          onChange={(val) => onChangeHandler(val, "url")}
        />

        <SeparatorLine opacity={0.25} />

        <AddIcon
          errors={errors.icon}
          icon={item.image || ""}
          onChange={(image) => onChangeHandler(image, "image")}
          onClear={() =>
            setItem((curr) => ({ ...curr, buttonImage: "", image: "" }))
          }
        />
      </S.BoxContainer>

      <AppFormFooter
        onSave={onSave}
        appTitle="link"
        saveDraft={true}
        isEditingItem={isEditingItem}
        onRemove={() => id && removeItemHandler(appConfig.id, id)}
      />
    </Page>
  );
};

export default Form;
