import * as S from "./styles";
import { Theme } from "../../../hooks";
import P from "../../atoms/Typography/P";
import { TIconOptions } from "../../../types";
import AvailableIcons from "../../../components/atoms/AvailableIcons";

interface IProps {
  href?: string;
  onClick: () => void;
  iconId?: TIconOptions;
  textFirstColor: string;
  widthAheadIcon?: string;
  textSecondColor?: string;
  aheadIconId?: TIconOptions;
}

const Navigation: React.FC<IProps> = ({
  href,
  iconId,
  onClick,
  textFirstColor,
  textSecondColor,
  widthAheadIcon = "10px",
  aheadIconId = "chevron",
}) => {
  const { primaryColor, textColor } = Theme.useTheme();

  return (
    <S.Container
      href={href}
      target="_blank"
      onClick={onClick}
      rel="noopener noreferrer"
    >
      <S.IconAndText>
        {iconId && (
          <S.Icon>
            <AvailableIcons option={iconId} color={primaryColor} />
          </S.Icon>
        )}

        <S.Texts>
          <P color={textColor} fontWeight={"400"}>
            {textFirstColor}
          </P>
          <P color={primaryColor} fontWeight={"regular"}>
            {textSecondColor}
          </P>
        </S.Texts>
      </S.IconAndText>

      <S.AheadIcon width={widthAheadIcon}>
        <AvailableIcons option={aheadIconId} color={primaryColor} />
      </S.AheadIcon>
    </S.Container>
  );
};

export default Navigation;
