import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Creator } from "../../services";
import { ICreatorData } from "../../types";
import { Auth, Loading, NavBar, Snackbar, Theme } from "../../hooks";

import EditPhone from "./components/EditPhone";
import Page from "../../components/atoms/Page";
import EditEmail from "./components/EditEmail";
import Button from "../../componentsV2/atoms/Button";
import EditUsername from "./components/EditUsername";
import P from "../../componentsV2/atoms/Typography/P";
import H4 from "../../componentsV2/atoms/Typography/H4";
import EditCategories from "./components/EditCategories";
import InputText from "../../componentsV2/molecules/InputText";
import MonetizeSuaStanti from "./components/MonetizeSuaStanti";
import PrimarySelectorList from "./components/PrimarySelectorList";
import BackgroundSelectorList from "./components/BackgroundSelectorList";
import TitleDescription from "../../componentsV2/molecules/TitleDescription";
import EditableProfileImage from "../../componentsV2/molecules/EditableProfileImage/index";

import * as S from "./styles";
import AppFormHeader from "../../componentsV2/organisms/AppFormHeader";

const unsavedMessage =
  "Você possui alterações não salvas, deseja descartá-las?";
const removeAccountMessage =
  "Tem certeza que deseja remover sua conta? Essa ação é irreversível, todos os seus dados serão perdidos.";

const Register: React.FC = () => {
  const [errors, setErrors] = useState<string[]>([]);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [creatorForm, setCreatorForm] = useState<ICreatorData>();
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [isUsernameLoading, setIsUsernameLoading] = useState(false);
  const [initialCreator, setInitialCreator] = useState<ICreatorData>();

  const hasUnsavedChanges =
    JSON.stringify(initialCreator) !== JSON.stringify(creatorForm);

  const navigate = useNavigate();
  const { primaryColor, textColor, backgroundColor } = Theme.useTheme();
  const { newError } = Snackbar.useSnackbar();
  const { hideNavBar, showNavBar } = NavBar.useNavBar();
  const { hideLoading, showLoading } = Loading.useLoading();
  const { logout, user, setUserHandler, token } = Auth.useAuth();

  const emailTimeout = useRef<NodeJS.Timeout | null>(null);
  const usernameTimeout = useRef<NodeJS.Timeout | null>(null);

  const isSaveButtonDisabled = !!(
    isUploadingImage ||
    isUsernameLoading ||
    isEmailLoading ||
    errors.length ||
    emailTimeout.current ||
    usernameTimeout.current
  );

  useEffect(() => {
    const hash = window.location.hash.slice(1).split("&")[0];

    if (!hash) return;

    document.getElementById(hash)?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    showNavBar();
    hasUnsavedChanges ? hideNavBar() : showNavBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUnsavedChanges]);

  useEffect(() => {
    const beforeunload = (event: BeforeUnloadEvent) => {
      if (!hasUnsavedChanges) {
        return undefined;
      }

      event.preventDefault();

      event.returnValue = unsavedMessage;
      return unsavedMessage;
    };

    window.addEventListener("beforeunload", beforeunload);

    return () => {
      window.removeEventListener("beforeunload", beforeunload);
    };
  }, [hasUnsavedChanges]);

  useEffect(() => {
    const get = async () => {
      try {
        showLoading();
        if (user.id) {
          const creator = await Creator.getCreator(user.id, token);
          delete creator.id;
          delete creator.password;
          delete creator.hasPersonalizedStanti;

          setCreatorForm(creator);

          const creatorDeepCopy: ICreatorData = { ...creator };

          if (creator.socialMedia && creator.socialMedia.length)
            creatorDeepCopy.socialMedia = [
              ...creator.socialMedia.map((m) => ({ ...m })),
            ];

          setInitialCreator(creatorDeepCopy);
        }
      } catch (error) {
        newError("Houve um erro ao obter seus dados");
      } finally {
        hideLoading();
      }
    };

    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  const onChangeProfileImage = async (image: string) => {
    setIsUploadingImage(true);
    try {
      const url = await Creator.uploadProfileImage(image);

      setCreatorForm((current) => ({
        ...current,
        profileImage: url,
      }));
    } catch (error) {
      newError("Houve um erro ao enviar a sua foto de perfil");
    } finally {
      setIsUploadingImage(false);
    }
  };

  const onChangeUsername = (username: string) => {
    setIsUsernameLoading(false);
    setErrors((current) => current.filter((val) => val !== "username"));

    setCreatorForm((current) => ({
      ...current,
      username,
    }));

    if (usernameTimeout.current) {
      clearTimeout(usernameTimeout.current);
      usernameTimeout.current = null;
    }

    if (username && username !== initialCreator?.username) {
      usernameTimeout.current = setTimeout(async () => {
        setIsUsernameLoading(true);

        try {
          const isUsernameRegistered = await Creator.checkUsernameAvailability(
            username
          );

          if (isUsernameRegistered) {
            setErrors((current) => [...current, "username"]);
            newError("Nome de usuário indisponível");
          }
        } catch (error) {
          newError("Erro ao verificar o nome de usuário");
        } finally {
          if (usernameTimeout.current) {
            clearTimeout(usernameTimeout.current);
            usernameTimeout.current = null;
          }
          setIsUsernameLoading(false);
        }
      }, 2000);
    }
  };

  const onChangeEmail = (email: string) => {
    setIsUsernameLoading(false);
    setErrors((current) => current.filter((val) => val !== "email"));

    setCreatorForm((current) => ({
      ...current,
      email,
    }));

    if (emailTimeout.current) {
      clearTimeout(emailTimeout.current);
      emailTimeout.current = null;
    }

    if (email && email !== initialCreator?.email) {
      emailTimeout.current = setTimeout(async () => {
        setIsEmailLoading(true);

        try {
          const isEmailRegistered = await Creator.checkEmailAvailability(email);

          if (isEmailRegistered) {
            setErrors((current) => [...current, "email"]);
            newError("Email já possui cadastro");
          }
        } catch (error) {
          newError("Erro ao verificar o email");
        } finally {
          if (emailTimeout.current) {
            clearTimeout(emailTimeout.current);
            emailTimeout.current = null;
          }
          setIsEmailLoading(false);
        }
      }, 2000);
    }
  };

  const onChangeTheme = (
    key: "primaryColor" | "backgroundColor",
    value: string
  ) => {
    setCreatorForm((curr) => ({ ...curr, [key]: value }));
    setUserHandler({ [key]: value });
  };

  const discardUnsavedData = () => {
    setUserHandler({ ...initialCreator });
    navigate("/");
  };

  const onDeleteAccount = async () => {
    const result = window.confirm(removeAccountMessage);
    if (result && user.id) {
      try {
        showLoading();
        await Creator.removeCreator(user.id, token);
        logout();
      } catch (error) {
        newError("Houve um erro ao remover a sua conta");
      } finally {
        hideLoading();
      }
    }
  };

  const onBackHandler = () => {
    if (hasUnsavedChanges) {
      const result = window.confirm(unsavedMessage);

      if (result) discardUnsavedData();
    } else navigate("/");
  };

  const onSave = async () => {
    showLoading();
    try {
      const updatedCreator: ICreatorData = { ...creatorForm };

      const requiredParams = [
        "email",
        "phone",
        "username",
        "primaryColor",
        "backgroundColor",
      ] as (keyof ICreatorData)[];

      requiredParams.forEach((key) => {
        if (!updatedCreator[key]) delete updatedCreator[key];
      });

      delete updatedCreator.id;

      const creator = await Creator.updateProfile(updatedCreator, token);
      setUserHandler(creator);
      navigate("/");
    } catch (error) {
      newError("Houve um erro ao salvar o seu perfil");
    } finally {
      hideLoading();
    }
  };

  return (
    <Page>
      <AppFormHeader appTitle="Editar perfil" onBack={onBackHandler} />

      <S.Content>
        <S.PersonalInfoEdit>
          <S.ImageProfile>
            <EditableProfileImage
              isLoading={isUploadingImage}
              onChangeImage={onChangeProfileImage}
              currentImage={creatorForm?.profileImage || ""}
            />
          </S.ImageProfile>

          <S.ContainerBox>
            <EditUsername
              username={creatorForm?.username}
              onChangeUsername={onChangeUsername}
              isUsernameLoading={isUsernameLoading}
              hasError={errors.includes("username")}
            />
            <S.Line opacity={0.15} />

            <InputText
              label="Nome Completo"
              value={creatorForm?.name || ""}
              onChange={(name: string) =>
                setCreatorForm((curr) => ({ ...curr, name }))
              }
            />
            <S.Line opacity={0.15} />

            <EditEmail
              email={creatorForm?.email}
              onChangeEmail={onChangeEmail}
              isEmailLoading={isEmailLoading}
              hasError={errors.includes("email")}
            />

            <S.Line opacity={0.15} />

            <EditPhone
              phone={creatorForm?.phone}
              onChangePhone={(phone) =>
                setCreatorForm((curr) => ({ ...curr, phone }))
              }
            />
          </S.ContainerBox>

          <S.ContainerBox>
            <InputText
              label="Sobre você"
              value={creatorForm?.about || ""}
              onChange={(about: string) =>
                setCreatorForm((curr) => ({ ...curr, about }))
              }
              rows={4}
              as="textarea"
              limit={true}
              charLimit={100}
              placeholder="Escreva uma breve bio"
            />
          </S.ContainerBox>

          <S.ContainerBox>
            <H4 color={textColor}>Aparência da sua Stanti</H4>

            <S.ContentBox>
              <P color={primaryColor}>Cor de destaque</P>
              <PrimarySelectorList
                value={creatorForm?.primaryColor || ""}
                selectedBackgroundColor={creatorForm?.backgroundColor}
                onChange={(color) => onChangeTheme("primaryColor", color)}
              />
            </S.ContentBox>

            <S.ContentBox>
              <P color={primaryColor}>Cor de fundo</P>
              <BackgroundSelectorList
                value={creatorForm?.backgroundColor || ""}
                selectedPrimaryColor={creatorForm?.primaryColor}
                onChange={(color) => onChangeTheme("backgroundColor", color)}
              />
            </S.ContentBox>
          </S.ContainerBox>

          <S.ContainerBox>
            <H4 color={textColor}>Interesses</H4>

            <S.ContentBox>
              <P color={primaryColor}>Selecione de 1 a 3 interesses</P>
              <EditCategories
                categories={creatorForm?.categories}
                onChangeCategories={(categories) =>
                  setCreatorForm((curr) => ({ ...curr, categories }))
                }
              />
            </S.ContentBox>
          </S.ContainerBox>

          <S.ContainerBox>
            <TitleDescription title="Carteira digital Stanti">
              Para receber pagamentos em sua Stanti, é necessario víncular sua
              conta ao Mercado Pago
            </TitleDescription>

            <MonetizeSuaStanti
              hasLinkedAccount={!!user.mercadoPagoIntegration}
            />
          </S.ContainerBox>
        </S.PersonalInfoEdit>

        <S.ButtonContainer>
          <Button
            variant="outline"
            borderColor={primaryColor}
            backgroundColor={primaryColor}
            onClick={logout}
          >
            <H4 color={primaryColor}>Desconectar</H4>
          </Button>

          <S.DeleteButton variant="default" onClick={onDeleteAccount}>
            <H4 color="#f94144">Deletar conta Stanti</H4>
          </S.DeleteButton>
        </S.ButtonContainer>

        {hasUnsavedChanges && (
          <S.SaveButton>
            <Button
              variant="solid"
              backgroundColor={primaryColor}
              disabled={isSaveButtonDisabled}
              onClick={() => !isSaveButtonDisabled && onSave()}
            >
              <H4 color={backgroundColor}>Salvar Alterações e Sair</H4>
            </Button>
          </S.SaveButton>
        )}
      </S.Content>
    </Page>
  );
};

export default Register;
