import { useRef, useState } from "react";
import PlayIcon from "../../../components/icons/PlayIcon";

import * as S from "./styles";

interface IProps {
  videoUrl: string;
  thumbUrl: string;
}

const AppVideo: React.FC<IProps> = ({ thumbUrl, videoUrl }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const video = useRef<HTMLVideoElement | null>(null);

  const playPauseVideoHandler = () => {
    isVideoPlaying ? video.current?.pause() : video.current?.play();
    setIsVideoPlaying((curr) => !curr);
  };

  return (
    <S.AppVideo>
      <S.Container
        isVideoPlaying={isVideoPlaying}
        onClick={playPauseVideoHandler}
      >
        <video ref={video} poster={thumbUrl} onEnded={playPauseVideoHandler}>
          <source src={videoUrl} type="video/mp4" />
        </video>

        <S.Play isVideoPlaying={isVideoPlaying}>
          <PlayIcon color="#fafafa" />
        </S.Play>
      </S.Container>
    </S.AppVideo>
  );
};

export default AppVideo;
