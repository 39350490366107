import styled from "styled-components";
import Small from "../../../../componentsV2/atoms/Typography/Small";

export const Info = styled.div`
  padding: 30px 0;
`;

export const SwitchAndText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Error = styled(Small)`
  display: flex;
  margin-top: 1rem;
  font-size: 0.75rem;
`;
