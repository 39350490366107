import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const BehanceIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="50"
      height="40"
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5467 18.6238C20.5467 18.6238 24.4265 18.3364 24.4265 13.7795C24.4265 9.22262 21.2523 7 17.2315 7H4V32.4599H17.2315C17.2315 32.4599 25.3084 32.7157 25.3084 24.9472C25.3084 24.9472 25.6606 18.6238 20.5467 18.6238ZM17.2315 11.5253C17.2315 11.5253 19.0297 11.5253 19.0297 14.174C19.0297 16.8228 17.9723 17.2074 16.7723 17.2074H9.82977V11.5253H17.2315ZM16.8575 27.935H9.82977V21.1309H17.2315C17.2315 21.1309 19.912 21.096 19.912 24.6274C19.912 27.6054 17.91 27.9116 16.8575 27.935ZM36.0811 13.4778C26.3027 13.4778 26.3113 23.2603 26.3113 23.2603C26.3113 23.2603 25.6405 32.9928 36.0811 32.9928C36.0811 32.9928 44.7817 33.4903 44.7817 26.2223H40.307C40.307 26.2223 40.4562 28.9593 36.2304 28.9593C36.2304 28.9593 31.7553 29.2598 31.7553 24.5301H44.931C44.931 24.5301 46.3725 13.4778 36.0811 13.4778ZM31.7061 21.1309C31.7061 21.1309 32.2522 17.2074 36.1804 17.2074C40.1086 17.2074 40.0585 21.1313 40.0585 21.1313L31.7061 21.1309ZM41.1012 11.6353H30.6089V8.50008H41.1012V11.6353Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default BehanceIcon;
