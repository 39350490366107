import AvailableIcons from "../../../components/atoms/AvailableIcons";
import { Auth } from "../../../hooks";
import { Analytics } from "../../../services";
import Button from "../../atoms/Button";
import H4 from "../../atoms/Typography/H4";
import P from "../../atoms/Typography/P";

import * as S from "./styles";

interface BannerProps {
  id: string;
  link?: string;
  label?: string;
  title?: string;
  textColor?: string;
  bannerColor?: string;
  isExternalLink?: boolean;
  onClose: (id: string) => void;
}

const Banner: React.FC<BannerProps> = ({
  id,
  link,
  title,
  label,
  onClose,
  textColor = "#FFFFFF",
  bannerColor = "#DDFF66",
  isExternalLink = true,
}) => {
  const { user } = Auth.useAuth();

  const onClickHandler = () => {
    Analytics.submitClickEvent({
      creator: user.id || "",
      origin: document.referrer,
      creatorUsername: user.username || "",
      description: `click-on-banner-${id}`,
      actionDescription: `click-on-banner-${id}`,
    });

    onClose(id);
  };

  const bannerWithoutLink = (
    <S.Banner backgroundColor={bannerColor}>
      <S.BannerText>
        <H4 color={textColor}>{title}</H4>
        <P color={textColor}>{label}</P>
      </S.BannerText>
      <Button
        variant="icon"
        backgroundColor="none"
        color={textColor}
        onClick={() => onClose(id)}
      >
        <AvailableIcons option="close" />
      </Button>
    </S.Banner>
  );

  const bannerWithLink = (
    <S.Banner backgroundColor={bannerColor}>
      <S.BannerLink
        to={link || ""}
        onClick={() => onClickHandler()}
        target={isExternalLink ? "_blank" : "_self"}
        rel={isExternalLink ? "noopener noreferrer" : ""}
      >
        <S.BannerText>
          <H4 color={textColor}>{title}</H4>
          <P color={textColor}>{label}</P>
        </S.BannerText>
      </S.BannerLink>
      <Button
        variant="icon"
        backgroundColor="none"
        color={textColor}
        onClick={() => onClose(id)}
      >
        <AvailableIcons option="close" />
      </Button>
    </S.Banner>
  );

  return link ? bannerWithLink : bannerWithoutLink;
};

export default Banner;
