import { ReactElement } from "react";

import { TIconOptionsTemp } from "../../../types";
import AtIcon from "../../icons/AtIcon";
import BoxIcon from "../../icons/BoxIcon";
import Chevron from "../../icons/Chevron";
import PetIcon from "../../icons/PetIcon";
import BusIcon from "../../icons/BusIcon";
import PinIcon from "../../icons/PinIcon";
import TagIcon from "../../icons/TagIcon";
import GymIcon from "../../icons/GymIcon";
import WebIcon from "../../icons/WebIcon";
import EditIcon from "../../icons/EditIcon";
import SongIcon from "../../icons/SongIcon";
import StarIcon from "../../icons/StarIcon";
import BabyIcon from "../../icons/BabyIcon";
import GameIcon from "../../icons/GameIcon";
import CallIcon from "../../icons/CallIcon";
import CartIcon from "../../icons/CartIcon";
import ChatIcon from "../../icons/ChatIcon";
import DiceIcon from "../../icons/DiceIcon";
import GiftIcon from "../../icons/GiftIcon";
import HomeIcon from "../../icons/HomeIcon";
import IdeaIcon from "../../icons/IdeaIcon";
import LinkIcon from "../../icons/LinkIcon";
import WorkIcon from "../../icons/WorkIcon";
import TodoIcon from "../../icons/TodoIcon";
import NearIcon from "../../icons/NearIcon";
import EmailIcon from "../../icons/EmailIcon";
import PhoneIcon from "../../icons/PhoneIcon";
import StoreIcon from "../../icons/StoreIcon";
import ClockIcon from "../../icons/ClockIcon";
import BeachIcon from "../../icons/BeachIcon";
import AlertIcon from "../../icons/AlertIcon";
import ChartIcon from "../../icons/ChartIcon";
import FilesIcon from "../../icons/FilesIcon";
import HeartIcon from "../../icons/HeartIcon";
import KeyUpIcon from "../../icons/KeyUpIcon";
import LabelIcon from "../../icons/LabelIcon";
import LearnIcon from "../../icons/LearnIcon";
import TimerIcon from "../../icons/TimerIcon";
import ThumbIcon from "../../icons/ThumbIcon";
import CloseIcon from "../../icons/CloseIcon";
import WorldIcon from "../../icons/WorldIcon";
import PizzaIcon from "../../icons/PizzaIcon";
import PlaceIcon from "../../icons/PlaceIcon";
import MouseIcon from "../../icons/MouseIcon";
import MovieIcon from "../../icons/MovieIcon";
import MusicIcon from "../../icons/MusicIcon";
import NightIcon from "../../icons/NightIcon";
import UploadIcon from "../../icons/UploadIcon";
import TicketIcon from "../../icons/TicketIcon";
import PuzzleIcon from "../../icons/PuzzleIcon";
import CameraIcon from "../../icons/CameraIcon";
import CoffeeIcon from "../../icons/CoffeeIcon";
import FlowerIcon from "../../icons/FlowerIcon";
import VolumeIcon from "../../icons/VolumeIcon";
import StantiIcon from "../../icons/StantiIcon";
import FolderIcon from "../../icons/FolderIcon";
import PeopleIcon from "../../icons/PeopleIcon";
import WarningIcon from "../../icons/WarningIcon";
import ForwardIcon from "../../icons/ForwardIcon";
import BookOneIcon from "../../icons/BookOneIcon";
import SocialMedia from "../../icons/SocialMedia";
import BookTwoIcon from "../../icons/BookTwoIcon";
import BubblesIcon from "../../icons/BubblesIcon";
import DiamondIcon from "../../icons/DiamondIcon";
import EditTwoIcon from "../../icons/EditTwoIcon";
import ExploreIcon from "../../icons/ExploreIcon";
import HeadsetIcon from "../../icons/HeadsetIcon";
import InfoOneIcon from "../../icons/InfoOneIcon";
import KeyDownIcon from "../../icons/KeyDownIcon";
import LoveTagIcon from "../../icons/LoveTagIcon";
import MessageIcon from "../../icons/MessageIcon";
import ReceiptIcon from "../../icons/ReceiptIcon";
import OpenPageIcon from "../../icons/OpenPageIcon";
import TrashCanIcon from "../../icons/TrashCanIcon";
import VideoOneIcon from "../../icons/VideoOneIcon";
import MoneyOneIcon from "../../icons/MoneyOneIcon";
import LockOpenIcon from "../../icons/LockOpenIcon";
import ArrowTopIcon from "../../icons/ArrowTopIcon";
import CalendarIcon from "../../icons/CalendarIcon";
import CheckOneIcon from "../../icons/CheckOneIcon";
import DocumentIcon from "../../icons/DocumentIcon";
import DownloadIcon from "../../icons/DownloadIcon";
import EmailTwoIcon from "../../icons/EmailTwoIcon";
import FastFoodIcon from "../../icons/FastFoodIcon";
import MoneyTwoIcon from "../../icons/MoneyTwoIcon";
import NotebookIcon from "../../icons/NotebookIcon";
import QuestionIcon from "../../icons/QuestionIcon";
import SmileOneIcon from "../../icons/SmileOneIcon";
import SmileTwoIcon from "../../icons/SmileTwoIcon";
import VideoTwoIcon from "../../icons/VideoTwoIcon";
import PersonOneIcon from "../../icons/PersonOneIcon";
import MessengerIcon from "../../icons/MessengerIcon";
import ArrowLeftIcon from "../../icons/ArrowLeftIcon";
import BookThreeIcon from "../../icons/BookThreeIcon";
import PersonPinIcon from "../../icons/PersonPinIcon";
import PersonTwoIcon from "../../icons/PersonTwoIcon";
import ChartBarsIcon from "../../icons/ChartBarsIcon";
import SearchOneIcon from "../../icons/SearchOneIcon";
import VoiceOverIcon from "../../icons/VoiceOverIcon";
import RestaurantIcon from "../../icons/RestaurantIcon";
import ClockWatchIcon from "../../icons/ClockWatchIcon";
import LinesChartIcon from "../../icons/LinesChartIcon";
import SmartphoneIcon from "../../icons/SmartphoneIcon";
import ArrowRightIcon from "../../icons/ArrowRightIcon";
import AttachmentIcon from "../../icons/AttachmentIcon";
import LockClosedIcon from "../../icons/LockClosedIcon";
import OnlineCallIcon from "../../icons/OnlineCallIcon";
import MicrophoneIcon from "../../icons/MicrophoneIcon";
import SmileThreeIcon from "../../icons/SmileThreeIcon";
import PersonPicsIcon from "../../icons/PersonPicsIcon";
import ArrowBottomIcon from "../../icons/ArrowBottomIcon";
import StarOutlineIcon from "../../icons/StarOutlineIcon";
import CheckCircleIcon from "../../icons/CheckCircleIcon";
import FingerPrintIcon from "../../icons/FingerPrintIcon";
import InfoOutlineIcon from "../../icons/InfoOutlineIcon";
import PersonGroupIcon from "../../icons/PersonGroupIcon";
import ShowPasswordIcon from "../../icons/ShowPasswordIcon";
import HeartOutlineIcon from "../../icons/HeartOutlineIcon";
import PhoneMessageIcon from "../../icons/PhoneMessageIcon";
import TransmissionIcon from "../../icons/TransmissionIcon";
import ArrowTopLeftIcon from "../../icons/ArrowTopLeftIcon";
import CameraSpecialIcon from "../../icons/CameraSpecialIcon";
import ArrowTopRightIcon from "../../icons/ArrowTopRightIcon";
import NotificationsIcon from "../../icons/NotificationsIcon";
import WarningOutlineIcon from "../../icons/WarningOutlineIcon";
import ArrowBottomLeftIcon from "../../icons/ArrowBottomLeftIcon";
import ArrowBottomRightIcon from "../../icons/ArrowBottomRightIcon";

import VscoIcon from "../../icons/VscoIcon";
import CopyIcon from "../../icons/CopyIcon";
import CheckIcon from "../../icons/CheckIcon";
import Vimeoicon from "../../icons/VimeoIcon";
import BeatportIcon from "../../icons/Beatport";
import BerealIcon from "../../icons/BerealIcon";
import TwitchIcon from "../../icons/TwitchIcon";
import TiktokIcon from "../../icons/TiktokIcon";
import TumblrIcon from "../../icons/TumblrIcon";
import TwitterIcon from "../../icons/TwitterIcon";
import YoutubeIcon from "../../icons/YoutubeIcon";
import BehanceIcon from "../../icons/BehanceIcon";
import TelegramIcon from "../../icons/TelegramIcon";
import WhatsappIcon from "../../icons/WhatsappIcon";
import DribbbleIcon from "../../icons/DribbbleIcon";
import LinkedinIcon from "../../icons/LinkedinIcon";
import FacebookIcon from "../../icons/FacebookIcon";
import PinterestIcon from "../../icons/PinterestIcon";
import InstagramIcon from "../../icons/InstagramIcon";
import DeviantartIcon from "../../icons/DeviantartIcon";
import SoundcloudIcon from "../../icons/SoundcloudIcon";
import ArtstationIcon from "../../icons/ArtstationIcon";
import WhatsappTwoIcon from "../../icons/WhatsappTwoIcon";
import InstagramTwoIcon from "../../icons/InstagramTwoIcon";

interface IProps {
  color?: string;
  opacity?: number;
  option: TIconOptionsTemp;
  blendMode?: "normal" | "overlay";
}

const AvailableIcons: React.FC<IProps> = ({
  option,
  color,
  blendMode,
  opacity,
}) => {
  const icons: { [key in TIconOptionsTemp]: ReactElement } = {
    at: <AtIcon color={color} blendMode={blendMode} opacity={opacity} />,
    bus: <BusIcon color={color} blendMode={blendMode} opacity={opacity} />,
    pet: <PetIcon color={color} blendMode={blendMode} opacity={opacity} />,
    box: <BoxIcon color={color} blendMode={blendMode} opacity={opacity} />,
    pin: <PinIcon color={color} blendMode={blendMode} opacity={opacity} />,
    gym: <GymIcon color={color} blendMode={blendMode} opacity={opacity} />,
    tag: <TagIcon color={color} blendMode={blendMode} opacity={opacity} />,
    web: <WebIcon color={color} blendMode={blendMode} opacity={opacity} />,
    game: <GameIcon color={color} blendMode={blendMode} opacity={opacity} />,
    song: <SongIcon color={color} blendMode={blendMode} opacity={opacity} />,
    near: <NearIcon color={color} blendMode={blendMode} opacity={opacity} />,
    baby: <BabyIcon color={color} blendMode={blendMode} opacity={opacity} />,
    call: <CallIcon color={color} blendMode={blendMode} opacity={opacity} />,
    cart: <CartIcon color={color} blendMode={blendMode} opacity={opacity} />,
    chat: <ChatIcon color={color} blendMode={blendMode} opacity={opacity} />,
    dice: <DiceIcon color={color} blendMode={blendMode} opacity={opacity} />,
    home: <HomeIcon color={color} blendMode={blendMode} opacity={opacity} />,
    idea: <IdeaIcon color={color} blendMode={blendMode} opacity={opacity} />,
    gift: <GiftIcon color={color} blendMode={blendMode} opacity={opacity} />,
    link: <LinkIcon color={color} blendMode={blendMode} opacity={opacity} />,
    star: <StarIcon color={color} blendMode={blendMode} opacity={opacity} />,
    work: <WorkIcon color={color} blendMode={blendMode} opacity={opacity} />,
    todo: <TodoIcon color={color} blendMode={blendMode} opacity={opacity} />,
    edit: <EditIcon color={color} blendMode={blendMode} opacity={opacity} />,
    chevron: <Chevron color={color} blendMode={blendMode} opacity={opacity} />,
    store: <StoreIcon color={color} blendMode={blendMode} opacity={opacity} />,
    thumb: <ThumbIcon color={color} blendMode={blendMode} opacity={opacity} />,
    alert: <AlertIcon color={color} blendMode={blendMode} opacity={opacity} />,
    night: <NightIcon color={color} blendMode={blendMode} opacity={opacity} />,
    pizza: <PizzaIcon color={color} blendMode={blendMode} opacity={opacity} />,
    mouse: <MouseIcon color={color} blendMode={blendMode} opacity={opacity} />,
    chart: <ChartIcon color={color} blendMode={blendMode} opacity={opacity} />,
    beach: <BeachIcon color={color} blendMode={blendMode} opacity={opacity} />,
    files: <FilesIcon color={color} blendMode={blendMode} opacity={opacity} />,
    clock: <ClockIcon color={color} blendMode={blendMode} opacity={opacity} />,
    heart: <HeartIcon color={color} blendMode={blendMode} opacity={opacity} />,
    keyUp: <KeyUpIcon color={color} blendMode={blendMode} opacity={opacity} />,
    label: <LabelIcon color={color} blendMode={blendMode} opacity={opacity} />,
    learn: <LearnIcon color={color} blendMode={blendMode} opacity={opacity} />,
    movie: <MovieIcon color={color} blendMode={blendMode} opacity={opacity} />,
    music: <MusicIcon color={color} blendMode={blendMode} opacity={opacity} />,
    phone: <PhoneIcon color={color} blendMode={blendMode} opacity={opacity} />,
    place: <PlaceIcon color={color} blendMode={blendMode} opacity={opacity} />,
    world: <WorldIcon color={color} blendMode={blendMode} opacity={opacity} />,
    timer: <TimerIcon color={color} blendMode={blendMode} opacity={opacity} />,
    receipt: (
      <ReceiptIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    folder: (
      <FolderIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    ticket: (
      <TicketIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    upload: (
      <UploadIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    volume: (
      <VolumeIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    camera: (
      <CameraIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    stanti: (
      <StantiIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    flower: (
      <FlowerIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    coffee: (
      <CoffeeIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    puzzle: (
      <PuzzleIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    twitch: (
      <TwitchIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    tiktok: (
      <TiktokIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    headset: (
      <HeadsetIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    infoOne: (
      <InfoOneIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    diamond: (
      <DiamondIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    bookOne: (
      <BookOneIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    twitter: (
      <TwitterIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    youtube: (
      <YoutubeIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    behance: (
      <BehanceIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    bookTwo: (
      <BookTwoIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    bubbles: (
      <BubblesIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    editTwo: (
      <EditTwoIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    forward: (
      <ForwardIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    explore: (
      <ExploreIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    keyDown: (
      <KeyDownIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    message: (
      <MessageIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    loveTag: (
      <LoveTagIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    warning: (
      <WarningIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    smileOne: (
      <SmileOneIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    smileTwo: (
      <SmileTwoIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    openPage: (
      <OpenPageIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    telegram: (
      <TelegramIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    whatsapp: (
      <WhatsappIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    whatsappTwo: (
      <WhatsappTwoIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    dribbble: (
      <DribbbleIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    linkedin: (
      <LinkedinIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    facebook: (
      <FacebookIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    moneyOne: (
      <MoneyOneIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    arrowTop: (
      <ArrowTopIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    moneyTwo: (
      <MoneyTwoIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    lockOpen: (
      <LockOpenIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    calendar: (
      <CalendarIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    check: <CheckIcon color={color} blendMode={blendMode} opacity={opacity} />,
    checkOne: (
      <CheckOneIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    document: (
      <DocumentIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    download: (
      <DownloadIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    email: <EmailIcon color={color} blendMode={blendMode} opacity={opacity} />,
    people: (
      <PeopleIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    emailTwo: (
      <EmailTwoIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    fastFood: (
      <FastFoodIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    linesChart: (
      <LinesChartIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    question: (
      <QuestionIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    notebook: (
      <NotebookIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    videoOne: (
      <VideoOneIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    videoTwo: (
      <VideoTwoIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    trashCan: (
      <TrashCanIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    voiceOver: (
      <VoiceOverIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    arrowLeft: (
      <ArrowLeftIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    chartBars: (
      <ChartBarsIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    messenger: (
      <MessengerIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    pinterest: (
      <PinterestIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    instagram: (
      <InstagramIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    personOne: (
      <PersonOneIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    personPin: (
      <PersonPinIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    bookThree: (
      <BookThreeIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    personTwo: (
      <PersonTwoIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    searchOne: (
      <SearchOneIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    personPics: (
      <PersonPicsIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    arrowRight: (
      <ArrowRightIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    lockClosed: (
      <LockClosedIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    microphone: (
      <MicrophoneIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    clockWatch: (
      <ClockWatchIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    attachment: (
      <AttachmentIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    restaurant: (
      <RestaurantIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    smartphone: (
      <SmartphoneIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    smileThree: (
      <SmileThreeIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    onlineCall: (
      <OnlineCallIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    arrowBottom: (
      <ArrowBottomIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    starOutline: (
      <StarOutlineIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    checkCircle: (
      <CheckCircleIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    fingerPrint: (
      <FingerPrintIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    infoOutline: (
      <InfoOutlineIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    phoneMessage: (
      <PhoneMessageIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    arrowTopLeft: (
      <ArrowTopLeftIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    heartOutline: (
      <HeartOutlineIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    transmission: (
      <TransmissionIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    instagramTwo: (
      <InstagramTwoIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    showPassword: (
      <ShowPasswordIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    notifications: (
      <NotificationsIcon
        color={color}
        blendMode={blendMode}
        opacity={opacity}
      />
    ),
    arrowTopRight: (
      <ArrowTopRightIcon
        color={color}
        blendMode={blendMode}
        opacity={opacity}
      />
    ),
    cameraSpecial: (
      <CameraSpecialIcon
        color={color}
        blendMode={blendMode}
        opacity={opacity}
      />
    ),
    warningOutline: (
      <WarningOutlineIcon
        color={color}
        blendMode={blendMode}
        opacity={opacity}
      />
    ),
    arrowBottomLeft: (
      <ArrowBottomLeftIcon
        color={color}
        blendMode={blendMode}
        opacity={opacity}
      />
    ),
    arrowBottomRight: (
      <ArrowBottomRightIcon
        color={color}
        blendMode={blendMode}
        opacity={opacity}
      />
    ),
    group: (
      <PersonGroupIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    close: <CloseIcon color={color} blendMode={blendMode} opacity={opacity} />,
    socialMedia: (
      <SocialMedia color={color} blendMode={blendMode} opacity={opacity} />
    ),
    vsco: <VscoIcon color={color} blendMode={blendMode} opacity={opacity} />,
    bereal: (
      <BerealIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    tumblr: (
      <TumblrIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    soundcloud: (
      <SoundcloudIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    beatport: (
      <BeatportIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    artstation: (
      <ArtstationIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    deviantart: (
      <DeviantartIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
    vimeo: <Vimeoicon color={color} blendMode={blendMode} opacity={opacity} />,
    copyIcon: (
      <CopyIcon color={color} blendMode={blendMode} opacity={opacity} />
    ),
  };

  return icons[option];
};

export default AvailableIcons;
