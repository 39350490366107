import { useEffect, useState } from "react";

import Page from "../../../../components/atoms/Page";
import BackgroundQualificationIcon from "../../../../components/icons/BackgroundQualificationIcon";
import Chevron from "../../../../components/icons/Chevron";
import Box from "../../../../componentsV2/atoms/Box";
import ProgressBar from "../../../../componentsV2/atoms/ProgressBar";
import RadioInput from "../../../../componentsV2/atoms/RadioInput";
import TitleDescription from "../../../../componentsV2/molecules/TitleDescription";
import { Auth, GA, Theme } from "../../../../hooks";
import { Analytics, Creator } from "../../../../services";
import { THasDigitalProducts } from "../../../../types";

import * as S from "./styles";

const QualificationStep: React.FC = () => {
  const [qualificationAnswer, setQualificationAnswer] =
    useState<THasDigitalProducts>();

  const { sendEvents } = GA.useGA();
  const { primaryColor, textColor } = Theme.useTheme();
  const { user, setUserHandler, token } = Auth.useAuth();

  useEffect(() => {
    Analytics.submitPageViewEvent({
      creator: user.id || "",
      origin: document.referrer,
      description: "qualification-step",
      creatorUsername: user.username || "",
      actionDescription: "answer-qualification-step",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBackHandler = () => {
    setUserHandler({ formStep: "social-media" });
  };

  const onNextHandler = async () => {
    Analytics.submitClickEvent({
      creator: user.id || "",
      origin: document.referrer,
      creatorUsername: user.username || "",
      meta: { answer: qualificationAnswer },
      description: "qualification-step-concluded",
      actionDescription: `qualification-step-concluded-${user.username}`,
    });

    const updatedUser = await Creator.updateProfile(
      {
        onboarding: true,
        qualifyAnswers: { hasDigitalProducts: qualificationAnswer },
      },
      token
    );

    sendEvents({
      category: "form",
      name: "CompleteRegistration",
      buttonLocation: "register-qualification-step",
    });

    window.fbq("track", "CompleteRegistration");

    setUserHandler(updatedUser);
  };

  const qualificationAnswers = [
    { label: "Sim, eu vendo.", value: "yes" },
    { label: "Tenho interesse em vender.", value: "intent" },
    { label: "Não tenho interesse em vender.", value: "no" },
  ];
  const qualificationLabels = qualificationAnswers.map(
    (answer) => answer.label
  );

  const onSelectAnswer = (index: number) => {
    setQualificationAnswer(
      qualificationAnswers[index].value as THasDigitalProducts
    );
  };

  const selectedIndex = qualificationAnswers.findIndex(
    (answer) => answer.value === qualificationAnswer
  );

  const isReady = qualificationAnswer;

  return (
    <Page>
      <S.Wrapper>
        <S.Container>
          <S.RegisterFormHeader>
            <S.Back variant="icon" onClick={onBackHandler}>
              <Chevron color="#fafafa" />
            </S.Back>
          </S.RegisterFormHeader>

          <S.ProgressBar>
            <S.Text>
              <S.Title color={textColor}>
                Conte-nos um pouco mais sobre você!
              </S.Title>
            </S.Text>

            <ProgressBar progress={isReady ? 100 : (5.5 / 6) * 100} />
          </S.ProgressBar>

          <S.Icon>
            <BackgroundQualificationIcon />
          </S.Icon>

          <Box>
            <TitleDescription title="Você vende algum produto ou serviço pela internet?">
              Exemplo: mentorias, cursos, e-books, arquivos, etc...
            </TitleDescription>

            <S.Answers>
              <RadioInput
                options={qualificationLabels}
                onSelect={(index) => onSelectAnswer(index)}
                selectedIndex={selectedIndex}
              />
            </S.Answers>
          </Box>

          <S.NextButton
            disabled={!isReady}
            variant="outline"
            borderColor={primaryColor}
            textColor={primaryColor}
            onClick={() => isReady && onNextHandler()}
          >
            Enviar resposta
          </S.NextButton>
        </S.Container>
      </S.Wrapper>
    </Page>
  );
};

export default QualificationStep;
