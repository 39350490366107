import styled, { css } from "styled-components";

type RadioProps = {
  protectionSize: "single" | "full";
};

export const Radio = styled.div<RadioProps>`
  ${({ protectionSize }) => css`
    gap: 20px;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: "space-around";
    border-radius: ${protectionSize === "full" && "12px"};
    background-color: ${protectionSize === "full" && "#fafafa0d"};
  `}
`;

type RadioItemProps = {
  isLast: boolean;
  isSelected: boolean;
  textColorSelected: string;
  protectionSize: "single" | "full";
};

export const RadioItem = styled.div<RadioItemProps>`
  ${({ isLast, isSelected, protectionSize, textColorSelected }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
    margin-right: ${!isLast && "20px"};
    align-items: center;
    justify-content: center;
    border-radius: ${protectionSize === "single" && "12px"};
    padding: ${protectionSize === "full" ? "10px 0" : "16px 0"};
    background-color: ${protectionSize === "single" && isSelected
      ? "#fafafa0d"
      : "transparent"};

    p {
      color: ${isSelected ? textColorSelected : "#fafafa80"};
    }
  `}
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Border = styled.span`
  width: 1px;
  content: "";
  height: 13px;
  border-right: 1px solid #fafafa40;
`;
