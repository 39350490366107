import styled from "styled-components";

export const SocialMediaLogin = styled.div`
  gap: 16px;
  height: auto;
  display: flex;
  cursor: pointer;
  user-select: none;
  border-radius: 12px;
  align-items: center;
  mix-blend-mode: screen;
  padding-left: 20px;
  background: #fafafa0a;
`;

export const Icon = styled.div`
  width: 22px;
  height: 22px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Text = styled.div`
  flex-grow: 1;
  display: flex;
  padding: 20px;
  align-items: center;
  border-radius: 12px;
  mix-blend-mode: screen;
  background: #fafafa0a;

  p {
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    font-style: normal;
  }
`;
