import { Navigate, Route } from "react-router-dom";

import Home from "./components/Home";
import Form from "./components/Form";
import Brands from "./components/Brands";
import EditProfile from "./components/EditProfile";

const AppMediaKit = () => {
  return (
    <Route path="media-kit">
      <Route index element={<Home />} />
      <Route path="edit" element={<Form />} />
      <Route path="edit/brands" element={<Brands />} />
      <Route path="edit/profile" element={<EditProfile />} />
      <Route index path="*" element={<Navigate to="/apps/media-kit" />} />
    </Route>
  );
};

export default AppMediaKit;
