import { DragEventHandler, ReactElement, TouchEventHandler } from "react";

import * as S from "./styles";
import DropZone from "../../../components/molecules/DropZone";

interface IProps {
  isMobileDragOn: boolean;
  onDropHandler: () => void;
  isDropZoneHovered: boolean;
  draggableItem: ReactElement;
  coordinates: { x: number; y: number };
  onDragEnd: DragEventHandler<HTMLDivElement>;
  onDragStart: DragEventHandler<HTMLDivElement>;
  onDragEnter: DragEventHandler<HTMLDivElement>;
  onDragLeave: DragEventHandler<HTMLDivElement>;
  touchEndHandler: TouchEventHandler<HTMLDivElement>;
  touchMoveHandler: TouchEventHandler<HTMLDivElement>;
  touchStartHandler: TouchEventHandler<HTMLDivElement>;
  touchCancelHandler: TouchEventHandler<HTMLDivElement>;
}

const DragAndDrop: React.FC<IProps> = ({
  onDragEnd,
  onDragEnter,
  onDragLeave,
  onDragStart,
  coordinates,
  onDropHandler,
  draggableItem,
  isMobileDragOn,
  touchEndHandler,
  touchMoveHandler,
  isDropZoneHovered,
  touchStartHandler,
  touchCancelHandler,
}) => {
  return (
    <S.Container
      onDrop={onDropHandler}
      onDragEnd={onDragLeave}
      onDragEnter={onDragEnter}
      coordinates={coordinates}
      isMobileDragOn={isMobileDragOn}
      onDragOver={(e) => e.preventDefault()}
    >
      <S.DraggableItem
        draggable
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        isMobileDragOn={isMobileDragOn}
      >
        <S.Drag
          onTouchEnd={touchEndHandler}
          onTouchMove={touchMoveHandler}
          onTouchStart={touchStartHandler}
          onTouchCancel={touchCancelHandler}
        >
          <span />
          <span />
          <span />
        </S.Drag>

        {draggableItem}
      </S.DraggableItem>

      <DropZone isDroppable={false} isHovered={isDropZoneHovered} />
    </S.Container>
  );
};

export default DragAndDrop;
