import styled from "styled-components";

export const Description = styled.div`
  margin-top: 28px;

  h2 {
    font-size: 16px;
    font-weight: 400;
    color: #fafafa;
  }

  p {
    font-size: 14px;
    color: #fafafa80;
  }
`;

export const ContentBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;
  gap: 1.25rem;

  svg {
    opacity: 1 !important;
  }
`;

export const InputGroup = styled.div`
  gap: 12px;
  display: flex;
  margin-top: 24px;
  padding-bottom: 16px;
  flex-direction: column;
`;
