import * as S from "./styles";
import { Theme } from "../../../../../../hooks";
import { IPosts } from "../../../../../../types/media-kit";
import P from "../../../../../../componentsV2/atoms/Typography/P";
import Small from "../../../../../../componentsV2/atoms/Typography/Small";
import AvailableIconsTemp from "../../../../../../components/atoms/AvailableIconsTemp";

interface IProps {
  post: IPosts;
  onRemove: () => void;
}

const PostDraggableCard: React.FC<IProps> = ({ post, onRemove }) => {
  const { textColor, primaryColor } = Theme.useTheme();

  return (
    <S.Card backgroundColor={`${textColor}0a`}>
      <S.Content>
        <S.Image image={post.thumbnailUrl} />

        <S.Information>
          <Small color={primaryColor}>Instagram da marca</Small>

          <P color={textColor}>@{post.brandUsername}</P>
        </S.Information>
      </S.Content>

      <S.TrashIcon onClick={() => onRemove()}>
        <AvailableIconsTemp option="trashCan" color="#F94144" />
      </S.TrashIcon>
    </S.Card>
  );
};

export default PostDraggableCard;
