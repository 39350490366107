import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const PinterestIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="50"
      height="40"
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5575 0C14.5408 0 9 7.82739 9 14.3529C9 18.3068 10.5067 21.8267 13.7469 23.1286C14.2816 23.3375 14.7514 23.1286 14.9134 22.55C15.0268 22.1481 15.2698 21.1356 15.3832 20.7016C15.5452 20.123 15.4804 19.9301 15.043 19.4158C14.1033 18.3228 13.5201 16.9085 13.5201 14.9154C13.5201 9.1132 17.8944 3.90566 24.9257 3.90566C31.1469 3.90566 34.5653 7.68274 34.5653 12.7135C34.5653 19.3354 31.6167 24.9287 27.2262 24.9287C24.7961 24.9287 22.9815 22.9357 23.5648 20.5087C24.2614 17.5996 25.6061 14.4493 25.6061 12.3599C25.6061 10.4794 24.5855 8.90426 22.4793 8.90426C20.0005 8.90426 18.0078 11.4437 18.0078 14.8511C18.0078 17.021 18.7531 18.4836 18.7531 18.4836C18.7531 18.4836 16.2095 29.1558 15.7721 31.0203C14.881 34.733 15.6425 39.2977 15.7073 39.7477C15.7397 40.021 16.0961 40.0853 16.2581 39.8763C16.4849 39.587 19.4335 35.9706 20.438 32.3543C20.7296 31.3256 22.0743 26.0377 22.0743 26.0377C22.8843 27.5646 25.2335 28.8987 27.7285 28.8987C35.181 28.8987 40.2195 22.1642 40.2195 13.1474C40.2195 6.34871 34.4033 0 25.5575 0Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default PinterestIcon;
