import AvailableIcons from "../../../../components/atoms/AvailableIcons";
import Link from "../../../../components/atoms/Link";
import P from "../../../../components/atoms/Typography/P";
import StantiIcon from "../../../../components/icons/StantiIcon";
import { Theme } from "../../../../hooks";

import H5 from "../../../../components/atoms/Typography/H5";
import InputText from "../../../../componentsV2/molecules/InputText";
import * as RegisterStyles from "../styles";
import * as UsernameStepStyles from "./styles";

const S = {
  ...UsernameStepStyles,
  ...RegisterStyles,
};

interface IProps {
  username: string;
  onNext: () => void;
  onLogin: () => void;
  onChangeUsername: (value: string) => void;
}

const { REACT_APP_STOREFRONT_BASE_URL } = process.env;

const UsernameStep: React.FC<IProps> = ({
  onNext,
  onLogin,
  username,
  onChangeUsername,
}) => {
  const { primaryColor, textColor } = Theme.useTheme();

  const socialMediaIcons = [
    {
      icon: "instagram",
      link: "https://instagram.com/stanti.co",
    },
    {
      icon: "linkedin",
      link: "https://www.linkedin.com/company/stanti",
    },
    {
      icon: "tiktok",
      link: "https://www.tiktok.com/@stanti.co",
    },
    {
      icon: "facebook",
      link: "https://www.facebook.com/Stantioficial",
    },
    {
      icon: "whatsapp",
      link: "https://wa.me/5511915657971",
    },
  ] as const;

  return (
    <S.Register>
      <S.RegisterFormHeader>
        <div className="stantiLogo">
          <StantiIcon color="#fafafa" />
        </div>

        <S.EnterButton textColor="#ddff66" variant="solid" onClick={onLogin}>
          Entrar
        </S.EnterButton>
      </S.RegisterFormHeader>

      <S.RegisterFormFirstStep>
        <S.TextContainer>
          <S.Title color={primaryColor}>
            Muito mais que
            <br />
            um link in bio
          </S.Title>

          <S.Subtitle color="#fafafa">
            Vamos começar?!
            <br />
            Qual vai ser o nome da <span>sua</span> Stanti?
          </S.Subtitle>
        </S.TextContainer>

        <S.InputBox>
          <InputText
            value={username}
            label="Nome de usuário"
            placeholder="seu_username"
            onChange={onChangeUsername}
            baseURL={`${REACT_APP_STOREFRONT_BASE_URL}/`}
          />
        </S.InputBox>

        <S.ForwardButton
          variant="outline"
          disabled={username.length < 3}
          textColor="#ddff66"
          borderColor="#ddff66"
          onClick={() => onNext()}
        >
          Avançar
        </S.ForwardButton>

        <S.PrivacyTerms>
          <P color="#fafafa">
            Ao avançar, você concorda com nossos
            <br />
            <a
              href="https://www.stanti.com.br/termos/termos-de-uso"
              target="_blank"
              rel="noopener noreferrer"
            >
              Termos de Uso
            </a>{" "}
            e{" "}
            <a
              href="https://www.stanti.com.br/termos/politica-de-privacidade"
              target="_blank"
              rel="noopener noreferrer"
            >
              Política de privacidade
            </a>
            .
          </P>
        </S.PrivacyTerms>

        <S.LoginText color={textColor}>
          Já possui uma conta?
          <br />
          <Link to="/login">Entre aqui</Link>
        </S.LoginText>
      </S.RegisterFormFirstStep>

      <S.RegisterFormFooter>
        <S.Contact>
          <H5 color="#fafafa">Fale conosco</H5>

          <S.SocialMedia>
            {socialMediaIcons.map((socialMedia) => (
              <a
                target="_blank"
                rel="noreferrer"
                href={socialMedia.link}
                key={socialMedia.icon}
              >
                <AvailableIcons option={socialMedia.icon} />
              </a>
            ))}
          </S.SocialMedia>
        </S.Contact>
      </S.RegisterFormFooter>
    </S.Register>
  );
};

export default UsernameStep;
