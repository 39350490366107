import InputNumberBase from "../../atoms/InputNumberBase";
import * as S from "./styles";

type InputNumberProps = {
  id?: string;
  value: number;
  label: string;
  labelColor?: string;
  max?: number;
  min?: number;
  onChange: (value: string) => void;
};

const InputNumber: React.FC<InputNumberProps> = ({
  id,
  max,
  min,
  label,
  value,
  onChange,
  labelColor,
}) => {
  return (
    <S.Container>
      <S.Label color={labelColor} htmlFor={id}>
        {label}
      </S.Label>

      <InputNumberBase
        id={id}
        value={value}
        onChange={onChange}
        max={max}
        min={min}
      />
    </S.Container>
  );
};

export default InputNumber;
