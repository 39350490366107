import P from "../../atoms/Typography/P";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

type OpenProps = {
  isOpen: boolean;
  openColor?: string;
};

export const Collapsable = styled.div<OpenProps>`
  ${({ isOpen }) => css`
    width: 100%;
    display: flex;
    padding: 20px;
    cursor: pointer;
    user-select: none;
    position: relative;
    align-items: center;
    border-radius: 12px;
    background-color: #fafafa0d;
    justify-content: space-between;
    border-bottom-left-radius: ${isOpen && 0};
    border-bottom-right-radius: ${isOpen && 0};
  `}
`;

export const Texts = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
`;

export const Date = styled(P)<OpenProps>`
  ${({ isOpen }) => css`
    opacity: ${isOpen ? 1 : 0.5};
  `};
`;

export const WeekDay = styled(P)<OpenProps>`
  ${({ isOpen, openColor }) => css`
    width: 100%;
    flex-grow: 1;
    padding: 0 80px;
    text-align: center;
    color: ${isOpen && openColor};
  `};
`;

export const NumberAppointmentsAndIcon = styled.div`
  gap: 44px;
  display: flex;
  align-items: center;
`;

export const NumberOfAppointments = styled(P)<OpenProps>`
  ${({ isOpen }) => css`
    opacity: ${isOpen ? 0.5 : 0.25};
  `};
`;

export const Icon = styled.div<OpenProps>`
  ${({ isOpen }) => css`
    width: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: ${isOpen && "rotate(90deg)"};

    svg {
      width: 100%;
      height: 100%;
    }
  `};
`;

type AppointmentsProps = {
  isLast: boolean;
};

export const Appointments = styled.div<AppointmentsProps>`
  ${({ isLast }) => css`
    gap: 20px;
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 0 20px 20px 20px;
    background-color: #fafafa0d;
    border-bottom-left-radius: ${isLast && "12px"};
    border-bottom-right-radius: ${isLast && "12px"};
  `}
`;
