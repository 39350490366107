import * as S from "./styles";
import P from "../Typography/P";
import { Theme } from "../../../hooks";
import React from "react";

interface IProps {
  values: string[];
  selectedIndex: number;
  onSelect: (index: number) => void;
  protectionSize: "single" | "full";
}

const RadioMenu: React.FC<IProps> = ({
  values,
  onSelect,
  selectedIndex,
  protectionSize,
}) => {
  const { primaryColor } = Theme.useTheme();

  const numItems = values.length;

  return (
    <S.Radio protectionSize={protectionSize} role="radio">
      {values.map((item, index) => (
        <S.Container key={item}>
          <S.RadioItem
            isLast={index === numItems - 1}
            protectionSize={protectionSize}
            onClick={() => onSelect(index)}
            textColorSelected={primaryColor}
            isSelected={index === selectedIndex}
          >
            <P fontWeight="regular" color="">
              {item}
            </P>
          </S.RadioItem>

          {index !== numItems - 1 && <S.Border />}
        </S.Container>
      ))}
    </S.Radio>
  );
};

export default RadioMenu;
