/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import * as S from "./styles";
import { MediaKit } from "../../../../services";
import { emptyUserInfo } from "../../../../utils/media-kit";
import H4 from "../../../../componentsV2/atoms/Typography/H4";
import InputBase from "../../../../componentsV2/atoms/InputBase";
import { IPersonalInfoEntity } from "../../../../types/media-kit";
import { Auth, Loading, Snackbar, Theme } from "../../../../hooks";
import InputText from "../../../../componentsV2/molecules/InputText";
import SeparatorLine from "../../../../componentsV2/atoms/SeparatorLine";
import AvailableIconsTemp from "../../../../components/atoms/AvailableIconsTemp";
import ImageReposition from "../../../../componentsV2/molecules/ImageReposition";

const validate = (data: IPersonalInfoEntity): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.name) errors.name = [...(errors.name || []), "Nome é obrigatório"];

  if (!data.email) {
    errors.email = [...(errors.email || []), "E-mail é obrigatório"];
  } else {
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(data.email))
      errors.email = [...(errors.email || []), "E-mail inválido"];
  }

  return errors;
};

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const EditProfile: React.FC = () => {
  const [cropCoverImage, setCropCoverImage] = useState<string>();
  const [cropProfileImage, setCropProfileImage] = useState<string>();
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [userInfo, setUserInfo] = useState<IPersonalInfoEntity>(emptyUserInfo);
  const [userInfoForm, setUserInfoForm] =
    useState<IPersonalInfoEntity>(emptyUserInfo);

  const { token } = Auth.useAuth();
  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { hideLoading, showLoading } = Loading.useLoading();
  const { textColor, primaryColor, backgroundColor } = Theme.useTheme();

  const navigate = useNavigate();

  const cover = useRef<HTMLInputElement | null>(null);
  const profile = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    try {
      showLoading();

      const run = async () => {
        const userData = await MediaKit.getPersonalInfo(token);

        if (userData) {
          setUserInfo(userData);
          setUserInfoForm(userData);
        }
      };

      run();
    } catch (error) {
      newError("Houve um erro ao importar os dados do usuário");
    } finally {
      hideLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeHandler = (value: any, field: keyof IPersonalInfoEntity) => {
    if (errors[field])
      setErrors((curr) => {
        const newErrors = { ...curr };
        delete newErrors[field];

        return newErrors;
      });

    if (field === "coverImageUrl" || field === "profileImageUrl") {
      const { files } = value.target;
      const file = (files || [])[0];

      if (file.size > MAX_FILE_SIZE) {
        if (field === "coverImageUrl") {
          if (cover.current) cover.current.value = "";
          return newError("Arquivo muito grande: máx 5Mb");
        } else {
          if (profile.current) profile.current.value = "";
          return newError("Arquivo muito grande: máx 5Mb");
        }
      }

      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = async () => {
          const base64 = reader.result?.toString();

          if (base64) {
            if (field === "coverImageUrl") {
              setCropCoverImage(base64);
            }
            if (field === "profileImageUrl") {
              setCropProfileImage(base64);
            }
          }

          if (cover.current) cover.current.value = "";
          if (profile.current) profile.current.value = "";
        };
      }

      return;
    }

    setUserInfoForm((curr) => ({ ...curr, [field]: value }));
  };

  const onChangeImage = async (field: "coverImageUrl" | "profileImageUrl") => {
    if (field === "coverImageUrl") {
      const imageUrl = await MediaKit.uploadPersonalImage(
        token,
        cropCoverImage || ""
      );
      setUserInfoForm((curr) => ({ ...curr, coverImageUrl: imageUrl }));
    }

    if (field === "profileImageUrl") {
      const imageUrl = await MediaKit.uploadPersonalImage(
        token,
        cropProfileImage || ""
      );
      setUserInfoForm((curr) => ({
        ...curr,
        profileImageUrl: imageUrl,
      }));
    }
  };

  const onDiscardImage = (field: "coverImageUrl" | "profileImageUrl") => {
    if (field === "coverImageUrl") setCropCoverImage("");
    if (field === "profileImageUrl") setCropProfileImage("");
  };

  const onChangeCategories = (val: string) => {
    if (!val) return onChangeHandler([], "categories");

    const categories = val.split(" ");

    if (categories.length > 3) {
      return;
    }

    const normalizedcategories = categories.map(
      (tag) => `${tag.replace(/([^a-zA-Z0-9-.])/g, "").toLowerCase()}`
    );

    onChangeHandler(normalizedcategories, "categories");
  };

  const onSave = async () => {
    try {
      showLoading();

      const currErrors = validate(userInfoForm);
      if (currErrors && Object.keys(currErrors).length) {
        setErrors(currErrors);
        window.scrollTo({ top: 0, behavior: "smooth" });
        return alert(
          "O cadastro possui erros, por favor verifique os campos para continuar"
        );
      }

      await MediaKit.savePersonalInfo(token, userInfoForm);

      newSuccess("Informações salvas com sucesso!");
      navigate("/apps/media-kit/edit");
    } catch (error) {
      newError("Houve um erro ao salvar as informações");
    } finally {
      hideLoading();
    }
  };

  const notChangedForm =
    JSON.stringify(userInfo) === JSON.stringify(userInfoForm);

  return (
    <S.Container>
      <S.Header>
        <S.CoverImage
          backgroundColor={primaryColor}
          background={userInfoForm?.coverImageUrl}
        >
          <InputBase
            type="file"
            ref={cover}
            onChange={(e) => onChangeHandler(e, "coverImageUrl")}
            accept="image/png, image/jpeg, image/jpg, image/webp"
          />

          <AvailableIconsTemp option="upload" color={textColor} />
        </S.CoverImage>

        {cropCoverImage && (
          <ImageReposition
            width={400}
            height={300}
            initialImage={cropCoverImage}
            onChangeImage={() => onChangeImage("coverImageUrl")}
            onDiscardImage={() => onDiscardImage("coverImageUrl")}
          />
        )}

        <S.ProfileImageContainer>
          <S.ProfileImageBox borderColor={primaryColor}>
            <InputBase
              type="file"
              ref={profile}
              accept="image/png, image/jpeg, image/jpg, image/webp"
              onChange={(e) => onChangeHandler(e, "profileImageUrl")}
            />

            <AvailableIconsTemp option="upload" color={textColor} />

            <S.Image
              size="large"
              imageUrl={userInfoForm?.profileImageUrl || primaryColor}
            />
          </S.ProfileImageBox>

          {cropProfileImage && (
            <ImageReposition
              initialImage={cropProfileImage}
              onChangeImage={() => onChangeImage("profileImageUrl")}
              onDiscardImage={() => onDiscardImage("profileImageUrl")}
            />
          )}
        </S.ProfileImageContainer>
      </S.Header>

      <S.Information>
        <H4 color={textColor}>Meu perfil</H4>
        <S.BoxContainer>
          <InputText
            label="Nichos (máximo 3)"
            placeholder="Adicione seus nichos aqui"
            onChange={(val) => onChangeCategories(val)}
            value={(userInfoForm?.categories || []).join(", ")}
          />

          <SeparatorLine opacity={0.25} />

          <InputText
            errors={errors.name}
            value={userInfoForm.name}
            label="Nome completo *"
            placeholder="Adicione seu nome"
            onChange={(val) => onChangeHandler(val, "name")}
          />

          <SeparatorLine opacity={0.25} />

          <InputText
            label="E-mail *"
            errors={errors.email}
            value={userInfoForm.email}
            placeholder="Adicione seu e-mail para contato"
            onChange={(val) => onChangeHandler(val, "email")}
          />

          <SeparatorLine opacity={0.25} />

          <InputText
            label="Cidade"
            value={userInfoForm.city || ""}
            placeholder="Adicione sua cidade aqui"
            onChange={(val) => onChangeHandler(val, "city")}
          />

          <SeparatorLine opacity={0.25} />

          <InputText
            label="Estado"
            charLimit={2}
            placeholder="Adicione seu estado aqui"
            value={userInfoForm.state?.toUpperCase() || ""}
            onChange={(val) => onChangeHandler(val.toUpperCase(), "state")}
          />

          <SeparatorLine opacity={0.25} />

          <InputText
            label="País"
            value={userInfoForm?.country || ""}
            placeholder="Adicione seu país aqui"
            onChange={(val) => onChangeHandler(val, "country")}
          />

          <SeparatorLine opacity={0.25} />

          <InputText
            limit
            as="textarea"
            label="Sobre"
            charLimit={3000}
            value={userInfoForm?.about || ""}
            onChange={(val) => onChangeHandler(val, "about")}
            placeholder="Perfis completos se destacam mais. Dica: Adicione uma descrição falando sobre você, sua personalidade, tom e como se relaciona com sua audiência."
          />
        </S.BoxContainer>

        {!notChangedForm && (
          <S.SaveButton
            variant="solid"
            onClick={() => onSave()}
            // disabled={notChangedForm}
            textColor={backgroundColor}
            backgroundColor={primaryColor}
          >
            Salvar alterações
          </S.SaveButton>
        )}
      </S.Information>
    </S.Container>
  );
};

export default EditProfile;
