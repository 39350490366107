import styled, { css } from "styled-components";

type NavBarProps = {
  backgroundColor: string;
};

export const Container = styled.div`
  left: 50%;
  width: 100%;
  bottom: 0px;
  z-index: 200;
  position: fixed;
  transform: translateX(-50%);
  overflow: visible;
  background-color: black;
  display: flex;
  justify-content: center;
`;

export const NavBar = styled.div<NavBarProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 960px;
  padding: 16px 36px;
`;

type ButtonProps = {
  isSelected: boolean;
  textColor: string;
  backgroundColor: string;
  isAppsPage: boolean;
};

export const Button = styled.div<ButtonProps>`
  ${({ isSelected, textColor, backgroundColor, isAppsPage }) => css`
    display: flex;
    cursor: pointer;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    opacity: ${isSelected ? 1 : 0.5};
    color: ${isSelected ? textColor : "#fafafa"};

    ${isAppsPage &&
    css`
      border-radius: 50%;
      padding: 16px;
      background: ${isSelected
        ? "none"
        : `radial-gradient(
        circle,
        rgba(0, 0, 0, 0) 60%,
        ${backgroundColor} 100%
      )`};
    `}

    svg {
      width: 20px;
      height: auto;
    }

    p {
      font-size: 8px;
      margin-top: 6px;
      letter-spacing: 1.2px;
    }
  `}
`;

type SpanProps = {
  backgroundColor: string;
  textColor: string;
};

export const Span = styled.span<SpanProps>`
  ${({ backgroundColor, textColor }) => css`
    position: absolute;
    content: "";
    top: 62px;
    width: 5px;
    height: 2px;
    border-radius: 40px;
    overflow: hidden;
    background-color: ${backgroundColor};
    color: ${textColor};
  `}
`;

export const Apps = styled.span<SpanProps>`
  ${({ backgroundColor, textColor }) => css`
    position: absolute;
    content: "";
    top: 12px;
    width: 61px;
    height: 61px;
    border-radius: 50%;
    overflow: hidden;
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 0) 45%,
      ${backgroundColor} 100%
    );
    color: ${textColor};
  `}
`;
