import styled, { css } from "styled-components";

type ContainerProps = {
  axisValuesColor: string;
};

export const Container = styled.div<ContainerProps>`
  ${({ axisValuesColor }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    div {
      svg {
        g {
          g {
            text {
              fill: ${axisValuesColor} !important;
            }
          }
        }
      }
    }
  `}
`;
