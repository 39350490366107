import { Theme } from "../../../hooks";
import Button from "../../atoms/Button";
import H5 from "../../atoms/Typography/H5";
import Small from "../../atoms/Typography/Small";

import * as S from "./styles";

interface IProps {
  title: string;
  segment: string;
  imageUrl: string;
  disabled: boolean;
  onCopyCode: () => void;
  onClickCard: () => void;
  onApplyForCampaign: () => void;
  status?:
    | "pending"
    | "approved"
    | "rejected"
    | "coming"
    | "exhaust"
    | "finished";
}
const buttonLabels = {
  coming: "Em breve!",
  rejected: "Cupons esgotados",
  approved: "Copiar seu código",
  exhaust: "Cupons esgotados :(",
  pending: "Solicitação em análise",
  finished: "Campanha finalizada :(",
};

const AffiliateCard: React.FC<IProps> = ({
  title,
  status,
  segment,
  disabled,
  imageUrl,
  onCopyCode,
  onClickCard,
  onApplyForCampaign,
}) => {
  const { textColor, primaryColor } = Theme.useTheme();

  const isApproved = status && status === "approved";

  const isCampainDisableButton =
    status &&
    ["pending", "exhaust", "rejected", "finished", "coming"].includes(status);

  const onClickHandler = () => {
    if (disabled) return;

    if (isApproved) onCopyCode();
    if (!status) onApplyForCampaign();
  };

  return (
    <S.Container onClick={onClickCard}>
      <S.Head>
        <Small color={textColor}>{segment.toLocaleUpperCase()}</Small>
        <H5 color={textColor}>{title}</H5>
      </S.Head>
      <S.Logo imageUrl={imageUrl} />

      <Button
        variant={isApproved ? "solid" : "outline"}
        onClick={(e) => {
          e.stopPropagation();
          onClickHandler();
        }}
        borderColor={isCampainDisableButton ? "#FAFAFA80" : primaryColor}
        textColor={
          isApproved
            ? "black"
            : isCampainDisableButton
            ? "#FAFAFA80"
            : primaryColor
        }
        backgroundColor={isApproved ? primaryColor : "none"}
        disabled={disabled || isCampainDisableButton}
      >
        {status ? buttonLabels[status] : "Solicitar código"}
      </Button>
    </S.Container>
  );
};

export default AffiliateCard;
