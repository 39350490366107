import styled, { css } from "styled-components";
import H4 from "../../atoms/Typography/H4";

type TitleProps = {
  titleSize: "medium" | "small";
};

export const Title = styled(H4)<TitleProps>`
  ${({ titleSize }) => css`
    font-size: ${titleSize === "small" ? "0.75rem" : "inherit"};
  `}
`;

type TextFieldProps = {
  centralize?: boolean;
};

export const TextField = styled.div<TextFieldProps>`
  ${({ centralize }) => css`
    gap: 6px;
    display: flex;
    flex-direction: column;
    text-align: ${centralize && "center"};
  `}
`;
