import { ICreatorData } from "../../../../types";
import InputText from "../../../../componentsV2/molecules/InputText";

interface IProps {
  hasError: boolean;
  isUsernameLoading: boolean;
  username: ICreatorData["username"];
  onChangeUsername: (username: string) => void;
}

const { REACT_APP_STOREFRONT_BASE_URL } = process.env;

const EditUsername: React.FC<IProps> = ({
  hasError,
  username,
  onChangeUsername,
  isUsernameLoading,
}) => {
  const status = isUsernameLoading ? "loading" : hasError ? "error" : "";

  return (
    <InputText
      label="Link"
      status={status}
      value={username || ""}
      onChange={onChangeUsername}
      baseURL={`${REACT_APP_STOREFRONT_BASE_URL}/`}
    />
  );
};

export default EditUsername;
