import styled from "styled-components";
import P from "../../atoms/Typography/P";
import Small from "../../atoms/Typography/Small";

type ContainerProps = {
  borderColor: string;
};

export const Container = styled.div<ContainerProps>`
  gap: 8px;
  width: 90%;
  z-index: 10;
  display: flex;
  max-width: 600px;
  align-self: center;
  padding: 24px 20px;
  position: relative;
  align-items: center;
  border-radius: 16px;
  flex-direction: column;
  background: #000;
  border: 1px solid ${({ borderColor }) => borderColor};
  overflow: hidden;
`;

type ImageContainerProps = {
  imageUrl: string;
};

export const ImageContainer = styled.div<ImageContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-position: center;
  background-size: contain;
  width: 285px;
  height: 85px;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 1.25rem;
  gap: 4px;

  p {
    font-size: 16px;
  }
`;

export const Username = styled.div`
  color: #fafafab3;
  font-size: 0.875rem;
  font-weight: 275;
  font-style: normal;
`;

/* ------------------------------------------ */

export const LinkBox = styled.div`
  gap: 6px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1.25rem;

  .text {
    text-align: center;
  }
`;

export const Link = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0.75rem;
  padding: 20px 24px;
  background-color: #fafafa1a;
  width: 100%;
  overflow: hidden;
  gap: 12px;

  .icon {
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

type LinkTextProps = {
  highlightColor: string;
};

export const LinkText = styled(P)<LinkTextProps>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: start;
  font-size: 0.875rem;

  span {
    color: ${({ highlightColor }) => highlightColor};
    font-size: 0.875rem;
  }
`;

export const CopyLink = styled.div`
  flex-grow: 1;
  display: flex;
  cursor: pointer;
  padding-top: 6px;
  user-select: none;

  button {
    width: 100%;
  }
`;

/* ------------------------------------------ */

export const SocialMedia = styled.div`
  gap: 16px;
  display: flex;
  margin-top: 24px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const SocialMediaText = styled(Small)`
  font-weight: 300;
  font-size: 0.875rem;
  color: #fafafa;
  line-height: 15px;
  font-style: normal;
  text-align: center;
  letter-spacing: 0px;
`;

export const SocialMediaBoxes = styled.div`
  gap: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    cursor: pointer;
    user-select: none;
  }

  svg {
    width: auto;
    height: 16px;
  }
`;
