import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import * as S from "./styles";
import Page from "../../../../components/atoms/Page";
import { App, Auth, Theme } from "../../../../hooks";
import Faq from "../../../../componentsV2/organisms/Faq";
import appConfig from "../../../../config/app-grupo.json";
import H3 from "../../../../componentsV2/atoms/Typography/H3";
import H4 from "../../../../componentsV2/atoms/Typography/H4";
import AddIcon from "../../../../componentsV2/organisms/AddIcon";
import { IForm } from "../../../AppVendaArquivos/components/Form";
import InputText from "../../../../componentsV2/molecules/InputText";
import AddImages from "../../../../componentsV2/organisms/AddImages";
import Navigation from "../../../../componentsV2/molecules/Navigation";
import SeparatorLine from "../../../../componentsV2/atoms/SeparatorLine";
import AppFormPrice from "../../../../componentsV2/organisms/AppFormPrice";
import AppFormHeader from "../../../../componentsV2/organisms/AppFormHeader";
import AppFormFooter from "../../../../componentsV2/organisms/AppFormFooter";
import ProfileIconCircle from "../../../../components/icons/ProfileIconCircle";
import ProfileImagePlaceholder from "../../../../components/icons/ProfileImagePlaceholder";

export type IChannelProvider = "whatsapp" | "telegram";

const validate = (data: IForm, url?: string): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!url)
    errors.url = [...(errors.url || []), "O link para acesso é obrigatório"];

  if (!data.title)
    errors.title = [...(errors.title || []), "Título obrigatório"];

  if (!data.description)
    errors.description = [
      ...(errors.description || []),
      "Descrição obrigatória",
    ];

  if (!data.price || data.price < 1)
    errors.price = [...(errors.price || []), "O valor mínimo é R$ 1"];

  return errors;
};

const Form = () => {
  const [item, setItem] = useState<IForm>({});
  const [providedURL, setProvidedURL] = useState(item.url);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [channelProvider, setChannelProvider] = useState<IChannelProvider>();

  const navigate = useNavigate();

  const { user } = Auth.useAuth();
  const { getItem, saveItemHandler, removeItemHandler } = App.useApp();
  const { backgroundColor, primaryColor, textColor } = Theme.useTheme();
  const { id } = useParams();

  useEffect(() => {
    const run = async () => {
      if (!id) return;

      const itemData = await getItem(appConfig.id, id);
      setItem(itemData || {});
      setProvidedURL(itemData?.url);
    };

    run();
  }, [getItem, id]);

  const onChangeHandler = (value: any, field: keyof IForm) => {
    if (errors[field])
      setErrors((curr) => {
        const newErrors = { ...curr };
        delete newErrors[field];

        return newErrors;
      });

    setItem((curr) => ({ ...curr, [field]: value }));
  };

  const onBackHandler = () => {
    if (channelProvider) {
      setChannelProvider(undefined);
      return;
    }

    navigate("/apps");
  };

  const onSave = (isDraft: boolean) => {
    const currErrors = validate(item, providedURL);

    if (currErrors && Object.keys(currErrors).length) {
      setErrors(currErrors);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return alert(
        "O cadastro possui erros, por favor verifique os campos para continuar"
      );
    }

    saveItemHandler(
      appConfig.id,
      {
        ...item,
        url: providedURL,
        channelProvider: channelProvider,
      },
      isDraft,
      id
    );
  };

  const updateMediaHandler = (index: number, imageUrl: string) => {
    setItem((curr) => {
      const newItem = {
        ...curr,
      };

      if (newItem.media) newItem.media[index] = imageUrl;

      return newItem;
    });
  };

  const removeMediaHandler = (index: number) => {
    setItem((curr) => {
      const newMedia = curr.media || [];
      newMedia.splice(index, 1);

      return { ...curr, media: newMedia };
    });
  };

  const addMediaHandler = (newImage: string) => {
    setItem((curr) => ({
      ...curr,
      media: [...(curr.media || []), newImage],
    }));
  };

  const onChangeTags = (val: string) => {
    if (!val) return onChangeHandler([], "tags");

    const tags = val.split(" ");

    if (tags.length > 3) {
      return;
    }

    if (tags[tags.length - 1] === "") {
      if (item.tags && item.tags[item.tags.length - 1] !== "#") {
        return onChangeHandler([...item.tags, "#"], "tags");
      } else tags.pop();
    }

    const normalizedTags = tags.map(
      (tag) => `#${tag.replace(/([^a-zA-Z0-9])/g, "").toLowerCase()}`
    );

    onChangeHandler(normalizedTags, "tags");
  };

  const isEditingItem = !!id;

  return (
    <Page>
      <AppFormHeader
        appTitle={appConfig.title}
        onBack={() => onBackHandler()}
      />

      {channelProvider || isEditingItem ? (
        <>
          <S.BoxContainer>
            <InputText
              label="Nome do grupo"
              errors={errors.title}
              value={item.title || ""}
              placeholder="Qual o nome do seu grupo?"
              onChange={(val) => onChangeHandler(val, "title")}
            />

            <SeparatorLine opacity={0.25} />

            <InputText
              errors={errors.url}
              value={providedURL || ""}
              onChange={setProvidedURL}
              label="Link do convite para entrar no grupo"
              placeholder="Cole aqui o link de convite para acesso"
            />

            <SeparatorLine opacity={0.25} />

            <AddIcon
              errors={errors.icon}
              icon={item.image || ""}
              onChange={(image) => onChangeHandler(image, "image")}
              onClear={() =>
                setItem((curr) => ({ ...curr, buttonImage: "", image: "" }))
              }
            />
          </S.BoxContainer>

          <AddImages
            errors={errors.media}
            images={item.media || []}
            onChange={(newImage) => addMediaHandler(newImage)}
            onRemoveImage={(index) => removeMediaHandler(index)}
            onUpdateImage={(index, imageUrl) =>
              updateMediaHandler(index, imageUrl)
            }
          />

          <S.BoxContainer>
            <H4 color={textColor}>Informações do grupo</H4>

            <InputText
              limit
              as="textarea"
              charLimit={3000}
              label="Descrição"
              errors={errors.description}
              value={item.description || ""}
              onChange={(val) => onChangeHandler(val, "description")}
              placeholder="Adicione uma descrição, falando um pouco mais sobre o seu grupo, que tipo de conteúdos seus fãs irão encontrar e outras informações que ajudem a promover seu produto."
            />

            <SeparatorLine opacity={0.25} />

            <InputText
              limit
              as="textarea"
              charLimit={500}
              errors={errors.targetAudience}
              label="Para quem é este grupo?"
              value={item.targetAudience || ""}
              onChange={(val) => onChangeHandler(val, "targetAudience")}
              placeholder="Em pouca palavras, explique quem é o público alvo do seu grupo? Quem são as pessoas, quais seus interesses, etc..."
            />
          </S.BoxContainer>

          <S.ImageContainer>
            <ProfileIconCircle />

            {user.profileImage ? (
              <S.ProfileImage imageUrl={user.profileImage}>
                <S.ProfileImageBorder color={primaryColor} />
              </S.ProfileImage>
            ) : (
              <S.ProfileImagePlaceholderContainer
                backgroundColor={primaryColor}
              >
                <ProfileImagePlaceholder color={backgroundColor} />

                <S.ProfileImageBorder color={primaryColor} />
              </S.ProfileImagePlaceholderContainer>
            )}

            <ProfileIconCircle flip />
          </S.ImageContainer>

          <S.BoxContainer>
            <H4 color={textColor}>Sobre você</H4>

            <InputText
              limit
              as="textarea"
              charLimit={3000}
              label="Sobre o autor"
              errors={errors.aboutAuthor}
              value={item.aboutAuthor || ""}
              onChange={(val) => onChangeHandler(val, "aboutAuthor")}
              placeholder="Conte um pouco sobre você. Isso ajuda na conversão de vendas do seu produto. Quanto mais informações, mais chances de vender!"
            />
          </S.BoxContainer>

          <Faq
            faqs={item.faq || []}
            onChange={(val) => onChangeHandler(val, "faq")}
          />

          <S.BoxContainer>
            <InputText
              label="Tags"
              onChange={onChangeTags}
              value={(item.tags || []).join(" ")}
              placeholder="Adicione até 3 tags separando as com vírgula"
            />
          </S.BoxContainer>

          <AppFormPrice
            appId={appConfig.id}
            errors={errors.price}
            value={item.price || 0}
            onChange={(val) => onChangeHandler(val, "price")}
          />

          <AppFormFooter
            onSave={onSave}
            saveDraft={true}
            appTitle="grupo"
            isEditingItem={isEditingItem}
            onRemove={() => id && removeItemHandler(appConfig.id, id)}
          />
        </>
      ) : (
        <S.BoxContainer>
          <H3 color={textColor}>Em qual plataforma está o seu grupo</H3>

          <S.Channels>
            <Navigation
              iconId="whatsapp"
              textFirstColor="Tenho um grupo de Whatsapp"
              onClick={() => setChannelProvider("whatsapp")}
            />

            <Navigation
              iconId="telegram"
              textFirstColor="Tenho um grupo de Telegram"
              onClick={() => setChannelProvider("telegram")}
            />
          </S.Channels>
        </S.BoxContainer>
      )}
    </Page>
  );
};

export default Form;
