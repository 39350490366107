import { ChangeEvent, useRef, useState } from "react";
import EditIcon from "../../../components/icons/EditIcon";
import LoadingIcon from "../../../components/icons/LoadingIcon";
import { Theme } from "../../../hooks";
import Button from "../../atoms/Button";
import InputBase from "../../atoms/InputBase";
import ProfileImage from "../../atoms/ProfileImage";
import ImageReposition from "../ImageReposition";

import * as S from "./styles";

interface EditableProfileImageProps {
  isLoading: boolean;
  currentImage: string;
  hideEditIcon?: boolean;
  onChangeImage: (image: string) => void;
}

const EditableProfileImage: React.FC<EditableProfileImageProps> = ({
  isLoading,
  currentImage,
  hideEditIcon,
  onChangeImage,
}) => {
  const [initialImage, setInitialImage] = useState("");

  const { primaryColor, backgroundColor } = Theme.useTheme();

  const input = useRef<HTMLInputElement | null>(null);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const file = (files || [])[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async () => {
        const base64 = reader.result?.toString();

        if (base64) setInitialImage(base64);

        if (input.current) input.current.value = "";
      };
    }
  };

  const onDiscardImage = () => {
    setInitialImage("");
  };

  return (
    <>
      <S.EditableProfileImage>
        {!hideEditIcon && (
          <>
            <Button variant="icon" backgroundColor={backgroundColor}>
              <EditIcon color={primaryColor} />
              <InputBase
                type="file"
                ref={input}
                onChange={onChangeHandler}
                accept="image/png, image/jpeg, image/jpg, image/webp"
              />
            </Button>
          </>
        )}
        <ProfileImage size="large" imageUrl={currentImage} />
        <S.Loading>
          {isLoading && <LoadingIcon color={backgroundColor} />}
        </S.Loading>
      </S.EditableProfileImage>

      {initialImage && (
        <ImageReposition
          initialImage={initialImage}
          onChangeImage={onChangeImage}
          onDiscardImage={onDiscardImage}
        />
      )}
    </>
  );
};

export default EditableProfileImage;
