import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const UploadIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 13.0002H9C9.55 13.0002 10 12.5502 10 12.0002V7.00021H11.59C12.48 7.00021 12.93 5.92021 12.3 5.29021L7.71 0.700215C7.32 0.310215 6.69 0.310215 6.3 0.700215L1.71 5.29021C1.08 5.92021 1.52 7.00021 2.41 7.00021H4V12.0002C4 12.5502 4.45 13.0002 5 13.0002ZM1 15.0002H13C13.55 15.0002 14 15.4502 14 16.0002C14 16.5502 13.55 17.0002 13 17.0002H1C0.45 17.0002 0 16.5502 0 16.0002C0 15.4502 0.45 15.0002 1 15.0002Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default UploadIcon;
