import styled, { css } from "styled-components";

export const PostsGrid = styled.div`
  gap: 16px;
  width: 100%;

  @media (max-width: 500px) {
    display: none;
  }
`;

export const Container = styled.div`
  gap: 20px;
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
`;

export const ImageBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BrandInfo = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
`;

type BrandLogoProps = {
  logo: string;
  borderColor: string;
};

export const BrandLogo = styled.div<BrandLogoProps>`
  ${({ logo, borderColor }) => css`
    width: 28px;
    height: 28px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: url(${logo});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid ${borderColor};
  `}
`;

export const PostsInfo = styled.div`
  gap: 20px;
  display: flex;
  align-items: center;
`;

export const Info = styled.div`
  gap: 4px;
  display: flex;
  align-items: center;

  svg {
    width: 12px;
    height: 12px;
  }
`;

type ImageProps = {
  image: string;
};

export const Image = styled.div<ImageProps>`
  ${({ image }) => css`
    display: flex;
    height: 300px;
    flex-shrink: 0;
    margin-top: 8px;
    margin-bottom: 12px;
    background: url(${image});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  `}
`;
