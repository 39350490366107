import CreditCardIcon from "../../../components/icons/CreditCardIcon";
import PixIcon from "../../../components/icons/PixIcon";
import { MP_CREDIT_CARD_FEE, MP_PIX_FEE } from "../../../constants";
import { Theme } from "../../../hooks";
import P from "../../atoms/Typography/P";
import InputPrice from "../../molecules/InputPrice";
import TitleDescription from "../../molecules/TitleDescription";

import * as S from "./styles";

interface IProps {
  value: number;
  appId: string;
  errors?: string[];
  onChange: (value: number) => void;
}

const baseFees = {
  grupo: 0.04,
  arquivo: 0.04,
  servico: 0.04,
  mentoria: 0.04,
  "curso-live": 0.04,
};

const AppFormPrice: React.FC<IProps> = ({ value, onChange, errors, appId }) => {
  const { primaryColor } = Theme.useTheme();

  const onChangePrice = (value: string) => {
    const onlyNumbersValue = value.replace(/[^0-9]/g, "");

    if (!onlyNumbersValue.length) return onChange(0);

    onChange(+onlyNumbersValue / 100);
  };

  const getCreditCardValue = () => {
    if (!value) return 0;

    const itemFee = baseFees[appId as keyof typeof baseFees];

    const gatewayFee = +value * MP_CREDIT_CARD_FEE;
    const stantiFee = +value * itemFee;

    const creditCardPrice = +value - gatewayFee - stantiFee;

    const normalizedCreditCardPrice = (
      Math.round((creditCardPrice + Number.EPSILON) * 100) / 100
    )
      .toFixed(2)
      .replace(".", ",");

    return normalizedCreditCardPrice;
  };

  const getPixValue = () => {
    if (!value) return 0;

    const itemFee = baseFees[appId as keyof typeof baseFees];

    const gatewayFee = +value * MP_PIX_FEE;
    const stantiFee = +value * itemFee;

    const pixPrice = +value - gatewayFee - stantiFee;

    const normalizedPixPrice = (
      Math.round((pixPrice + Number.EPSILON) * 100) / 100
    )
      .toFixed(2)
      .replace(".", ",");

    return normalizedPixPrice;
  };

  const parseValue = (val: number) => {
    if (!val) return "";

    return value.toFixed(2).replace(".", ",");
  };

  return (
    <S.FormPriceBox>
      <TitleDescription
        titleSize="small"
        title="Preço"
        titleColor={primaryColor}
      >
        Defina quanto seu cliente pagará por este produto
      </TitleDescription>

      <InputPrice onChange={onChangePrice} value={parseValue(value)} />

      <S.TotalValues>
        <S.ValuesText color="#fafafa80">
          Abaixo você confere quanto você receberá na sua conta do Mercado Pago
          de acordo com a forma de pagamento do seu cliente.
        </S.ValuesText>

        <S.TotalValue>
          <div className="icon">
            <PixIcon />
          </div>

          <P color="#FAFAFA80">No pix, você receberá R$ {getPixValue()}</P>
        </S.TotalValue>

        <S.TotalValue>
          <div className="icon">
            <CreditCardIcon />
          </div>

          <P color="#FAFAFA80">
            No cartão, você receberá R$ {getCreditCardValue()}
          </P>
        </S.TotalValue>
      </S.TotalValues>

      {errors && errors.length && (
        <S.ErrorMessage color="#FF4D4F">
          Erros: {errors.join(", ")}
        </S.ErrorMessage>
      )}
    </S.FormPriceBox>
  );
};

export default AppFormPrice;
