import FacebookIcon from "../../../components/icons/FacebookIcon";
import GoogleIcon from "../../../components/icons/GoogleIcon";
import { Theme } from "../../../hooks";
import P from "../../atoms/Typography/P";

import * as S from "./styles";

interface IProps {
  onClick: () => void;
  socialMedia: "Google" | "Facebook";
}

const SocialLoginButton: React.FC<IProps> = ({ onClick, socialMedia }) => {
  const { textColor } = Theme.useTheme();

  return (
    <S.SocialMediaLogin onClick={onClick}>
      <S.Icon>
        {socialMedia === "Google" ? <GoogleIcon /> : <FacebookIcon />}
      </S.Icon>

      <S.Text>
        <P color={textColor}>Entrar com sua conta do {socialMedia}</P>
      </S.Text>
    </S.SocialMediaLogin>
  );
};

export default SocialLoginButton;
