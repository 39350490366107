import styled, { css } from "styled-components";

import P from "../../../../componentsV2/atoms/Typography/P";

export const Images = styled.div`
  gap: 8px;
  display: grid;
  margin-top: 32px;
  grid-template-columns: repeat(auto-fill, minmax(108px, 1fr));
`;

type TextProps = {
  selectedPosts: boolean;
  colorWhenSelected: string;
};

export const Text = styled(P)<TextProps>`
  ${({ selectedPosts, colorWhenSelected }) => css`
    color: ${selectedPosts ? colorWhenSelected : "curr"};
  `}
`;
