import * as S from "./styles";
import InputText from "../InputText";
import LinkIcon from "../../../components/icons/LinkIcon";
import { Theme } from "../../../hooks";
import AvailableIcons from "../../../components/atoms/AvailableIcons";

type MediaWithLinkProps = {
  index: number;
  galleryItem: {
    url: string;
    link?: string;
  };
  onRemoveMedia?: () => void;
  allowMediaRemoval?: boolean;
  setLink: (value: string) => void;
};

const MediaWithLink: React.FC<MediaWithLinkProps> = ({
  index,
  setLink,
  galleryItem,
  onRemoveMedia,
  allowMediaRemoval = false,
}) => {
  const { textColor, primaryColor } = Theme.useTheme();

  const label = `Foto ${index + 1 < 10 ? `0${index + 1}` : `${index + 1}`}`;

  return (
    <S.ImageContent backgroundColor={textColor}>
      <S.TextImageAndClose>
        <S.TextAndImage>
          <S.Image imageUrl={galleryItem.url} />

          <S.Label color={textColor}>{label}</S.Label>
        </S.TextAndImage>

        {allowMediaRemoval && (
          <S.CloseIcon onClick={onRemoveMedia}>
            <AvailableIcons option="close" color={primaryColor} />
          </S.CloseIcon>
        )}
      </S.TextImageAndClose>

      <S.Link>
        <LinkIcon color={`${textColor}40`} />

        <InputText
          onChange={setLink}
          placeholder="Link (opcional)"
          value={galleryItem.link || ""}
        />
      </S.Link>
    </S.ImageContent>
  );
};

export default MediaWithLink;
