import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const FacebookIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="50"
      height="40"
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.2991 39V19.9978H34.2159L35 13.4495H28.2991L28.3092 10.172C28.3092 8.46414 28.4922 7.54901 31.2592 7.54901H34.9581V1H29.0405C21.9325 1 19.4307 4.17662 19.4307 9.51869V13.4502H15V19.9985H19.4307V39H28.2991Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default FacebookIcon;
