import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const InstagramIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="50"
      height="40"
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.5044 9.7445C18.9708 9.7445 14.5075 14.3233 14.5075 20C14.5075 25.6767 18.9708 30.2555 24.5044 30.2555C30.0379 30.2555 34.5012 25.6767 34.5012 20C34.5012 14.3233 30.0379 9.7445 24.5044 9.7445ZM24.5044 26.6674C20.9284 26.6674 18.0051 23.6773 18.0051 20C18.0051 16.3227 20.9197 13.3326 24.5044 13.3326C28.089 13.3326 31.0036 16.3227 31.0036 20C31.0036 23.6773 28.0803 26.6674 24.5044 26.6674ZM37.2419 9.325C37.2419 10.6549 36.1978 11.7171 34.9102 11.7171C33.6138 11.7171 32.5784 10.646 32.5784 9.325C32.5784 8.00402 33.6225 6.93295 34.9102 6.93295C36.1978 6.93295 37.2419 8.00402 37.2419 9.325ZM43.863 11.7528C43.7151 8.54848 43.0016 5.71014 40.7134 3.37164C38.4339 1.03314 35.6671 0.301238 32.5436 0.140578C29.3244 -0.0468593 19.6756 -0.0468593 16.4564 0.140578C13.3416 0.292313 10.5748 1.02421 8.28661 3.36271C5.99838 5.70122 5.29364 8.53955 5.13703 11.7438C4.95432 15.0463 4.95432 24.9448 5.13703 28.2472C5.28494 31.4515 5.99838 34.2899 8.28661 36.6284C10.5748 38.9669 13.3329 39.6988 16.4564 39.8594C19.6756 40.0469 29.3244 40.0469 32.5436 39.8594C35.6671 39.7077 38.4339 38.9758 40.7134 36.6284C42.9929 34.2899 43.7064 31.4515 43.863 28.2472C44.0457 24.9448 44.0457 15.0552 43.863 11.7528ZM39.7041 31.7907C39.0255 33.5401 37.7117 34.8879 35.9977 35.593C33.4311 36.6373 27.3407 36.3963 24.5044 36.3963C21.668 36.3963 15.5689 36.6284 13.011 35.593C11.3057 34.8968 9.99191 33.549 9.30457 31.7907C8.28661 29.1576 8.52153 22.9097 8.52153 20C8.52153 17.0903 8.29531 10.8334 9.30457 8.2093C9.98321 6.45989 11.297 5.11213 13.011 4.40701C15.5776 3.36271 21.668 3.6037 24.5044 3.6037C27.3407 3.6037 33.4398 3.37164 35.9977 4.40701C37.703 5.1032 39.0168 6.45096 39.7041 8.2093C40.7221 10.8424 40.4872 17.0903 40.4872 20C40.4872 22.9097 40.7221 29.1666 39.7041 31.7907Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default InstagramIcon;
