import * as S from "./styles";
import { Theme } from "../../../hooks";
import { TProductType } from "../../../types";
import P from "../../../componentsV2/atoms/Typography/P";
import Label from "../../../componentsV2/atoms/Typography/Label";
import AvailableIcons from "../../../components/atoms/AvailableIcons";

type TProduct = {
  id: string;
  title: string;
  app: TProductType;
};

interface IProps {
  products: Array<TProduct>;
  onProductClick: (productId: string) => void;
}

const ProductList: React.FC<IProps> = ({ products, onProductClick }) => {
  const { textColor, primaryColor } = Theme.useTheme();

  return (
    <S.ProductList>
      {products.map((product) => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const appData = require(`../../../config/app-${product.app}.json`);

        return (
          <S.Card
            backgroundColor={`${textColor}0d`}
            key={`${product.app}||${product.id}`}
            onClick={() => onProductClick(`${product.app}||${product.id}`)}
          >
            <S.LabelAndTitle>
              <Label color={primaryColor}>{appData.title}</Label>

              <P color={textColor}>{product.title}</P>
            </S.LabelAndTitle>

            <S.Icon>
              <AvailableIcons option="chevron" color={primaryColor} />
            </S.Icon>
          </S.Card>
        );
      })}
    </S.ProductList>
  );
};

export default ProductList;
