import styled, { css } from "styled-components";
import Box from "../../../../componentsV2/atoms/Box";
import Button from "../../../../componentsV2/atoms/Button";

export const Header = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  justify-content: flex-end;
  transform: rotate(180deg);
`;

export const BoxContainer = styled(Box)`
  gap: 20px;
`;

export const Buttons = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

type SocialMediaButtonProps = {
  textColor: string;
};

export const SocialMediaButton = styled(Button)<SocialMediaButtonProps>`
  ${({ textColor }) => css`
    gap: 12px;
    display: flex;
    align-items: center;
    flex-direction: row;
    color: ${textColor};
    justify-content: center;
  `}
`;

export const Icon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const NavigationAndVisualize = styled.div`
  gap: 12px;
  display: flex;
  margin: 20px 0 32px;
  flex-direction: column;
`;

export const Configuration = styled(Box)`
  gap: 20px;
  margin-top: 32px;
`;
