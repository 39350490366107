import * as S from "./styles";
import { Theme } from "../../../hooks";
import AvailableIcons from "../../../components/atoms/AvailableIcons";

interface IHeader {
  onClickBack: () => void;
}

const Header: React.FC<IHeader> = ({ onClickBack }) => {
  const { primaryColor, textColor } = Theme.useTheme();

  return (
    <S.Header>
      <S.BackButton
        onClick={() => {
          onClickBack();
        }}
      >
        <AvailableIcons option="chevron" color={primaryColor} />
      </S.BackButton>

      <S.Text color={textColor} fontWeight="300">
        Extrato
      </S.Text>
    </S.Header>
  );
};

export default Header;
