import { Theme } from "../../../hooks";
import InputBase from "../InputBase";
import * as S from "./styles";

type InputNumberBaseProps = {
  id?: string;
  max?: number;
  min?: number;
  value: number;
  placeholder?: string;
  onChange: (val: string) => void;
};

const InputNumberBase: React.FC<InputNumberBaseProps> = ({
  id,
  max = 100,
  min = 0,
  value,
  onChange,
  placeholder,
}) => {
  const { primaryColor } = Theme.useTheme();

  return (
    <S.Container>
      <S.MinusButton
        textColor={primaryColor}
        disabled={value < 1 || value < min}
        onClick={() => onChange(String((+value || 0) - 1))}
      >
        -
      </S.MinusButton>

      <InputBase
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={({ target }) => onChange(target.value)}
      />

      <S.PlusButton
        textColor={primaryColor}
        disabled={value >= max}
        onClick={() => onChange(String((+value || 0) + 1))}
      >
        +
      </S.PlusButton>
    </S.Container>
  );
};

export default InputNumberBase;
