import { useEffect, useRef, useState } from "react";
import CheckIcon from "../../../components/icons/CheckIcon";
import CloseIcon from "../../../components/icons/CloseIcon";
import HidePasswordIcon from "../../../components/icons/HidePasswordIcon";
import LoadingIcon from "../../../components/icons/LoadingIcon";
import { Theme } from "../../../hooks";
import InputBase from "../../atoms/InputBase";
import * as S from "./styles";

type InputTextProps = {
  id?: string;
  value: string;
  rows?: number;
  label?: string;
  limit?: boolean;
  errors?: string[];
  charLimit?: number;
  disabled?: boolean;
  autofocus?: boolean;
  placeholder?: string;
  allLowerCase?: boolean;
  preventScroll?: boolean;
  baseURL?: string;
  as?: "textarea" | "input";
  onChange: (val: string) => void;
  status?: "success" | "error" | "loading" | "";
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">;

const InputText: React.FC<InputTextProps> = ({
  id,
  as = "input",
  rows = 5,
  value,
  label,
  limit,
  status,
  errors,
  disabled,
  onChange,
  autofocus,
  charLimit = 100,
  placeholder,
  allLowerCase,
  preventScroll,
  baseURL,
  ...props
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const input = useRef<HTMLInputElement>(null);
  const { textColor } = Theme.useTheme();

  const onFocusHandler = () => {
    if (window.navigator.userAgent.includes("Mobi")) {
      document.body.style.paddingBottom = "400px";
      document.body.style.backgroundColor = "#080808";

      if (!preventScroll) {
        setTimeout(() => {
          if (input.current)
            window.scrollTo({
              left: 0,
              top: input.current?.offsetTop - 100,
              behavior: "smooth",
            });
        }, 10);
      }
    }
  };

  const onBlurHandler = () => {
    if (window.navigator.userAgent.includes("Mobi"))
      document.body.style.paddingBottom = "0";
  };

  const onChangeHandler = (val: string) => {
    if (charLimit)
      return allLowerCase
        ? onChange(val.toLowerCase().substring(0, charLimit))
        : onChange(val.substring(0, charLimit));

    allLowerCase ? onChange(val.toLowerCase()) : onChange(val);
  };

  const iconToRender = {
    loading: <LoadingIcon />,
    success: <CheckIcon />,
    error: <CloseIcon />,
  };

  useEffect(() => {
    if (autofocus && input.current) input.current.focus();
  }, [autofocus]);

  return (
    <S.Container>
      {label || limit ? (
        <S.LabelAndLimit>
          {label ? <S.Label htmlFor={id}>{label}</S.Label> : null}

          {limit ? (
            <S.RightBar>
              <S.CharLimit
                color={textColor}
              >{`${value.length}/${charLimit}`}</S.CharLimit>
            </S.RightBar>
          ) : null}
        </S.LabelAndLimit>
      ) : null}

      <S.InputAndIcon hasIcon={props.type === "password"}>
        {baseURL && <S.BaseUrl color="#fafafa60">{baseURL}</S.BaseUrl>}
        <InputBase
          id={id}
          as={as}
          rows={rows}
          ref={input}
          value={value}
          autoComplete="off"
          disabled={disabled}
          onBlur={onBlurHandler}
          onFocus={onFocusHandler}
          placeholder={placeholder}
          onChange={({ target }: any) => onChangeHandler(target.value)}
          {...props}
          // Manter o type na última posição para que o ...props não o sobrescreva
          type={
            props.type === "password" && isPasswordVisible ? "text" : props.type
          }
        />

        <S.Icons>
          {props.type === "password" &&
            (isPasswordVisible ? (
              <HidePasswordIcon onClick={() => setIsPasswordVisible(false)} />
            ) : (
              <S.PasswordIcon onClick={() => setIsPasswordVisible(true)} />
            ))}

          {!!status && iconToRender[status]}
        </S.Icons>
      </S.InputAndIcon>

      {errors && errors.length ? (
        <S.ErrorMessage color="#FF4D4F">
          Erros: {errors.join(", ")}
        </S.ErrorMessage>
      ) : null}
    </S.Container>
  );
};

export default InputText;
