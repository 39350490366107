import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const EmailIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="50"
      height="40"
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9 6H41.1C43.245 6 45 7.575 45 9.5V30.5C45 32.425 43.245 34 41.1 34H9.9C7.755 34 6 32.425 6 30.5L6.0195 9.5C6.0195 7.575 7.755 6 9.9 6ZM26.5335 21.1724L40.32 13.4374C40.8075 13.1574 41.1 12.6849 41.1 12.1774C41.1 11.0049 39.6765 10.3049 38.565 10.9174L25.5 18.2499L12.435 10.9174C11.3235 10.3049 9.9 11.0049 9.9 12.1774C9.9 12.6849 10.1925 13.1574 10.68 13.4374L24.4665 21.1724C25.0905 21.5224 25.9095 21.5224 26.5335 21.1724Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default EmailIcon;
