import * as S from "./styles";
import { Theme } from "../../../../../../hooks";
import { IPostsEntity } from "../../../../../../types/media-kit";
import Small from "../../../../../../componentsV2/atoms/Typography/Small";
import AvailableIconsTemp from "../../../../../../components/atoms/AvailableIconsTemp";

interface IProps {
  posts: IPostsEntity;
  imageWidth?: number;
}

const PostsGrid: React.FC<IProps> = ({ posts }) => {
  const { textColor, primaryColor } = Theme.useTheme();

  return (
    <S.PostsGrid>
      <S.Container>
        {posts.posts.map((post) => (
          <S.ImageBox key={post.id}>
            <S.BrandInfo>
              <S.BrandLogo
                borderColor={primaryColor}
                logo={post.brandLogo || ""}
              />

              <Small color={textColor}>@{post.brandUsername}</Small>
            </S.BrandInfo>

            <S.Image image={post.thumbnailUrl} />

            <S.PostsInfo>
              <S.Info>
                <AvailableIconsTemp
                  option="showPassword"
                  color={`${textColor}8f`}
                />

                <Small color={textColor}>{post.viewCount || "-"}</Small>
              </S.Info>

              <S.Info>
                <AvailableIconsTemp option="heart" color={`${textColor}8f`} />

                <Small color={textColor}>{post.likeCount || "-"}</Small>
              </S.Info>

              <S.Info>
                <AvailableIconsTemp option="message" color={`${textColor}8f`} />

                <Small color={textColor}>{post.commentCount || "-"}</Small>
              </S.Info>
            </S.PostsInfo>
          </S.ImageBox>
        ))}
      </S.Container>
    </S.PostsGrid>
  );
};

export default PostsGrid;
