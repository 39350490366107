import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import {
  ISocialMediaEntity,
  IPersonalInfoEntity,
  IInstagramBusinessAccounts,
} from "../../../../types/media-kit";
import {
  emptyUserInfo,
  getInstagramAuhtorizationUrl,
  getTiktokAuhtorizationUrl,
} from "../../../../utils/media-kit";
import { MediaKit } from "../../../../services";
import Page from "../../../../components/atoms/Page";
import Button from "../../../../componentsV2/atoms/Button";
import H3 from "../../../../componentsV2/atoms/Typography/H3";
import Switch from "../../../../componentsV2/molecules/Switch";
import Navigation from "../../../../componentsV2/molecules/Navigation";
import SeparatorLine from "../../../../componentsV2/atoms/SeparatorLine";
import AvailableIcons from "../../../../components/atoms/AvailableIcons";
import { Auth, Loading, NavBar, Snackbar, Theme } from "../../../../hooks";
import InputSelect from "../../../../componentsV2/molecules/InputSelectTemp";
import TitleDescription from "../../../../componentsV2/molecules/TitleDescription";

const { REACT_APP_STOREFRONT_BASE_URL } = process.env;

const Home: React.FC = () => {
  const [updateData, setUpdateData] = useState<boolean>(false);
  const [tiktokData, setTiktokData] = useState<ISocialMediaEntity>();
  const [instagramData, setInstagramData] = useState<ISocialMediaEntity>();
  // const [visibleOnStoreFront, setVisibleOnStoreFront] = useState<boolean>();
  const [personalData, setPersonalData] =
    useState<IPersonalInfoEntity>(emptyUserInfo);
  const [instagramAccounts, setInstagramAccounts] =
    useState<IInstagramBusinessAccounts[]>();
  const [selectedInstagramAccount, setSelectedInstagramAccount] =
    useState<IInstagramBusinessAccounts>();
  const [multipleInstagramAccount, setMultipleInstagramAccount] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const { token, user } = Auth.useAuth();
  const { hideNavBar, showNavBar } = NavBar.useNavBar();
  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();
  const { textColor, primaryColor, backgroundColor } = Theme.useTheme();

  useEffect(() => {
    showLoading();
    try {
      if (window.location.search && window.location.search !== "?") {
        const { code } = window.location.search
          .substring(1)
          .split("&")
          .reduce(
            (acc, curr) => {
              const [key, ...value] = curr.split("=");
              return { ...acc, [key]: value.join("=") };
            },
            { code: "" }
          );

        if (code) {
          try {
            const run = async () => {
              const newData = await MediaKit.getTikTokAccessToken(token, code);

              setTiktokData(newData);
              window.location.search = "";
              setUpdateData((curr) => !curr);
            };

            run();
          } catch (error) {
            newError("Não foi possível conectar à conta do TikTok");
          }
        }
      } else if (window.location.hash && window.location.hash !== "#") {
        const { access_token } = window.location.hash
          .substring(1)
          .split("&")
          .reduce(
            (acc, curr) => {
              const [key, ...value] = curr.split("=");
              return { ...acc, [key]: value.join("=") };
            },
            { access_token: "" }
          );

        if (access_token) {
          try {
            const run = async () => {
              const accounts = await MediaKit.registerInstagram(
                token,
                access_token
              );

              if (accounts && accounts.length > 1) {
                setInstagramAccounts(accounts);
                setMultipleInstagramAccount(true);
              } else {
                setUpdateData((curr) => !curr);
              }
            };

            run();
          } catch (error) {
            newError("Não foi possível conectar à conta do Instagram");
          } finally {
            window.location.hash = "";
          }
        }
      } else {
        const run = async () => {
          const fullData = await MediaKit.getMediaKitData(token);

          if (fullData.mediaKitData.tiktok) {
            setTiktokData(fullData.mediaKitData.tiktok);
          }
          if (fullData.mediaKitData.instagram) {
            if (fullData.mediaKitData.instagram.instagramBusinessAccounts) {
              setInstagramAccounts(
                fullData.mediaKitData.instagram.instagramBusinessAccounts
              );
              setMultipleInstagramAccount(true);
            } else {
              setInstagramData(fullData.mediaKitData.instagram);
            }
          }
          if (fullData.mediaKitData.personal) {
            setPersonalData(fullData.mediaKitData.personal);
            // setVisibleOnStoreFront(
            //   fullData.mediaKitData.personal.visibleOnStorefront
            // );
          }
          hideLoading();
        };

        run();
      }
    } catch (error) {
      newError("Houve um erro ao obter os dados do TikTok");
    } finally {
      hideLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  const onClickInstagram = async () => {
    hideNavBar();

    const authorizationUrl = getInstagramAuhtorizationUrl();

    window.location.href = authorizationUrl;

    return () => showNavBar();
  };

  const onClickTikTok = async () => {
    hideNavBar();

    const authorizationUrl = getTiktokAuhtorizationUrl();

    window.location.href = authorizationUrl;

    return () => showNavBar();
  };

  const onChangeVisibility = async () => {
    try {
      showLoading();

      await MediaKit.savePersonalInfo(token, {
        ...personalData,
        visibleOnStorefront: !personalData.visibleOnStorefront,
      });
      setUpdateData((curr) => !curr);
    } catch (error) {
      throw newError("Não foi possível alterar a visibilidade do Mídia Kit");
    } finally {
      hideLoading();
    }
  };

  const onSelectInstagramAccount = (index: number) => {
    const account = instagramAccounts && instagramAccounts[index];
    setSelectedInstagramAccount(account);
  };

  const onSaveSelectedInstagramAccount = async () => {
    await MediaKit.updateInstagram(token, selectedInstagramAccount?.id || "");
    setMultipleInstagramAccount(false);
    setUpdateData((curr) => !curr);
  };

  const accountSelectedIndex =
    instagramAccounts &&
    instagramAccounts.findIndex((item) => item === selectedInstagramAccount);

  const onRemoveMidiaKit = async () => {
    await MediaKit.removeMediaKitEntity(token, "media-kit");
    setInstagramData(undefined);
    setTiktokData(undefined);
    setUpdateData((curr) => !curr);
  };

  const onCopyLink = () => {
    navigator.clipboard.writeText(
      `${REACT_APP_STOREFRONT_BASE_URL}/${user.username}/media-kit`
    );

    newSuccess("Link copiado!");
  };

  const tiktokText = tiktokData ? "Trocar" : "Conectar";
  const instagramText = instagramData ? "Trocar" : "Conectar";

  const accountsUsernames = (instagramAccounts || []).map(
    (account) => account.username
  );

  const accountsProfilePic = (instagramAccounts || []).map(
    (account) => account.profilePictureUrl
  );

  const content = (() => {
    if (multipleInstagramAccount) {
      return (
        <S.BoxContainer>
          <TitleDescription
            titleColor={primaryColor}
            textColor={`${textColor}80`}
            title="Selecione seu Instagram"
          >
            Foi encontrada mais de uma conta do Instagram associada ao seu
            Login. Por favor, selecione a conta de interesse, para que possamos
            importar as informações para o Mídia Kit.
          </TitleDescription>

          <InputSelect
            listType="images"
            options={accountsUsernames}
            images={accountsProfilePic}
            selectedIndex={accountSelectedIndex || 0}
            onSelect={(val) => onSelectInstagramAccount(val)}
          />

          <Button
            variant="outline"
            textColor={primaryColor}
            borderColor={primaryColor}
            disabled={!selectedInstagramAccount}
            onClick={() => onSaveSelectedInstagramAccount()}
          >
            Salvar seleção
          </Button>
        </S.BoxContainer>
      );
    } else if (tiktokData || instagramData) {
      return (
        <>
          <S.NavigationAndVisualize>
            <Navigation
              widthAheadIcon="14px"
              aheadIconId="copyIcon"
              onClick={() => onCopyLink()}
              textSecondColor={`${user.username}/media-kit`}
              textFirstColor={`${REACT_APP_STOREFRONT_BASE_URL}/`}
            />

            <Button
              variant="solid"
              textColor={backgroundColor}
              backgroundColor={primaryColor}
              onClick={() => navigate("/apps/media-kit/edit")}
            >
              Visualizar/editar mídia kit
            </Button>
          </S.NavigationAndVisualize>

          <SeparatorLine opacity={0.25} />

          <S.Configuration>
            <H3 color={primaryColor}>Configurações</H3>

            <Switch
              label="Exibir na Stanti"
              setIsOn={() => onChangeVisibility()}
              isOn={personalData.visibleOnStorefront}
            />

            <S.Buttons>
              <S.SocialMediaButton
                variant="outline"
                textColor={primaryColor}
                borderColor={primaryColor}
                onClick={() => onClickInstagram()}
              >
                <S.Icon>
                  <AvailableIcons option="instagramTwo" color={primaryColor} />
                </S.Icon>
                {instagramText} Instagram
              </S.SocialMediaButton>

              <S.SocialMediaButton
                variant="outline"
                textColor={primaryColor}
                borderColor={primaryColor}
                onClick={() => onClickTikTok()}
              >
                <S.Icon>
                  <AvailableIcons option="tiktok" color={primaryColor} />
                </S.Icon>
                {tiktokText} TikTok
              </S.SocialMediaButton>
            </S.Buttons>

            <Button
              variant="default"
              textColor="#F94144"
              onClick={() => onRemoveMidiaKit()}
            >
              Apagar Mídia kit
            </Button>
          </S.Configuration>
        </>
      );
    }

    return (
      <S.BoxContainer>
        <TitleDescription
          title="Crie seu mídia kit"
          titleColor={primaryColor}
          textColor={`${textColor}80`}
        >
          Para começar é muito fácil, basta conectar sua conta de criador de
          conteúdo ou marca do Instagram e/ou Tiktok.
        </TitleDescription>

        <S.Buttons>
          <S.SocialMediaButton
            variant="solid"
            textColor={backgroundColor}
            backgroundColor={primaryColor}
            onClick={() => onClickInstagram()}
          >
            <S.Icon>
              <AvailableIcons option="instagramTwo" color={backgroundColor} />
            </S.Icon>
            Conectar Instagram
          </S.SocialMediaButton>

          <S.SocialMediaButton
            variant="solid"
            textColor={backgroundColor}
            backgroundColor={primaryColor}
            onClick={() => onClickTikTok()}
          >
            <S.Icon>
              <AvailableIcons option="tiktok" color={backgroundColor} />
            </S.Icon>
            Conectar TikTok
          </S.SocialMediaButton>
        </S.Buttons>
      </S.BoxContainer>
    );
  })();

  return (
    <Page>
      <S.Header>
        <Button
          variant="icon"
          backgroundColor={`${textColor}1f`}
          onClick={() => navigate("/apps")}
        >
          <AvailableIcons option="chevron" color={textColor} />
        </Button>
      </S.Header>

      <TitleDescription
        title="Mídia Kit"
        titleColor={textColor}
        textColor={`${textColor}80`}
      >
        Mostre para marcas e parceiros porque eles devem trabalhar com você. No
        mídia kit Stanti, exiba seus trabalhos com marcas e tenha suas
        estatísticas atualizadas automaticamente.
      </TitleDescription>

      {content}
    </Page>
  );
};

export default Home;
