import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import { Instagram } from "../../../../services";
import AppImageSelector from "../AppImageSelector";
import Page from "../../../../components/atoms/Page";
import Box from "../../../../componentsV2/atoms/Box";
import Button from "../../../../componentsV2/atoms/Button";
import { IInstagramPosts, ILinkToPostInstagram } from "../../../../types";
import { Auth, Loading, NavBar, Snackbar, Theme } from "../../../../hooks";
import appConfig from "../../../../config/app-link-to-post-instagram.json";
import AppFormHeader from "../../../../componentsV2/organisms/AppFormHeader";
import TitleDescription from "../../../../componentsV2/molecules/TitleDescription";

const {
  REACT_APP_INSTAGRAM_BASE_URL,
  REACT_APP_INSTAGRAM_CLIENT_ID,
  REACT_APP_INSTAGRAM_URL_REDIRECT,
} = process.env;

const Form: React.FC = () => {
  const [posts, setPosts] = useState<IInstagramPosts[]>([]);
  const [changeSelection, setChangeSelection] = useState<boolean>(false);
  const [currInstaUsername, setCurrInstaUsername] = useState<string>("");
  const [currentPosts, setCurrentPosts] = useState<ILinkToPostInstagram[]>([]);

  const navigate = useNavigate();
  const { token } = Auth.useAuth();
  const { newError } = Snackbar.useSnackbar();
  const { textColor, primaryColor } = Theme.useTheme();
  const { hideNavBar, showNavBar } = NavBar.useNavBar();
  const { showLoading, hideLoading } = Loading.useLoading();

  useEffect(() => {
    hideNavBar();

    const getPostsFromInstagram = async (code: string) => {
      showLoading();
      try {
        const [{ posts: postsFromInstagram }, { posts: currentPosts }] =
          await Promise.all([
            Instagram.getPostsFromInstagram(code, token),
            Instagram.getPosts(token),
          ]);

        setPosts(postsFromInstagram);
        setCurrInstaUsername(postsFromInstagram[0].username);

        if (currentPosts) setCurrentPosts(currentPosts);
      } catch (error) {
        newError("Houve um erro ao obter os posts");
        navigate(`/apps/${appConfig.id}`);
      } finally {
        hideLoading();
      }
    };
    if (window.location.search && window.location.search !== "?") {
      const { code } = window.location.search
        .substring(1)
        .split("&")
        .reduce(
          (acc, curr) => {
            const [key, ...value] = curr.split("=");
            return { ...acc, [key]: value.join("=") };
          },
          { code: "" }
        );

      if (code) {
        getPostsFromInstagram(code);
      } else {
        newError("Não foi possível conectar à conta do Instagram");
        navigate(`/apps/${appConfig.id}`);
      }
    } else {
      const params = [
        `client_id=${REACT_APP_INSTAGRAM_CLIENT_ID}`,
        `redirect_uri=${REACT_APP_INSTAGRAM_URL_REDIRECT}`,
        `scope=user_profile,user_media`,
        `response_type=code`,
      ].join("&");

      const url = `${REACT_APP_INSTAGRAM_BASE_URL}?${params}`;

      window.location.href = url;
    }

    return () => showNavBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectHandler = (index: number) => {
    setChangeSelection(true);

    setPosts((curr) => {
      const newPosts = [...curr];

      newPosts[index].isUsed = !newPosts[index].isUsed;

      return newPosts;
    });
  };

  const selectedPostsAmount = posts.filter((i) => i.isUsed).length;

  const selectedPostsDescription = (() => {
    if (selectedPostsAmount === 1) return "1 post selecionado";

    return `${selectedPostsAmount} posts selecionados`;
  })();

  const onSaveHandler = async () => {
    showLoading();

    try {
      const postsDiffOrWithoutUser: ILinkToPostInstagram[] =
        currentPosts.filter((post) => post.username !== currInstaUsername);

      const postsId: string[] = posts.map((p) => {
        return p.id;
      });

      const postsDiffUser: ILinkToPostInstagram[] =
        postsDiffOrWithoutUser.filter((post) => !postsId.includes(post.id));

      const selectedPostsCurrUser: ILinkToPostInstagram[] = posts
        .filter((post) => post.isUsed)
        .map((post) => {
          const currentPostIndex = currentPosts.findIndex(
            (p) => p.id === post.id
          );

          if (currentPostIndex >= 0) {
            if (currentPosts[currentPostIndex].username === undefined) {
              return {
                ...currentPosts[currentPostIndex],
                username: currInstaUsername,
              };
            }
            return currentPosts[currentPostIndex];
          }

          return {
            link: null,
            id: post.id,
            url: post.media_url,
            postUrl: post.permalink,
            username: post.username,
          };
        });

      const selectedPosts = postsDiffUser.concat(selectedPostsCurrUser);

      await Instagram.savePostsSelected(token, selectedPosts);

      navigate(`/apps/${appConfig.id}`);
    } catch (error) {
      newError("Houve um erro ao salvar as publicações");
    } finally {
      hideLoading();
    }
  };

  return (
    <Page>
      <AppFormHeader
        appTitle="Instagram"
        onBack={() => navigate(`/apps/${appConfig.id}`)}
      />

      <Box>
        <TitleDescription
          titleColor={textColor}
          title="Compartilhar post"
          textColor={`${textColor}80`}
        >
          Selecione abaixo quais posts deseja exibir na sua Stanti, em seguida,
          clique em avançar
        </TitleDescription>

        <S.Text
          color={textColor}
          colorWhenSelected={primaryColor}
          selectedPosts={selectedPostsAmount > 0}
        >
          {selectedPostsDescription}
        </S.Text>

        <Button
          textColor={textColor}
          borderColor={textColor}
          disabled={!changeSelection}
          variant={changeSelection ? "solid" : "outline"}
          onClick={changeSelection ? onSaveHandler : () => null}
          backgroundColor={changeSelection ? `${textColor}0d` : "transparent"}
        >
          Avançar
        </Button>
      </Box>

      <S.Images>
        {posts.map((item, index) => (
          <AppImageSelector
            key={item.id}
            images={item.media_url}
            isSelected={item.isUsed}
            onClick={() => onSelectHandler(index)}
          />
        ))}
      </S.Images>
    </Page>
  );
};

export default Form;
