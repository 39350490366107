import styled, { css } from "styled-components";
import InputBase from "../../atoms/InputBase";
import Small from "../../atoms/Typography/Small";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 0.5rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Day = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const InputCheckbox = styled.div`
  display: flex;
  align-items: center;
`;

type SelectedProps = {
  borderColor: string;
};

export const Selected = styled.div<SelectedProps>`
  width: 18px;
  height: 18px;
  overflow: hidden;
  border: 1px solid;
  position: relative;
  border-radius: 4px;
  background-color: rgba(250, 250, 250, 0.2);

  border-color: ${({ borderColor }) => borderColor};
`;

type IndicatorProps = {
  color: string;
  isSelected: boolean;
};

export const Indicator = styled.div<IndicatorProps>`
  border-color: ${({ color }) => color};

  ${({ isSelected, color }) =>
    isSelected &&
    css`
      width: 7px;
      height: 10px;
      border: solid;
      border-radius: 2px;
      border-width: 0 2px 2px 0;
      color: ${color};

      margin-top: 1px;
      margin-left: 5px;

      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
    `}
`;

export const Hours = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: end;
`;

type InputHoursProps = {
  color: string;
};

export const InputHours = styled(InputBase)<InputHoursProps>`
  max-width: 64px;
  font-size: 12px;
  min-height: 34px;
  padding: 0.5rem 1rem;
  text-align: center;
  border-radius: 12px;
  mix-blend-mode: screen;
  background-color: #fafafa0d;
  color: ${({ color }) => color};

  &::placeholder {
    font-size: 12px;
    color: #71726f;
  }
`;

export const Error = styled(Small)`
  margin: 0.5rem 0;
`;
