import styled, { css } from "styled-components";

import Button from "../../atoms/Button";
import P from "../../atoms/Typography/P";
import SeparatorLine from "../../atoms/SeparatorLine";

type ContainerProps = {
  backgroundColor: string;
};

export const Container = styled.div<ContainerProps>`
  ${({ backgroundColor }) => css`
    width: 100%;
    display: flex;
    cursor: pointer;
    user-select: none;
    padding: 12px 20px;
    align-items: center;
    border-radius: 12px;
    flex-direction: column;
    background-color: ${backgroundColor};
  `}
`;

export const SummaryContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CustomerInfo = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: center;
`;

type CircledProps = {
  borderColor: string;
};

export const CircledInitials = styled.div<CircledProps>`
  ${({ borderColor }) => css`
    width: 32px;
    height: 32px;
    display: flex;
    flex-shrink: 0;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 1px solid ${borderColor};
  `}
`;

type CustomerNameProps = {
  isOpen: boolean;
};

export const CustomerName = styled.div<CustomerNameProps>`
  ${({ isOpen }) => css`
    max-width: ${isOpen ? "200px" : "104px"};
  `}
`;

export const ValueAndIcon = styled.div`
  gap: 40px;
  display: flex;
  align-items: center;
`;

export const Value = styled(P)`
  display: flex;
  white-space: nowrap;
`;

type IconProps = {
  isOpen: boolean;
};

export const Icon = styled.div<IconProps>`
  ${({ isOpen }) => css`
    width: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: ${isOpen && "rotate(90deg)"};

    svg {
      width: 100%;
      height: 100%;
    }
  `}
`;

export const Hr = styled(SeparatorLine)`
  margin: 12px 0 20px;
`;

export const DetailsAndContacts = styled.div`
  gap: 12px;
  width: 100%;
  display: flex;
  margin-bottom: 12px;
  flex-direction: column;
`;

export const Title = styled(P)`
  opacity: 0.5;
  line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
`;

export const ContactsAndValue = styled.div`
  display: flex;
  margin-top: 4px;
  align-items: center;
  justify-content: space-between;
`;

export const Buttons = styled.div`
  gap: 12px;
  display: flex;
`;

export const AnchorTag = styled.a`
  width: 100%;
  height: 100%;
`;

export const IconButton = styled(Button)`
  width: 40px;
  height: 40px;
  display: flex;
  cursor: pointer;
  user-select: none;
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
