import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const PeopleIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.334 9.16699C14.7173 9.16699 15.8257 8.05033 15.8257 6.66699C15.8257 5.28366 14.7173 4.16699 13.334 4.16699C11.9507 4.16699 10.834 5.28366 10.834 6.66699C10.834 8.05033 11.9507 9.16699 13.334 9.16699ZM6.66732 9.16699C8.05065 9.16699 9.15898 8.05033 9.15898 6.66699C9.15898 5.28366 8.05065 4.16699 6.66732 4.16699C5.28398 4.16699 4.16732 5.28366 4.16732 6.66699C4.16732 8.05033 5.28398 9.16699 6.66732 9.16699ZM6.66732 10.8337C4.72565 10.8337 0.833984 11.8087 0.833984 13.7503V15.0003C0.833984 15.4587 1.20898 15.8337 1.66732 15.8337H11.6673C12.1257 15.8337 12.5007 15.4587 12.5007 15.0003V13.7503C12.5007 11.8087 8.60898 10.8337 6.66732 10.8337ZM13.334 10.8337C13.0923 10.8337 12.8173 10.8503 12.5257 10.8753C12.5423 10.8837 12.5507 10.9003 12.559 10.9087C13.509 11.6003 14.1673 12.5253 14.1673 13.7503V15.0003C14.1673 15.292 14.109 15.5753 14.0173 15.8337H18.334C18.7923 15.8337 19.1673 15.4587 19.1673 15.0003V13.7503C19.1673 11.8087 15.2757 10.8337 13.334 10.8337Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default PeopleIcon;
