import { Fragment } from "react";
import { Theme } from "../../../../hooks";
import { IAppItem, TIconOptions } from "../../../../types";

import * as S from "./styles";
import EditIcon from "../../../../components/icons/EditIcon";
import H4 from "../../../../componentsV2/atoms/Typography/H4";
import H5 from "../../../../componentsV2/atoms/Typography/H5";

interface IProps {
  appAlias: string;
  appTitle: string;
  items: IAppItem[];
  isDraft?: boolean;
  sectionTitle: string;
  appIcon: TIconOptions;
  onItemClick: (id: string) => void;
}

const StantiPayProduct: React.FC<IProps> = ({ items, onItemClick }) => {
  const { textColor, primaryColor } = Theme.useTheme();

  return (
    <S.Items>
      {items.map((item, index) => {
        return (
          <Fragment key={index}>
            <S.StantiPayProduct onClick={() => item.id && onItemClick(item.id)}>
              <S.IndexProduct>
                <H4 color={primaryColor} fontWeight="600">
                  {index + 1}
                </H4>
              </S.IndexProduct>

              <S.Content>
                <H5 color={textColor} fontWeight="300">
                  {item.title}
                </H5>

                <S.EditIcon>
                  <EditIcon />
                </S.EditIcon>
              </S.Content>
            </S.StantiPayProduct>
          </Fragment>
        );
      })}
    </S.Items>
  );
};

export default StantiPayProduct;
