import { useState } from "react";

import * as S from "./styles";
import { Theme } from "../../../hooks";
import P from "../../atoms/Typography/P";
import AvailableIcons from "../../../components/atoms/AvailableIcons";
import Button from "../../atoms/Button";

interface IProps {
  label?: string;
  options: string[];
  colors?: string[];
  selectedIndex: number;
  userCategories?: string[];
  onSelect: (index: number) => void;
  listType?: "colors" | "text" | "interests";
  removeCategories?: (index: number) => void;
}

const InputSelect: React.FC<IProps> = ({
  label,
  colors,
  options,
  onSelect,
  selectedIndex,
  userCategories,
  listType = "text",
  removeCategories,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { primaryColor, backgroundColor, textColor } = Theme.useTheme();

  return (
    <>
      <S.Container
        isOpen={isOpen}
        listType={listType}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen && <S.Backdrop onClick={() => setIsOpen(false)} />}

        <S.Dropdown
          isOpen={isOpen}
          listType={listType}
          textColor={textColor}
          borderColor={primaryColor}
          backgroundColor={backgroundColor}
        >
          <S.Selected>
            <S.IconAndText>
              {listType === "colors" && (
                <S.Icon color={(colors && colors[selectedIndex]) || ""} />
              )}

              <P color={textColor} fontWeight="regular">
                {!options[selectedIndex] && label
                  ? label
                  : options[selectedIndex]}
              </P>
            </S.IconAndText>

            <S.AheadIcon>
              <AvailableIcons option="chevron" color={primaryColor} />
            </S.AheadIcon>
          </S.Selected>

          {isOpen &&
            options.map((item, index) => {
              if (index !== selectedIndex) {
                return (
                  <li onClick={() => onSelect(index)} key={item}>
                    <S.IconAndText>
                      {listType === "colors" && (
                        <S.Icon color={(colors && colors[index]) || ""} />
                      )}

                      <P color={textColor} fontWeight="regular">
                        {item}
                      </P>
                    </S.IconAndText>
                  </li>
                );
              }
            })}
        </S.Dropdown>
      </S.Container>
      {listType === "interests" && (
        <div>
          {userCategories && userCategories.length > 0 && (
            <P color="#FAFAFA26">Selecionados</P>
          )}

          {userCategories?.map((category, index) => {
            return (
              <S.InterestButton
                as="div"
                variant="outline"
                borderColor="#FAFAFA26"
                key={index}
              >
                <P color="#FFFF">
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </P>

                <Button
                  variant="default"
                  onClick={() => removeCategories?.(index)}
                >
                  <AvailableIcons option="close" />
                </Button>
              </S.InterestButton>
            );
          })}
        </div>
      )}
    </>
  );
};

export default InputSelect;
