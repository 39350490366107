import styled from "styled-components";

export const Container = styled.div`
  gap: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type LabelProps = {
  color?: string;
};

export const Label = styled.label<LabelProps>`
  color: ${({ color }) => (color ? color : "#FAFAFA")};
  font-size: 0.75rem;
`;
