import * as S from "./styles";
import { Theme } from "../../../../../../hooks";
import { TIconOptionsTemp } from "../../../../../../types";
import H2 from "../../../../../../componentsV2/atoms/Typography/H2";
import Small from "../../../../../../componentsV2/atoms/Typography/Small";
import AvailableIconsTemp from "../../../../../../components/atoms/AvailableIconsTemp";

interface IProps {
  value: string;
  label: string;
  tolltip: string;
  iconId: TIconOptionsTemp;
}

const StatsBox: React.FC<IProps> = ({ value, label, tolltip, iconId }) => {
  const { textColor, primaryColor, backgroundColor } = Theme.useTheme();

  return (
    <S.Box backgroundColor={`${textColor}0a`}>
      <S.IconAndTip>
        <S.Icon>
          <AvailableIconsTemp option={iconId} color={primaryColor} />
        </S.Icon>

        <S.Tolltip>
          <S.TipIcon>
            <AvailableIconsTemp option="infoOne" color={`${textColor}29`} />
          </S.TipIcon>

          <S.Tip
            textColor={textColor}
            borderColor={`${textColor}80`}
            backgroundColor={backgroundColor}
          >
            {tolltip}
          </S.Tip>
        </S.Tolltip>
      </S.IconAndTip>

      <S.ValueAndLabel>
        <H2 color={textColor}>{value}</H2>

        <Small color={textColor}>{label}</Small>
      </S.ValueAndLabel>
    </S.Box>
  );
};

export default StatsBox;
