import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import * as S from "./styles";
import { IAppItem } from "../../../../types";
import Page from "../../../../components/atoms/Page";
import Box from "../../../../componentsV2/atoms/Box";
import { App, Auth, Theme } from "../../../../hooks";
import Faq from "../../../../componentsV2/organisms/Faq";
import { duration } from "../../../../constants/calendar";
import { Analytics, Calendar } from "../../../../services";
import appConfig from "../../../../config/app-servico.json";
import P from "../../../../componentsV2/atoms/Typography/P";
import Switch from "../../../../componentsV2/molecules/Switch";
import AddIcon from "../../../../componentsV2/organisms/AddIcon";
import RadioInput from "../../../../componentsV2/atoms/RadioInput";
import AddImages from "../../../../componentsV2/organisms/AddImages";
import InputText from "../../../../componentsV2/molecules/InputText";
import SeparatorLine from "../../../../componentsV2/atoms/SeparatorLine";
import InputNumber from "../../../../componentsV2/molecules/InputNumber";
import InputSelect from "../../../../componentsV2/molecules/InputSelect";
import { Separator } from "../../../../componentsV2/organisms/Faq/styles";
import { ICalendar, TSchedulingOption } from "../../../../types/calendar";
import AppFormPrice from "../../../../componentsV2/organisms/AppFormPrice";
import AppFormHeader from "../../../../componentsV2/organisms/AppFormHeader";
import TitleDescription from "../../../../componentsV2/molecules/TitleDescription";
import AppFormFooter from "../../../../componentsV2/organisms/AppFormFooter";

const validate = (data: IAppItem): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.title)
    errors.title = [...(errors.title || []), "Título obrigatório"];

  if (!data.price || data.price < 1)
    errors.price = [...(errors.price || []), "O valor mínimo é R$ 1"];

  if (!data.serviceType)
    errors.serviceType = [
      ...(errors.serviceType || []),
      "Selecione o tipo de serviço",
    ];

  if (data.schedulingOption === "agenda") {
    if (!data.duration)
      errors.duration = [
        ...(errors.duration || []),
        "A seleção da duração é obrigatória",
      ];

    if (!data.numberOfSessions || data.numberOfSessions < 1)
      errors.numberOfSessions = [
        ...(errors.numberOfSessions || []),
        "Número de sessões deve ser maior ou igual a 1",
      ];
  }

  return errors;
};

const normalizeData = (
  data: IAppItem,
  schedulingOption?: TSchedulingOption
): IAppItem => {
  const newData: { [key: string]: any } = { ...data };

  if (schedulingOption === "no-date") {
    delete newData.time;
    delete newData.dates;
    delete newData.duration;
    delete newData.numberOfSessions;
  }

  const arrayDataToBeChecked = ["faq", "media", "tags"];

  arrayDataToBeChecked.forEach((field) => {
    if (!newData[field] || !newData[field].length) delete newData[field];
  });

  return { ...newData, schedulingOption };
};

const Form: React.FC = () => {
  const { id } = useParams();
  const { primaryColor, textColor } = Theme.useTheme();

  const [item, setItem] = useState<IAppItem>({});
  const [schedulingOption, setSchedulingOption] =
    useState<TSchedulingOption>("no-date");
  const [calendar, setCalendar] = useState<ICalendar | null>(null);
  const [isSwitchOn, setIsSwitchOn] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

  const { user } = Auth.useAuth();

  const navigate = useNavigate();
  const { token } = Auth.useAuth();
  const { getItem, saveItemHandler, removeItemHandler } = App.useApp();

  useEffect(() => {
    const run = async () => {
      const [itemData, calendar] = await Promise.all([
        id ? getItem(appConfig.id, id) : null,
        Calendar.getCalendar(token),
      ]);

      setItem(itemData || {});
      setCalendar(calendar || null);

      setSchedulingOption(itemData?.schedulingOption || "no-date");
      setIsSwitchOn(itemData?.schedulingOption === "agenda");
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getItem, id]);

  const onSave = useCallback(
    (isDraft: boolean) => {
      const normalizedData = normalizeData(item, schedulingOption);

      if (!isDraft) {
        const currErrors = validate(normalizedData);

        if (currErrors && Object.keys(currErrors).length) {
          setErrors(currErrors);

          window.scrollTo({ top: 0, behavior: "smooth" });
          return alert(
            "O cadastro possui erros, por favor verifique os campos para continuar"
          );
        }
      }
      const redirectPath = isDraft && !calendar ? "/calendar" : "/";

      saveItemHandler(
        appConfig.id,
        normalizedData,
        isDraft,
        id,
        null,
        redirectPath
      );
    },
    [item, schedulingOption, calendar, saveItemHandler, id]
  );

  const onChangeHandler = (value: any, field: keyof IAppItem) => {
    if (errors[field])
      setErrors((curr) => {
        const newErrors = { ...curr };
        delete newErrors[field];

        return newErrors;
      });

    setItem((curr) => ({ ...curr, [field]: value }));
  };

  const updateMediaHandler = (index: number, imageUrl: string) => {
    setItem((curr) => {
      const newItem = {
        ...curr,
      };

      if (newItem.media) newItem.media[index] = imageUrl;

      return newItem;
    });
  };

  const removeMediaHandler = (index: number) => {
    setItem((curr) => {
      const newMedia = curr.media || [];
      newMedia.splice(index, 1);

      return { ...curr, media: newMedia };
    });
  };

  const addMediaHandler = (newImage: string) => {
    setItem((curr) => ({
      ...curr,
      media: [...(curr.media || []), newImage],
    }));
  };

  const onChangeTags = (val: string) => {
    if (!val) return onChangeHandler([], "tags");

    const tags = val.split(" ");

    if (tags.length > 3) {
      return;
    }

    if (tags[tags.length - 1] === "") {
      if (item.tags && item.tags[item.tags.length - 1] !== "#") {
        return onChangeHandler([...item.tags, "#"], "tags");
      } else tags.pop();
    }

    const normalizedTags = tags.map(
      (tag) => `#${tag.replace(/([^a-zA-Z0-9])/g, "").toLowerCase()}`
    );

    onChangeHandler(normalizedTags, "tags");
  };

  const onAddCalendarClick = () => {
    Analytics.submitClickEvent({
      origin: appConfig.id,
      creator: user.id || "",
      description: "set-my-agenda",
      actionDescription: "open-my-agenda",
      creatorUsername: user.username || "",
    });

    onSave(true);

    navigate("/calendar");
  };

  const switchChangeHandler = () => {
    setIsSwitchOn(!isSwitchOn);

    if (item.schedulingOption === "agenda") {
      return setSchedulingOption("no-date");
    }

    setSchedulingOption("agenda");
  };

  const isEditingItem = !!id;

  const durationOptions = duration.map(
    (durationItem) => `${durationItem.text} de duração por sessão`
  );

  const durationSelectedIndex = duration.findIndex(
    (durationItem) => durationItem.durationFormat === item.duration
  );

  const serviceTypeOptions = ["Online", "Presencial"];

  const serviceTypeSelected =
    (item?.serviceType || "").charAt(0).toUpperCase() +
    (item?.serviceType || "").slice(1);

  const onSelectServiceType = (index: number) => {
    const valueSelected = serviceTypeOptions[index].toLocaleLowerCase();

    onChangeHandler(valueSelected, "serviceType");
  };

  return (
    <Page bgColor="#080808">
      <AppFormHeader
        appTitle={appConfig.title}
        onBack={() => navigate(`/apps`)}
      />

      <Box>
        <S.LabelService color={primaryColor}>Tipo de serviço</S.LabelService>

        <RadioInput
          options={serviceTypeOptions}
          onSelect={(val) => onSelectServiceType(val)}
          selectedIndex={serviceTypeOptions.indexOf(serviceTypeSelected)}
        />
      </Box>

      <Box>
        <InputText
          limit
          label="Título"
          errors={errors.title}
          value={item.title || ""}
          onChange={(val) => onChangeHandler(val, "title")}
          placeholder={`Nome do ${appConfig.title.toLowerCase()}`}
        />
        <SeparatorLine />

        <InputText
          limit
          as="textarea"
          charLimit={500}
          label="Descrição"
          errors={errors.description}
          value={item.description || ""}
          onChange={(val) => onChangeHandler(val, "description")}
          placeholder="Uma breve descrição e sobre o que o usuário irá encontrar neste produto/serviço"
        />
        <SeparatorLine />

        <AddIcon
          errors={errors.icon}
          icon={item.image || ""}
          onChange={(image) => onChangeHandler(image, "image")}
          onClear={() =>
            setItem((curr) => ({ ...curr, buttonImage: "", image: "" }))
          }
        />
      </Box>

      <Box>
        <Switch
          labelSize="small"
          isOn={isSwitchOn}
          color={primaryColor}
          label="Datas, horários e agendamentos"
          setIsOn={switchChangeHandler}
        />

        {isSwitchOn ? (
          <>
            <P color={textColor} fontWeight="regular">
              Ao selecionar esta opção, você disponibilizará seus horários
              definidos previamente por você no menu Editar Perfil.
            </P>

            {calendar ? (
              <S.AgendaOptions>
                <InputNumber
                  labelColor={primaryColor}
                  label="Quantidade de sessões"
                  value={item.numberOfSessions ?? 0}
                  onChange={(val: any) =>
                    onChangeHandler(val, "numberOfSessions")
                  }
                />

                <Separator color="#fafafa" />

                <S.Label color={primaryColor} fontWeight="regular">
                  Selecione a duração de casa sessão
                </S.Label>

                <InputSelect
                  label="Selecione a opção"
                  options={durationOptions}
                  selectedIndex={durationSelectedIndex || 0}
                  onSelect={(index) => {
                    onChangeHandler(duration[index].durationFormat, "duration");
                  }}
                />
              </S.AgendaOptions>
            ) : (
              <S.BoxContent>
                <TitleDescription
                  centralize={true}
                  titleColor={textColor}
                  textColor={`${textColor}80`}
                  title="Nenhum horário cadastrado"
                >
                  Você ainda não possui horários cadastrados. Clique no botão
                  abaixo para definir sua agenda de trabalho para agendamentos.
                </TitleDescription>

                <S.AddCalendar
                  variant="solid"
                  onClick={() => onAddCalendarClick()}
                >
                  Cadastrar meus horários
                </S.AddCalendar>
              </S.BoxContent>
            )}

            {(errors.duration || errors.numberOfSessions) &&
              [...errors.duration, errors.numberOfSessions].length && (
                <S.ErrorMessage color="#FF4D4F">
                  Erros:{" "}
                  {[...errors.duration, errors.numberOfSessions].join(", ")}
                </S.ErrorMessage>
              )}
          </>
        ) : null}
      </Box>

      <AddImages
        errors={errors.media}
        images={item.media || []}
        onChange={(newImage) => addMediaHandler(newImage)}
        onRemoveImage={(index) => removeMediaHandler(index)}
        onUpdateImage={(index, imageUrl) => updateMediaHandler(index, imageUrl)}
      />

      <Faq
        onChange={(val) => onChangeHandler(val, "faq")}
        faqs={item.faq || []}
      />

      <Box>
        <InputText
          label="Tags"
          value={(item.tags || []).join(" ")}
          onChange={onChangeTags}
          placeholder="Adicione até 3 tags separando com espaço"
        />
      </Box>

      <AppFormPrice
        appId={appConfig.id}
        errors={errors.price}
        value={item.price || 0}
        onChange={(val) => onChangeHandler(val, "price")}
      />

      <AppFormFooter
        onSave={onSave}
        saveDraft={true}
        appTitle={appConfig.title}
        isEditingItem={isEditingItem}
        onRemove={() => id && removeItemHandler(appConfig.id, id)}
      />
    </Page>
  );
};

export default Form;
