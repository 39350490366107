import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const LinesChartIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.324609 10.3171L0.249609 10.2421C-0.0753906 9.9171 -0.0753906 9.3921 0.249609 9.0671L5.32461 3.98376C5.64961 3.65876 6.17461 3.65876 6.49961 3.98376L9.24128 6.72543L14.5663 0.742097C14.8829 0.383764 15.4413 0.375431 15.7746 0.708764C16.0829 1.02543 16.0996 1.52543 15.8079 1.85043L9.83294 8.57543C9.51628 8.93376 8.96628 8.95043 8.62461 8.60876L5.91628 5.90043L1.49961 10.3171C1.18294 10.6421 0.649609 10.6421 0.324609 10.3171ZM1.49961 15.3171L5.91628 10.9004L8.62461 13.6088C8.96628 13.9504 9.51628 13.9338 9.83294 13.5754L15.8079 6.85043C16.0996 6.52543 16.0829 6.02543 15.7746 5.70876C15.4413 5.37543 14.8829 5.38376 14.5663 5.7421L9.24128 11.7254L6.49961 8.98376C6.17461 8.65876 5.64961 8.65876 5.32461 8.98376L0.249609 14.0671C-0.0753906 14.3921 -0.0753906 14.9171 0.249609 15.2421L0.324609 15.3171C0.649609 15.6421 1.18294 15.6421 1.49961 15.3171Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default LinesChartIcon;
