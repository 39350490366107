import styled, { css } from "styled-components";

import Box from "../../../../componentsV2/atoms/Box";
import Button from "../../../../componentsV2/atoms/Button";
import H4 from "../../../../componentsV2/atoms/Typography/H4";
import ProfileImage from "../../../../componentsV2/atoms/ProfileImage";
import SeparatorLine from "../../../../componentsV2/atoms/SeparatorLine";

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 108px;
  flex-direction: column;
  overflow-x: hidden;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
`;

type CoverProps = {
  background?: string;
  backgroundColor: string;
};

export const CoverImage = styled.div<CoverProps>`
  ${({ background, backgroundColor }) => css`
    width: 100%;
    height: 300px;
    opacity: 0.56;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundColor};
    background: ${background && `url(${background})`};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    svg {
      width: 24px;
      height: 24px;
    }

    input[type="file"] {
      top: 0;
      right: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      position: absolute;
    }
  `}
`;

export const ProfileAndEdit = styled.div`
  width: 100%;
  display: flex;
  max-width: 960px;
  padding-left: 20px;
  padding-right: 24px;
  align-items: flex-end;
  justify-content: space-between;
`;

type ProfileImageProps = {
  borderColor: string;
};

export const ProfileImageBox = styled.div<ProfileImageProps>`
  ${({ borderColor }) => css`
    width: 126px;
    padding: 2px;
    height: 126px;
    display: flex;
    margin-top: -63px;
    border-radius: 50%;
    position: relative;
    align-items: center;
    align-self: center;
    justify-content: center;
    border: 2px solid ${borderColor};

    svg {
      width: 24px;
      height: 24px;
      position: absolute;
    }

    input[type="file"] {
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      position: absolute;
    }
  `}
`;

export const Image = styled(ProfileImage)`
  position: absolute;
`;

export const EditIcon = styled.div`
  width: 16px;
  z-index: 10;
  height: 16px;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Categories = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
`;

type CategorieProps = {
  backgroundColor: string;
};

export const Categorie = styled.div<CategorieProps>`
  ${({ backgroundColor }) => css`
    display: flex;
    padding: 2px 8px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundColor};
  `}
`;

export const UserInformation = styled.div`
  gap: 20px;
  top: -40px;
  width: 100%;
  display: flex;
  margin-top: 24px;
  max-width: 960px;
  position: relative;
  flex-direction: column;
  padding: 0 20px 0 168px;

  @media (max-width: 960px) {
    top: 0;
    padding: 0 20px;
  }
`;

export const InfoAndSocialMedia = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const BasicInfo = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const TitleH4 = styled(H4)`
  cursor: pointer;
  user-select: none;
  text-decoration: underline;
`;

export const SocialMedia = styled.div`
  gap: 8px;
  display: flex;

  svg {
    width: 28px;
    height: 28px;
  }
`;

export const Followers = styled(Box)`
  gap: 0;
  height: 100%;
  display: flex;
  max-width: 400px;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    margin-top: -4px;
  }
`;

type FollowersBoxProps = {
  separator: boolean;
  separatorColor?: string;
};

export const FollowersBox = styled.div<FollowersBoxProps>`
  ${({ separator, separatorColor }) => css`
    gap: 8px;
    width: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: ${separator && `1px solid ${separatorColor}`};
  `}
`;

export const FollowersIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 32px;
    height: 32px;
  }
`;

export const FollowersText = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
`;

export const Separator = styled(SeparatorLine)`
  margin: 40px 0;
`;

export const Stats = styled.div`
  width: 100%;
  display: flex;
  max-width: 960px;
  flex-direction: column;
  padding: 0 20px 0 168px;

  @media (max-width: 960px) {
    padding: 0 20px;
  }
`;

type BoxesProps = {
  minmax: string;
};

export const Boxes = styled.div<BoxesProps>`
  ${({ minmax }) => css`
    gap: 12px;
    width: 100%;
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(auto-fill, minmax(${minmax}, 1fr));
  `}
`;

export const LocationBoxes = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
`;

export const Public = styled.div`
  gap: 12px;
  width: 100%;
  display: flex;
  max-width: 960px;
  margin-top: 32px;
  flex-direction: column;
  padding: 0 20px 0 168px;

  @media (max-width: 960px) {
    padding: 0 20px;
  }
`;

export const Brands = styled.div`
  width: 100%;
  display: flex;
  max-width: 960px;
  flex-direction: column;
  padding: 0 20px 0 168px;

  @media (max-width: 960px) {
    padding: 0 20px;
  }
`;

export const TitleAndEditBrands = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;

  svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;

export const EditButton = styled(Button)`
  margin-top: 20px;
`;

export const Posts = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CallToActionSocialMedia = styled.div`
  width: 100%;
  display: flex;
  max-width: 960px;
  margin-bottom: 20px;
  flex-direction: column;
  padding: 0 20px 0 168px;

  @media (max-width: 960px) {
    padding: 0 20px;
  }
`;

export const ConnectSocialMedia = styled(Box)`
  margin: 12px 0;
`;

type SocialMediaButtonProps = {
  textColor: string;
};

export const SocialMediaButton = styled(Button)<SocialMediaButtonProps>`
  ${({ textColor }) => css`
    gap: 12px;
    display: flex;
    align-items: center;
    flex-direction: row;
    color: ${textColor};
    justify-content: center;
  `}
`;

export const Icon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
