import * as S from "./styles";
import P from "../Typography/P";
import { Theme } from "../../../hooks";

interface IProps {
  options: string[];
  selectedIndex?: number;
  onSelect: (index: number) => void;
}

const RadioInput: React.FC<IProps> = ({ options, onSelect, selectedIndex }) => {
  const { primaryColor, textColor } = Theme.useTheme();

  return (
    <S.Container>
      {options.map((item, index) => (
        <S.OptionBox
          key={item}
          onClick={() => onSelect(index)}
          backgroundColor={`${textColor}0a`}
        >
          <S.SelectorOut backgroundOut={`${textColor}33`}>
            <S.SelectorIn
              backgroundIn={primaryColor}
              isSelected={index === selectedIndex}
            />
          </S.SelectorOut>

          <P color={textColor} fontWeight="regular">
            {item}
          </P>
        </S.OptionBox>
      ))}
    </S.Container>
  );
};

export default RadioInput;
