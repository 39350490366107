import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import * as S from "./styles";
import { IAppItem } from "../../types";
import Header from "./components/Header";
import Banners from "./components/Banners";
import { Apps, Creator, MediaKit } from "../../services";
import Box from "../../componentsV2/atoms/Box";
import Page from "../../components/atoms/Page";
import ProfileBody from "./components/ProfileBody";
import CallIcon from "../../components/icons/CallIcon";
import LinkIcon from "../../components/icons/LinkIcon";
import AddContent from "../../components/molecules/AddContent";
import Navigation from "../../componentsV2/molecules/Navigation";
import MentorshipIcon from "../../components/icons/MentorshipIcon";
import OnlineCallIcon from "../../components/icons/OnlineCallIcon";
import SocialMediaIcon from "../../components/icons/SocialMediaIcon";
import { App, Auth, Loading, Snackbar, StantiPay } from "../../hooks";
import BookDownloadIcon from "../../components/icons/BookDownloadIcon";
import ImageGalleryIcon from "../../components/icons/ImageGalleryIcon";
import InstagramTwoIcon from "../../components/icons/InstagramTwoIcon";
import CopyLinkModal from "../../componentsV2/organisms/CopyLinkModal";
import PrivateGroupsIcon from "../../components/icons/PrivateGroupsIcon";

const appBoxes = [
  {
    label: "Links",
    route: "link-externo",
    icon: <LinkIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
  },
  {
    icon: <CallIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
    label: "Serviço",
    route: "servico",
  },
  {
    route: "mentoria",
    label: "Mentorias",
    icon: <MentorshipIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
  },
  {
    route: "arquivo",
    label: "Ebooks e Arquivos",
    icon: (
      <BookDownloadIcon blendMode="overlay" opacity={0.9} color="#fafafa" />
    ),
  },
  {
    route: "grupo",
    label: "Grupos Privados",
    icon: (
      <PrivateGroupsIcon blendMode="overlay" opacity={0.9} color="#fafafa" />
    ),
  },
  {
    route: "arquivo/course/new",
    label: "Cursos Gravados",
    icon: <OnlineCallIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
  },
  {
    label: "Fotos e Imagens",
    route: "galeria-imagens",
    icon: (
      <ImageGalleryIcon blendMode="overlay" opacity={0.9} color="#fafafa" />
    ),
  },
  {
    label: "Post do Instagram",
    route: "link-to-post-instagram",
    icon: (
      <InstagramTwoIcon blendMode="overlay" opacity={0.9} color="#fafafa" />
    ),
  },
  {
    route: "redes-sociais",
    label: "Redes sociais",
    icon: <SocialMediaIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
  },
];

const { REACT_APP_STOREFRONT_BASE_URL } = process.env;

const Home: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [appItems, setAppItems] = useState<IAppItem[]>([]);

  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [isCopyModalOpen, setIsCopyModalOpen] = useState<boolean>(false);

  const [initialAppItems, setInitialAppItems] = useState<IAppItem[]>([]);

  const navigate = useNavigate();
  const { search } = useLocation();
  const { saveItemHandler } = App.useApp();
  const { user, token, setUserHandler } = Auth.useAuth();
  const { saveProductHandler } = StantiPay.useStantiPay();
  const { hideLoading, showLoading } = Loading.useLoading();
  const { newError, newSuccess, newInfo } = Snackbar.useSnackbar();

  const getAppItems = async () => {
    try {
      if (user.id) {
        const items = await Apps.getAllAppItems(token);

        setAppItems(items);
        setInitialAppItems(items);
      }
    } catch (error) {
      newError("Houve um erro ao obter os itens");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");

    if (!code) return;

    const run = async () => {
      try {
        showLoading();
        const creator = await Creator.linkWithMercadoPago(code, token);
        setUserHandler(creator);

        const [, appId, itemId] = (state || "").split("/");

        appId === "stanti-pay"
          ? await saveProductHandler({}, itemId, creator.mercadoPagoIntegration)
          : await saveItemHandler(
              appId,
              {},
              false,
              itemId,
              creator.mercadoPagoIntegration
            );

        getAppItems();

        newSuccess("Conta vinculada com sucesso");
        navigate(`/`);
      } catch (error) {
        newError("Houve um erro ao conectar ao Mercado Pago");
      } finally {
        hideLoading();
      }
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    getAppItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id, token]);

  useEffect(() => {
    const run = async () => {
      if (user.id && !search) {
        const creator = await Creator.getCreator(user.id, token);

        setUserHandler(creator);
      }
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPublish = async (appItem: IAppItem) => {
    if (
      !appItem.isDraft &&
      !user.mercadoPagoIntegration &&
      [
        "mentoria",
        "curso-live",
        "arquivo",
        "grupo",
        "servico",
        "stanti-pay",
      ].includes(appItem.app || "")
    ) {
      newInfo("Conecte sua conta do Mercado Pago.");
      return navigate(`/mercado-pago/${appItem.app}/${appItem.id}`);
    }

    const updatedItem = { ...appItem };
    delete updatedItem.id;
    delete updatedItem.app;

    try {
      showLoading();
      if (appItem.app === "media-kit") {
        await MediaKit.savePersonalInfo(token, {
          visibleOnStorefront: !appItem.isDraft,
        });
      } else {
        await Apps.updateAppItem(
          appItem.app || "",
          appItem.id || "",
          token,
          updatedItem
        );
      }

      await getAppItems();
    } catch (error) {
      newError((error as { message: string }).message);
    } finally {
      hideLoading();
    }
  };

  return (
    <Page>
      <Header />

      <S.NavigationContainer>
        <Navigation
          iconId="link"
          widthAheadIcon="14px"
          aheadIconId="openPage"
          textSecondColor={user.username}
          onClick={() => setIsCopyModalOpen(true)}
          textFirstColor={`${REACT_APP_STOREFRONT_BASE_URL}/`}
        />

        <Navigation
          iconId="calendar"
          onClick={() => navigate("/calendar")}
          textFirstColor="Ver seus horários e agendamentos"
        />
      </S.NavigationContainer>

      {appItems.length > 0 && <S.HomeSeparatorLine />}

      <Banners />

      <ProfileBody
        loading={loading}
        appItems={appItems}
        onPublish={onPublish}
        setAppItems={setAppItems}
        initialAppItems={initialAppItems}
        setInitialAppItems={setInitialAppItems}
      />

      <Box>
        <AddContent items={appBoxes} sectionTitle="ATALHOS" />
      </Box>

      {isCopyModalOpen && (
        <S.Modal
          onClick={() => {
            setIsCopied(false);
            setIsCopyModalOpen(false);
          }}
        >
          <CopyLinkModal
            isCopied={isCopied}
            setIsCopied={() => setIsCopied(true)}
          />
        </S.Modal>
      )}
    </Page>
  );
};

export default Home;
