import { useState } from "react";

import * as S from "./styles";
import { Theme } from "../../../../../../../../hooks";
import P from "../../../../../../../../componentsV2/atoms/Typography/P";
import { ISocialMediaEntity } from "../../../../../../../../types/media-kit";
import { calculatePercentageLocation } from "../../../../../../../../utils/media-kit";

interface IProps {
  data: ISocialMediaEntity["location"] | undefined;
}

const LocationTable: React.FC<IProps> = ({ data }) => {
  const [text, setText] = useState<string>("mais");
  const [sliceValue, setSliceValue] = useState<number>(7);

  const { primaryColor, textColor } = Theme.useTheme();

  const onClickSeeMore = () => {
    if (text === "mais") {
      setSliceValue((curr) => (data && data.length) || curr);
      setText("menos");
    } else {
      setSliceValue(7);
      setText("mais");
    }
  };

  const normalizedData = calculatePercentageLocation(data);

  return (
    <S.Container>
      {normalizedData &&
        normalizedData?.slice(0, sliceValue).map((item) => (
          <S.Text key={item.city}>
            <S.City color={textColor}>
              {item.city}, {item.state}
            </S.City>

            <S.Points color={`${textColor}29`} />

            <P color={textColor}>{item.value}</P>
          </S.Text>
        ))}

      <S.SeeMoreBox onClick={() => onClickSeeMore()}>
        <S.SeeMore color={primaryColor}>ver {text}</S.SeeMore>
      </S.SeeMoreBox>
    </S.Container>
  );
};

export default LocationTable;
