import styled, { css } from "styled-components";
import { Theme } from "../../../hooks";
import Button from "../../atoms/Button";

export const Container = styled.div`
  margin-top: 2rem;
`;

export const InputTextArea = styled.div`
  position: relative;
  background: #fafafa05;
  border-radius: 12px;
  padding: 1.25rem;
`;

export const ButtonDelete = styled(Button)`
  ${() => {
    const { primaryColor } = Theme.useTheme();

    return css`
      position: absolute;
      right: -10px;
      top: 0;
      bottom: 0;
      margin: auto;
      border: 1px solid ${primaryColor};
    `;
  }}
`;

export const ButtonAddQuestion = styled(Button)`
  margin-top: 1.25rem;
`;

type SeparatorProps = {
  color: string;
};

export const Separator = styled.span<SeparatorProps>`
  ${({ color }) => css`
    content: "";
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    border-left: 1px dashed ${color};
    width: 1px;
    height: 0.75rem;
    margin: auto;
  `}
`;
