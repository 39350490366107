import { useNavigate, useParams } from "react-router-dom";

import Page from "../../components/atoms/Page";
import AppVideo from "../../componentsV2/organisms/AppVideo";
import AppFormHeader from "../../componentsV2/organisms/AppFormHeader";
import TitleDescription from "../../componentsV2/molecules/TitleDescription";
import MercadoPagoIntegration from "../../componentsV2/molecules/MercadoPagoIntegration";

const { REACT_APP_OAUTH_MERCADO_PAGO } = process.env;

import * as S from "./styles";

const AppLinkMercadoPagoAccount: React.FC = () => {
  const navigate = useNavigate();
  const { appId, itemId } = useParams();

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const appConfig = require(`../../config/app-${appId}`);

  if (!appConfig) navigate("/");

  return (
    <Page bgColor="#080808">
      <AppFormHeader
        appTitle="Mercado Pago"
        onBack={() => navigate(`/apps/${appId}/${itemId}`)}
      />

      <S.Card>
        <AppVideo
          thumbUrl="https://production-stanti-apps-images.s3.amazonaws.com/didi-mp-thumb.jpg"
          videoUrl="https://production-stanti-apps-images.s3.amazonaws.com/_Didi_MercadoPago2.mp4"
        />

        <S.Content>
          <TitleDescription title="Conecte-se e monetize!">
            Para receber pagamentos pela Stanti é necessário que você vincule
            sua conta ao <strong>Mercado Pago</strong>.
          </TitleDescription>

          <MercadoPagoIntegration
            hasLinkedAccount={false}
            onClick={() =>
              (window.location.href = `${REACT_APP_OAUTH_MERCADO_PAGO}&state=mercado-pago/${appId}/${itemId}?linked=true`)
            }
          />
        </S.Content>
      </S.Card>
    </Page>
  );
};

export default AppLinkMercadoPagoAccount;
