import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const WhatsappTwoIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4538 2.47031C12.8638 0.876562 10.7464 0 8.49621 0C3.85156 0 0.0720982 3.77946 0.0720982 8.42411C0.0720982 9.90781 0.459152 11.3574 1.19531 12.6362L0 17L4.46629 15.8275C5.69576 16.4991 7.0808 16.852 8.49241 16.852H8.49621C13.1371 16.852 17 13.0725 17 8.4279C17 6.17768 16.0438 4.06406 14.4538 2.47031ZM8.49621 15.4328C7.23638 15.4328 6.00312 15.0951 4.92924 14.4576L4.675 14.3058L2.02634 15.0002L2.73214 12.4161L2.56518 12.1504C1.86317 11.0348 1.49509 9.74844 1.49509 8.42411C1.49509 4.56496 4.63705 1.42299 8.5 1.42299C10.3708 1.42299 12.1277 2.15156 13.4482 3.47589C14.7688 4.80022 15.5808 6.55714 15.577 8.4279C15.577 12.2908 12.3554 15.4328 8.49621 15.4328ZM12.3364 10.1886C12.1277 10.0824 11.0917 9.57388 10.8982 9.50558C10.7047 9.43348 10.5643 9.39933 10.4239 9.61183C10.2835 9.82433 9.88125 10.2949 9.75603 10.4391C9.6346 10.5795 9.50938 10.5984 9.30067 10.4922C8.06362 9.87366 7.25156 9.38795 6.43571 7.98772C6.21942 7.61585 6.65201 7.64241 7.05424 6.83795C7.12254 6.69754 7.08839 6.57612 7.03527 6.46987C6.98214 6.36362 6.56094 5.32768 6.38638 4.90647C6.21563 4.49665 6.04107 4.55357 5.91205 4.54598C5.79063 4.53839 5.65022 4.53839 5.50982 4.53839C5.36942 4.53839 5.14174 4.59152 4.94821 4.80022C4.75469 5.01272 4.21205 5.52121 4.21205 6.55714C4.21205 7.59308 4.96719 8.59487 5.06964 8.73527C5.17589 8.87567 6.55335 11.0007 8.66696 11.9152C10.0027 12.492 10.5263 12.5413 11.1942 12.4426C11.6002 12.3819 12.4388 11.9342 12.6134 11.4408C12.7879 10.9475 12.7879 10.5263 12.7348 10.4391C12.6855 10.3442 12.5451 10.2911 12.3364 10.1886Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default WhatsappTwoIcon;
