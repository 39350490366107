import { useEffect, useState } from "react";

import Page from "../../../../components/atoms/Page";
import Button from "../../../../componentsV2/atoms/Button";
import ProgressBar from "../../../../componentsV2/atoms/ProgressBar";
import InputText from "../../../../componentsV2/molecules/InputText";
import { LOCAL_STORAGE_AUTHENTICATION_METHOD } from "../../../../constants";
import { Auth, GA, Theme } from "../../../../hooks";
import { Analytics, Creator } from "../../../../services";
import { TSteps } from "../../../../types";
import { maskPhoneNumber } from "../../../../utils/numbers";

import * as S from "./styles";

export interface IInformationStepForm {
  name: string;
  email: string;
  phone: string;
  password: string;
}

const authenticationMethod =
  localStorage.getItem(LOCAL_STORAGE_AUTHENTICATION_METHOD) || "";

const PhoneStep: React.FC = () => {
  const [phone, setPhone] = useState<string>("");
  const isCellphoneValid = phone && phone.length >= 11;

  const { sendEvents } = GA.useGA();
  const { textColor } = Theme.useTheme();
  const { user, setUserHandler, token, logout } = Auth.useAuth();

  useEffect(() => {
    Analytics.submitPageViewEvent({
      creator: user.id || "",
      origin: document.referrer,
      description: "information-step",
      creatorUsername: user.username || "",
      actionDescription: "phone-information-step",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNextHandler = async () => {
    setPhone(phone.trim());

    const payload = {
      phone: phone,
      formStep: "profile-image" as TSteps,
    };

    (Object.keys(payload) as (keyof typeof payload)[]).forEach((key) => {
      if (!payload[key]) delete payload[key];
    });

    Analytics.submitClickEvent({
      creator: user.id || "",
      origin: document.referrer,
      creatorUsername: user.username || "",
      description: "information-step-concluded",
      actionDescription: `information-step-concluded-${user.username}`,
    });

    const authentication = authenticationMethod.split("-")[2];

    sendEvents({
      name: "Lead",
      buttonLocation: "login",
      category: `${authentication}-button`,
    });
    window.fbq("track", "Lead");

    const updateUser = await Creator.updateProfile(payload, token);

    setUserHandler(updateUser);
  };

  return (
    <Page>
      <S.PhoneStepWrapper>
        <S.Container>
          <S.RegisterFormHeader>
            <S.Back variant="solid" color="#fafafa05" onClick={logout}>
              Sair
            </S.Back>
          </S.RegisterFormHeader>

          <S.ProgressBar>
            <S.FormTitle color={textColor}>Criando sua Stanti</S.FormTitle>

            <ProgressBar progress={(1.5 / 6) * 100} />
          </S.ProgressBar>

          <S.FormSubtitle color="#fafafa">
            Para continuar, informe seu telefone:
          </S.FormSubtitle>
          <S.Form>
            <InputText
              type="tel"
              pattern="[0-9]*"
              inputMode="numeric"
              label="Celular (Whatsapp)"
              placeholder="Digite seu Whatsapp"
              value={maskPhoneNumber(phone)}
              status={isCellphoneValid ? "success" : ""}
              onChange={(val) => setPhone((val || "").replace(/[^0-9]/g, ""))}
            />
          </S.Form>

          <Button
            variant="outline"
            textColor="#ddff66"
            borderColor="#ddff66"
            disabled={!isCellphoneValid}
            onClick={onNextHandler}
          >
            Avançar
          </Button>
        </S.Container>
      </S.PhoneStepWrapper>
    </Page>
  );
};

export default PhoneStep;
