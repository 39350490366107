import styled from "styled-components";
import Box from "../../componentsV2/atoms/Box";

export const Card = styled(Box)`
  gap: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
