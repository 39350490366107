import * as S from "./styles";
import { Theme } from "../../../hooks";
import P from "../../atoms/Typography/P";
import Small from "../../atoms/Typography/Small";
import { IOrder, TIconOptions } from "../../../types";
import { IIncomeReport } from "../../../services/order";
import { normalizeNumber } from "../../../utils/numbers";
import { normalizeDateToLocale } from "../../../utils/dates";
import AvailableIcons from "../../../components/atoms/AvailableIcons";
import { getWhatsappUrl } from "../../../pages/Calendar/components/Appointment/utils";

interface IProps {
  sale: IOrder;
  isOpen: boolean;
  onClick: () => void;
  productsWithSales: IIncomeReport["totalizers"]["productsWithSales"];
}

const CollapsableOrder: React.FC<IProps> = ({
  sale,
  isOpen,
  onClick,
  productsWithSales,
}) => {
  const { primaryColor, textColor } = Theme.useTheme();

  const normalizedValue = `R$ ${normalizeNumber(sale.value)}`;
  const normalizedDate = normalizeDateToLocale(sale.createdAt);

  const customerInitials = (() => {
    const splittedName = sale.name.trim().split(" ");

    const firstInitial = splittedName[0].substring(0, 1).toUpperCase();
    const lastInitial =
      splittedName.length > 1
        ? splittedName[splittedName.length - 1].substring(0, 1).toUpperCase()
        : "";

    return firstInitial + lastInitial;
  })();

  const whatsappUrl = getWhatsappUrl(sale.phone);

  const contacts = [
    { option: "whatsappTwo", href: whatsappUrl },
    { option: "email", href: `mailto:${sale.email}` },
    { option: "phone", href: `tel:${sale.phone}` },
  ];

  const serviceTitle = productsWithSales.find(
    (product) => product.id === sale.productId
  )?.title;

  return (
    <S.Container backgroundColor={`${textColor}05`}>
      <S.SummaryContainer onClick={onClick}>
        <S.CustomerInfo>
          <S.CircledInitials borderColor={primaryColor}>
            <Small color={textColor}>{customerInitials}</Small>
          </S.CircledInitials>

          <S.CustomerName isOpen={isOpen}>
            <P color={textColor} fontWeight="regular" ellipsis={true}>
              {sale.name}
            </P>
          </S.CustomerName>
        </S.CustomerInfo>

        <S.ValueAndIcon>
          {!isOpen && (
            <S.Value color={primaryColor} fontWeight="regular">
              {normalizedValue}
            </S.Value>
          )}

          <S.Icon isOpen={isOpen}>
            <AvailableIcons option="chevron" color={primaryColor} />
          </S.Icon>
        </S.ValueAndIcon>
      </S.SummaryContainer>

      {isOpen && (
        <>
          <S.Hr opacity={0.25} />

          <S.DetailsAndContacts>
            <S.Title color={`${textColor}80`} fontWeight="regular">
              {serviceTitle}
            </S.Title>

            <P color={`${textColor}40`} fontWeight="regular">
              {normalizedDate}
            </P>

            <S.ContactsAndValue>
              <S.Buttons>
                {contacts.map((item) => (
                  <S.IconButton
                    variant="solid"
                    key={item.option}
                    backgroundColor="#fafafa0d"
                  >
                    <S.AnchorTag
                      target="_blank"
                      href={item.href || ""}
                      rel="noopener noreferrer"
                    >
                      <AvailableIcons option={item.option as TIconOptions} />
                    </S.AnchorTag>
                  </S.IconButton>
                ))}
              </S.Buttons>

              {isOpen && (
                <P color={primaryColor} fontWeight="regular">
                  {normalizedValue}
                </P>
              )}
            </S.ContactsAndValue>
          </S.DetailsAndContacts>
        </>
      )}
    </S.Container>
  );
};

export default CollapsableOrder;
