import { ReactElement } from "react";

import * as S from "./styles";
import { Theme } from "../../../hooks";
import Switcher from "../../atoms/SwitchBase";
import EditIcon from "../../../components/icons/EditIcon";

interface IProps {
  label: string;
  title: string;
  icon: ReactElement;
  isPublished: boolean;
  onEditHandler: () => void;
  onPublishItem: (publish: boolean) => void;
}

const DraggableAppItem: React.FC<IProps> = ({
  icon,
  label,
  title,
  isPublished,
  onEditHandler,
  onPublishItem,
}) => {
  const { primaryColor, textColor } = Theme.useTheme();

  const switcherHandler = (isPublished: boolean) => {
    onPublishItem(!isPublished);
  };

  return (
    <S.Content>
      <S.Text>
        <S.ContentLabel color={isPublished ? textColor : `${textColor}80`}>
          <div className="icon">{icon}</div>

          {label}
        </S.ContentLabel>

        <S.Title color={isPublished ? textColor : `${textColor}80`}>
          {title}
        </S.Title>
      </S.Text>

      <S.Actions>
        <div className="editIcon" onClick={onEditHandler}>
          <EditIcon color={isPublished ? primaryColor : `${textColor}80`} />
        </div>

        <div className="switcher">
          <Switcher isOn={isPublished} setIsOn={switcherHandler} />
        </div>
      </S.Actions>
    </S.Content>
  );
};

export default DraggableAppItem;
