import AvailableIcons from "../../../components/atoms/AvailableIcons";
import { Theme } from "../../../hooks";
import InputBase from "../InputBase";

import * as S from "./styles";

interface IProps {
  searchTerm: string;
  onSearch: (search: string) => void;
}

const SearchInput: React.FC<IProps> = ({ searchTerm, onSearch }) => {
  const { textColor } = Theme.useTheme();
  return (
    <S.Container>
      <AvailableIcons option="searchOne" color={textColor} />
      <InputBase
        placeholder="Buscar produto ou marca"
        value={searchTerm}
        onChange={({ target }) => onSearch(target.value)}
      />
    </S.Container>
  );
};

export default SearchInput;
