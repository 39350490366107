import styled from "styled-components";
import Box from "../../atoms/Box";
import SeparatorLine from "../../atoms/SeparatorLine";
import P from "../../atoms/Typography/P";
import Small from "../../atoms/Typography/Small";

export const BoxVacancy = styled(Box)`
  gap: 0px;
`;

export const Line = styled(SeparatorLine)`
  display: block;
  margin: 1.25rem 0;
`;

export const Title = styled(P)`
  margin-bottom: 1.25rem;
  padding-top: 10px;
`;

export const Error = styled(Small)`
  display: flex;
  margin-top: 1rem;
  font-size: 0.75rem;
`;
