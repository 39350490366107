import styled from "styled-components";
import Box from "../../atoms/Box";
import P from "../../atoms/Typography/P";
import Small from "../../atoms/Typography/Small";

export const FormPriceBox = styled(Box)`
  margin-bottom: 20px;
`;

export const TotalValues = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const ValuesText = styled(P)`
  font-size: 0.875rem;
  margin-bottom: 20px;
`;

export const TotalValue = styled.div`
  gap: 20px;
  display: flex;
  align-items: center;

  &:last-of-type {
    margin-top: 8px;
  }

  p {
    font-size: 0.875rem;
  }

  .icon {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export const ErrorMessage = styled(Small)`
  font-size: 0.75rem;
`;
