import styled from "styled-components";
import P from "../../../../components/atoms/Typography/P";
import { Container, Wrapper } from "../styles";

import bgImage from "../../../../components/assets/image_bg.png";
import Box from "../../../../componentsV2/atoms/Box";
import Button from "../../../../componentsV2/atoms/Button";

export const InformationStep = styled(Wrapper)`
  display: flex;
  align-items: center;
  flex-direction: column;

  &::after {
    content: "";
    width: 140%;
    top: -550px;
    left: -175px;
    height: 140%;
    opacity: 0.7;
    cursor: none;
    position: absolute;
    filter: blur(100px);
    pointer-events: none;
    background: url(${bgImage});
    background-size: cover;
    background-position: top center;

    @media (max-width: 920px) {
      width: 120%;
      left: -40px;
      top: -250px;
      height: 120%;
      filter: blur(70px);
    }

    @media (max-width: 600px) {
      left: 40px;
      top: -150px;
      filter: blur(60px);
    }
  }
`;

export const RegisterFormHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    transform: scale(-1);
  }
`;

export const ProgressBar = styled.div`
  gap: 18px;
  display: flex;
  padding-top: 60px;
  flex-direction: column;
`;

export const Back = styled(Button)`
  color: #fafafa;
`;

export const Title = styled(P)`
  font-size: 1.5rem;
`;

export const FormSubtitle = styled(P)`
  margin-top: 1.5rem;
`;

export const InformationContainer = styled(Container)`
  width: 100%;
  flex-grow: 1;
  display: flex;
  max-width: 960px;
  flex-direction: column;

  .googleOrFacebook {
    gap: 12px;
    display: flex;
    flex-direction: column;
  }
`;

export const Form = styled(Box)`
  margin: 1rem 0 1.25rem;
`;

export const Text = styled(P)`
  align-self: center;
  padding: 1.25rem 0;
`;

export const GoogleOrFacebook = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  display: flex;
  margin-top: 60px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
`;
