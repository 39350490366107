import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import * as S from "./styles";
import { IYoutubeAppItem } from "../../types";
import { Youtube } from "../../../../services";
import Page from "../../../../components/atoms/Page";
import Button from "../../../../componentsV2/atoms/Button";
import appConfig from "../../../../config/app-youtube.json";
import H4 from "../../../../componentsV2/atoms/Typography/H4";
import RadioInput from "../../../../componentsV2/atoms/RadioInput";
import InputText from "../../../../componentsV2/molecules/InputText";
import SeparatorLine from "../../../../componentsV2/atoms/SeparatorLine";
import AppFormHeader from "../../../../componentsV2/organisms/AppFormHeader";
import { GA, Auth, Theme, NavBar, Loading, Snackbar } from "../../../../hooks";
import TitleDescription from "../../../../componentsV2/molecules/TitleDescription";
import ExternalLinkSkeleton from "../../../../components/atoms/ExternalLinkSkeleton";

const emptyItem: IYoutubeAppItem = {
  url: "",
  type: "video",
  createdAt: "",
  updatedAt: "",
  removed: false,
};

const Home: React.FC = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState<IYoutubeAppItem>(emptyItem);

  const navigate = useNavigate();

  const { token } = Auth.useAuth();
  const { sendEvents } = GA.useGA();
  const { showNavBar } = NavBar.useNavBar();
  const { primaryColor, textColor } = Theme.useTheme();
  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { hideLoading, showLoading } = Loading.useLoading();

  const options = { video: "Vídeo do Youtube", channel: "Canal do Youtube" };
  const optionsKeys = Object.keys(options);
  const optionsValues = Object.values(options);

  useEffect(() => {
    const run = async () => {
      try {
        setLoading(true);
        const data = id ? await Youtube.getContent(token, id) : null;

        const normalizeData: IYoutubeAppItem = data && {
          url: data.url,
          type: data.type,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          removed: data.removed,
        };

        data && setContent(normalizeData);
      } catch (error) {
        newError("Houve um erro ao obter os posts");
      } finally {
        setLoading(false);
      }
    };

    run();

    return () => showNavBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeTypeHandler = (index: number) => {
    setContent((curr) => {
      const newContent = { ...curr };
      newContent.type = optionsKeys[index] as IYoutubeAppItem["type"];

      return newContent;
    });
  };

  const onChangeUrlHandler = (value: string) => {
    setContent((curr) => {
      const newContent = { ...curr };
      newContent.url = value;

      return newContent;
    });
  };

  const onRemoveHandler = async () => {
    try {
      showLoading();
      if (id) await Youtube.deleteContent(token, id);

      newSuccess("Conteudo removido com sucesso");

      sendEvents({ name: "remove-sku", category: appConfig.id });

      navigate("/");
    } catch (error) {
      newError("Houve um erro ao remover o conteúdo");
    } finally {
      hideLoading();
    }
  };

  const onSaveHandler = async () => {
    try {
      showLoading();

      id
        ? await Youtube.updateContent(token, id, {
            url: content.url,
            type: content.type,
          })
        : await Youtube.createContent(token, {
            url: content.url,
            type: content.type,
          });

      newSuccess("Alterações salvas com sucesso");

      id
        ? sendEvents({ name: "edit-sku", category: appConfig.id })
        : sendEvents({ name: "new-sku", category: appConfig.id });
      navigate("/");
    } catch (error) {
      newError("Houve um erro ao salvar as alterações");
    } finally {
      hideLoading();
    }
  };

  const typeSelectedValue = content.type === "video" ? "vídeo" : "canal";

  const isEditingItem = !!id;

  return (
    <Page>
      <AppFormHeader
        appTitle={appConfig.title}
        onBack={() => navigate("/apps")}
      />

      <S.Text>
        <TitleDescription
          titleColor={textColor}
          textColor={`${textColor}80`}
          title="Adicione vídeos e canais do Youtube"
        >
          Compartilhe seus vídeos e canais do Youtube na sua Stanti e divulgue
          seu conteúdo para sua base de fãs.
        </TitleDescription>
      </S.Text>

      {loading ? (
        <ExternalLinkSkeleton />
      ) : (
        <>
          <S.BoxContainer>
            <H4 color={textColor}>Meu conteúdo do Youtube</H4>

            <RadioInput
              options={optionsValues}
              onSelect={(val) => onChangeTypeHandler(val)}
              selectedIndex={optionsKeys.indexOf(content.type) || 0}
            />

            <SeparatorLine opacity={0.25} />

            <InputText
              label="Link do Youtube"
              value={content?.url || ""}
              onChange={(val) => onChangeUrlHandler(val)}
              placeholder="Cole o link do vídeo/canal aqui..."
            />
          </S.BoxContainer>

          <S.Buttons>
            <Button
              variant="outline"
              textColor={primaryColor}
              borderColor={primaryColor}
              onClick={() => onSaveHandler()}
            >
              Publicar {typeSelectedValue}
            </Button>

            <Button
              variant="solid"
              textColor={textColor}
              onClick={() => onSaveHandler()}
            >
              Salvar como rascunho
            </Button>

            {isEditingItem && (
              <Button
                variant="default"
                textColor="#F94144"
                onClick={() => onRemoveHandler()}
              >
                Apagar {typeSelectedValue}
              </Button>
            )}
          </S.Buttons>
        </>
      )}
    </Page>
  );
};

export default Home;
