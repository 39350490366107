import { useLocation, useNavigate } from "react-router-dom";

import HomeIcon from "../../../components/icons/HomeIcon";
import PlusIcon from "../../../components/icons/PlusIcon";
import MoneyTwoIcon from "../../../components/icons/MoneyTwoIcon";
import ShowPasswordIcon from "../../../components/icons/ShowPasswordIcon";

import { Auth, Theme } from "../../../hooks";
import { Analytics } from "../../../services";
import ProfileImage from "../../atoms/ProfileImage";

import * as S from "./styles";

const NavBar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = Auth.useAuth();
  const { primaryColor, backgroundColor } = Theme.useTheme();

  const buttons = [
    {
      id: "home",
      label: "Home",
      path: "/",
      icon: (color?: string) => <HomeIcon color={color} />,
    },
    {
      id: "dash",
      path: "/dashboard",
      label: "Dashboard",
      icon: (color?: string) => <MoneyTwoIcon color={color} />,
    },
    {
      id: "apps",
      path: "/apps",
      label: "Adicionar",
      icon: (color?: string) => <PlusIcon color={color} />,
    },
    {
      id: "preview",
      path: "/preview",
      label: "Preview",
      icon: (color?: string) => <ShowPasswordIcon color={color} />,
    },
    {
      id: "profile",
      path: "/edit",
      label: "Meu perfil",
      icon: (imageUrl?: string) => (
        <ProfileImage size="small" imageUrl={imageUrl || ""} />
      ),
    },
  ];

  const isSelected = (button: typeof buttons[number]) => {
    if (location.pathname === "/") return button.path === location.pathname;

    return location.pathname.split("/")[1] === button.path?.substring(1);
  };

  const onClickHandler = (id: string, path: string) => {
    Analytics.submitClickEvent({
      meta: { button: id },
      creator: user.id || "",
      origin: document.referrer,
      creatorUsername: user.username || "",
      description: "click-on-navbar",
      actionDescription: `click-on-navbar-${id}`,
    });

    navigate(path);
  };

  const renderIcon = (
    button: typeof buttons[number],
    color?: string,
    imageUrl?: string
  ) => {
    if (button.id === "profile") {
      return button.icon(imageUrl);
    }

    return button.icon(isSelected(button) ? color : "#fafafa");
  };

  const renderActiveButton = (button: typeof buttons[number]) => {
    if (isSelected(button) && button.path === "/apps") {
      return <S.Apps backgroundColor={primaryColor} textColor={primaryColor} />;
    }

    return isSelected(button) ? (
      <S.Span backgroundColor={primaryColor} textColor={primaryColor} />
    ) : null;
  };

  return (
    <S.Container>
      <S.NavBar backgroundColor={backgroundColor}>
        {buttons.map((button) => (
          <S.Button
            key={button.id}
            textColor={primaryColor}
            backgroundColor={primaryColor}
            isSelected={isSelected(button)}
            isAppsPage={button.path === "/apps"}
            onClick={() => onClickHandler(button.id, button.path)}
          >
            {renderActiveButton(button)}

            <>{renderIcon(button, primaryColor, user?.profileImage)}</>
          </S.Button>
        ))}
      </S.NavBar>
    </S.Container>
  );
};

export default NavBar;
