import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth, GA, Snackbar, StantiPay, Theme } from "../../../../hooks";
import { IAppProduct, TIconOptions } from "../../../../types";

import ExternalLinkSkeleton from "../../../../components/atoms/ExternalLinkSkeleton";
import Page from "../../../../components/atoms/Page";
import SeparatorLine from "../../../../components/atoms/SeparatorLine";
import appConfig from "../../../../config/app-stanti-pay.json";
import StantiPayProduct from "../StantiPayProduct";
import * as S from "./styles";

import Button from "../../../../componentsV2/atoms/Button";
import H4 from "../../../../componentsV2/atoms/Typography/H4";
import Navigation from "../../../../componentsV2/molecules/Navigation";
import TitleDescription from "../../../../componentsV2/molecules/TitleDescription";
import AppFormHeader from "../../../../componentsV2/organisms/AppFormHeader";

const { REACT_APP_STOREFRONT_BASE_URL } = process.env;

const Home = () => {
  const [productsList, setProductsList] = useState<IAppProduct[]>([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);

  const navigate = useNavigate();
  const { sendEvents } = GA.useGA();
  const { getProducts } = StantiPay.useStantiPay();
  const { textColor, primaryColor, backgroundColor } = Theme.useTheme();
  const { user } = Auth.useAuth();
  const { newSuccess } = Snackbar.useSnackbar();

  const urlStantiPay = user.username + "/pay";

  useEffect(() => {
    const run = async () => {
      const products = await getProducts();

      setIsLoadingProducts(false);

      if (products) setProductsList(products);
    };

    run();
  }, [getProducts]);

  const onClickHandler = () => {
    sendEvents({ name: "new-sku", category: appConfig.id });
    navigate(`/apps/${appConfig.id}/new`);
  };

  const onItemClickHandler = (id: string) => {
    sendEvents({
      name: "edit-sku",
      category: appConfig.id,
      buttonLocation: `home-app-${appConfig.id}`,
    });
    navigate(`/apps/${appConfig.id}/${id}`);
  };

  const onCopyHandler = () => {
    navigator.clipboard.writeText(
      `${REACT_APP_STOREFRONT_BASE_URL}/${urlStantiPay}`
    );
    newSuccess("Link copiado");
  };

  return (
    <Page bgColor="#080808">
      <AppFormHeader
        appTitle={appConfig.title}
        onBack={() => navigate(`/apps`)}
      />

      <S.Head>
        <TitleDescription title="Centralize seus pagametos em um link único, tão simples quanto o Pix.">
          Para começar é muito fácil, cadastre seus produtos e serviços e envie
          seu link sempre que precisar realizar uma cobrança.
        </TitleDescription>
      </S.Head>

      <S.LinkToPay>
        <Navigation
          iconId="link"
          widthAheadIcon="14px"
          aheadIconId="copyIcon"
          textSecondColor={urlStantiPay}
          onClick={onCopyHandler}
          textFirstColor={`${REACT_APP_STOREFRONT_BASE_URL}/`}
        />
      </S.LinkToPay>

      <SeparatorLine />

      <S.MyProducts>
        {isLoadingProducts && <ExternalLinkSkeleton />}

        {productsList && productsList.length > 0 && (
          <>
            <H4 color={textColor}>Produtos/serviços cadastrados:</H4>
            <StantiPayProduct
              items={productsList}
              appAlias={appConfig.id}
              sectionTitle=""
              appTitle={appConfig?.title}
              appIcon={appConfig?.icon as TIconOptions}
              onItemClick={(id: string) => onItemClickHandler(id)}
            />
          </>
        )}
        <S.NewProduct>
          <Button
            variant="solid"
            backgroundColor={primaryColor}
            onClick={onClickHandler}
            textColor={backgroundColor}
          >
            Adicionar novo produto/serviço
          </Button>
        </S.NewProduct>
      </S.MyProducts>
    </Page>
  );
};

export default Home;
