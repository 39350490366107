import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth, Loading, Snackbar } from "../../../../hooks";
import { Analytics } from "../../../../services";
import { ICreatorCampaign } from "../../../../services/affiliates/types";

import Page from "../../../../components/atoms/Page";
import SearchInput from "../../../../componentsV2/atoms/SearchInput";
import TitleDescription from "../../../../componentsV2/molecules/TitleDescription";
import AppFormHeader from "../../../../componentsV2/organisms/AppFormHeader";
import appConfig from "../../../../config/app-afiliados.json";
import affiliates from "../../../../services/affiliates";
import CampaignsList from "../CampaignsList";

import * as S from "./styles";

const Home: React.FC = () => {
  const [search, setSearch] = useState("");
  const [campaigns, setCampaigns] = useState<ICreatorCampaign[]>([]);

  const navigate = useNavigate();
  const { token, user } = Auth.useAuth();
  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { hideLoading, showLoading, isLoading } = Loading.useLoading();

  const getCampaigns = useCallback(async () => {
    try {
      showLoading();
      const currentCampaigns = await affiliates.getCreatorCampaigns(token);

      setCampaigns(currentCampaigns);
    } catch (error) {
      newError("Houve um erro ao buscar as campanhas");
    } finally {
      hideLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onApplyForCampaign = useCallback(async (campaignId: string) => {
    try {
      showLoading();

      const currentCampaign = campaigns.find((c) => c.id === campaignId);

      if (currentCampaign) {
        Analytics.submitClickEvent({
          creator: user.id || "",
          origin: "home-app-afiliados",
          creatorUsername: user.username || "",
          description: `affiliation-request-${currentCampaign.brand}`,
          actionDescription: `affiliation-request-home-${currentCampaign.brand}`,
        });
      }

      await affiliates.applyForCampaign(campaignId, token);
      await getCampaigns();

      newSuccess("Recebemos sua solicitação! Entraremos em contato em breve");
    } catch (error) {
      newError("Houve um erro ao enviar sua solicitação");
      hideLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  useEffect(() => {
    Analytics.submitPageViewEvent({
      origin: "add-content",
      creator: user.id || "",
      creatorUsername: user.username || "",
      description: "home-app-afiliados",
      actionDescription: "home-app-afiliados",
    });
  }, [user.id, user.username]);

  return (
    <Page bgColor="#080808">
      <AppFormHeader
        appTitle={appConfig.title}
        onBack={() => navigate(`/apps`)}
      />

      <S.Container>
        <TitleDescription title="Torne-se embaixador da sua marca favorita e comece a divulgar seu link exclusivo.">
          Escolha uma das campanhas disponíveis, abaixo, e receba um link/cupom
          com descontos exclusivos para seus fãs.
        </TitleDescription>

        <SearchInput onSearch={setSearch} searchTerm={search} />
      </S.Container>

      <CampaignsList
        searchTerm={search}
        campaigns={campaigns}
        isLoading={isLoading}
        onApplyForCampaign={onApplyForCampaign}
      />
    </Page>
  );
};

export default Home;
