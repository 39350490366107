import styled, { css } from "styled-components";

export const PostsSlider = styled.div`
  display: none;

  @media (max-width: 500px) {
    gap: 16px;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    user-select: none;
    overflow-x: hidden;
    position: relative;
    align-items: center;
    flex-direction: column;
  }
`;

type ContainerProps = {
  paddingLeft: string;
  centralize: boolean;
  clearPadding: boolean;
  transformValue: number;
};

export const Container = styled.div<ContainerProps>`
  ${({ paddingLeft, centralize, clearPadding, transformValue }) => css`
    gap: 20px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    justify-content: ${centralize && "center"};
    padding-left: ${clearPadding ? paddingLeft : 0};
    transform: ${`translateX(-${transformValue}px)`};
  `}
`;

export const ImageBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BrandInfo = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
`;

type BrandLogoProps = {
  logo: string;
  borderColor: string;
};

export const BrandLogo = styled.div<BrandLogoProps>`
  ${({ logo, borderColor }) => css`
    width: 28px;
    height: 28px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: url(${logo});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid ${borderColor};
  `}
`;

export const PostsInfo = styled.div`
  gap: 20px;
  display: flex;
  align-items: center;
`;

export const Info = styled.div`
  gap: 4px;
  display: flex;
  align-items: center;

  svg {
    width: 12px;
    height: 12px;
  }
`;

type ImageProps = {
  width: string;
  image: string;
};

export const Image = styled.div<ImageProps>`
  ${({ width, image }) => css`
    display: flex;
    flex-shrink: 0;
    height: 300px;
    width: ${width};
    margin-top: 8px;
    margin-bottom: 12px;
    background: url(${image});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  `}
`;

export const Indicators = styled.div`
  gap: 8px;
  z-index: 100;
  display: flex;
  position: relative;
`;

type IndicatorProps = {
  isSelected: boolean;
  selectedColor: string;
  selectedWidth: string;
  notSelectedColor: string;
};

export const Indicator = styled.span<IndicatorProps>`
  ${({ isSelected, selectedColor, notSelectedColor, selectedWidth }) => css`
    height: 8px;
    cursor: pointer;
    border-radius: 6px;
    width: ${isSelected ? selectedWidth : "8px"};
    background-color: ${isSelected ? selectedColor : notSelectedColor};
  `}
`;
