import styled, { css } from "styled-components";

type SelectedImageProps = {
  image: string;
};

export const SelectedImage = styled.div<SelectedImageProps>`
  ${({ image }) => css`
    width: 88px;
    height: 88px;
    display: flex;
    border-radius: 8px;
    background: url(${image});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  `}
`;

export const BrandContainer = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
