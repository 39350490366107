import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const VimeoIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="50"
      height="40"
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.4128 7.10998C41.7684 4.42014 38.7356 3.65987 35.6728 4.13038C34.102 4.37527 28.799 6.74478 26.9922 12.4225C30.189 12.1722 31.8665 12.6548 31.5587 16.2175C31.4283 17.7105 30.6872 19.3437 29.8538 20.9105C28.8959 22.719 27.0939 26.2637 24.7353 23.7104C22.611 21.4068 22.774 17.0089 22.2902 14.0766C22.0161 12.4278 21.7299 10.3822 21.1971 8.69111C20.7421 7.2338 19.6897 5.48221 18.4025 5.09843C17.0219 4.69199 15.3156 5.33257 14.3148 5.9319C11.198 7.7822 8.86324 10.3314 6.18723 12.5291C5.84954 12.8727 6.0495 13.1613 6.16319 13.417C6.54751 14.0186 6.69599 14.3837 7.42883 14.491C9.33137 14.7735 11.1406 12.6955 12.4013 14.8615C13.1725 16.1797 13.4131 17.6255 13.9052 19.0456C14.5602 20.9482 15.0689 23.0125 15.6077 25.1958C16.5214 28.8854 17.636 34.401 20.7911 35.7581C22.3979 36.4453 24.8141 35.5241 26.0366 34.7895C29.3534 32.8007 32.0509 30.0079 34.1498 26.9853C39.612 19.1247 42.2307 11.2265 42.5155 9.72208C42.718 8.63173 42.5852 7.73189 42.4128 7.10998Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default VimeoIcon;
