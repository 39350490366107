import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 20px;
  border-radius: 12px;
  background-color: #fafafa0a;

  svg {
    width: 16px;
    height: 16px;
  }

  input {
    width: 100%;
  }
`;
