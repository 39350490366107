import { useState } from "react";
import { Creator } from "../../services";
import { useNavigate } from "react-router-dom";
import { Loading, Snackbar, Theme } from "../../hooks";

import Link from "../../components/atoms/Link";
import Page from "../../components/atoms/Page";
import Box from "../../componentsV2/atoms/Box";
import Chevron from "../../components/icons/Chevron";
import Button from "../../componentsV2/atoms/Button";
import InputText from "../../componentsV2/molecules/InputText";

import * as S from "./styles";

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [message, setMessage] = useState("");

  const { primaryColor, textColor } = Theme.useTheme();
  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();

  const onResetHandler = async () => {
    if (!user) return;

    showLoading();
    try {
      const email = await Creator.sendEmailToResetPassword(user);

      setMessage(`E-mail enviado para ${email}!\nCheque sua caixa de entrada.`);
      newSuccess("E-mail enviado com sucesso!");
    } catch (error) {
      if (error && (error as { message: string }).message === "CREA001") {
        setMessage("Usuário/e-mail não encontrado");
        newError("Usuário não encontrado");
      } else {
        newError("Houve um erro ao redefinir a senha");
      }
    } finally {
      hideLoading();
    }
  };

  return (
    <Page>
      <S.Container>
        <S.RegisterFormHeader>
          <S.Back
            variant="icon"
            color="#fafafa05"
            onClick={() => navigate("/")}
          >
            <Chevron color="#fafafa" />
          </S.Back>
        </S.RegisterFormHeader>

        <S.TextContainer>
          <S.Title color={primaryColor}>
            Esqueceu?
            <br />
            Acontece :)
          </S.Title>

          <S.Subtitle color="#fafafa">
            Vamos te ajudar a criar uma nova senha.
          </S.Subtitle>
        </S.TextContainer>

        <S.Form>
          <Box>
            <InputText
              value={user}
              onChange={(val) => {
                setUser(val);
                if (message) setMessage("");
              }}
              label="E-mail ou username"
              placeholder="Digite seu e-mail ou nome de usuário..."
            />
          </Box>

          <Button
            variant="outline"
            borderColor={primaryColor}
            textColor={primaryColor}
            onClick={onResetHandler}
            disabled={!user || user.length <= 3 || message.length > 1}
          >
            Solicitar redifinição de senha
          </Button>

          <S.Message color={primaryColor}>{message}</S.Message>

          <S.RegisterFormFooter>
            <S.LinkText color={textColor}>
              Ainda não possui uma conta? <br />
              <Link to="/register"> Clique aqui para criar gratuitamente.</Link>
            </S.LinkText>
          </S.RegisterFormFooter>
        </S.Form>
      </S.Container>
    </Page>
  );
};

export default ForgotPassword;
