import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const TumblrIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="24"
      height="37"
      viewBox="0 0 24 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3868 28.1121C23.1568 27.988 22.8767 28.0001 22.6583 28.1438C21.0047 29.2307 19.3283 29.7819 17.6753 29.7819C16.787 29.7819 16.0238 29.5835 15.3391 29.1735C14.8738 28.898 14.4415 28.4163 14.2865 27.9977C14.1361 27.5996 14.1378 26.528 14.1401 25.0451L14.1408 16.0024H21.6539C22.0469 16.0024 22.3654 15.6838 22.3654 15.2909V8.93717C22.3654 8.54426 22.0469 8.22564 21.6539 8.22564H14.1408V0.711536C14.1408 0.318626 13.8223 0 13.4292 0H8.36794C8.00819 0 7.70508 0.268534 7.66153 0.625725C7.45006 2.366 7.05559 3.81099 6.48792 4.92283C5.93435 6.0186 5.18539 6.9722 4.26068 7.7583C3.51982 8.39015 2.25158 9.0015 0.49096 9.57542C0.198092 9.67091 0 9.94385 0 10.2519V15.291C0 15.684 0.318483 16.0026 0.711535 16.0026H4.83175V27.7665C4.83175 29.5 5.01562 30.8044 5.39415 31.7548C5.7798 32.7204 6.46444 33.6296 7.42957 34.4577C8.3789 35.2711 9.53429 35.9074 10.8674 36.3501C12.1599 36.7716 13.1892 37 14.9247 37C16.3919 37 17.7904 36.8479 19.082 36.5476C20.3515 36.2513 21.7882 35.7278 23.3523 34.9916C23.6018 34.8742 23.7609 34.6235 23.7609 34.3478V28.7385C23.7606 28.4768 23.6171 28.2363 23.3868 28.1121Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default TumblrIcon;
