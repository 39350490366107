import { useEffect, useState } from "react";
import { Creator } from "../../../../services";
import { useLocation, useNavigate } from "react-router-dom";
import { Loading, Snackbar, Theme } from "../../../../hooks";

import SuccessfulChange from "../SuccessfulChange";
import Box from "../../../../componentsV2/atoms/Box";
import Page from "../../../../components/atoms/Page";
import Link from "../../../../components/atoms/Link";
import Button from "../../../../componentsV2/atoms/Button";
import Chevron from "../../../../components/icons/Chevron";
import InputText from "../../../../componentsV2/molecules/InputText";
import SeparatorLine from "../../../../componentsV2/atoms/SeparatorLine";

import * as NewPasswordStyles from "./styles";
import * as ForgotPasswordStyles from "../../styles";
const S = {
  ...NewPasswordStyles,
  ...ForgotPasswordStyles,
};

const NewPassword: React.FC = () => {
  const [userData, setUserData] = useState<{
    name: string;
    username: string;
    profileImage: string;
  } | null>(null);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const { primaryColor, textColor } = Theme.useTheme();

  const navigate = useNavigate();
  const { newError } = Snackbar.useSnackbar();
  const { hideLoading, showLoading } = Loading.useLoading();

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const resetToken = urlParams.get("reset_token");

  useEffect(() => {
    if (!resetToken) navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onResetPasswordHandler = async () => {
    if (!password || !repeatPassword || !resetToken) return;

    if (password !== repeatPassword) return newError("As senhas não coincidem");

    showLoading();

    try {
      const { name, username, profileImage } = await Creator.resetPassword(
        resetToken,
        password
      );

      setUserData({ name, profileImage, username });
    } catch (error) {
      if (
        error &&
        ["LGOT003", "TOKE001"].includes((error as { message: string }).message)
      ) {
        newError("Expirou! Clique em esqueci minha senha e comece novamente");
      } else {
        newError("Houve um erro ao redefinir a senha");
      }
    } finally {
      hideLoading();
    }
  };

  if (userData)
    return (
      <SuccessfulChange
        username={userData.username}
        profileImage={userData.profileImage || ""}
      />
    );

  return (
    <Page>
      <S.Container>
        <S.RegisterFormHeader>
          <S.Back
            variant="icon"
            color="#fafafa05"
            onClick={() => navigate("/login")}
          >
            <Chevron color="#fafafa" />
          </S.Back>
        </S.RegisterFormHeader>

        <S.TextContainer>
          <S.Title color={primaryColor}>
            Esqueceu?
            <br />
            Acontece :)
          </S.Title>

          <S.Subtitle color="#fafafa">
            Vamos te ajudar a criar uma nova senha.
          </S.Subtitle>
        </S.TextContainer>

        <S.Form>
          <Box>
            <InputText
              type="password"
              value={password}
              onChange={setPassword}
              label="Digite uma nova senha"
              placeholder="Digite sua nova senha"
            />

            <SeparatorLine opacity={0.2} />

            <InputText
              type="password"
              value={repeatPassword}
              onChange={setRepeatPassword}
              placeholder="Confirmar a nova senha"
              label="Repetir senha"
            />
          </Box>

          <Button
            variant="outline"
            borderColor={primaryColor}
            textColor={primaryColor}
            onClick={onResetPasswordHandler}
            disabled={!password || !(password === repeatPassword)}
          >
            Salvar nova senha
          </Button>

          <S.RegisterFormFooter>
            <S.LinkText color={textColor}>
              Ainda não possui uma conta? <br />
              <Link to="/register"> Clique aqui para criar gratuitamente.</Link>
            </S.LinkText>
          </S.RegisterFormFooter>
        </S.Form>
      </S.Container>
    </Page>
  );
};

export default NewPassword;
