import * as S from "./styles";
import { Auth, Theme } from "../../hooks";
import { Analytics } from "../../services";
import { useNavigate } from "react-router-dom";
import CustomizeAppList from "./components/CustomizeAppList";
import MonetizedAppList from "./components/MonetizedAppList";
import Navigation from "../../componentsV2/molecules/Navigation";
import AvailableIcons from "../../components/atoms/AvailableIcons";
import TitleDescription from "../../componentsV2/molecules/TitleDescription";

const StantiPlace: React.FC = () => {
  const { user } = Auth.useAuth();
  const navigate = useNavigate();
  const { primaryColor, backgroundColor, textColor } = Theme.useTheme();

  const onClickHandler = (app: string, route: string) => {
    Analytics.submitClickEvent({
      description: app,
      origin: "add-content",
      creator: user.id || "",
      actionDescription: `app-${app}`,
      creatorUsername: user.username || "",
    });

    navigate(`/apps/${route}`);
  };

  return (
    <S.StantiApps backgroundColor={backgroundColor}>
      <S.AppSectionContainer>
        <S.Icon borderColor={primaryColor}>
          <AvailableIcons option="link" color={primaryColor} />
        </S.Icon>

        <TitleDescription
          centralize={true}
          titleColor={textColor}
          textColor={`${textColor}80`}
          title="Adicione quantos links você quiser"
        >
          Compartilhe todos os seus links em um só lugar
        </TitleDescription>

        <S.Links>
          <Navigation
            iconId="link"
            textFirstColor="Adicione um link"
            onClick={() => onClickHandler("link-externo", "link-externo")}
          />

          <Navigation
            iconId="socialMedia"
            textFirstColor="Adicione suas redes sociais"
            onClick={() => onClickHandler("redes-sociais", "redes-sociais")}
          />
        </S.Links>
      </S.AppSectionContainer>

      <S.AppSectionContainer>
        <S.Icon borderColor={primaryColor}>
          <AvailableIcons option="moneyTwo" color={primaryColor} />
        </S.Icon>

        <TitleDescription
          centralize={true}
          titleColor={textColor}
          textColor={`${textColor}80`}
          title="Ganhe dinheiro vendendo na Stanti"
        >
          Venda serviços e produtos e escale o seu negócio
        </TitleDescription>

        <MonetizedAppList onClick={onClickHandler} />
      </S.AppSectionContainer>

      <S.AppSectionContainer>
        <S.Icon borderColor={primaryColor}>
          <AvailableIcons option="camera" color={primaryColor} />
        </S.Icon>

        <TitleDescription
          centralize={true}
          titleColor={textColor}
          textColor={`${textColor}80`}
          title="Dê personalidade ao seu link"
        >
          Conecte suas redes sociais e deixe o seu link com a sua cara
        </TitleDescription>

        <CustomizeAppList onClick={onClickHandler} />
      </S.AppSectionContainer>
    </S.StantiApps>
  );
};

export default StantiPlace;
