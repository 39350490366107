import styled, { css } from "styled-components";
import StantiIcon from "../../../components/icons/StantiIcon";

type ImagePreviewProps = {
  imageUrl: string;
};

export const ImagePreview = styled.div<ImagePreviewProps>`
  ${({ imageUrl }) => css`
    width: 90px;
    height: 90px;
    display: flex;
    position: relative;
    border-radius: 18px;
    align-items: center;
    background-size: cover;
    justify-content: center;
    background-position: center;
    background-color: #fafafa0d;
    background-image: url(${imageUrl});
  `}
`;

export const Icon = styled.div`
  width: 40px;
  height: 40px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

type UploadImageProps = {
  backgroundColor: string;
};

export const UploadImage = styled.div<UploadImageProps>`
  right: -20px;
  width: 32px;
  height: 32px;
  display: flex;
  cursor: pointer;
  position: absolute;
  border-radius: 50%;
  align-items: center;
  top: calc(50% - 16px);
  justify-content: center;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.2);
  background-color: ${({ backgroundColor }) => backgroundColor};

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const StantiIconWithMargin = styled(StantiIcon)`
  margin-right: -8px;
`;
