import styled, { css } from "styled-components";

import P from "../../../../../../../../componentsV2/atoms/Typography/P";

export const Container = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  padding: 20px 0;
  flex-direction: column;
`;

export const Text = styled.div`
  gap: 8px;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const City = styled(P)`
  display: flex;
  flex-shrink: 0;
  flex-wrap: nowrap;

  @media (max-width: 600px) {
    max-width: 250px;
  }

  @media (max-width: 300px) {
    max-width: 160px;
  }
`;

export const SeeMoreBox = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  padding-top: 8px;
  user-select: none;
  justify-content: flex-end;
`;

export const SeeMore = styled(P)`
  text-decoration: underline;
`;

type PointsProps = {
  color: string;
};

export const Points = styled.div<PointsProps>`
  ${({ color }) => css`
    width: 100%;
    display: flex;
    border-bottom: 1px dashed ${color};
  `}
`;
