import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Header from "./components/Header";
import { Auth } from "../../hooks";
import { Analytics } from "../../services";
import Page from "../../components/atoms/Page";
import Radio from "../../componentsV2/atoms/RadioMenu";
import YourAppointments from "./components/YourAppointments";
import WorkAndLunchHours from "./components/WorkAndLunchHours";
import CreatorImageBackground from "../../components/atoms/CreatorImageBackground";

const Calendar: React.FC = () => {
  const [optionSelected, setOptionSelected] = useState<number>(0);

  const navigate = useNavigate();
  const { user } = Auth.useAuth();

  useEffect(() => {
    Analytics.submitPageViewEvent({
      origin: "calendar",
      creator: user.id || "",
      creatorUsername: user.username || "",
      description: "agenda-and-appointments",
      actionDescription: "agenda-and-appointments",
    });
  }, [user.id, user.username]);

  const onClickBack = () => {
    navigate("/");
  };

  return (
    <Page>
      <CreatorImageBackground />

      <Header onClickBack={() => onClickBack()} />

      <div style={{ marginTop: "40px" }}>
        <Radio
          protectionSize="single"
          selectedIndex={optionSelected}
          onSelect={(val) => setOptionSelected(val)}
          values={["Agendamentos", "Configurações"]}
        />
      </div>

      {optionSelected === 0 ? <YourAppointments /> : <WorkAndLunchHours />}
    </Page>
  );
};

export default Calendar;
