import { useEffect, useState } from "react";

import Chevron from "../../../../components/icons/Chevron";
import Button from "../../../../componentsV2/atoms/Button";
import ProgressBar from "../../../../componentsV2/atoms/ProgressBar";
import SeparatorLine from "../../../../componentsV2/atoms/SeparatorLine";
import SocialLoginButton from "../../../../componentsV2/atoms/SocialLoginButton";
import InputText from "../../../../componentsV2/molecules/InputText";
import { LOCAL_STORAGE_INFO_STEP_DURATION } from "../../../../constants";
import { GA, Snackbar, Theme } from "../../../../hooks";
import { maskPhoneNumber } from "../../../../utils/numbers";

import * as S from "./styles";

export interface IInformationStepForm {
  name: string;
  email: string;
  phone: string;
  password: string;
}

interface IProps {
  username: string;
  onNext: () => void;
  onBack: () => void;
  isUsernameAvailable: boolean;
  onBlurUsernameInput: () => void;
  onRegisterWithGoogle: () => void;
  onRegisterWithFacebook: () => void;
  onChangeEmail: (val: string) => void;
  informationStepForm: IInformationStepForm;
  onChangeUsername: (value: string) => void;
  emailFieldStatus: "loading" | "error" | "success" | "";
  informationStepFormHandler: (key: string, val: string) => void;
}

const { REACT_APP_STOREFRONT_BASE_URL } = process.env;

const InformationStep: React.FC<IProps> = ({
  onNext,
  onBack,
  username,
  onChangeEmail,
  onChangeUsername,
  emailFieldStatus,
  isUsernameAvailable,
  onBlurUsernameInput,
  onRegisterWithGoogle,
  onRegisterWithFacebook,
  informationStepFormHandler,
  informationStepForm: { email, name, password, phone },
}) => {
  const [pageLoadedTime, setPageLoadedTime] = useState<number>(0);
  const [showStepTwo, setShowStepTwo] = useState(false);

  const isNameValid = name && name.length > 2;
  const isPasswordValid = password && password.length > 5;
  const isCellphoneValid = phone && phone.length >= 11;

  const isReady =
    isUsernameAvailable &&
    isNameValid &&
    isPasswordValid &&
    isCellphoneValid &&
    emailFieldStatus === "success";

  const { sendEvents } = GA.useGA();
  const { textColor } = Theme.useTheme();
  const { newError } = Snackbar.useSnackbar();

  useEffect(() => {
    setPageLoadedTime(new Date().getTime());
  }, []);

  const onNextHandler = () => {
    try {
      informationStepFormHandler("name", name.trim());
      informationStepFormHandler("email", email.trim());
      informationStepFormHandler("phone", phone.trim());

      const stepConcludedTime = new Date();

      const stepDurationInSeconds =
        (stepConcludedTime.getTime() - pageLoadedTime) / 1000;

      localStorage.setItem(
        LOCAL_STORAGE_INFO_STEP_DURATION,
        stepDurationInSeconds.toString()
      );

      sendEvents({
        name: "Lead",
        category: "form",
        buttonLocation: "register-information-step",
      });

      window.fbq("track", "Lead");
      onNext();
    } catch (error) {
      newError("Houve um erro ao cadastrar seu perfil");
    }
  };

  useEffect(() => {
    if (!!username && !!name && !!email) setShowStepTwo(true);
  }, [email, name, username]);

  const progress =
    ([username, name, email, phone, password].filter((i) => !!i).length / 30 +
      1 / 6) *
    100;

  return (
    <S.InformationStep>
      <S.InformationContainer>
        <S.RegisterFormHeader>
          <S.Back variant="icon" color="#fafafa05" onClick={onBack}>
            <Chevron color="#fafafa" />
          </S.Back>
        </S.RegisterFormHeader>

        <S.ProgressBar>
          <S.Title color={textColor}>Criando sua Stanti</S.Title>

          <ProgressBar progress={progress} />
        </S.ProgressBar>

        <S.FormSubtitle color="#fafafa">Preencha seus dados:</S.FormSubtitle>
        <S.Form>
          <InputText
            value={username}
            label="Nome de usuário"
            onChange={onChangeUsername}
            onBlur={onBlurUsernameInput}
            status={isUsernameAvailable ? "success" : ""}
            baseURL={`${REACT_APP_STOREFRONT_BASE_URL}/`}
          />

          <SeparatorLine color="#fafafa26" />

          <InputText
            value={name}
            label="Nome completo"
            placeholder="Nome completo"
            status={isNameValid ? "success" : ""}
            onChange={(val) => informationStepFormHandler("name", val)}
          />

          <SeparatorLine color="#fafafa26" />

          <InputText
            value={email}
            label="E-mail"
            placeholder="E-mail"
            status={emailFieldStatus}
            onChange={(val) => onChangeEmail(val)}
          />

          {showStepTwo ? (
            <>
              <InputText
                type="password"
                label="Senha"
                value={password}
                placeholder="Digite uma senha"
                status={isPasswordValid ? "success" : ""}
                onChange={(val) => informationStepFormHandler("password", val)}
              />

              <SeparatorLine color="#fafafa26" />

              <InputText
                type="tel"
                label="Celular (Whatsapp)"
                pattern="[0-9]*"
                inputMode="numeric"
                placeholder="Celular"
                value={maskPhoneNumber(phone)}
                status={isCellphoneValid ? "success" : ""}
                onChange={(val) =>
                  informationStepFormHandler(
                    "phone",
                    (val || "").replace(/[^0-9]/g, "")
                  )
                }
              />
            </>
          ) : null}
        </S.Form>

        {showStepTwo ? (
          <Button
            variant="outline"
            borderColor="#ddff66"
            textColor="#ddff66"
            onClick={() => onNextHandler()}
            disabled={!isReady}
          >
            Avançar
          </Button>
        ) : null}

        <S.Text color="#fafafa">ou</S.Text>

        <S.GoogleOrFacebook>
          <SocialLoginButton
            socialMedia="Google"
            onClick={onRegisterWithGoogle}
          />

          <SocialLoginButton
            socialMedia="Facebook"
            onClick={onRegisterWithFacebook}
          />
        </S.GoogleOrFacebook>
      </S.InformationContainer>
    </S.InformationStep>
  );
};

export default InformationStep;
