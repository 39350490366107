import styled from "styled-components";
import Label from "../../../components/atoms/Typography/Label";
import P from "../../atoms/Typography/P";

export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  padding: 1rem 1.5rem;
  flex-shrink: 100;
  position: relative;
  border-radius: 12px;
  justify-content: space-between;
  background-color: #fafafa05;
`;

export const Text = styled.div`
  gap: 0.5rem;
  flex-grow: 0;
  display: flex;
  padding: 4px 0;
  margin-top: 4px;
  overflow: hidden;
  flex-direction: column;

  .icon {
    width: 12px;
    height: 12px;
    margin-top: -2px;
    margin-right: 8px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export const ContentLabel = styled(Label)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.5rem;
`;

export const Title = styled(P)`
  font-size: 0.75rem;
`;

export const Actions = styled.div`
  gap: 10px;
  display: flex;

  .editIcon {
    width: 12px;
    height: 12px;
    display: flex;
    cursor: pointer;
    align-self: center;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .switcher {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
  }
`;
